import { Tooltip, message } from "antd";
import Button from "antd/lib/button";
import React, { useState } from "react";
import { auditService } from "shared/services/AuditService";

export interface ExtractLogsButtonProps {
  entityName: string;
  entityIds: number[];
  hasPermission?: boolean;
}
export const ExtractLogsButton = ({
  entityName,
  entityIds,
  hasPermission,
}: ExtractLogsButtonProps) => {
  const [logLoading, setLogLoading] = useState(false);

  const createPreSignedDowload = (url: string) => {
    Object.assign(document.createElement("a"), {
      href: url,
    }).click();
  };

  const handleGetLogs = () => {
    if (entityIds.length) {
      setLogLoading(true);
      auditService
        .getEntityLogs({ entityName, entityIds })
        .then((response) => {
          createPreSignedDowload(response.presignedUrl);
        })
        .catch(() => message.error("Não foi possivel criar o arquivo de logs"))
        .finally(() => {
          setLogLoading(false);
        });
    }
  };

  const isDisabledButton = !entityIds.length;

  return hasPermission ? (
    <Tooltip
      title={isDisabledButton ? "Selecione pelo menos um item da listagem" : ""}
    >
      <Button
        id="btn-extract-logs"
        className="pix-action-button"
        disabled={isDisabledButton}
        loading={logLoading}
        onClick={handleGetLogs}
      >
        {logLoading ? "Extraindo Logs" : "Extrair Logs"}
      </Button>
    </Tooltip>
  ) : (
    <></>
  );
};
