import { Form, message } from "antd";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemTextArea } from "components/FormItem/FormItemTextArea";
import { Input } from "components/Input";
import { InputClickToCount } from "components/InputClickToCount/InputClickToCount";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { SimilarityDisplay } from "components/SimilarityDisplay/SimilarityDisplay";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { SearchBrandsSelect } from "containers/SearchBrandsSelect/SearchBrandsSelect";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { CountStatusEnum } from "shared/enum/CountStatusEnum";
import {
  SubBrandCreate,
  SubBrandListItem,
  SubBrandUpdate,
} from "shared/models/SubBrand";
import { subBrandService } from "shared/services/SubBrandService";
import { utcToPtBrDate } from "util/date.util";
import styles from "./SubBrandEdit.module.scss";

type SubBrandEditProps = {
  subBrandId: number;
  initialBrandId?: never;
};

type SubBrandCreationProps = {
  subBrandId?: never;
  initialBrandId?: number;
};

type SubBrandProps = {
  afterCancel?: () => void;
  afterSave?: (newSubBrand?: SubBrandListItem) => void;
} & (SubBrandEditProps | SubBrandCreationProps);

export const SubBrandEdit = ({
  subBrandId,
  initialBrandId,
  afterCancel,
  afterSave,
}: SubBrandProps) => {
  const [form] = Form.useForm();

  const [totalProducts, setTotalProducts] = useState<number>();
  const [
    statusCountingTotalProducts,
    setStatusCountingTotalProducts,
  ] = useState<CountStatusEnum>(CountStatusEnum.LOADING_STATUS);
  const [isFormValid, setFormValid] = useState(false);

  // Form state values
  const [sanitationValue, setSanitationValue] = useState<string>();
  const [selectedBrandId, setSelectedBrandId] = useState<number>();
  const [hiddenLastModfied, setHiddenLastModfied] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [similar, setSimilar] = useState<string[]>([]);
  const [loadingSimilar, setLoadingSimilar] = useState(false);

  // Handle edition initial state
  useEffect(() => {
    if (subBrandId) {
      trackPromise(subBrandService.findById(subBrandId))
        .then((subBrand) => {
          form.setFieldsValue({ ...subBrand });
          setSanitationValue(subBrand.subBrandSanitation?.value);
          setSelectedBrandId(subBrand.brandId);
          setDeleted(subBrand.deleted);
          setHiddenLastModfied(false);
          setFormValid(true);
        })
        .catch(() => message.error("Erro ao buscar detalhe da sub marca"));

      subBrandService
        .findTotalProductsBySubBrandId(subBrandId)
        .then((totalProducts) => {
          setTotalProducts(totalProducts);
          setStatusCountingTotalProducts(CountStatusEnum.COMPLETED);
        })
        .catch(() =>
          setStatusCountingTotalProducts(CountStatusEnum.ERROR_STATUS)
        );
    }
  }, [subBrandId, form]);

  // Handle creation initial state
  useEffect(() => {
    if (initialBrandId) {
      form.setFieldsValue({ ...form.getFieldsValue });
      setSelectedBrandId(initialBrandId);
    }
  }, [initialBrandId, form]);

  // Handle creation initial state withou brandId
  useEffect(() => {
    if (!initialBrandId && !subBrandId) {
      form.resetFields();
      setSelectedBrandId(undefined);
      setHiddenLastModfied(true);
    }
  }, [initialBrandId, subBrandId, form]);

  const onFinish = (formValues: any) => {
    //Edit Sub Brand
    if (subBrandId) {
      const updatedSubBrand: SubBrandUpdate = {
        ...formValues,
        subBrandId: subBrandId,
        subBrandSanitation: sanitationValue,
        brandId: selectedBrandId,
        deleted: deleted,
      };

      trackPromise(subBrandService.update(updatedSubBrand))
        .then((newSubBrand) => {
          afterSave?.({ ...newSubBrand } as SubBrandListItem);
          message.success("Sub marca atualizada com sucesso!");
        })
        .catch(() => message.error("Erro ao atualizar a Sub Marca!"));
    }
    // Create new Sub brand
    else {
      const newSubBrand: SubBrandCreate = {
        ...formValues,
        subBrandSanitation: sanitationValue,
        brandId: selectedBrandId,
        deleted: deleted,
      };

      trackPromise(subBrandService.create(newSubBrand))
        .then((newSubBrand) => {
          afterSave?.({ ...newSubBrand } as SubBrandListItem);
          message.success("Sub marca criada com sucesso!");
        })
        .catch(() => message.error("Erro ao criar Sub Marca!"));
    }
  };

  const onCancelForm = () => {
    afterCancel?.();
  };

  const handleFormValid = (brandId?: number) => {
    setFormValid(!!brandId && !!form.getFieldValue("subBrandName"));
  };

  const onChangeBrand = (brandId: number) => {
    form.setFieldsValue({
      brandId: brandId,
      ...form.getFieldsValue,
    });
    setSelectedBrandId(brandId);
    handleFormValid(brandId);
  };

  const findSimilarSubBrands = (value: string) => {
    setLoadingSimilar(true);
    subBrandService
      .findAll(0, { term: value }, 10)
      .then((res) => {
        setSimilar(
          res.content.map((sb) => `${sb.brandName} > ${sb.subBrandName}`)
        );
      })
      .catch(() => message.error("Erro ao buscar Marcas similares"))
      .finally(() => setLoadingSimilar(false));
  };

  const onBlurName = (value: string) => {
    value ? findSimilarSubBrands(value) : setSimilar([]);
  };

  return (
    <div className={styles.wrapper}>
      <Form form={form} onFinish={onFinish}>
        <FormItemText
          label="Submarca"
          name="subBrandName"
          id="subBrandName"
          onChange={() => {
            handleFormValid(selectedBrandId);
          }}
          onBlur={(evt) => onBlurName(evt.target.value)}
        />
        <div style={{ marginBottom: "3%" }}>
          <SimilarityDisplay
            values={similar}
            loading={loadingSimilar}
            entityNameToDisplay="Sub marcas"
          />
        </div>

        <SearchBrandsSelect
          onChange={(brandId) => {
            onChangeBrand(brandId);
          }}
          parentName="sub-brand-brand-filter"
          brandId={selectedBrandId}
          initialBrandId={initialBrandId ?? form.getFieldValue("brandId")}
        />

        <FormItemTextArea
          label="Comentários"
          name="subBrandComment"
          id="subBrandComment"
        />

        <FormItemText label="Site" name="subBrandSite" id="subBrandSite" />

        {!hiddenLastModfied && (
          <Input
            name="lastModified"
            id="line-last-modified"
            label="Última atualização"
            placeholder="Última atualização"
            value={utcToPtBrDate(form.getFieldValue("lastModified"))}
            disabled
          />
        )}

        <SanitationSelect
          label="Saneamento"
          value={sanitationValue}
          onChange={(value) => {
            setSanitationValue(value);
          }}
        />

        {!!subBrandId && (
          <>
            <InputClickToCount
              countStatus={statusCountingTotalProducts}
              entity="Produtos Associados"
              total={totalProducts}
              link={`/produto?productFilters=${encodeURI(
                JSON.stringify({ subBrandId })
              )}`}
              linkText="Ir para produtos"
              linkParamsName="productFilters"
            />
            <div style={{ marginTop: "15px" }}>
              <YehSwitch
                checkedChildren="Ativo"
                unCheckedChildren="Inativo"
                checked={!deleted}
                onChange={(f) => setDeleted(!f)}
              />
            </div>
          </>
        )}
        <CancelAndSave onCancel={onCancelForm} disabled={!isFormValid} />
      </Form>
    </div>
  );
};
