import { Alert, Button, Col, Input, List, Row, Tag, message } from "antd";
import { Icons } from "components/Icons";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { PixTaxonomySelect } from "containers/PixTaxonomySelect/PixTaxonomySelect";
import {
  getFullTaxonomyName,
  getSegmentName,
} from "containers/TaxonomyTab/TaxonomyList";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductLine } from "shared/models/ProductLine";
import {
  ProductLineTaxonomy as ProductLineTaxonomyT,
  ProductLineTaxonomyFilter,
} from "shared/models/ProductLineTaxonomy";
import { BUSegment } from "shared/models/Taxonomy";
import { productLineTaxonomyService } from "shared/services/ProductLineTaxonomyService";
import {
  filterValidSegments,
  sortSegments,
  taxonomyService,
} from "shared/services/TaxonomyService";
import styles from "../../../containers/TaxonomyTab/TaxonomyTab.module.scss";

type ProductLineTaxonomyProps = {
  productLine: ProductLine;
};

export const ProductLineTaxonomy = ({
  productLine,
}: ProductLineTaxonomyProps) => {
  const [productLineTaxonomies, setProductLineTaxonomies] = useState<
    ProductLineTaxonomyT[]
  >([]);
  const [last, setLast] = useState(false);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<ProductLineTaxonomyFilter>({
    productLineId: productLine.id,
    deleted: false,
  });
  const [segments, setSegments] = useState<BUSegment[]>([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<BUSegment>();
  const [term, setTerm] = useState<string>();

  const findProductLineTaxonomy = useCallback(
    (filter: ProductLineTaxonomyFilter, page: number) => {
      trackPromise(productLineTaxonomyService.getProductLines(page, filter))
        .then(({ data: { content, last, page } }) => {
          setProductLineTaxonomies((old) =>
            page === 0 ? [...content] : [...old, ...content]
          );
          setFilter(filter);
          setLast(last);
          setPage(page);
        })
        .catch(() => {
          message.error("Erro ao buscar relações de Taxonomia");
        });
    },
    []
  );

  useEffect(() => {
    findProductLineTaxonomy(
      { productLineId: productLine.id, deleted: false },
      0
    );

    setSelectedTaxonomy(undefined);
  }, [findProductLineTaxonomy, productLine]);

  useEffect(() => {
    trackPromise(taxonomyService.getScopedTaxonomies())
      .then((result) => {
        const filteredSegments = filterValidSegments(result.content);
        filteredSegments.sort(sortSegments);
        setSegments(filteredSegments);
      })
      .catch((error) => message.error("Erro ao buscar possíveis taxonomias."));
  }, []);

  const onChangeTaxonomy = (taxonomy: BUSegment) => {
    setSelectedTaxonomy(taxonomy);
  };

  const onAddTaxonomy = () => {
    trackPromise(
      productLineTaxonomyService.associate(
        productLine.id,
        selectedTaxonomy!.leafId
      )
    )
      .then(({ data }) => {
        setProductLineTaxonomies([data, ...productLineTaxonomies]);
        message.success("Taxonomia associada com sucesso!");
      })
      .catch(() => message.error("Erro ao associar Taxonomia."));
  };

  const onRemoveAssociation = (id: number, index: number) => {
    trackPromise(productLineTaxonomyService.delete(id))
      .then(() => {
        productLineTaxonomies.splice(index, 1);
        setProductLineTaxonomies([...productLineTaxonomies]);
        message.success("Taxonomia removida com sucesso!");
      })
      .catch(() => message.error("Erro ao remover associação"));
  };

  return (
    <div style={{ padding: "10px" }}>
      <Alert
        icon={<Icons name="infoCircleOutlined" />}
        message={
          <>
            Linha selecionada: <b>{productLine.name}</b>
          </>
        }
        showIcon
        type="info"
      />
      <>
        <div />
        <label>Nova taxonomia</label>
        <PixTaxonomySelect
          segments={segments}
          onChange={onChangeTaxonomy}
          selectedBus={[]}
        />
      </>
      {selectedTaxonomy && (
        <>
          <Row>
            <Col span={18}>
              <Row
                key={
                  selectedTaxonomy.branch[0].id + "-" + selectedTaxonomy.leafId
                }
                justify="space-around"
                align="middle"
                gutter={[8, 0]}
                className={styles.taxonomiesWrapper}
              >
                <Col span={22}>
                  <Tag className={styles.taxonomyItem} color="geekblue">
                    {getSegmentName(selectedTaxonomy)}
                  </Tag>
                  <Tag
                    className={styles.taxonomyItem}
                    title={getFullTaxonomyName(selectedTaxonomy)}
                  >
                    {getFullTaxonomyName(selectedTaxonomy)}
                  </Tag>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <div
                style={{
                  justifyContent: "center",
                  marginTop: "30px",
                  display: "flex",
                }}
              >
                <Button type="primary" onClick={onAddTaxonomy}>
                  Adicionar
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
      <List<ProductLineTaxonomyT>
        header={
          <>
            <b>Taxonomias Vinculadas</b>

            <div style={{ justifyContent: "center", display: "flex" }}>
              <Input onChange={(e) => setTerm(e.target.value)} />
              <Button
                type="primary"
                onClick={() =>
                  findProductLineTaxonomy({ ...filter, term: term }, 0)
                }
              >
                Buscar
              </Button>
            </div>
          </>
        }
        dataSource={productLineTaxonomies}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button
                type="primary"
                onClick={() => onRemoveAssociation(item.id, index)}
              >
                Remover
              </Button>,
            ]}
          >{`${item.taxonomyId} - ${item.taxonomyDescription}`}</List.Item>
        )}
      />
      <LoadMoreButton
        last={last}
        onFetchMoreData={(_) => findProductLineTaxonomy(filter, page + 1)}
      />
    </div>
  );
};
