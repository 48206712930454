import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";
import {
  ProductLineTaxonomy,
  ProductLineTaxonomyFilter,
} from "shared/models/ProductLineTaxonomy";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/product-line-taxonomy";

class ProductLineTaxonomyService {
  getProductLines(
    page: number,
    filters: ProductLineTaxonomyFilter
  ): AxiosPromise<PixResponsePage<ProductLineTaxonomy>> {
    return axios.get(BASE_PATH, {
      params: objectToParams({ page, ...filters }),
    } as AxiosRequestConfig);
  }

  associate(
    productLineId: number,
    taxonomyId: number
  ): AxiosPromise<ProductLineTaxonomy> {
    return axios.post(
      `${BASE_PATH}/product-line/${productLineId}/taxonomy/${taxonomyId}`
    );
  }

  delete(id: number): AxiosPromise<void> {
    return axios.delete(`${BASE_PATH}/${id}`);
  }
}

export const productLineTaxonomyService = new ProductLineTaxonomyService();
