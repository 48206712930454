import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";
import { Button, Col, Row } from "antd";
import cx from "classnames";
import { Icons } from "components/Icons";
import { CharacteristicsDisplay } from "containers/CharacteristicsDisplay/CharacteristicsDisplay";
import React from "react";
import { ProductById } from "shared/models/Product";
import styles from "./ProductDetails.module.scss";
import { linkToProduct } from "pages/Products/components/ProductsAdvancedFilters";

type ProductDetailsProps = {
  product: ProductById;
  onAssociationClick: () => void;
  onClickEdit: () => void;
  addAttribute: () => void;
};

interface FeatureItemProps {
  description: string;
  isProductDetail?: boolean;
  title: string;
}

const FeatureItem = ({
  title,
  description,
  isProductDetail,
}: FeatureItemProps) => (
  <div>
    <h3 className={isProductDetail ? styles.title : styles.subtitle}>
      {title}
    </h3>
    <p className={isProductDetail ? styles.detail : styles.description}>
      {description}
    </p>
  </div>
);

export const ProductDetails = ({
  product,
  onAssociationClick,
  onClickEdit,
  addAttribute,
}: ProductDetailsProps) => {
  const associatedItemsButtonStyle = cx(
    styles.detailsButton,
    styles.greenButton
  );

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.featuresSectionWrapper}>
        <FeatureItem
          title="ID Produto Padrão"
          description={product.id.toString()}
          isProductDetail
        />

        <FeatureItem
          title="Descrição"
          description={product.description}
          isProductDetail
        />
        <FeatureItem
          title="Saneamento"
          description={product.productSanitation?.label || "-"}
          isProductDetail
        />
        <CharacteristicsDisplay attributes={product.productAttributes} />

        <Row justify="space-around" className={styles.spaceBefore}>
          <Col xxl={12} xl={15} lg={18}>
            <Link to={linkToProduct({ productId: [product.id] })}>
              <Button
                type="primary"
                icon={<ArrowRightOutlined />}
                ghost
                size="small"
              >
                Ir para produtos
              </Button>
            </Link>
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={onClickEdit}
              size="small"
            >
              Editar
            </Button>
          </Col>
        </Row>
      </div>
      <div className={styles.footerWrapper}>
        <h2 className={styles.footerTitle}>Buscar por:</h2>
        <div className={styles.footerButtonWrapper}>
          <Button
            className={associatedItemsButtonStyle}
            onClick={onAssociationClick}
          >
            <Icons name="checkSquareOutlined" />
            <p>Associações</p>
          </Button>
        </div>
      </div>
    </div>
  );
};
