import { Icons } from "components/Icons";
import React, { Component, ReactNode } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IdName } from "shared/models/IdName";
import { getItemStyle, getListStyle } from "shared/utils/DraggableListUtils";

interface DraggableListProps<T> {
  items: T[];
  onReorder: (result: T[]) => void;
  actionButtons?: (item: T) => React.ReactNode;
  showDraggableIcon?: boolean;
  alwaysShowActionButtons?: boolean;
}

interface DraggableListState<T> {
  items: T[];
  hovering: null | number;
}

export class DraggableList<T extends IdName> extends Component<
  DraggableListProps<T>,
  DraggableListState<T>
> {
  constructor(props: DraggableListProps<T>) {
    super(props);
    this.state = {
      items: this.props.items,
      hovering: null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps: DraggableListProps<T>) {
    if (this.props.items !== prevProps.items) {
      this.setState({ items: this.props.items });
    }
  }

  private reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        items,
      },
      () => this.props.onReorder(this.state.items)
    );
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      onMouseEnter={() => this.setState({ hovering: item.id })}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {this.props.alwaysShowActionButtons ? (
                        <>
                          <div>
                            <Icons
                              name="dragOutlined"
                              style={{ paddingRight: "10px" }}
                            />

                            {item.name}
                          </div>
                          <div style={{ alignItems: "end" }}>
                            {this.props.actionButtons?.(item)}
                          </div>
                        </>
                      ) : (
                        <>
                          {item.name}
                          {this.state.hovering === item.id && (
                            <div style={{ alignItems: "end" }}>
                              <Icons name="dragOutlined" />
                              {this.props.actionButtons?.(item)}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
