import { Button } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FilterLabel from "components/FilterLabel/FilterLabel";
import {
  FormItemBoolean,
  SelectBooleanValue,
} from "components/FormItem/FormItemBoolean";
import { FormItemMultipleInteger } from "components/FormItem/FormItemMultipleInteger";
import { FormItemSelect } from "components/FormItem/FormItemSelect";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemWrapper } from "components/FormItem/FormItemWrapper";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { IdName } from "shared/models/IdName";
import OptionData from "shared/models/OptionData";
import { manufacturerService } from "shared/services/ManufacturerService";
import { productLineService } from "shared/services/ProductLineService";
import { scrollFunction } from "shared/utils/ScrollUtils";
import { handleFilterChange } from "util/filter.util";
import styles from "./BrandsAdvancedFilters.module.scss";

export class BrandsFilters {
  brandId?: number[];
  deleted?: SelectBooleanValue;
  manufacturerId?: number;
  productLineId?: number;
  brandSanitation?: string;
  term?: string;
}

interface BrandsAdvancedFiltersProps {
  onClose: () => void;
  onConfirm: (filters: BrandsFilters) => void;
  originalFilters: BrandsFilters;
  visible: boolean;
  parentName: string; // Helps defining unique ids since this component can be reused
  fixedProductLine?: IdName;
  fixedStatus?: boolean;
}

export const BrandsAdvancedFilters = ({
  onClose,
  onConfirm,
  originalFilters,
  visible,
  parentName,
  fixedProductLine,
  fixedStatus,
}: BrandsAdvancedFiltersProps) => {
  const [filters, setFilters] = useState<BrandsFilters>(originalFilters);
  const [productLinesPage, setProductLinesPage] = useState(0);
  const [productLinesLast, setProductLinesLast] = useState(true);
  const [productLinesTerm, setProductLinesTerm] = useState("");
  const [productLineOptions, setProductLineOptions] = useState<OptionData[]>(
    []
  );
  const [manufacturersPage, setManufacturersPage] = useState(0);
  const [manufacturersLast, setManufacturersLast] = useState(true);
  const [manufacturersTerm, setManufacturersTerm] = useState("");
  const [manufacturerOptions, setManufacturerOptions] = useState<OptionData[]>(
    []
  );

  const handleConfirmClick = () => {
    onConfirm(filters);
  };

  const handleSearchProductLines = (page: number, term: string) => {
    if (!term.length) {
      return;
    }
    productLineService
      .getProductLines(page, {
        term,
        deleted: "false",
        productLineId: fixedProductLine?.id
          ? [fixedProductLine?.id]
          : undefined,
      })
      .then((response) => {
        setProductLineOptions((oldOptions) => [
          ...(page > 0 ? oldOptions : []),
          ...response.content.map((it) => ({
            label: it.name,
            value: it.id,
          })),
        ]);
        setProductLinesPage(page);
        setProductLinesLast(response.last);
        setProductLinesTerm(term);
      });
  };

  const handleSearchManufacturers = (page: number, term: string) => {
    if (!term.length) {
      return;
    }
    manufacturerService
      .getManufacturers(page, { term, deleted: "false" })
      .then((response) => {
        setManufacturerOptions((oldOptions) => [
          ...(page > 0 ? oldOptions : []),
          ...response.content.map((it) => ({
            label: it.name,
            value: it.id,
          })),
        ]);
        setManufacturersPage(page);
        setManufacturersLast(response.last);
        setManufacturersTerm(term);
      });
  };

  const debounceSearchProductLines = debounce((term: string) => {
    handleSearchProductLines(0, term);
  }, 600);

  const debounceSearchManufacturers = debounce((term: string) => {
    handleSearchManufacturers(0, term);
  }, 600);

  const loadMoreManufacturers = () => {
    if (!manufacturersLast) {
      handleSearchManufacturers(manufacturersPage + 1, manufacturersTerm);
    }
  };

  const loadMoreProductLines = () => {
    if (!productLinesLast) {
      handleSearchProductLines(productLinesPage + 1, productLinesTerm);
    }
  };

  useEffect(() => setFilters({ ...originalFilters }), [originalFilters]);

  useEffect(() => {
    if (!!fixedProductLine) {
      setProductLineOptions([
        { label: fixedProductLine.name, value: fixedProductLine.id },
      ]);
      setFilters((oldFilters) => ({
        ...oldFilters,
        productLineId: fixedProductLine?.id,
      }));
    }
  }, [fixedProductLine]);

  return (
    <FormItemWrapper
      title="Filtros avançados para marcas"
      visible={visible}
      submit={handleConfirmClick}
      onCancel={() => {
        setFilters({ ...originalFilters, productLineId: fixedProductLine?.id });
        onClose();
      }}
    >
      <FormItemMultipleInteger
        id={`${parentName}-filter-brand-id`}
        label="IDs Marca"
        onChange={(value) => handleFilterChange("brandId", value, setFilters)}
        value={filters.brandId}
      />
      <FormItemSelect
        id={`${parentName}-filter-manufacturer-id`}
        label="Fabricante"
        onChange={(value) =>
          handleFilterChange("manufacturerId", value, setFilters)
        }
        onSearch={debounceSearchManufacturers}
        options={manufacturerOptions}
        value={filters.manufacturerId}
        onPopupScroll={(evt) => scrollFunction(evt, loadMoreManufacturers)}
      />
      <FormItemSelect
        id={`${parentName}-filter-product-line-id`}
        label="Linha"
        onChange={(productLineId) => setFilters({ ...filters, productLineId })}
        onSearch={debounceSearchProductLines}
        options={productLineOptions}
        value={fixedProductLine?.id ?? filters.productLineId}
        disabled={!!fixedProductLine}
        onPopupScroll={(evt) => scrollFunction(evt, loadMoreProductLines)}
      />
      <FormItemBoolean
        label="Status"
        id={`${parentName}-filters-deleted`}
        onChange={(deleted) => setFilters({ ...filters, deleted })}
        textForFalse="Ativo"
        textForTrue="Inativo"
        value={filters.deleted}
        disabled={fixedStatus}
      />
      <FormItem label={<FilterLabel text="Saneamento" htmlFor="saneamento" />}>
        <div id="saneamento" style={{ marginTop: "-4%" }}>
          <SanitationSelect
            onChange={(brandSanitation) =>
              setFilters({ ...filters, brandSanitation })
            }
            value={filters.brandSanitation}
          />
        </div>
      </FormItem>
      <FormItemText
        id={`${parentName}-filter-term`}
        label="Termo genérico"
        onChange={(e) => handleFilterChange("term", e, setFilters)}
        value={filters.term}
      />
      <div className={styles.cleanBtn}>
        <Button
          onClick={() =>
            setFilters((oldFilters) => ({
              productLineId: fixedProductLine?.id,
              deleted: fixedStatus ? oldFilters.deleted : undefined,
            }))
          }
          type="link"
        >
          Limpar filtros
        </Button>
      </div>
    </FormItemWrapper>
  );
};
