import { MergeCellsOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Button, Empty } from "antd";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { ExtractLogsButton } from "components/AuditLogExtract/ExtractLogsButton";
import EmptySelection from "components/EmptySelection/EmptySelection";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { TabItem } from "components/Tabs/Tabs";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { useProductLines } from "hook/ProductLineHook";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { EntitiesEnum } from "shared/enum/EntitiesEnum";
import { AttributeTypes, Characteristic } from "shared/models/Characteristic";
import {
  ProductLineListView,
  ProductLine as ProductLineType,
} from "shared/models/ProductLine";
import { ProductLineFormMetadata } from "shared/models/User";
import { productLineService } from "shared/services/ProductLineService";
import { MergeRequest } from "shared/types/Merge";
import { getEntityPermissions } from "util/UserPermissionUtils";
import styles from "./ProductLines.module.scss";
import { CreateProductLine } from "./components/CreateProductLine";
import { ProductLineAttribute } from "./components/ProductLineAttribute";
import { ProductLineCharts } from "./components/ProductLineCharts";
import {
  ProductLinesAdvancedFilters,
  ProductLinesFilters,
} from "./components/ProductLinesAdvancedFilters";
import { ProductLineTaxonomy } from "./components/ProductLineTaxonomy";

const tabItems: TabItem[] = [
  {
    title: "Linha",
    value: "productLine",
  },
  {
    title: "Características",
    value: "attributes",
  },
  {
    title: "Taxonomias",
    value: "taxonomy",
  },
  {
    title: "Gráficos",
    value: "charts",
  },
];

const columns = [
  {
    title: "ID LINHA",
    dataIndex: "id",
  },
  {
    title: "NOME LINHA YANDEH",
    dataIndex: "name",
  },
  {
    title: "N. MARCAS",
    dataIndex: "totalBrands",
  },
  {
    key: "active",
    dataIndex: "deleted",
    title: "Ativo",
    align: "center" as any,
    render: (deleted: boolean) => <ActiveInactiveItem deleted={deleted} />,
    width: 62,
  },
  {
    key: "sanitation",
    title: "Saneamento",
    dataIndex: ["productLineSanitation", "label"],
    width: 100,
    align: "center" as any,
  },
];

type ProductLineProps = DefaultPage & RouteComponentProps;

const ProductLines = ({ title }: ProductLineProps) => {
  const {
    last,
    terms,
    filters,
    productLines,
    refreshList,
    clearFilters,
    handleSearch,
    fetchMoreData,
    fetchProductLines,
    handleRemoveFilter,
  } = useProductLines();

  const [filtersModalVisible, setFiltersModalVisible] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [isListExpanded, setListExpanded] = useState(false);
  const [isWriting, setWriting] = useState(false);
  const [selected, setSelected] = useState<ProductLineType>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ProductLineListView[]>([]);
  const [productLineMetadata] = useState<ProductLineFormMetadata | null>(
    getEntityPermissions<ProductLineFormMetadata>("productLine")
  );

  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  const onClickMergeMode = () => {
    if (isMergeMode) {
      setSelectedRows([]);
    }

    setIsMergeMode(!isMergeMode);
  };

  const getActionButtons = (
    <>
      <Button
        onClick={onClickMergeMode}
        icon={<MergeCellsOutlined />}
        id="btn-merge"
        hidden={!productLineMetadata?.merge}
      >
        {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
      </Button>
      <ExtractLogsButton
        entityName={EntitiesEnum.PRODUCT_LINE}
        entityIds={selectedRows}
        hasPermission={productLineMetadata?.readAudit}
      />
    </>
  );

  const getOnSelectItem = useCallback(
    (value: ProductLineListView[]) => {
      if (isMergeMode) {
        return setSelectedItems(value);
      }
      return handleSelectedProductLine(value);
    },
    [isMergeMode]
  );

  const handleMultiSelectClick = () => {
    if (multiselectMode && selectedRows.length) {
      const firstSelected = selectedRows[0];
      handleSelectedProductLine(
        productLines.filter((item) => item.id === firstSelected)
      );
    }
  };

  const secondaryActions = (
    <ToggleMultiSelectButton
      multiselectMode={multiselectMode}
      setMultiselectMode={setMultiselectMode}
      disabled={false}
      onChange={handleMultiSelectClick}
    />
  );

  const getSearchComponent = () => {
    return (
      <CrudListContent<ProductLineListView>
        actionButtons={getActionButtons}
        secondaryActions={secondaryActions}
        columns={columns}
        data={productLines}
        fetchMoreData={fetchMoreData}
        filterProps={{
          terms,
          advancedFilters: filters,
          handleFilterIconClick: () => setFiltersModalVisible((f) => !f),
          handleClearFilters: clearFilters,
        }}
        handleCreateNewClick={handleCreateNewClick}
        handleRemoveFilter={handleRemoveFilter}
        handleSearch={handleSearch}
        isExpanded={isListExpanded}
        last={last}
        rowKey="id"
        selectedRows={selectedRows}
        setExpanded={setListExpanded}
        setSelected={getOnSelectItem}
        multiselect={multiselectMode}
        title="Listagem da Linha Yandeh"
        protectedCrudAttributes={{
          protectCreate: !productLineMetadata?.create,
        }}
        deletedStatusProps={{
          onChangeDeletedStatus: (deleted) =>
            handleModalSubmit({ ...filters, deleted }),
          value: filters.deleted,
          disabled: !productLineMetadata?.editDeleted,
        }}
      />
    );
  };

  const handleCreateNewClick = () => {
    setWriting(false);
    setSelected(undefined);
    setTimeout(() => setWriting(true));
  };

  const handleSelectedProductLine = (value: ProductLineListView[]) => {
    setWriting(false);
    setSelectedRows(value.map((item) => item.id));

    if (value?.length === 1 && value[0].id) {
      trackPromise(productLineService.getProductLineById(value[0].id)).then(
        (lineById) => {
          setSelected({ ...lineById });
          setWriting(true);
        }
      );
    } else {
      setSelected(undefined);
      setWriting(false);
    }
  };

  const handleSelectedTab = (value: TabItem) => {
    setSelectedTab(value);
  };

  const handleCancel = () => {
    setWriting(false);
    setSelected(undefined);
    setSelectedRows([]);
  };

  const handleSaveAttributes = (
    attributes?: Record<AttributeTypes, Characteristic[]>
  ) => {
    setSelected((actualSelected) => {
      return { ...actualSelected, attributes } as ProductLineType;
    });
  };

  const onRemoveMergeItem = (remainingItems: ProductLineListView[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.id)
      .filter((itemId) =>
        remainingItems.some((remainItem) => remainItem.id === itemId)
      );
    setSelectedRows(newSelectedRows);
  };

  const getProductLineTab = () => {
    if (!selected && !isWriting) {
      return <EmptySelection />;
    }
    return (
      <CreateProductLine
        productLineId={selected?.id}
        productLine={selected}
        refresh={refreshList}
      />
    );
  };

  const getAttributeTab = () => {
    return !selected ? (
      <Empty
        style={{ paddingTop: "42px" }}
        description="Selecione uma linha primeiro, antes de editar os atributos."
      />
    ) : (
      <ProductLineAttribute
        productLine={selected}
        onSaveAttributes={handleSaveAttributes}
        onCancel={handleCancel}
      />
    );
  };

  const getTaxonomyTab = () => {
    return !selected ? (
      <Empty
        style={{ paddingTop: "42px" }}
        description="Selecione uma linha primeiro, antes de editar as taxonomias."
      />
    ) : (
      <ProductLineTaxonomy productLine={selected} />
    );
  };

  const getChartTab = () => {
    return <ProductLineCharts productLine={selected} />;
  };

  const getSideActionComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<ProductLineListView>
          data={selectedItems}
          getLabeledItem={(data) => `${data.id} ${data.name}`}
          getItemId={(data) => data.id}
          onCancel={() => {
            setIsMergeMode(false);
            setSelectedRows([]);
            setSelectedItems([]);
          }}
          entityName="productLine"
          mergeItems={(mergeRequest: MergeRequest) =>
            productLineService.merge(mergeRequest)
          }
          afterMerge={refreshList}
          onRemoveItem={onRemoveMergeItem}
        />
      );
    }

    return (
      <>
        <CrudCreateComponent
          handleSelectedTab={handleSelectedTab}
          isCreateExpanded={isCreateExpanded}
          tabItems={tabItems}
          selectedTab={selectedTab}
          setCreateExpanded={setCreateExpanded}
        />
        <div className={styles.wrapper}>
          {selectedTab.value === "productLine" && getProductLineTab()}
          {selectedTab.value === "attributes" && getAttributeTab()}
          {selectedTab.value === "charts" && getChartTab()}
          {selectedTab.value === "taxonomy" && getTaxonomyTab()}
        </div>
      </>
    );
  };

  const handleModalSubmit = (filters: ProductLinesFilters) => {
    fetchProductLines(0, filters);
    setFiltersModalVisible(false);
  };

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getSideActionComponent()}
        isCreateExpanded={isCreateExpanded}
        isListExpanded={isListExpanded}
      />
      <ProductLinesAdvancedFilters
        originalFilters={filters}
        visible={filtersModalVisible}
        onConfirm={handleModalSubmit}
        onClose={() => setFiltersModalVisible(false)}
        parentName="product-lines"
        fixedProductLineFilters={{
          deleted: !!productLineMetadata?.editDeleted ? undefined : "false",
        }}
      />
    </>
  );
};

export default ProductLines;
