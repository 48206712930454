import { RouteComponentProps } from "@reach/router";
import { message } from "antd";
import { TabItem } from "components/Tabs/Tabs";
import { CrudContent } from "containers/CrudContent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { PixImage } from "containers/PixImage";
import { useGtin } from "hook/GtinHook";
import {
  ProductFilters,
  ProductsAdvancedFilters,
} from "pages/Products/components/ProductsAdvancedFilters";
import React, { useContext, useEffect, useState } from "react";
import { GtinListItem } from "shared/models/GtinCode";
import { ProductFormMetadata } from "shared/models/User";
import { userService } from "shared/services/UserService";
import {
  USER_PERMISSION_STORAGE_KEY,
  getEntityPermissions,
} from "util/UserPermissionUtils";
import { putObjectAsQueryParam } from "util/query.param.util";
import { readInitialTab } from "util/yeh.query.param.util";
import { GtinTabs, gtinTabs } from "./components/GtinTabs";

import styles from "./GtinList.module.scss";

type GtinProps = DefaultPage & RouteComponentProps;

const gtinListColumns = [
  {
    title: "GTIN",
    dataIndex: "gtin",
  },
  {
    title: "Cód. Venda",
    dataIndex: "salesCode",
    render: (_: string, item: GtinListItem) =>
      item.salesCode ? item.salesCode : "Sem código",
  },
  {
    title: "Imagem",
    dataIndex: "mainAsset",
    render: (_: string, item: GtinListItem) => {
      return (
        <PixImage
          alt={item.description}
          imageUrl={item.imageUrl}
          width="auto"
          height="auto"
          maxWidth="100%"
          maxHeight="40px"
        />
      );
    },
  },
  {
    title: "Descrição",
    dataIndex: "description",
  },
  {
    title: "ID PP",
    dataIndex: "productId",
    render: (_: string, item: GtinListItem) =>
      item.productId ? item.productId : "Sem ID PP",
  },
];

export const GtinList = ({ title }: GtinProps) => {
  const { setCurrentTitle } = useContext(PageContext);
  const {
    last,
    gtins,
    terms,
    filters,
    refresh,
    setTerms,
    fetchGtins,
    handleSearch,
    fetchMoreData,
    handleClearFilters,
    handleRemoveFilter,
  } = useGtin();

  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const [tab, setTab] = useState<TabItem>(readInitialTab(gtinTabs));
  const [selected, setSelected] = useState<GtinListItem>();
  const [selectedRows, setSelectedRows] = useState<number[]>();
  const [isListExpanded, setListExpanded] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [creating, setCreating] = useState(false);
  const [
    productMetaData,
    setProductMetaData,
  ] = useState<ProductFormMetadata | null>(
    getEntityPermissions<ProductFormMetadata>("product")
  );

  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  useEffect(() => putObjectAsQueryParam(filters, "filters"), [filters]);

  useEffect(() => {
    let productPermissions: ProductFormMetadata | null = getEntityPermissions<ProductFormMetadata>(
      "product"
    );
    //Needed because this function is executed before Route.tsx
    if (!productPermissions) {
      userService
        .getPermissions()
        .then((permissions) => {
          sessionStorage.setItem(
            USER_PERMISSION_STORAGE_KEY,
            JSON.stringify(permissions)
          );
          setProductMetaData(permissions.product);
          return;
        })
        .catch(() =>
          message.error("Erro na consulta de dados de permissão do usuário.")
        );
    }
    setProductMetaData(productPermissions!);
  }, []);

  const onSelectGtin = (values: GtinListItem[]) => {
    setSelected(values?.length === 1 ? values[0] : undefined);
  };

  const handleAdvancedFilterConfirm = (
    advancedFilterValues: ProductFilters
  ) => {
    if (advancedFilterValues.term !== filters.term) {
      setTerms(!!advancedFilterValues.term ? [advancedFilterValues.term] : []);
    }
    fetchGtins(0, advancedFilterValues);
    setAdvancedFilterOpen(false);
  };

  const handleCreateNewClick = () => {
    setSelected(undefined);
    setCreating(true);
    setSelectedRows([]);
    setTab(gtinTabs[0]); // DETALHE
  };

  const handleCancelClick = () => {
    setCreating(false);
    setSelected(undefined);
    setSelectedRows([]);
    setCreateExpanded(false);
  };

  const getSearchComponent = () => {
    return (
      <CrudListContent<GtinListItem>
        handleCreateNewClick={handleCreateNewClick}
        columns={gtinListColumns}
        data={gtins}
        fetchMoreData={fetchMoreData}
        filterProps={{
          terms: terms,
          advancedFilters: filters,
          handleClearFilters: handleClearFilters,
          handleFilterIconClick: () => setAdvancedFilterOpen((open) => !open),
        }}
        last={last}
        setExpanded={setListExpanded}
        handleSearch={handleSearch}
        rowKey="gtin"
        setSelected={onSelectGtin}
        selectedRows={selectedRows}
        title="Listagem de GTINs"
        isExpanded={isListExpanded}
        handleRemoveFilter={handleRemoveFilter}
        protectedCrudAttributes={{
          protectCreate: !productMetaData?.create,
        }}
      />
    );
  };

  const getEditGtinComponent = () => (
    <aside className={styles.wrapper}>
      <GtinTabs
        gtin={selected?.gtin}
        refreshList={refresh}
        isCreateExpanded={isCreateExpanded}
        setCreateExpanded={setCreateExpanded}
        creating={creating}
        handleCancelClick={handleCancelClick}
        tab={tab}
        setTab={setTab}
        setCreating={setCreating}
      />
    </aside>
  );

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getEditGtinComponent()}
        isListExpanded={isListExpanded}
        isCreateExpanded={isCreateExpanded}
      />
      <ProductsAdvancedFilters
        parentName="gtins"
        originalFilters={filters}
        onClose={() => setAdvancedFilterOpen(false)}
        onConfirm={handleAdvancedFilterConfirm}
        visible={advancedFilterOpen}
        label="Filtro Avançado de GTINs"
      />
    </>
  );
};
