import { Divider } from "antd";
import cx from "classnames";
import { Input } from "components/Input";
import { Select, SelectProps } from "components/Select";
import { get as _get, debounce } from "lodash";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { PixResponsePage } from "shared/services/PixResponse";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { scrollFunction } from "shared/utils/ScrollUtils";
import styles from "./PixSelectFromModel.module.scss";

interface Props<SelectType extends object>
  extends Omit<SelectProps<number>, "loadMoreButton" | "onLoadMoreClick"> {
  className?: string;
  fetchFunction: (
    term?: string[],
    page?: number,
    parentId?: number,
    id?: number
  ) => Promise<PixResponsePage<SelectType>>;
  label?: string | ReactNode;
  labelSeparator: string;
  objectAttributeLabel: string | string[];
  objectAttributeValue: string;
  onChangeValue: (value: number, option: any) => void;
  parentId?: number;
  selectedValue?: SelectType;
  showId: boolean;
  showTerm: boolean;
  showDivider?: boolean;
}

interface FilterProps {
  id?: string;
  term?: string;
}

const PixSelectFromModel = <SelectType extends Object>({
  className,
  fetchFunction,
  label,
  labelSeparator,
  objectAttributeLabel,
  objectAttributeValue,
  onChangeValue,
  parentId,
  selectedValue,
  showId,
  showTerm,
  showDivider = false,
  ...otherProps
}: Props<SelectType>) => {
  const [filter, setFilter] = useState<FilterProps>({});
  const [lastPage, setLastPage] = useState(true);
  const [page, setPage] = useState(0);
  const [valuesList, setValuesList] = useState<SelectType[]>([]);

  const isItemInList = (
    list: SelectType[],
    selected: SelectType,
    key: keyof SelectType
  ) => {
    if (!list || !list.length || !selected || !selected[key]) {
      return false;
    }
    return list.filter((item) => item[key] === selected[key]).length > 0;
  };

  const processResultAfterService = useCallback(
    (result: PixResponsePage<SelectType>) => (oldValues: SelectType[]) => {
      const processedResult: SelectType[] =
        page === 0 ? result.content : [...oldValues, ...result.content];
      if (
        selectedValue &&
        selectedValue[objectAttributeValue as keyof SelectType] &&
        !isItemInList(
          processedResult,
          selectedValue,
          objectAttributeValue as keyof SelectType
        )
      ) {
        processedResult.unshift(selectedValue);
      }
      return processedResult;
    },
    [page, selectedValue, objectAttributeValue]
  );

  const fetchValues = (term?: string, id?: string, page?: number) => {
    if (!term && !id) {
      setValuesList([]);
      setLastPage(true);
      return;
    }
    fetchFunction(
      !!term ? [term] : undefined,
      page,
      parentId,
      !!id && id.length > 0 ? Number(id) : undefined
    )
      .then((result) => {
        setValuesList(processResultAfterService(result));
        setLastPage(result.last);
      })
      .catch((error) => getErrorMessage("Erro ao buscar valores do atributo."));
  };

  const debounceSearch = useCallback(
    debounce((term?: string, id?: string) => fetchValues(term, id, 0), 1000),
    []
  );

  useEffect(() => debounceSearch(filter.term, filter.id), [
    filter,
    debounceSearch,
  ]);

  useEffect(() => {
    if (
      !selectedValue ||
      !selectedValue[objectAttributeValue as keyof SelectType]
    ) {
      return;
    }
    setValuesList((oldList) => {
      if (
        oldList.length === 0 ||
        !isItemInList(
          oldList,
          selectedValue,
          objectAttributeValue as keyof SelectType
        )
      ) {
        return [selectedValue];
      }
      return [...oldList];
    });
  }, [selectedValue, objectAttributeValue]);

  useEffect(() => {
    setValuesList(selectedValue ? [selectedValue] : []);
  }, [parentId, selectedValue]);

  const onLoadMoreClick = () => {
    if (!lastPage) {
      setPage((oldpage) => {
        let newPage = oldpage + 1;
        fetchValues(filter.term, filter.id, newPage);
        return newPage;
      });
    }
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (open && valuesList.length === 0) {
      fetchValues();
    }
  };

  const getObjectLabel = (value: object) => {
    if (Array.isArray(objectAttributeLabel)) {
      let label = objectAttributeLabel
        .map((item) => _get(value, item))
        .join(labelSeparator);
      return label;
    }
    return _get(value, objectAttributeLabel);
  };

  const showSearch = !showId && !showTerm;
  return (
    <>
      {showDivider && <Divider />}

      {showId && (
        <Input
          label="Número identificador"
          type="number"
          value={filter.id}
          onChange={(evt) => setFilter({ ...filter, id: evt.target.value })}
        />
      )}
      {showTerm && (
        <Input
          label="Termo de pesquisa"
          value={filter.term}
          onChange={(evt) => setFilter({ ...filter, term: evt.target.value })}
        />
      )}
      <Select
        allowClear
        className={cx(styles.pixSelect, className)}
        label={label}
        onChange={(value: number, option: any) => {
          onChangeValue(value, option);
          !value && setFilter({});
        }}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onSearch={
          showSearch ? (term) => setFilter({ ...filter, term }) : undefined
        }
        showSearch={showSearch}
        onPopupScroll={(evnt) => scrollFunction(evnt, onLoadMoreClick)}
        options={valuesList.map((value: object) => ({
          label: getObjectLabel(value),
          value: _get(value, objectAttributeValue),
          original: value,
        }))}
        value={
          selectedValue
            ? ((selectedValue[
                objectAttributeValue as keyof SelectType
              ] as unknown) as number)
            : undefined
        }
        {...otherProps}
      />
    </>
  );
};

PixSelectFromModel.defaultProps = {
  filterOption: false,
  labelSeparator: " > ",
  viewOnly: false,
  showId: false,
  showTerm: false,
};

export default PixSelectFromModel;
