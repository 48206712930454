import { DeleteOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { Option } from "antd/lib/mentions";
import InputInteger from "components/Input/InputInteger/InputInteger";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { useFormTouched } from "hook/FormTouchedHook";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { CustomPackaging } from "shared/models/CustomPackaging";
import { UnitType } from "shared/models/GtinCode";
import { customPackagingService } from "shared/services/CustomPackagingService";
import { gtinCodeService } from "shared/services/GtinCodeService";
import styles from "./CustomPackagingEdit.module.scss";

type CustomPackagingProps = {
  productId: number;
  isCreateExpanded: boolean;
};

type FormType = {
  packaging: CustomPackaging[];
};

const INITIAL_PAGE = 0;

export const CustomPackagingEdit = ({
  productId,
  isCreateExpanded,
}: CustomPackagingProps) => {
  const [isTouched, setIsTouched] = useFormTouched(false);
  const [unitTypes, setUnitTypes] = useState<UnitType[]>([]);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [last, setLast] = useState(false);

  const [form] = Form.useForm<FormType>();

  const findPackaging = useCallback(
    (page: number) => {
      trackPromise(customPackagingService.findAllByProductId(productId, page))
        .then(({ content, page, last }) => {
          setPage(page);
          setLast(last);
          let currentContent = !page ? [] : form.getFieldValue("packaging");
          form.setFieldsValue({ packaging: [...currentContent, ...content] });
        })
        .catch(() => {
          message.error(
            "Erro ao buscar as embalagens de código interno do produto"
          );
        });
    },
    [productId, form]
  );

  useEffect(() => {
    trackPromise(gtinCodeService.findAllPackingUnits())
      .then(setUnitTypes)
      .catch(() =>
        message.error("Erro ao buscar os tipos de unidade de embalagem.")
      );
  }, []);

  useEffect(() => {
    findPackaging(0);
  }, [findPackaging]);

  const onFinish = (values: FormType) => {
    const normalizedValues = values.packaging
      .filter((pack) => pack.supplierId)
      .map((pack) => {
        return { ...pack, supplierId: pack.supplierId!.replace(/\D/g, "") };
      });

    trackPromise(customPackagingService.putCustomPackaging(normalizedValues))
      .then(() => {
        findPackaging(INITIAL_PAGE);
        message.success("Embalagens atualizadas com sucesso");
      })
      .catch(() => message.error("Erro ao atualizar embalagens"));
  };

  const onCancel = () => {
    form.resetFields();
    setIsTouched(false);
  };

  const onAddNewPackaging = () => {
    const basePackaging: CustomPackaging = {
      productId: productId,
    };

    form.setFieldsValue({
      packaging: [basePackaging, ...form.getFieldValue("packaging")],
    });
  };

  const onDeletePackaging = (index: number) => {
    const packaging = form.getFieldValue(["packaging", index]);

    if (packaging.id) {
      trackPromise(customPackagingService.deleteCustomPackaging(packaging.id))
        .then(() => {
          message.success("Embalagem removida com sucesso");
          removeItemFromForm(index);
        })
        .catch(() => message.error("Erro ao deletar embalagem"));
    } else {
      removeItemFromForm(index);
    }
  };

  const removeItemFromForm = (index: number) => {
    let packaging = form.getFieldValue("packaging");
    const removedItem = packaging.splice(index, 1);
    if (removedItem) {
      form.setFieldsValue({ packaging: [...packaging] });
    }
  };

  return (
    <div
      style={{
        width: isCreateExpanded ? "100%" : "900px",
      }}
    >
      <Row justify="end">
        <Button
          type="primary"
          className={styles.addNewButton}
          onClick={onAddNewPackaging}
          id="btn-new-packaging"
        >
          Adicionar nova embalagem
        </Button>
      </Row>
      <Card
        headStyle={{ padding: 0, marginTop: "-5px", marginBottom: "10px" }}
        style={{
          borderRadius: "12px",
          overflow: "hidden",
        }}
        bodyStyle={{
          padding: 0,
        }}
        title={
          <Row justify="space-around" className={styles.tableHeader}>
            <Col span={3}>
              <span>CNPJ</span>
            </Col>
            <Col span={2}>
              <Tooltip title="Código interno">
                <span>SKU</span>
              </Tooltip>
            </Col>
            <Col span={4}>
              <span>Descrição</span>
            </Col>
            <Col span={2}>
              <Tooltip title="Unidade de medida faturada">
                <span>UM</span>
              </Tooltip>
            </Col>
            <Col span={1}>
              <Tooltip title="Quantidade da embalagem faturada">
                <span>Quantidade</span>
              </Tooltip>
            </Col>
            <Col span={1}>
              <Tooltip title="Conversão da unidade para unidade menor">
                <span>Conv</span>
              </Tooltip>
            </Col>
            <Col span={1} />
          </Row>
        }
      >
        {isTouched && (
          <Row justify="center" style={{ paddingBottom: "15px" }}>
            <Alert
              message="O formulário contem mudanças não salvas"
              type="warning"
              showIcon
            />
          </Row>
        )}
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={() => setIsTouched(true)}
        >
          <Form.List
            name="packaging"
            initialValue={form.getFieldValue("packaging")}
          >
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <>
                      <Row
                        key={field.key}
                        justify="space-around"
                        style={{ marginBottom: "-20px" }}
                      >
                        <Col span={3}>
                          <Form.Item name={[index, "supplierId"]}>
                            <MaskedInput
                              mask={"11.111.111/1111-11"}
                              placeholder="CNPJ"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={[index, "sku"]}>
                            <Input placeholder="SKU" />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name={[index, "description"]}>
                            <Input placeholder="Descrição" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={[index, "unit"]}>
                            <Select
                              id="unit-type-select"
                              showSearch
                              placeholder="Selecione uma unidade de medida"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.value
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              <Option value={undefined}>{""}</Option>
                              {unitTypes?.map((option: UnitType) => (
                                <Option
                                  value={option.description}
                                  key={option.acronym}
                                >
                                  {option.description}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Form.Item name={[index, "size"]}>
                            <InputInteger placeholder="Quantidade" />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Form.Item name={[index, "unitSize"]}>
                            <InputInteger placeholder="Conv" />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Popconfirm
                            title="Deletar embalagem?"
                            onConfirm={() => onDeletePackaging(index)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <Button
                              id={`btn-remove-${index}`}
                              shape="circle"
                              type="primary"
                              size="small"
                              icon={<DeleteOutlined />}
                            />
                          </Popconfirm>
                        </Col>
                      </Row>
                      <Divider style={{ margin: "10px" }} />
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <LoadMoreButton
            onFetchMoreData={() => {
              findPackaging(page + 1);
            }}
            last={last}
          />
          <CancelAndSave onCancel={onCancel} disabled={!isTouched} />
        </Form>
      </Card>
    </div>
  );
};
