import { Tooltip } from "antd";
import Button from "antd/lib/button";
import { Icons } from "components/Icons";
import React from "react";
import styles from "./ToggleMultiSelect.module.scss";
export interface ToggleMultiSelectButtonProps {
  disabled?: boolean;
  multiselectMode: boolean;
  setMultiselectMode: (multiSelectMode: boolean) => void;
  onChange: (multiSelectMode: boolean) => void;
}
export const ToggleMultiSelectButton = ({
  disabled,
  multiselectMode,
  setMultiselectMode,
  onChange,
}: ToggleMultiSelectButtonProps) => {
  const onClick = () => {
    setMultiselectMode(!multiselectMode);
    onChange(!multiselectMode);
  };
  return (
    <Tooltip
      title={
        multiselectMode
          ? "Mudar para seleção única"
          : "Mudar para multi seleção"
      }
      placement="bottom"
    >
      <Button
        id="btn-select-mode"
        type="link"
        icon={
          <Icons
            name={
              multiselectMode ? "checkCircleOutlined" : "checkSquareOutlined"
            }
          />
        }
        className={styles.secondaryButton}
        onClick={onClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};
