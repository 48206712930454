import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Skeleton, Tag, List, Row } from "antd";
import React from "react";

export type SimilarityDisplayProps = {
  values: string[];
  loading: boolean;
  entityNameToDisplay: string;
};

export const SimilarityDisplay = ({
  values,
  loading,
  entityNameToDisplay,
}: SimilarityDisplayProps) => {
  const displayValuesAndInfoMessage = () => {
    return values.length ? (
      <>
        <Row justify="center" style={{ padding: "4%" }}>
          <div>
            <Tag
              icon={<ExclamationCircleOutlined />}
              style={{ whiteSpace: "normal", textAlign: "center" }}
              color="warning"
            >
              <span>
                Existem registros de {entityNameToDisplay} similares. Verifique
                se nenhum é equivalente ao novo, para evitar duplicidade.
              </span>
            </Tag>
          </div>
        </Row>
        <List
          size="small"
          bordered
          dataSource={values}
          renderItem={(value) => (
            <List.Item style={{ fontSize: "90%", lineHeight: 1.3 }}>
              {value}
            </List.Item>
          )}
        />
      </>
    ) : (
      <></>
    );
  };

  return <>{loading ? <Skeleton /> : displayValuesAndInfoMessage()}</>;
};
