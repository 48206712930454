import { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { SimpleImage } from "shared/models/Asset";
import { productService } from "shared/services/ProductService";

export const useImages = () => {
  const [images, setImages] = useState<SimpleImage[]>([]);
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);
  const [productId, setProductId] = useState<number>();

  const getDetails = useCallback((productId?: number, page?: number) => {
    if (!productId) {
      return;
    }

    trackPromise(productService.getAssetsDetails(productId, page)).then(
      (response) => {
        setImages((old) => [
          ...(response.page === 0 ? [] : old),
          ...response.content,
        ]);
        setPage(response.page);
        setLast(response.last);
      }
    );
  }, []);

  const loadMore = () => {
    getDetails(productId, page + 1);
  };

  useEffect(() => {
    getDetails(productId, 0);
  }, [productId, getDetails]);

  return { last, images, setProductId, loadMore, getDetails, page };
};
