import React from "react";
import styles from "./CrudCreateComponent.module.scss";

import { Button, Tooltip } from "antd";
import { Icons } from "components/Icons";
import { TabItem, Tabs } from "components/Tabs/Tabs";

interface CrudCreateComponentProps {
  handleSelectedTab: (item: TabItem) => void;
  isCreateExpanded: boolean;
  selectedTab: TabItem;
  setCreateExpanded: (expanded: boolean) => void;
  tabItems: TabItem[];
}

export const CrudCreateComponent = ({
  handleSelectedTab,
  isCreateExpanded,
  selectedTab,
  setCreateExpanded,
  tabItems,
}: CrudCreateComponentProps) => {
  return (
    <div className={styles.createNavBar}>
      <Tabs
        tabItems={tabItems}
        selectedTab={selectedTab}
        onClick={handleSelectedTab}
        className={styles.tabs}
      />
      <div>
        <Tooltip title="Expandir" placement="bottom">
          <Button
            type="link"
            id="expand-detail-button"
            icon={
              <Icons
                name={isCreateExpanded ? "shrinkOutlined" : "arrowsAltOutlined"}
              />
            }
            className={styles.btnExpand}
            onClick={() => setCreateExpanded(!isCreateExpanded)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
