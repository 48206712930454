import { Button, Form } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import {
  FormItemBoolean,
  SelectBooleanValue,
} from "components/FormItem/FormItemBoolean";
import { FormItemMultipleInteger } from "components/FormItem/FormItemMultipleInteger";
import { FormItemSelect } from "components/FormItem/FormItemSelect";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemWrapper } from "components/FormItem/FormItemWrapper";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import OptionData from "shared/models/OptionData";
import { handleFilterChange } from "util/filter.util";
import { handleSearchProductLines } from "util/product-line.util";
import styles from "./ManufacturersAdvancedFilters.module.scss";

export class ManufacturersFilters {
  deleted?: SelectBooleanValue;
  manufacturerId?: number[];
  productLineId?: number;
  term?: string;
  sanitation?: string;
}

interface ManufacturersAdvancedFiltersProps {
  fixedStatus?: boolean;
  onClose: () => void;
  onConfirm: (filters: ManufacturersFilters) => void;
  originalFilters: ManufacturersFilters;
  visible: boolean;
  parentName: string; // Helps defining unique ids since this component can be reused
}

export const ManufacturersAdvancedFilters = ({
  fixedStatus,
  onClose,
  onConfirm,
  originalFilters,
  visible,
  parentName,
}: ManufacturersAdvancedFiltersProps) => {
  const [filters, setFilters] = useState<ManufacturersFilters>(originalFilters);
  const [productLineOptions, setProductLineOptions] = useState<OptionData[]>(
    []
  );

  const handleConfirmClick = () => {
    onConfirm(filters);
  };

  const debounceSearchProductLines = debounce((term: string) => {
    handleSearchProductLines(term, setProductLineOptions);
  }, 500);

  useEffect(() => setFilters({ ...originalFilters }), [originalFilters]);

  return (
    <FormItemWrapper
      title="Filtros avançados para fabricantes"
      visible={visible}
      submit={handleConfirmClick}
      onCancel={() => {
        setFilters({ ...originalFilters });
        onClose();
      }}
    >
      <FormItemMultipleInteger
        label="IDs Fabricante"
        value={filters.manufacturerId}
        id={`${parentName}-filter-manufacturer-id`}
        onChange={(value) =>
          handleFilterChange("manufacturerId", value, setFilters)
        }
      />
      <FormItemSelect
        id={`${parentName}-filter-manufacturer-product-line-id`}
        label="Linha"
        onChange={(productLineId) => setFilters({ ...filters, productLineId })}
        onSearch={debounceSearchProductLines}
        options={productLineOptions}
        value={filters.productLineId}
      />
      <FormItemBoolean
        label="Status"
        id={`${parentName}-filter-manufacturer-deleted`}
        onChange={(deleted) => setFilters({ ...filters, deleted })}
        textForFalse="Ativo"
        textForTrue="Inativo"
        value={filters.deleted}
        disabled={fixedStatus}
      />
      <FormItemText
        id={`${parentName}-filter-manufacturer-term`}
        label="Termo Genérico"
        onChange={(e) => handleFilterChange("term", e, setFilters)}
        value={filters.term}
      />
      <Form.Item label={<FilterLabel text="Saneamento" htmlFor="saneamento" />}>
        <div id="saneamento" style={{ marginTop: "-3%" }}>
          <SanitationSelect
            value={filters.sanitation}
            onChange={(sanitation) => setFilters({ ...filters, sanitation })}
          />
        </div>
      </Form.Item>

      <div className={styles.cleanBtn}>
        <Button
          onClick={() =>
            setFilters((oldFilters) => ({
              deleted: fixedStatus ? oldFilters.deleted : undefined,
            }))
          }
          type="link"
        >
          Limpar filtros
        </Button>
      </div>
    </FormItemWrapper>
  );
};
