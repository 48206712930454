import cx from "classnames";
import React, { ReactNode } from "react";
import styles from "./CrudContent.module.scss";

import { Card, Col, Row } from "antd";
import { DefaultContent } from "containers/DefaultContent";

type DashboardProps = {
  searchComponent: ReactNode;
  createComponent: ReactNode;
  isListExpanded: boolean;
  isCreateExpanded: boolean;
  borderless?: boolean;
};

const DEFAULT_LIST_SPAN = 16;
const DEFAULT_CREATE_SPAN = 8;
const DEFAULT_TOTAL_SPAN = 24;

export const CrudContent = ({
  searchComponent,
  createComponent,
  isListExpanded,
  isCreateExpanded,
  borderless,
}: DashboardProps) => {
  const leftSectionStyle = cx(styles.card, { [styles.borderless]: borderless });
  const rightSectionStyle = cx(styles.card, styles.dataDisplay);
  return (
    <DefaultContent>
      <Row gutter={2}>
        <Col
          id="main-list-content"
          span={
            isListExpanded
              ? DEFAULT_TOTAL_SPAN
              : isCreateExpanded
              ? 0
              : DEFAULT_LIST_SPAN
          }
          order={1}
          className={styles.cardColumn}
        >
          <Card className={leftSectionStyle}>{searchComponent}</Card>
        </Col>
        <Col
          id="side-list-content"
          span={
            isCreateExpanded
              ? DEFAULT_TOTAL_SPAN
              : isListExpanded
              ? 0
              : DEFAULT_CREATE_SPAN
          }
          order={2}
          className={styles.cardColumn}
        >
          <Card className={rightSectionStyle}>{createComponent}</Card>
        </Col>
      </Row>
    </DefaultContent>
  );
};
