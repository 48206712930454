import { Button, Spin } from "antd";
import cx from "classnames";
import { Icons } from "components/Icons";
import React from "react";
import styles from "./LoadMoreButton.module.scss";

export interface LoadMoreButtonProps {
  last: boolean;
  onFetchMoreData: (event: React.MouseEvent) => void;
  className?: string;
  isFetching?: boolean;
}

export const LoadMoreButton = ({
  last,
  onFetchMoreData,
  className,
  isFetching,
}: LoadMoreButtonProps) => {
  return (
    <>
      <div className={cx(styles.loadMoreData, className)}>
        {!last && (
          <Button
            className="load-more-btn"
            type="link"
            id="load-more-items"
            icon={
              isFetching ? (
                <>
                  <Spin size="small" /> &nbsp;
                </>
              ) : (
                <Icons name="reloadOutlined" />
              )
            }
            onClick={onFetchMoreData}
          >
            Carregar Mais Registros
          </Button>
        )}
        {last && (
          <div className={styles.allDataLoaded}>Todos registros carregados</div>
        )}
      </div>
    </>
  );
};
