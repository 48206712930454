import { Alert, message } from "antd";
import { SelectProps } from "antd/lib/select";
import { Select } from "components/Select";
import React, { useEffect, useState } from "react";
import { productService } from "shared/services/ProductService";

import { Option } from "rc-select";
import { ValueLabel } from "shared/models/Product";

type IndustryRotationSelect = {
  label?: string | React.ReactNode;
  warning?: string;
} & SelectProps<string>;

export const IndustryRotationSelect = ({
  label,
  warning,
  ...rest
}: IndustryRotationSelect) => {
  const [industryRotation, setIndustryRotation] = useState<ValueLabel[]>([]);
  const [loading, setLoading] = useState(true);
  const getLabel = () => {
    if (!label) return;

    return typeof label === "string" ? <div>{label}</div> : label;
  };
  useEffect(() => {
    productService
      .findIndustryRotation()
      .then(setIndustryRotation)
      .catch(() => message.error("Erro ao buscar rotação de insdustria."))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div id="select-industry-rotation">
      <Select<string> label={getLabel()} loading={loading} {...rest}>
        <Option key="no-value-key" value="">
          {""}
        </Option>
        {industryRotation.map((industryRotation) => (
          <Option key={industryRotation.value} value={industryRotation.value}>
            {industryRotation.label}
          </Option>
        ))}
      </Select>
      {warning && <Alert message={warning} type="warning" showIcon closable />}
    </div>
  );
};
