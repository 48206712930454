import { Button, Form, Input, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React, { useEffect, useState } from "react";
import BatchProcessStatusEnum from "shared/enum/BatchProcessStatus";
import styles from "./MainUploadsAdvancedFilters.module.scss";
import BatchProcessType from "shared/models/BatchProcessType";

const { Option } = Select;

export class MainUploadFilters {
  status?: string;
  username?: string;
  initialDate?: string;
  endDate?: string;
  description?: string;
  batchProcessTypes?: string[];
}

interface MainUploadsAdvancedFiltersProps {
  applyFilters: (newFilters?: MainUploadFilters) => void;
  originalFilters: MainUploadFilters;
  visible: boolean;
  batchProcessTypes: BatchProcessType[];
}

const MainUploadsAdvancedFilters = ({
  applyFilters,
  originalFilters,
  visible,
  batchProcessTypes,
}: MainUploadsAdvancedFiltersProps) => {
  const [newFilters, setNewFilters] = useState<MainUploadFilters>({});

  useEffect(() => {
    setNewFilters(originalFilters);
  }, [originalFilters, visible]);

  return (
    <Modal
      visible={visible}
      title="Filtros avançados para importações"
      onCancel={() => applyFilters()}
      onOk={() => applyFilters(newFilters)}
      okText="Filtrar"
      cancelText="Cancelar"
    >
      <Form onFinish={() => applyFilters(newFilters)}>
        <Form.Item
          label={<FilterLabel text="Usuário" htmlFor="filters-username" />}
        >
          <Input
            id="filters-username"
            value={newFilters.username}
            onChange={(e) => {
              e.persist();
              setNewFilters((f) => ({ ...f, username: e.target.value }));
            }}
            allowClear
            placeholder="Usuário"
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Descrição" htmlFor="filters-description" />}
        >
          <Input
            id="filters-description"
            value={newFilters.description}
            onChange={(e) => {
              e.persist();
              setNewFilters((f) => ({ ...f, description: e.target.value }));
            }}
            allowClear
            placeholder="Descrição"
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Status" htmlFor="filters-status" />}
        >
          <Select<string>
            id="filters-status"
            allowClear
            placeholder="Status"
            value={newFilters.status}
            onChange={(status) => setNewFilters((f) => ({ ...f, status }))}
          >
            {Object.values(BatchProcessStatusEnum).map((status) => (
              <Option key={status.value} value={status.value}>
                {status.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Data Inicial" htmlFor="initial-date" />}
        >
          <Input
            type="date"
            id="initial-date"
            value={newFilters.initialDate}
            allowClear
            onChange={(e) => {
              e.persist();
              setNewFilters((f) => ({ ...f, initialDate: e.target.value }));
            }}
          />
        </Form.Item>
        <Form.Item label={<FilterLabel text="Data Final" htmlFor="end-date" />}>
          <Input
            type="date"
            id="end-date"
            value={newFilters.endDate}
            allowClear
            onChange={(e) => {
              e.persist();
              setNewFilters((f) => ({ ...f, endDate: e.target.value }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel
              text="Tipos de importação"
              htmlFor="select-batch-type"
            />
          }
        >
          <Select
            id="select-batch-type"
            mode="multiple"
            placeholder="Selecione os tipos de importação"
            value={newFilters.batchProcessTypes}
            allowClear
            options={[
              {
                value: "",
                label: "",
              },
              ...batchProcessTypes.map((it) => ({
                value: it.id,
                label: it.description,
              })),
            ]}
            onChange={(value) =>
              setNewFilters((oldFilters) => ({
                ...oldFilters,
                batchProcessTypes: value,
              }))
            }
          />
        </Form.Item>
        <div className={styles.cleanBtn}>
          <Button onClick={() => setNewFilters({})} type="link">
            Limpar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MainUploadsAdvancedFilters;
