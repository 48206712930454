import { Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  AssociationDataTypeSelect,
  AssociationFilterBase,
} from "shared/models/Association";
import styles from "./AdvancedFilterProductCatch.module.scss";

const { Option } = Select;

interface AdvancedFilterYandehProductProps {
  onClose: () => void;
  onConfirm: (filters: AssociationFilterBase) => void;
  parentFilters: AssociationFilterBase;
  status: boolean;
  associationDataTypeSelected: AssociationDataTypeSelect;
  associationDataType: AssociationDataTypeSelect[];
  onChangeDataType: (data: AssociationDataTypeSelect) => void;
}

const AdvancedFilterProductCatch = ({
  onClose,
  onConfirm,
  parentFilters,
  status,
  associationDataTypeSelected,
  associationDataType,
  onChangeDataType,
}: AdvancedFilterYandehProductProps) => {
  const [filters, setFilters] = useState<AssociationFilterBase>(parentFilters);

  useEffect(() => {
    setFilters(parentFilters);
  }, [parentFilters]);

  const handleConfirmClick = () => {
    onConfirm(filters);
  };

  const getOptions = (items: string[]) => {
    return items.map((item, idx) => (
      <Option key={idx} value={item}>
        {item}
      </Option>
    ));
  };

  const handleChange = (name: string, values: string[]) => {
    setFilters((oldFilters) => {
      return { ...oldFilters, [name]: [...values] };
    });
  };

  const hideReceiverCompanyCode =
    associationDataTypeSelected.value === "supplier-catalog";

  return (
    <div>
      <Modal
        className={styles.modal}
        visible={status}
        title={
          <b className={styles.modalTitle}>
            Filtro Avançado Capturas de Produto
          </b>
        }
        onOk={handleConfirmClick}
        onCancel={() => onClose()}
        cancelText="Cancelar"
        okText="Filtrar"
        width={864}
      >
        <div className={styles.inputWrapper}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <label className={styles.inputTitle} htmlFor="data-type">
                Tipos de dados
              </label>
              <Select
                id="data-type"
                defaultValue={associationDataTypeSelected.value}
                className={styles.multiselect}
                onChange={(value) =>
                  onChangeDataType(
                    associationDataType.filter(
                      (data) => data.value === value
                    )[0]
                  )
                }
              >
                {associationDataType.map((data, index) => {
                  return (
                    <Option
                      key={`association_data_type_${index}`}
                      value={data.value}
                    >
                      {data.title}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <label className={styles.inputTitle} htmlFor="search-term">
                Termo genérico
              </label>
              <Input
                id="search-term"
                className={styles.multiselect}
                onChange={(e) => {
                  e.persist();
                  setFilters((f) => ({ ...f, term: [e.target.value] }));
                }}
                value={filters.term.join(" ")}
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={hideReceiverCompanyCode ? 24 : 12}>
              <label
                className={styles.inputTitle}
                htmlFor="emitter-company-codes"
              >
                CNPJ Emitente
              </label>
              <Select<string[]>
                id="emitter-company-codes"
                className={styles.multiselect}
                mode="tags"
                onChange={(value) => handleChange("emitterCompanyCodes", value)}
                tokenSeparators={[","]}
                value={filters.emitterCompanyCodes}
              >
                {getOptions(filters.emitterCompanyCodes)}
              </Select>
            </Col>
            {!hideReceiverCompanyCode && (
              <Col span={12}>
                <label
                  className={styles.inputTitle}
                  htmlFor="receiver-company-codes"
                >
                  CNPJ Destinatário
                </label>
                <Select<string[]>
                  id="receiver-company-codes"
                  className={styles.multiselect}
                  mode="tags"
                  onChange={(value) =>
                    handleChange("receiverCompanyCodes", value)
                  }
                  tokenSeparators={[","]}
                  value={filters.receiverCompanyCodes}
                >
                  {getOptions(filters.receiverCompanyCodes)}
                </Select>
              </Col>
            )}
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={16}>
              <label className={styles.inputTitle} htmlFor="sku-codes">
                SKUs
              </label>
              <Select<string[]>
                id="sku-codes"
                className={styles.multiselect}
                mode="tags"
                onChange={(value) => handleChange("skus", value)}
                tokenSeparators={[","]}
                value={filters.skus}
              >
                {getOptions(filters.skus)}
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default AdvancedFilterProductCatch;
