import { Form, Select } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React from "react";

interface FormItemMultipleIntegerProps {
  id: string;
  label: string;
  onChange: (value: valueType[]) => void;
  value?: number[];
}

export const FormItemMultipleInteger = ({
  id,
  label,
  onChange,
  value,
}: FormItemMultipleIntegerProps) => (
  <Form.Item label={<FilterLabel text={label} htmlFor={id} />}>
    <Select<number[]>
      id={id}
      mode="tags"
      onChange={onChange}
      tokenSeparators={[",", " "]}
      value={value}
    />
  </Form.Item>
);
