import { Button, Popconfirm } from "antd";
import React, { ReactNode } from "react";
import styles from "./CancelAndSave.module.scss";

// Put this inside a form with <form onSubmit={}>. Clicking in the save button will trigger the form action.

type CancelAndSaveProps = {
  onCancel: () => void;
  disabled?: boolean;
  hidden?: boolean;
  cancelTitle?: string;
  saveTitle?: string;
  saveText?: string;
  beforeRenderPopconfirm?: () => void;
  showConfirm?: boolean;
  showConfirmTitle?: string | ReactNode;
  onConfirm?: () => void;
};

const CancelAndSave = ({
  onCancel,
  disabled,
  hidden,
  cancelTitle,
  saveTitle,
  showConfirm,
  showConfirmTitle,
  onConfirm,
  saveText,
  beforeRenderPopconfirm,
}: CancelAndSaveProps) => {
  const getOkButton = () => (
    <Button
      htmlType="submit"
      type="primary"
      disabled={disabled}
      title={saveTitle}
      id="save-item"
      onClick={() => beforeRenderPopconfirm?.()}
      hidden={hidden}
    >
      {saveText || "Salvar"}
    </Button>
  );

  return (
    <div className={styles.buttons}>
      <Button
        htmlType="button"
        onClick={onCancel}
        title={cancelTitle}
        hidden={hidden}
      >
        Cancelar
      </Button>
      {showConfirm && !disabled ? (
        <Popconfirm
          placement="topRight"
          title={showConfirmTitle}
          okText="Sim"
          cancelText="Não"
          onConfirm={onConfirm}
        >
          {getOkButton()}
        </Popconfirm>
      ) : (
        getOkButton()
      )}
    </div>
  );
};

export default CancelAndSave;
