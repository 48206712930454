import { Form, Select, SelectProps } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import FilterLabel from "components/FilterLabel/FilterLabel";
import {
  ManufacturersAdvancedFilters,
  ManufacturersFilters,
} from "pages/Manufacturers/components/ManufacturersAdvancedFilters";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Manufacturer } from "shared/models/Manufacturer";
import OptionData from "shared/models/OptionData";
import { manufacturerService } from "shared/services/ManufacturerService";
import { scrollFunction } from "shared/utils/ScrollUtils";

interface SearchManufacturerSelectProps extends SelectProps<number> {
  fixedManufacturer?: boolean;
  fixedStatus?: boolean;
  initialManufacturer?: Manufacturer;
  onChange: (manufacturerId: number) => void;
  parentName: string;
}

export const SearchManufacturerSelect = ({
  fixedManufacturer,
  fixedStatus,
  initialManufacturer,
  onChange,
  parentName,
  ...rest
}: SearchManufacturerSelectProps) => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<ManufacturersFilters>({
    deleted: fixedStatus ? "false" : undefined,
  });
  const [options, setOptions] = useState<OptionData[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [last, setLast] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [
    selectedManufacturerId,
    setSelectedManufacturerId,
  ] = useState<number>();

  const fetchManufacturers = (page: number, filters: ManufacturersFilters) => {
    trackPromise(
      manufacturerService.getManufacturers(page, filters).then((response) => {
        setOptions((oldOptions) => [
          ...(page === 0 ? [] : oldOptions),
          ...response.content.map((m) => ({ label: m.name, value: m.id })),
        ]);
        setOptionsOpen(true);
        setLast(response.last);
        setFilters(filters);
      })
    );
    setFiltersOpen(false);
    setPage(page);
  };

  const loadMore = () => {
    if (!last) {
      fetchManufacturers(page + 1, filters);
    }
  };

  useEffect(() => {
    if (initialManufacturer) {
      setOptions([
        {
          label: initialManufacturer.name,
          value: initialManufacturer.id,
        },
      ]);
      setSelectedManufacturerId(initialManufacturer.id);
    }
  }, [initialManufacturer]);

  useEffect(() => {
    if (!searchText.length) {
      return;
    }

    const interval = setTimeout(
      () =>
        fetchManufacturers(0, {
          term: searchText,
          deleted: fixedStatus ? "false" : undefined,
        }),
      600
    );

    return () => {
      clearTimeout(interval);
    };
  }, [searchText, fixedStatus]);

  return (
    <Form.Item
      label={
        <>
          <FilterLabel
            text="Fabricante"
            htmlFor="search-manufacturers-select"
          />
          <FilterIcon
            filters={filters}
            onClick={() => setFiltersOpen((f) => !f)}
          />
        </>
      }
    >
      <ManufacturersAdvancedFilters
        visible={filtersOpen}
        parentName={parentName}
        originalFilters={filters}
        onConfirm={(filters) => fetchManufacturers(0, filters)}
        onClose={() => setFiltersOpen(false)}
        fixedStatus={fixedStatus}
      />
      <Select<number>
        id="search-manufacturers-select"
        filterOption={false}
        allowClear
        options={options}
        onSearch={setSearchText}
        showSearch
        open={optionsOpen}
        onDropdownVisibleChange={setOptionsOpen}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
        onChange={(manufacturerId) => {
          setSelectedManufacturerId(manufacturerId);
          onChange(manufacturerId);
        }}
        value={selectedManufacturerId}
        {...rest}
      />
    </Form.Item>
  );
};
