import { Button } from "antd";
import {
  FormItemBoolean,
  SelectBooleanValue,
} from "components/FormItem/FormItemBoolean";
import { FormItemMultipleInteger } from "components/FormItem/FormItemMultipleInteger";
import { FormItemSelect } from "components/FormItem/FormItemSelect";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemWrapper } from "components/FormItem/FormItemWrapper";
import React, { useEffect, useState } from "react";
import {
  AttributeTypes,
  CharacteristicFilters,
} from "shared/models/Characteristic";
import { NameAlias } from "shared/services/SelectOptionType";
import { handleFilterChange } from "util/filter.util";
import styles from "./AdvancedFilters.module.scss";

interface AdvancedFiltersModalProps {
  status: boolean;
  onOk: (updatedAdvancedFilters: CharacteristicFilters) => void;
  onCancel: () => void;
  types: NameAlias[];
  filters: CharacteristicFilters;
  fixedStatus?: boolean;
  parentName: string;
}

export const AdvancedFiltersModal = ({
  status,
  onOk,
  onCancel,
  types,
  filters,
  fixedStatus,
  parentName,
}: AdvancedFiltersModalProps) => {
  const [advancedFilters, setAdvancedFilters] = useState<CharacteristicFilters>(
    filters
  );

  const onChangeType = (value: string) => {
    setAdvancedFilters({
      ...advancedFilters,
      type: value as AttributeTypes,
    });
  };

  const onChangeDeleted = (value: SelectBooleanValue) => {
    setAdvancedFilters({
      ...advancedFilters,
      deleted: value,
    });
  };
  useEffect(() => {
    setAdvancedFilters(filters);
  }, [filters]);

  return (
    <FormItemWrapper
      title="Filtros avançados para características"
      visible={status}
      submit={() => onOk(advancedFilters)}
      onCancel={onCancel}
    >
      <FormItemMultipleInteger
        id={`${parentName}-characteristics-id-filter`}
        label="IDs Característica"
        onChange={(value) =>
          handleFilterChange("attributeId", value, setAdvancedFilters)
        }
        value={advancedFilters.attributeId}
      />
      <FormItemSelect
        id="attribute-type"
        label="Tipo de Característica"
        options={types.map((t) => ({
          value: t.name,
          label: t.alias,
        }))}
        onChange={onChangeType}
        value={advancedFilters.type}
      />
      <FormItemBoolean
        id="characteristic-filter"
        label="Status"
        onChange={(deleted) => onChangeDeleted(deleted)}
        textForFalse="Ativo"
        textForTrue="Inativo"
        value={advancedFilters.deleted}
        disabled={fixedStatus}
      />
      <FormItemText
        id="characteristics-filter-term"
        label="Termo genérico"
        onChange={(e) => handleFilterChange("term", e, setAdvancedFilters)}
        value={advancedFilters.term}
      />
      <div className={styles.cleanBtn}>
        <Button
          onClick={() => {
            setAdvancedFilters({});
          }}
        >
          Limpar filtros
        </Button>
      </div>
    </FormItemWrapper>
  );
};
