import React from "react";

import styles from "./FilterLabel.module.scss";

interface FilterLabelProps {
  text: string;
  htmlFor: string;
}

const FilterLabel = ({ text, htmlFor }: FilterLabelProps) => (
  <label className={styles.inputTitle} htmlFor={htmlFor}>
    {text}
  </label>
);

export default FilterLabel;
