import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { LabelValue } from "shared/types/utils/LabelValue";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/asset-tags";

class AssetTagsService {
  async list(page: number = 0, size: number = 20) {
    const r = await axios.request<PixResponsePage<LabelValue>>({
      method: "GET",
      url: `${BASE_PATH}`,
      params: objectToParams({ page, size }),
    } as AxiosRequestConfig);
    return r.data;
  }
}

export const assetTagsService = new AssetTagsService();
