import { compact } from "lodash";

export const getQueryParameter = (name: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || "";
};

export const getQueryParameterArray = (name: string) =>
  compact(
    getQueryParameter(name)
      .split(",")
      .map((it) => it.trim())
  );

const setPageWithoutRedirect = (url: string) =>
  window.history.replaceState(null, "", url);

const currentLocationWithoutQuery = () =>
  window.location.origin + window.location.pathname;

export const updateQueryParam = (name: string, value: any) => {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set(name, value.toString().trim());

  // This ends up appending a "?" even with no query param
  setPageWithoutRedirect(
    currentLocationWithoutQuery() + "?" + searchParams.toString()
  );
};

export const removeQueryParam = (name: string) => {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(name);
  setPageWithoutRedirect(
    currentLocationWithoutQuery() + ("?" + searchParams.toString())
  );
};

export const objectToParams = (object: any) => {
  let result = {};
  Object.keys(object)
    .filter((key) => object[key] != null && object[key].toString().length > 0)
    .forEach((key) => {
      if (Array.isArray(object[key])) {
        result = {
          ...result,
          [key]: object[key].map((it: any) => "" + it).join(","),
        };
      } else {
        result = { ...result, [key]: "" + object[key] };
      }
    });

  return result;
};

export const putObjectAsQueryParam = (object: any, name: string) => {
  // Avoid a lot of empty objects in
  let params = {} as any;
  for (let key in object) {
    let strRepr = "" + (object[key] != null ? object[key] : "");
    if (strRepr.length > 0) {
      params[key] = strRepr;
    }
  }
  if (Object.keys(params).length === 0) {
    removeQueryParam(name);
  } else {
    updateQueryParam(name, JSON.stringify(params));
  }
};
