import { TabItem } from "components/Tabs/Tabs";
import { getQueryParameter } from "./query.param.util";

export const readTermQueryParam = (): string[] =>
  getQueryParameter("term")
    ?.split(",")
    .filter((it: string) => it.length > 0) || [];

export const readInitialTab = (tabItems: TabItem[]) =>
  tabItems.find((tab) => tab.value === getQueryParameter("tab")) || tabItems[0];
