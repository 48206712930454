import React from "react";
import NumberFormat from "react-number-format";
import { ProductCharacteristic } from "shared/models/Product";

import style from "./CharacteristicsDetail.module.scss";

interface CharacteristicsDetailProps {
  attribute: ProductCharacteristic;
}

export const CharacteristicsDetail = ({
  attribute,
}: CharacteristicsDetailProps) => {
  return (
    <div className={style.attributeWrapper}>
      <label className={style.attributeLabel}>
        {attribute.attribute.label}
      </label>
      <div>
        {attribute.valueMask ? (
          <NumberFormat
            value={attribute.value}
            format={attribute.valueMask}
            displayType="text"
          />
        ) : (
          attribute.value
        )}
      </div>
    </div>
  );
};
