import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import cx from "classnames";
import React, { ReactNode } from "react";
import styles from "./SearchInput.module.scss";

type Props = {
  className?: string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onPressEnter?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  suffix?: ReactNode;
  value?: string;
};

export const SearchInput = ({
  className,
  disabled,
  placeholder,
  onBlur,
  onChange,
  onPressEnter,
  suffix,
  value,
}: Props) => (
  <Input
    disabled={disabled}
    data-testid="search-input"
    placeholder={placeholder || "Pesquisar"}
    className={cx(styles.searchInput, className)}
    prefix={<SearchOutlined />}
    onChange={(e) => onChange && onChange(e.currentTarget.value)}
    onBlur={(e) => onBlur && onBlur(e.currentTarget.value)}
    onPressEnter={(e) => onPressEnter && onPressEnter(e.currentTarget.value)}
    suffix={suffix}
    value={value}
  />
);
