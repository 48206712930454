import { message } from "antd";
import { trackPromise } from "react-promise-tracker";
import OptionData from "shared/models/OptionData";
import { productLineService } from "shared/services/ProductLineService";

export const handleSearchProductLines = (
  term: string,
  setProductLineOptions: React.Dispatch<React.SetStateAction<OptionData[]>>
) => {
  if (term.length === 0) {
    return;
  }
  trackPromise(
    productLineService.getProductLines(0, { term, deleted: "false" })
  )
    .then((response) =>
      setProductLineOptions(
        response.content.map((productLine) => ({
          value: productLine.id,
          label: productLine.name,
        }))
      )
    )
    .catch(() => message.error("Erro ao buscar linhas"));
};
