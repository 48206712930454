import { RightCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { PixLink } from "components/PixLink/PixLink";
import React from "react";

interface CharacteristicsLinkProps {
  attributeId: number;
}

export const CharacteristicsLink = ({
  attributeId,
}: CharacteristicsLinkProps) => {
  return (
    <PixLink
      link="/caracteristicas"
      label={
        <Tooltip title="Ir para características">
          <RightCircleOutlined />
        </Tooltip>
      }
      params={{ attributeId: [attributeId] }}
    />
  );
};
