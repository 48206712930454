import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Brand } from "shared/models/Brand";
import { productLineService } from "shared/services/ProductLineService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { filterProductLineWithBrand } from "shared/utils/Utils";
import { AddBrand } from "./AddBrand";
import { LineBrand } from "./LineBrand";

interface AssignedBrandsProps {
  productLineId: number;
}

export const AssignedBrands = ({ productLineId }: AssignedBrandsProps) => {
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);
  const [productLineBrands, setProductLineBrands] = useState<Brand[]>([]);

  const fetchProductLineBrands = useCallback(
    (productLineId: number, page: number) => {
      productLineService
        .getProductLineBrands({
          productLineId,
          page,
        })
        .then((response) => {
          setPage(response.page);
          setLast(response.last);
          setProductLineBrands((old) => [
            ...(page === 0 ? [] : old),
            ...filterProductLineWithBrand(response.content).map(
              ({ brand }) => brand!
            ),
          ]);
        })
        .catch(() => message.error("Erro ao obter as marcas da linha"));
    },
    []
  );

  const handleDeleteBrand = (productLineId: number, brandId: number) => {
    productLineService
      .removeBrandFromLine(productLineId, brandId)
      .then(() => {
        fetchProductLineBrands(productLineId, 0);
        message.success("Marca desvinculada com sucesso!");
      })
      .catch(() => getErrorMessage("Erro ao desassociar marca."));
  };

  useEffect(() => {
    fetchProductLineBrands(productLineId, 0);
  }, [fetchProductLineBrands, productLineId]);

  return (
    <section>
      <AddBrand
        productLineId={productLineId}
        refresh={() => fetchProductLineBrands(productLineId, 0)}
      />
      <LineBrand
        brandsData={productLineBrands}
        deleteBrand={(b) => handleDeleteBrand(productLineId, b.id)}
        last={last}
        onFetchMoreData={() => fetchProductLineBrands(productLineId, page + 1)}
      />
    </section>
  );
};
