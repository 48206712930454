import { DeleteOutlined } from "@ant-design/icons";
import { Card, Col, Drawer, Form, Input, Row, Tag } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import SearchBooksProductSelect from "containers/SearchBooksProductSelect/SearchBooksProductSelect";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import BookProduct from "shared/models/BookProduct";
import DataCluster from "shared/models/DataCluster";
import DataClusterBase from "shared/models/DataClusterBase";
import dataClusterService from "shared/services/DataClusterService";
import styles from "./CreateDataCluster.module.scss";

interface CreateOrEditDataClusterProps {
  isOpen: boolean;
  onClose: (dataCluster?: DataClusterBase) => void;
  editDataCluster?: DataCluster;
}

const CreateOrEditDataCluster = ({
  editDataCluster,
  isOpen,
  onClose,
}: CreateOrEditDataClusterProps) => {
  const [dataCluster, setDataCluster] = useState<DataClusterBase>({
    description: "",
    productIds: [],
  });
  const [selectedBookProducts, setSelectedBookProducts] = useState<
    BookProduct[]
  >([]);

  const close = (dataCluster?: DataClusterBase) => {
    onClose(dataCluster);
    setDataCluster({ description: "", productIds: [] });
  };

  const loadDetails = async (dataCluster: DataCluster) => {
    let last = false;
    let page = 0;
    while (!last) {
      let booksPage = await trackPromise(
        dataClusterService.detailBooksCluster(
          dataCluster.dataClusterId,
          page,
          {},
          100
        )
      );

      last = booksPage.last;

      // We must keep the order that comes from the data cluster
      // or we will end up changing main product
      setSelectedBookProducts((old) =>
        [...old, ...booksPage.content].sort(
          (a, b) =>
            dataCluster.productIds.indexOf(a.productId) -
            dataCluster.productIds.indexOf(b.productId)
        )
      );
      page++;
    }
  };

  useEffect(() => {
    setSelectedBookProducts([]);
    if (isOpen) {
      if (!!editDataCluster) {
        setDataCluster(editDataCluster);
        loadDetails(editDataCluster);
      } else {
        setSelectedBookProducts([]);
        setDataCluster({ description: "", productIds: [] });
      }
    }
  }, [editDataCluster, isOpen]);

  return (
    <Drawer
      visible={isOpen}
      closable={true}
      onClose={() => close()}
      width={(2 * window.innerWidth) / 3}
      className={styles.drawer}
      destroyOnClose
    >
      <div className={styles.heading}>
        <h1>{!!editDataCluster ? "Editar grupo" : "Novo grupo"}</h1>
      </div>

      <Form
        layout="vertical"
        className={styles.form}
        onFinish={() =>
          close({
            ...dataCluster,
            productIds: selectedBookProducts.map((it) => it.productId),
          })
        }
      >
        <Form.Item
          label={
            <FilterLabel
              text="Descrição do grupo"
              htmlFor="data-cluster-description"
            />
          }
          rules={[{ required: true, message: "A descrição é obrigatória" }]}
        >
          <Input
            id="data-cluster-description"
            value={dataCluster.description}
            onChange={(e) =>
              setDataCluster({ ...dataCluster, description: e.target.value })
            }
            required
          />
        </Form.Item>

        <SearchBooksProductSelect
          hideMain
          parentName="create-data-cluster"
          onChange={(bookProduct: BookProduct) => {
            setSelectedBookProducts((old) => [
              ...old.filter((it) => it.productId !== bookProduct.productId),
              bookProduct,
            ]);
          }}
        />
        <Tag color="purple">
          {selectedBookProducts.length}{" "}
          {selectedBookProducts.length < 2 ? "item" : "itens"} para agrupar
        </Tag>
        {selectedBookProducts.map((product) => (
          <Card key={product.productId}>
            <Row>
              <Col span={23}>
                <p>{product.description}</p>
                {product.blackList && (
                  <p>
                    <strong>Black list</strong>
                  </p>
                )}
              </Col>
              <Col
                span={1}
                onClick={() =>
                  setSelectedBookProducts((old) =>
                    old.filter((it) => it.productId !== product.productId)
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <DeleteOutlined />
              </Col>
            </Row>
          </Card>
        ))}
        <CancelAndSave onCancel={onClose} />
      </Form>
    </Drawer>
  );
};

export default CreateOrEditDataCluster;
