import { Form, Select } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import { SubBrandAdvancedFilters } from "pages/SubBrand/SubBrandsAdvancedFilters";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { IdName } from "shared/models/IdName";
import { SubBrandListFilter, SubBrandListItem } from "shared/models/SubBrand";
import { subBrandService } from "shared/services/SubBrandService";
import { scrollFunction } from "shared/utils/ScrollUtils";

interface SearchSubBrandsSelectProps {
  onChange?: (sb: number) => void;
  parentName: string;
  fixedBrand?: IdName;
  initialSubBrandId?: number;
  setSelectedSubBrand?: (idName?: IdName) => void;
  subBrandId?: number;
  fixedFilters?: Readonly<SubBrandListFilter>;
}

export const SearchSubBrandsSelect = ({
  parentName,
  fixedBrand,
  onChange,
  initialSubBrandId,
  setSelectedSubBrand,
  subBrandId,
  fixedFilters,
}: SearchSubBrandsSelectProps) => {
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);
  const [filters, setFilters] = useState<SubBrandListFilter>(
    fixedFilters || {
      deleted: "false",
      subBrandSanitation: "ALTO",
    }
  );
  const [subBrands, setSubBrands] = useState<SubBrandListItem[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [alreadyLoadInitialSubBrand, setAlreadyLoadInitialSubBrand] = useState(
    false
  );

  useEffect(() => {
    if (initialSubBrandId && !alreadyLoadInitialSubBrand) {
      subBrandService
        .findAll(0, { subBrandId: [initialSubBrandId] })
        .then(({ content }) => setSubBrands(content));
      setAlreadyLoadInitialSubBrand(true);
    }
  }, [initialSubBrandId, alreadyLoadInitialSubBrand]);

  const fetchSubBrands = (page: number, filters: SubBrandListFilter) => {
    const newFilters: SubBrandListFilter = {
      ...filters,
      ...fixedFilters,
    };
    trackPromise(subBrandService.findAll(page, filters)).then((response) => {
      setSubBrands((old) => [...(page === 0 ? [] : old), ...response.content]);
      setOptionsOpen(true);
      setFiltersOpen(false);
      setFilters(newFilters);
      setLast(response.last);
      setPage(page);
    });
  };
  const debounceSearchSubBrands = debounce(
    (term: string, filters: SubBrandListFilter, brandId?: number) => {
      if (!term) {
        return;
      }
      fetchSubBrands(0, {
        ...filters,
        term,
        brandId,
        subBrandSanitation: "ALTO",
      });
    },
    600
  );

  const handleChange = (id: number) => {
    onChange?.(id);
    const subBrand = subBrands.find((sb) => sb.subBrandId === id);
    setSelectedSubBrand &&
      setSelectedSubBrand(
        subBrand
          ? { id: subBrand.subBrandId, name: subBrand.subBrandName }
          : undefined
      );
  };

  const loadMore = () => {
    if (!last) {
      fetchSubBrands(page + 1, filters);
    }
  };

  return (
    <Form.Item
      label={
        <>
          <FilterLabel
            text="Sub Marca"
            htmlFor={`${parentName}-search-sub-brands-select`}
          />
          <FilterIcon filters={filters} onClick={() => setFiltersOpen(true)} />
        </>
      }
    >
      <SubBrandAdvancedFilters
        visible={filtersOpen}
        parentName={parentName}
        originalFilters={{
          brandId: fixedBrand?.id,
          ...filters,
        }}
        onConfirm={(filters) => fetchSubBrands(0, filters)}
        onClose={() => setFiltersOpen(false)}
        fixedStatus
        fixedBrand={fixedBrand}
      />
      <Select<number>
        id={`${parentName}-search-brands-select`}
        filterOption={false}
        allowClear
        options={subBrands.map((sb) => ({
          value: sb.subBrandId,
          label: sb.subBrandName,
        }))}
        onChange={handleChange}
        onSearch={(term) =>
          debounceSearchSubBrands(term, filters, fixedBrand?.id)
        }
        showSearch
        open={optionsOpen}
        onDropdownVisibleChange={setOptionsOpen}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
        value={subBrandId}
      />
    </Form.Item>
  );
};
