import { DeleteOutlined } from "@ant-design/icons";
import { Cascader, Col, Popover, Row, Tag } from "antd";
import { CascaderOptionType, CascaderValueType } from "antd/lib/cascader";
import { Icons } from "components/Icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { BUSegment, Taxonomy } from "shared/models/Taxonomy";
import { taxonomyService } from "shared/services/TaxonomyService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getNormalizedNumberArrayFilter } from "util/filter.util";
import styles from "./TaxonomyCascader.module.scss";

interface TaxonomyCascaderProps {
  onChange: (taxonomyIds: number[]) => void;
  id?: string;
  taxonomyIds?: number[];
}

export interface Option {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
}

export const TaxonomyCascader = ({
  onChange,
  id,
  taxonomyIds,
}: TaxonomyCascaderProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [cascaderValue, setCascaderValue] = useState<CascaderValueType>();
  const [selectedOptions, setSelectedOptions] = useState<
    CascaderOptionType[]
  >();
  const [selectedBuSegments, setSelectedBuSegments] = useState<BUSegment[]>([]);

  const getNormalizedTaxonomyIdsFilters = () =>
    getNormalizedNumberArrayFilter(taxonomyIds ?? []);

  const mapTaxonomiesToOptions = (taxonomies: Taxonomy[]): Option[] => {
    return taxonomies.map((tax) => {
      const option: Option = {
        value: tax.leafId.toString(),
        label: tax.description,
        isLeaf: false,
      };
      return option;
    });
  };

  useEffect(() => {
    trackPromise(taxonomyService.getTaxonomies(undefined, undefined, 0))
      .then((result) => setOptions(mapTaxonomiesToOptions(result.content)))
      .catch((_) => getErrorMessage("Erro ao buscar taxonomias."));
  }, []);

  const fetchTaxonomyBranchByLeafId = (
    taxonomyId: number,
    content: BUSegment[]
  ) => {
    let buSegment = content.find(
      (it) => String(it.leafId) === String(taxonomyId)
    );

    if (buSegment) {
      return buSegment.branch;
    }
    return [];
  };

  useEffect(() => {
    if (taxonomyIds && taxonomyIds.length) {
      const normalizedTaxonomyIdsFilters = getNormalizedNumberArrayFilter(
        taxonomyIds
      );
      trackPromise(
        taxonomyService.getTaxonomiesBranchTree(normalizedTaxonomyIdsFilters)
      )
        .then(({ content }) => {
          const buSegments = normalizedTaxonomyIdsFilters.map((taxonomyId) => ({
            branch: fetchTaxonomyBranchByLeafId(taxonomyId, content),
            leafId: taxonomyId,
            totalChildren: 0,
          }));

          setSelectedBuSegments(buSegments);
        })
        .catch(() => getErrorMessage("Erro ao buscar taxonomias."));
    } else {
      setSelectedBuSegments([]);
    }
  }, [taxonomyIds]);

  const loadData = (selectedOptions?: CascaderOptionType[]) => {
    const targetOption = selectedOptions![selectedOptions!.length - 1];
    targetOption.loading = true;

    taxonomyService
      .getTaxonomies(
        undefined,
        { page: 0, size: 99 },
        targetOption.value as number
      )
      .then((response) => {
        targetOption.loading = false;
        targetOption.children = mapTaxonomiesToOptions(response.content);
        setOptions([...options]);
      });
  };

  const onChangeValue = (
    value: CascaderValueType,
    selectedOptions?: CascaderOptionType[]
  ) => {
    setCascaderValue(value);
    setSelectedOptions(selectedOptions);
  };

  const getLastIndexSelectedId = (option: CascaderOptionType[]): number => {
    return option[option.length - 1].value as number;
  };

  const onDropdownVisibleChange = (visible: boolean) => {
    if (!visible && cascaderValue && selectedOptions) {
      let newTaxonomyId = getLastIndexSelectedId(selectedOptions);
      let updatedTaxonomyIds = new Set([
        ...getNormalizedTaxonomyIdsFilters(),
        Number(newTaxonomyId),
      ]);
      setCascaderValue(undefined);
      onChange(Array.from(updatedTaxonomyIds));
    }
  };

  const getFullTaxonomyName = (segment: BUSegment) =>
    segment.branch.map((item) => item.label).join(" > ");

  const handleRemoveTaxonomy = (index: number) => {
    onChange(getNormalizedTaxonomyIdsFilters().filter((_, i) => i !== index));
  };

  return (
    <>
      <Row justify="center">
        <Col span={23}>
          <Cascader
            id={id}
            options={options}
            onChange={onChangeValue}
            loadData={loadData}
            value={cascaderValue}
            onPopupVisibleChange={onDropdownVisibleChange}
            expandTrigger="click"
            placeholder="Selecione uma taxonomia"
            changeOnSelect
            allowClear={false}
            style={{ minWidth: "100%" }}
          />
        </Col>
        <Col span={1}>
          <Popover
            content={
              "Para selecionar um taxonomia intermediaria, clique duas vezes sobre a opção."
            }
            title=""
            className={styles.helpMessageIcon}
          >
            <Icons name="InfoCircleTwoTone" />
          </Popover>
        </Col>
      </Row>
      {selectedBuSegments.map((taxonomy, index) => (
        <Row
          key={taxonomy.leafId + "-" + taxonomy.leafId}
          justify="space-around"
          align="middle"
          className={styles.taxonomiesWrapper}
          gutter={[8, 0]}
        >
          <Col span={22}>
            <Tag
              className={styles.taxonomyItem}
              title={getFullTaxonomyName(taxonomy)}
            >
              {getFullTaxonomyName(taxonomy)}
            </Tag>
          </Col>
          <Col span={2}>
            <DeleteOutlined onClick={() => handleRemoveTaxonomy(index)} />
          </Col>
        </Row>
      ))}
    </>
  );
};
