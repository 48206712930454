import { Button, Tooltip } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import {
  FormItemBoolean,
  SelectBooleanValue,
} from "components/FormItem/FormItemBoolean";
import { Icons } from "components/Icons";
import PixSearchByTerm from "containers/PixSearchByTerm/PixSearchByTerm";
import React, { ReactNode, useState } from "react";
import styles from "./SearchTopBar.module.scss";

interface SearchTopBarProps {
  isExpanded?: boolean;
  handleSearch: (value: string) => void;
  handleRemoveFilter?: (filter: string) => void;
  secondaryActions?: ReactNode;
  setExpanded?: (expanded: boolean) => void;
  advancedFilters?: { filter: string; color: string }[];
  searchSuffix?: ReactNode;
  deletedStatusProps?: {
    onChangeDeletedStatus: (newStatus: SelectBooleanValue) => void;
    value?: SelectBooleanValue;
    disabled?: boolean;
  };
  filterProps: {
    terms: string[];
    advancedFilters?: any;
    handleFilterIconClick?: () => void;
    handleClearFilters?: () => void;
  };
}

export const SearchTopBar = ({
  isExpanded,
  filterProps,
  advancedFilters,
  handleSearch,
  handleRemoveFilter,
  secondaryActions,
  deletedStatusProps,
  setExpanded,
  searchSuffix,
}: SearchTopBarProps) => {
  const [searchInputValue, setSeachInputValue] = useState<string>("");

  return (
    <div className={styles.nav}>
      <PixSearchByTerm
        advancedFilters={advancedFilters}
        filters={filterProps.terms}
        inputValue={searchInputValue}
        onChange={(value) => setSeachInputValue(value)}
        onRemoveFilter={handleRemoveFilter}
        onSearch={(value) => {
          handleSearch(value);
          setSeachInputValue("");
        }}
        suffix={searchSuffix}
      />
      <div className={styles.icons}>
        <Button
          className={styles.btnClearFilters}
          onClick={filterProps.handleClearFilters}
          type="link"
          size="small"
        >
          Limpar Filtros
        </Button>
        {filterProps.advancedFilters && filterProps.handleFilterIconClick && (
          <FilterIcon
            className={styles.btnNav}
            filters={filterProps.advancedFilters}
            onClick={filterProps.handleFilterIconClick}
          />
        )}
        {secondaryActions}
        {deletedStatusProps && (
          <div className={styles.deletedSelect}>
            <FormItemBoolean
              label="Status"
              id={`adv-filter-deleted`}
              onChange={deletedStatusProps.onChangeDeletedStatus}
              textForFalse="Ativo"
              textForTrue="Inativo"
              value={deletedStatusProps.value}
              disabled={deletedStatusProps.disabled}
            />
          </div>
        )}
        {setExpanded && (
          <Tooltip title="Expandir" placement="bottom">
            <Button
              icon={
                <Icons
                  name={isExpanded ? "shrinkOutlined" : "arrowsAltOutlined"}
                />
              }
              className={styles.btnNav}
              onClick={() => {
                setExpanded?.(!isExpanded);
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
