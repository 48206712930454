import axios from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import {
  SubBrandCreate,
  SubBrandDetail,
  SubBrandListFilter,
  SubBrandListItem,
  SubBrandUpdate,
} from "shared/models/SubBrand";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/sub-brand";

class SubBrandService {
  findAll = async (page: number, filter: SubBrandListFilter, size = 20) => {
    const r = await axios.request<PixResponsePage<SubBrandListItem>>({
      method: "GET",
      url: BASE_PATH,
      params: objectToParams({ ...filter, page, size }),
    });
    return r.data;
  };

  findById = async (id: number) => {
    const r = await axios.request<SubBrandDetail>({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
    });
    return r.data;
  };

  findTotalProductsBySubBrandId = async (id: number) => {
    const r = await axios.request<number>({
      method: "GET",
      url: `${BASE_PATH}/${id}/total/products`,
    });
    return r.data;
  };

  update = async (data: SubBrandUpdate): Promise<SubBrandUpdate> => {
    const r = await axios.request({
      method: "PUT",
      url: `${BASE_PATH}/${data.subBrandId}`,
      data,
    });
    return r.data;
  };

  create = async (data: SubBrandCreate) => {
    const r = await axios.request<SubBrandUpdate>({
      url: BASE_PATH,
      method: "POST",
      data,
    });
    return r.data;
  };

  async merge(mergeRequest: MergeRequest) {
    const r = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data: mergeRequest,
    });
    return r.data;
  }
}

export const subBrandService = new SubBrandService();
