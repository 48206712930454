import { message, Popover, Row } from "antd";
import { Icons } from "components/Icons";
import { IndustryRotationSelect } from "components/IndustryRotationSelect/IndustryRotationSelect";
import { Input } from "components/Input";
import { InputClickToCount } from "components/InputClickToCount/InputClickToCount";
import { MarketRotationSelect } from "components/MarketRotationSelect/MakertRotationSelect";
import { PixTextArea } from "components/PixTextArea";
import { ProductStatusSelect } from "components/ProductStatusSelect/ProductStatusSelect";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { Select } from "components/Select";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { compact, debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { CountStatusEnum } from "shared/enum/CountStatusEnum";
import { SanitationEnum } from "shared/enum/SanitationEnum";
import { ProductById } from "shared/models/Product";
import { ProductFormMetadata } from "shared/models/User";
import { productLineService } from "shared/services/ProductLineService";
import { productService } from "shared/services/ProductService";
import { subBrandService } from "shared/services/SubBrandService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { scrollFunction } from "shared/utils/ScrollUtils";
import { utcToPtBrDate } from "util/date.util";
import { getEntityPermissions } from "util/UserPermissionUtils";
import styles from "./CreateProduct.module.scss";

interface CreateProductProps {
  isExpanded: boolean;
  afterSave: (updatedProduct: ProductById) => void;
  onCancel: () => void;
  selected: ProductById;
}

interface OptionType {
  value: number;
  label: string;
}

interface WithBrandOption extends OptionType {
  brandId?: number;
}

export const CreateProduct = ({
  onCancel,
  afterSave,
  selected,
}: CreateProductProps) => {
  const [productLinesOptions, setProductLinesOptions] = useState<OptionType[]>(
    []
  );
  const [productLineId, setProductLineId] = useState<number>();
  const [brandId, setBrandId] = useState<number>();
  const [productLinePage, setProductLinePage] = useState(0);
  const [productLineTerm, setProductLineTerm] = useState("");
  const [productLineLast, setProductLineLast] = useState(true);
  const [openDescription, setOpenDescription] = useState("");
  const [productMetada] = useState<ProductFormMetadata | null>(
    getEntityPermissions<ProductFormMetadata>("product")
  );

  const [productLineBrandsOptions, setProductLineBrandsOptions] = useState<
    WithBrandOption[]
  >([]);
  const [subBrandsOptions, setSubBrandsOptions] = useState<OptionType[]>([]);
  const [subBrandsTerm, setSubBrandsTerm] = useState("");
  const [subBrandsLast, setSubBrandsLast] = useState(true);
  const [subBrandsPage, setSubBrandsPage] = useState(0);
  const [productLineBrandId, setProductLineBrandId] = useState<number>();
  const [productLineBrandPage, setProductLineBrandPage] = useState(0);
  const [productLineBrandTerm, setProductLineBrandTerm] = useState("");
  const [productLineBrandLast, setProductLineBrandLast] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [productStatus, setProductStatus] = useState<string>();
  const [productSanitation, setProductSanitation] = useState<string>();
  const [marketRotation, setMarketRotation] = useState<string>();
  const [industryRotation, setIndustryRotation] = useState<string>();
  const [
    openDescriptionWarning,
    setOpenDescriptionWarning,
  ] = useState<string>();
  const [countStatus, setCountStatus] = useState<CountStatusEnum>(
    CountStatusEnum.INIT_STATUS
  );
  const [associationsCount, setAssociationsCount] = useState<number>();
  const [subBrandId, setSubBrandId] = useState<number>();

  useEffect(() => {
    setProductLineId(selected.productLine.id);
    setProductLinesOptions([
      { value: selected.productLine.id, label: selected.productLine.name },
    ]);

    setProductLineBrandId(selected.productLineBrandId);
    setBrandId(selected.brand.id);
    setProductLineBrandsOptions([
      {
        value: selected.productLineBrandId,
        label: compact([selected.manufacturer.name, selected.brand.name]).join(
          " > "
        ),
      },
    ]);
    setDeleted(selected.deleted);
    setProductStatus(selected.productStatus.value);
    setProductSanitation(selected.productSanitation?.value);
    setOpenDescription(selected?.openDescription || "");
    setMarketRotation(selected.marketRotation?.value || "");
    setIndustryRotation(selected.industryRotation?.value);
    setSubBrandId(selected.subBrand?.subBrandId);

    // Count status
    setAssociationsCount(undefined);
    setCountStatus(CountStatusEnum.INIT_STATUS);

    if (selected.subBrand) {
      setSubBrandsOptions([
        {
          value: selected.subBrand.subBrandId,
          label: selected.subBrand.subBrandName,
        },
      ]);
    }
  }, [selected]);

  const handleSubmit = () => {
    trackPromise(
      productService.update(selected.id, {
        productId: selected.id,
        productLineBrandId: productLineBrandId!,
        deleted,
        productStatusId: productStatus,
        taxonomies: selected.taxonomies,
        openDescription: openDescription,
        productSanitation: productSanitation,
        marketRotation,
        industryRotation,
        subBrandId: subBrandId,
        // Currently, this screen does not update attributes not assets. We can keep them
        attributeIds: selected.productAttributes.map((ea) => ea.id),
        assets: selected.assets,
      })
    )
      .then((response) => {
        message.success(
          "Todos os campos do produto foram atualizados com sucesso"
        );
        afterSave(response);
      })
      .catch(() => {
        getErrorMessage("Erro ao atualizar produto");
      });
  };

  const fetchProductLineData = useCallback((term: string, page: number) => {
    // No searh in case of empty term
    if (!compact(term).length) {
      return;
    }
    // Clear dropdown's label
    setProductLineId(undefined);

    // For load more
    setProductLineTerm(term);

    trackPromise(
      productLineService
        .getProductLines(page, {
          term,
          deleted: "false",
          productLineSanitation: "ALTO",
        })
        .then((response) => {
          setProductLinesOptions((options) => [
            ...(page === 0 ? [] : options),
            ...response.content.map((it) => ({
              value: it.id,
              label: it.name,
            })),
          ]);

          setProductLineLast(response.last);
        })
    );
  }, []);

  const fetchBrandData = useCallback(
    (terms: string[], page: number, lineId?: number) => {
      // No search in case of empty term
      if (compact(terms).length === 0) {
        return;
      }
      // Clear dropdown label
      setProductLineBrandId(undefined);

      // For load more
      setProductLineBrandTerm(terms[0]);

      trackPromise(
        productLineService
          .getProductLineBrands({
            productLineId: lineId,
            term: terms,
            manufacturerBrandSanitation: SanitationEnum.ALTO,
            page,
          })
          .then((response) => {
            setProductLineBrandsOptions((options) => [
              ...(page === 0 ? [] : options),
              ...response.content.map((it) => ({
                value: it.id,
                brandId: it.brand?.id,
                label: compact([
                  it.brand?.manufacturer.name,
                  it.brand?.name,
                ]).join(" > "),
              })),
            ]);
            setProductLineBrandLast(response.last);
          })
      );
    },
    []
  );

  const fetchSubBrandData = useCallback(
    (term: string, page: number, brandId?: number) => {
      // Clear dropdown label
      setSubBrandId(undefined);

      if (term.length === 0) {
        return;
      }

      // For load more
      setSubBrandsTerm(term);

      trackPromise(
        subBrandService
          .findAll(page, {
            brandId,
            term,
            deleted: "false",
            subBrandSanitation: "ALTO",
          })
          .then((response) => {
            setSubBrandsOptions((options) => [
              ...(page === 0 ? [] : options),
              ...response.content.map((it) => ({
                value: it.subBrandId,
                label: it.subBrandName,
              })),
            ]);
            setSubBrandsLast(response.last);
            setSubBrandsPage(page);
          })
      );
    },
    []
  );

  const clearLine = () => {
    setProductLineId(undefined);
    setProductLineBrandId(undefined);
    setSubBrandId(undefined);
  };

  const debounceSearchProductLine = useCallback(
    debounce(
      (term: string, page: number) => fetchProductLineData(term, page),
      600
    ),
    []
  );

  const debounceSearchBrand = useCallback(
    debounce(
      (term: string, lineId?: number) => fetchBrandData([term], 0, lineId),
      600
    ),
    []
  );

  const debounceSearchSubBrand = useCallback(
    debounce(
      (term: string, brandId?: number) => fetchSubBrandData(term, 0, brandId),
      600
    ),
    []
  );

  const loadMoreProductLines = () => {
    if (!productLineLast) {
      fetchProductLineData(productLineTerm, productLinePage + 1);
      setProductLinePage((page) => page + 1);
    }
  };

  const loadMoreProductLineBrands = () => {
    if (!productLineId || productLineBrandLast) {
      return;
    }
    fetchBrandData(
      [productLineBrandTerm],
      productLineBrandPage + 1,
      productLineId
    );
    setProductLineBrandPage((page) => page + 1);
  };

  const loadMoreSubBrands = () => {
    if (!brandId || subBrandsLast) {
      return;
    }
    fetchSubBrandData(subBrandsTerm, subBrandsPage + 1, brandId);
  };

  const handleChangeLine = (value: number) => {
    setProductLineBrandId(undefined);
    setProductLineBrandsOptions([]);
    setProductLineId(value);
  };

  const clearOpenDescription = () => {
    setOpenDescription("");
    setOpenDescriptionWarning("O campo descrição aberta foi limpo");
  };

  const hadleHighSanitationLevel = (productSanitation: string) => {
    productSanitation === "ALTO"
      ? clearOpenDescription()
      : setOpenDescriptionWarning("");
    setProductSanitation(productSanitation);
  };

  const handleCountAssociations = () => {
    setCountStatus(CountStatusEnum.LOADING_STATUS);
    productService
      .countAssociations(selected.id)
      .then((response) => {
        setAssociationsCount(response.total);
        setCountStatus(CountStatusEnum.COMPLETED);
      })
      .catch(() => setCountStatus(CountStatusEnum.ERROR_STATUS));
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Input
        label="Descrição Aberta"
        name="openDescription"
        value={openDescription}
        onChange={(e) => setOpenDescription(e.target.value)}
        disabled={!productMetada?.editOpenDescription}
      />
      <Input
        disabled={true}
        label="Descrição Curta"
        name="description"
        value={selected.description}
      />
      <Input
        disabled={true}
        label="Descrição Abreviada"
        name="shortDescription"
        value={selected.shortDescription}
      />
      <PixTextArea
        autoSize={true}
        disabled={true}
        label="Descrição Estendida"
        name="extendedDescription"
        value={selected.extendedDescription}
      />
      <Select<number>
        label="Linha Yandeh"
        options={productLinesOptions}
        value={productLineId}
        disabled={!productMetada?.editProductLine || selected.deleted}
        onChange={handleChangeLine}
        allowClear
        onClear={clearLine}
        showSearch
        onSearch={(term) => {
          debounceSearchProductLine(term, 0);
          setProductLinePage(0);
        }}
        onPopupScroll={(evnt) => scrollFunction(evnt, loadMoreProductLines)}
        filterOption={false}
      />
      <Select<number>
        label="Fabricante | Marca"
        options={productLineBrandsOptions}
        value={productLineBrandId}
        onChange={(value) => {
          const selected = productLineBrandsOptions.find(
            (plb) => plb.value === value
          );

          setProductLineBrandId(value);

          if (brandId !== selected?.brandId) {
            setBrandId(selected?.brandId);
            setSubBrandsOptions([]);
            setSubBrandId(undefined);
          }
        }}
        allowClear
        onClear={() => setProductLineBrandId(undefined)}
        showSearch
        onSearch={(term) => {
          debounceSearchBrand(term, productLineId);
          setProductLineBrandPage(0);
        }}
        disabled={
          !productMetada?.editManufacturer || !productLineId || selected.deleted
        }
        placeholder={!productLineId ? "Escolha uma linha primeiro" : ""}
        onPopupScroll={(evnt) =>
          scrollFunction(evnt, loadMoreProductLineBrands)
        }
        filterOption={false}
      />
      <Select<number>
        label="Sub Marca"
        options={subBrandsOptions}
        value={subBrandId}
        onChange={setSubBrandId}
        allowClear
        onClear={() => setSubBrandId(undefined)}
        showSearch
        onSearch={(term) => {
          debounceSearchSubBrand(term, brandId);
        }}
        disabled={!productLineId || !brandId || selected.deleted}
        placeholder={
          !productLineId || !brandId ? "Escolha uma marca primeiro" : ""
        }
        onPopupScroll={(evnt) => scrollFunction(evnt, loadMoreSubBrands)}
        filterOption={false}
      />
      <ProductStatusSelect
        value={productStatus}
        onSelectStatus={setProductStatus}
        label="Status Comercial"
        disabled={!productMetada?.editProductCommercialStatus}
      />
      <SanitationSelect
        value={productSanitation}
        onChange={hadleHighSanitationLevel}
        label={
          <Row className={styles.sanitationSelect}>
            <p>Saneamento</p>
            <Popover
              content={
                "Ao alterar o saneamento para alto o campo descrição aberta será limpo"
              }
              title=""
            >
              <Icons name="InfoCircleTwoTone" />
            </Popover>
          </Row>
        }
        warning={openDescriptionWarning}
        disabled={!productMetada?.editProductSanitation}
      />
      <MarketRotationSelect
        value={marketRotation}
        onChange={setMarketRotation}
        label="Giro de Mercado "
        disabled={!productMetada?.editMarketRotation}
      />
      <IndustryRotationSelect
        value={industryRotation}
        onChange={setIndustryRotation}
        label="Giro de Industria"
        disabled={!productMetada?.editIndustryRotation}
      />
      <Input
        name="lastModified"
        id="product-last-modified"
        label="Última atualização"
        placeholder="Última atualização"
        value={utcToPtBrDate(selected.lastModified)}
        disabled
      />
      <InputClickToCount
        countStatus={countStatus}
        entity="associações"
        onCount={handleCountAssociations}
        total={associationsCount}
        link="/associacoes"
        linkText="Ir para associações"
        linkParams={{ productId: [selected.id] }}
        linkParamsName="productFilters"
      />
      <div className={styles.divider} />
      {!!selected && (
        <YehSwitch
          checkedChildren="Ativo"
          unCheckedChildren="Inativo"
          checked={!deleted}
          onChange={(f) => setDeleted(!f)}
          disabled={!productMetada?.editDeleted}
        />
      )}
      <CancelAndSave
        onCancel={onCancel}
        showConfirm={!!selected && deleted !== selected.deleted}
        showConfirmTitle={`O produto está ${
          deleted ? "ativo" : "inativo"
        }. Tem certeza de que deseja ${deleted ? "inativar" : "ativar"}?`}
        onConfirm={handleSubmit}
        disabled={
          !productMetada?.update ||
          !productLineId ||
          !productLineBrandId ||
          !productStatus
        }
      />
    </form>
  );
};
