import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

const grid = 8;

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderBottom: "1px solid var(--color-light-gray)",
  background: isDragging
    ? "var(--color-light-blue-with-opacity)"
    : "transparent",
  color: isDragging ? "var(--color-light-blue)" : "var(--color-dark-blue)",
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
  background: "transparent",
  padding: `0 ${grid}`,
  width: "100%",
});
