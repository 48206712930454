import { Alert, message } from "antd";
import { SelectProps } from "antd/lib/select";
import { Select } from "components/Select";
import React, { useEffect, useState } from "react";
import { ValueLabel } from "shared/models/Product";
import { productService } from "shared/services/ProductService";

import { Option } from "rc-select";

type MarketRotationSelect = {
  label?: string | React.ReactNode;
  warning?: string;
} & SelectProps<string>;

export const MarketRotationSelect = ({
  label,
  warning,
  ...rest
}: MarketRotationSelect) => {
  const [marketRotation, setMarketRotation] = useState<ValueLabel[]>([]);
  const [loading, setLoading] = useState(true);
  const getLabel = () => {
    if (!label) return;

    return typeof label === "string" ? <div>{label}</div> : label;
  };
  useEffect(() => {
    productService
      .findMarketRotation()
      .then(setMarketRotation)
      .catch(() => message.error("Erro ao buscar giro de mercado."))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div id="select-market-rotation">
      <Select<string> label={getLabel()} loading={loading} {...rest}>
        <Option key="no-value-key" value="">
          {""}
        </Option>
        {marketRotation.map((marketRotation) => (
          <Option key={marketRotation.value} value={marketRotation.value}>
            {marketRotation.label}
          </Option>
        ))}
      </Select>
      {warning && <Alert message={warning} type="warning" showIcon closable />}
    </div>
  );
};
