import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { ValueLabel } from "shared/models/Product";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/commercial-status";

class CommercialStatusService {
  async findCommercialStatus() {
    const response = await axios.request<ValueLabel[]>({
      method: "GET",
      url: BASE_PATH,
      headers: { "Content-Type": "application/json" },
    } as AxiosRequestConfig);
    return response.data;
  }
}

export const commercialStatusService = new CommercialStatusService();
