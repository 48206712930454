import { Switch, SwitchProps } from "antd";
import React from "react";
import styles from "./YehSwitch.module.scss";

interface YehSwitchProps {
  label?: string;
}

export const YehSwitch = ({
  label,
  style,
  ...rest
}: YehSwitchProps & SwitchProps) => (
  <div style={style}>
    {label && (
      <>
        <label className={styles.label}>{label}</label>
        <br />
      </>
    )}
    <Switch {...rest} />
  </div>
);
