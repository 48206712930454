import { useState, useEffect, Dispatch, SetStateAction } from "react";

export const useFormTouched = (
  initialState: boolean
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isTouched, setIsTouched] = useState(initialState);

  useEffect(() => {
    const onUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = "";
      window.confirm(
        "O formulário contem mudanças não salvas, deseja prosseguir?"
      );
    };
    if (isTouched) {
      window.addEventListener("beforeunload", onUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isTouched]);

  return [isTouched, setIsTouched];
};
