import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Popconfirm,
  Row,
  Skeleton,
} from "antd";
import { GtinPackingEditTable } from "components/Gtin/GtinPackingEditTable/GtinPackingEditTable";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { GtinCodeSimpleDetail } from "shared/models/GtinCode";
import { gtinCodeService } from "shared/services/GtinCodeService";
import styles from "./CreateProduct.module.scss";

interface PackageTabProps {
  productId: number;
  handleExpandTab: (expand: boolean) => void;
  isCreateExpanded: boolean;
  afterSave?: () => void;
  afterGenerateSalesCode?: (gtin: string) => void;
}

export const PackageTab = ({
  productId,
  handleExpandTab,
  isCreateExpanded,
  afterSave,
  afterGenerateSalesCode,
}: PackageTabProps) => {
  const [gtins, setGtins] = useState<GtinCodeSimpleDetail[]>([]);
  const [gtinSearch, setGtinSearch] = useState("");
  const [visibleSearchAlert, setVisibleSearchAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const fetchGtins = useCallback((productId: number, gtinSearch: string) => {
    setLoading(true);
    gtinCodeService
      .findDetailsByGtins({
        productId,
        gtins: gtinSearch ? [gtinSearch] : [],
      })
      .then((filteredGtins) => {
        setGtins([...filteredGtins]);
      })
      .catch(() => message.error("Erro ao filtrar GTINS."))
      .finally(() => setLoading(false));
  }, []);

  const handleEditGtin = (updatedGtins: GtinCodeSimpleDetail[]) => {
    setGtins(updatedGtins);
    afterSave?.();
  };

  const handleSubmit = () => {
    trackPromise(gtinCodeService.updateGtinPackingAttributes(gtins))
      .then(() => {
        message.success(
          "Informações de embalagem dos GTIN atualizada com sucesso."
        );
        fetchGtins(productId, "");
        setGtinSearch("");
      })
      .catch(() =>
        message.error("Erro ao atualizar informações de embalagem do GTIN.")
      );
  };

  useEffect(() => fetchGtins(productId, ""), [productId, fetchGtins]);

  const onClickSaveOrCancel = () => {
    handleExpandTab(false);
  };

  return (
    <div
      style={{
        width: isCreateExpanded ? "100%" : "800px",
      }}
    >
      {isLoading ? (
        <div style={{ padding: "10px" }}>
          <Skeleton active />
        </div>
      ) : (
        <>
          <div>
            <Row style={{ padding: "5%" }}>
              <Col span={18}>
                <Input
                  id="search-gtin-input"
                  placeholder="Digite um GTIN para realizar a busca"
                  onChange={(e) => {
                    setGtinSearch(e.currentTarget.value);
                  }}
                />
              </Col>
              <Col style={{ paddingLeft: "4px" }} span={2}>
                <Popconfirm
                  placement="bottomRight"
                  visible={visibleSearchAlert}
                  title="A edição atual de todos os GTINs será perdida caso um novo GTIN seja buscado. Deseja busca o GTIN informado antes de salvar a edição atual?"
                  onConfirm={() => {
                    fetchGtins(productId, gtinSearch);
                    setVisibleSearchAlert(false);
                  }}
                  onCancel={() => {
                    setVisibleSearchAlert(false);
                    setGtinSearch("");
                  }}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button
                    id="search-gtin-btn"
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={() => setVisibleSearchAlert(!!gtins.length)}
                  >
                    Pesquisar
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </div>
          {gtins.length ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className={styles.wrapper}
            >
              <GtinPackingEditTable
                gtins={[...gtins]}
                afterSubmit={handleEditGtin}
                afterCancel={onClickSaveOrCancel}
                afterGenerateSalesCode={afterGenerateSalesCode}
              />
            </form>
          ) : (
            <div style={{ padding: "8%" }}>
              <Empty description="Não há GTIN(s) associados ao produto" />
            </div>
          )}
        </>
      )}
    </div>
  );
};
