import { Button, Card, Drawer } from "antd";
import { Icons } from "components/Icons";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { PixLink } from "components/PixLink/PixLink";
import { CreateBrand } from "pages/Brands/components/CreateBrand";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { IdName } from "shared/models/IdName";
import { Manufacturer } from "shared/models/Manufacturer";
import { ManufacturerMetadata } from "shared/models/User";
import { brandService } from "shared/services/BrandService";
import { getEntityPermissions } from "util/UserPermissionUtils";
import styles from "./ManufacturerBrands.module.scss";

interface ManufacturerBrandProps {
  handleSave: () => void;
  manufacturer?: Manufacturer;
}

export const ManufacturerBrands = ({
  handleSave,
  manufacturer,
}: ManufacturerBrandProps) => {
  const [createBrandVisible, setCreateBrandVisible] = useState(false);
  const [brands, setBrands] = useState<IdName[]>([]);
  const [last, setLast] = useState(true);
  const [page, setPage] = useState(0);
  const [manufacturerMetaData] = useState<ManufacturerMetadata | null>(
    getEntityPermissions<ManufacturerMetadata>("manufacturer")
  );

  const fetchBrands = (page: number, manufacturer?: Manufacturer) => {
    if (!manufacturer?.id) {
      return;
    }

    trackPromise(
      brandService.getBrands(
        page,
        {
          manufacturerId: manufacturer.id,
          deleted: "false",
        },
        ["brandName,asc"]
      )
    ).then((response) => {
      setBrands((oldBrands) => [
        ...(page === 0 ? [] : oldBrands),
        ...response.content,
      ]);
      setPage(page);
      setLast(response.last);
    });
  };

  useEffect(() => fetchBrands(0, manufacturer), [manufacturer]);

  const handleCreateClick = () => {
    setCreateBrandVisible(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <h2>Marcas relacionadas</h2>
        <Button
          type="link"
          onClick={handleCreateClick}
          hidden={!manufacturerMetaData?.addBrand}
        >
          <Icons name="fontawesomePlusSquare" /> Adicionar marcas
        </Button>
      </div>
      {!!brands.length && (
        <Card className={styles.card}>
          {brands.map((brand) => (
            <PixLink
              key={brand.id}
              link="/marca"
              params={{ brandId: brand.id }}
              label={
                <Button key={brand.id} type="link" className={styles.brandItem}>
                  <p>{brand.name}</p>
                </Button>
              }
            />
          ))}
        </Card>
      )}
      {
        <LoadMoreButton
          onFetchMoreData={() => fetchBrands(page + 1, manufacturer)}
          last={last}
        />
      }
      <Drawer
        title="Criar nova marca"
        width="40vw"
        visible={createBrandVisible}
        bodyStyle={{ backgroundColor: "#f2f2f2" }}
        onClose={() => setCreateBrandVisible(false)}
      >
        {createBrandVisible && (
          <CreateBrand
            initialManufacturer={manufacturer}
            fixedManufacturer
            onSubmit={() => {
              handleSave();
              fetchBrands(0, manufacturer);
              setCreateBrandVisible(false);
            }}
            onCancel={() => setCreateBrandVisible(false)}
          />
        )}
      </Drawer>
    </div>
  );
};
