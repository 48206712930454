import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import React from "react";
import iconList from "./iconList";

type IconsProps = {
  name: string;
} & AntdIconProps;

export const Icons = React.forwardRef(
  ({ name, ...otherProps }: IconsProps, iconRef) => {
    const selectedIcon = iconList.find((item) => item.name === name);
    return <>{selectedIcon && selectedIcon.icon(otherProps, iconRef)}</>;
  }
);
