import React, { useCallback, useEffect, useState } from "react";
import styles from "./PixTaxonomyGlobalSelect.module.scss";

import { debounce as _debounce, find as _find, map as _map } from "lodash";

import { Select as AntdSelect } from "antd";
import { Select } from "components/Select";
import { trackPromise } from "react-promise-tracker";
import { TaxonomyBranch } from "shared/models/Taxonomy";
import { taxonomyService } from "shared/services/TaxonomyService";
import { getErrorMessage } from "shared/utils/ResponseUtils";

const { Option } = AntdSelect;

export interface TaxonomyBranchWithDisplayValue {
  branches: TaxonomyBranch[];
  displayValue: string;
  id: number;
}

export interface PixTaxonomyGlobalSelectProps {
  label?: string;
  onChange: (value: TaxonomyBranchWithDisplayValue) => void;
  selected?: TaxonomyBranchWithDisplayValue;
}

const PixTaxonomyGlobalSelect = ({
  label,
  onChange,
  selected,
}: PixTaxonomyGlobalSelectProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [branchOptions, setBranchOptions] = useState<
    TaxonomyBranchWithDisplayValue[]
  >([]);

  const debounceSearch = _debounce((value: string) => {
    setSearchValue(value);
  }, 800);

  const searchForBranches = useCallback(() => {
    trackPromise(taxonomyService.getTaxonomiesByBranch([searchValue]))
      .then((res) => {
        const fetchItemBranches = res.map((item) => item.branch);

        const mergeFirstItems = (item: TaxonomyBranch[]): TaxonomyBranch[] => {
          const firstItem = `${item[0].label} / ${item[1].label}`;
          const remainingItems = item.slice(2);
          return [
            { label: firstItem, id: item[1].id, type: item[1].type },
            ...remainingItems,
          ];
        };

        const branchesWithFirstItemsMerged = _map(fetchItemBranches, (item) =>
          mergeFirstItems(item)
        );
        const formatDisplayValue = (branches: TaxonomyBranch[]) =>
          _map(branches, (item) => item.label).join(" > ");

        const formattedBranches = _map(
          branchesWithFirstItemsMerged,
          (item, index) => {
            return {
              branches: [...item],
              displayValue: formatDisplayValue(item),
              id: index + 1,
            };
          }
        );
        setBranchOptions(formattedBranches);
      })
      .catch((err) =>
        getErrorMessage("Não foi possível buscar por itens de taxonomia.")
      );
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      return;
    }
    searchForBranches();
  }, [searchValue, searchForBranches]);

  const handleChange = (value: number) => {
    if (value === 0) {
      setBranchOptions([]);
    }
    let selectedItem = _find(
      branchOptions,
      (branch: TaxonomyBranchWithDisplayValue) => branch.id === value
    );
    onChange(selectedItem || ({} as TaxonomyBranchWithDisplayValue));
  };

  return (
    <>
      <Select
        autoFocus
        className={styles.selectSearch}
        onChange={(value: number) => {
          handleChange(value);
        }}
        value={selected?.id || undefined}
        filterOption={false}
        onSearch={(value: string) => {
          debounceSearch(value);
        }}
        showSearch
        notFoundContent="Nenhum item encontrado. Digite um termo para buscar."
        label={label}
      >
        {selected && (
          <Option key={0} value={0}>
            Limpar valores
          </Option>
        )}
        {_map(branchOptions, (value) => (
          <Option key={value.id} value={value.id}>
            {value.displayValue}
          </Option>
        ))}
      </Select>
    </>
  );
};

export { PixTaxonomyGlobalSelect };
