import { uniq } from "lodash";
import { BrandsFilters } from "pages/Brands/components/BrandsAdvancedFilters";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { BrandListView } from "shared/models/Brand";
import { brandService } from "shared/services/BrandService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getInitialFilter } from "util/filter.util";
import { readTermQueryParam } from "util/yeh.query.param.util";
import { getTerms } from "./HookUtil";

const getInitialFilters = () =>
  ({
    deleted: "false",
    ...getInitialFilter("filters", BrandsFilters),
  } as BrandsFilters);

export const useBrands = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<BrandsFilters>(getInitialFilters());
  const [terms, setTerms] = useState(readTermQueryParam());
  const [last, setLast] = useState(true);
  const [brands, setBrands] = useState<BrandListView[]>([]);

  const fetchBrands = (page: number, filters: BrandsFilters) =>
    trackPromise(brandService.getBrands(page, filters))
      .then((response) => {
        setFilters(filters);
        setPage(response.page);
        setLast(response.last);
        setTerms((o) => getTerms(o, filters.term));
        setBrands((old) => [...(page > 0 ? old : []), ...response.content]);
      })
      .catch(() => {
        getErrorMessage("Erro ao buscar marca");
      });

  const handleRemoveFilter = (term: string) => {
    const newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);
    fetchBrands(0, { ...filters, term: newTerms.join(" ") });
  };

  const handleSearch = (value: string) => {
    const newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    const newFilters = { ...filters, term: newTerms.join(" ") };
    fetchBrands(0, newFilters);
  };

  const fetchMoreData = () => {
    if (!last) {
      fetchBrands(page + 1, filters);
    }
  };

  const refreshList = () => {
    fetchBrands(0, filters);
  };

  useEffect(() => {
    fetchBrands(0, getInitialFilters());
  }, []);

  return {
    terms,
    last,
    brands,
    filters,
    setFilters,
    fetchBrands,
    refreshList,
    fetchMoreData,
    handleSearch,
    handleRemoveFilter,
  };
};
