import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { ProductLinesFilters } from "pages/ProductLines/components/ProductLinesAdvancedFilters";
import { Word } from "react-wordcloud";
import {
  ProductLine,
  ProductLineBrand,
  ProductLineListView,
  ProductLineUpdate,
} from "shared/models/ProductLine";
import { Sankey } from "shared/models/Sankey";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/product-lines";
const INDICATORS_PATH = CORE_LOAD_BALANCER_URL + "/indicators/product-lines";

export interface GetProductLineBrandsParams {
  productLineId?: number;
  term?: string[];
  page?: number;
  manufacturerId?: number;
  brandId?: number;
  manufacturerBrandSanitation?: string;
  size?: number;
  sort?: string[];
}

export const defaultGetProductLineBrandsParams = Object.freeze<GetProductLineBrandsParams>(
  {
    term: [""],
    page: 0,
    size: 20,
  }
);

export class ListProductLineServiceFilters {
  term?: string[];
  manufacturerId?: number;
  brandId?: number;
  page?: number;
  sort?: string[];
  size?: number;
  deleted?: boolean;
  productLineId?: number;
}

export const defaultListProductLineServiceFiltersParams = Object.freeze<ListProductLineServiceFilters>(
  {
    term: [""],
    page: 0,
    sort: [""],
    size: 20,
  }
);

class ProductLineService {
  async getProductLines(
    page: number,
    filters: ProductLinesFilters,
    sort: string[] = [],
    size?: number
  ) {
    const params = objectToParams({ page, sort, size, ...filters });
    const r = await axios.request<PixResponsePage<ProductLineListView>>({
      method: "GET",
      url: BASE_PATH,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getProductLineById(id: number) {
    const r = await axios.request<ProductLine>({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getProductLineBrands(params: GetProductLineBrandsParams) {
    const {
      term,
      page,
      size,
      manufacturerId,
      brandId,
      manufacturerBrandSanitation,
      productLineId,
      sort,
    } = {
      ...defaultGetProductLineBrandsParams,
      ...params,
    };

    const axiosParams = objectToParams({
      term,
      page,
      size,
      sort,
      manufacturerId,
      brandId,
      manufacturerBrandSanitation,
    });
    const r = await axios.request<PixResponsePage<ProductLineBrand>>({
      method: "GET",
      url: `${BASE_PATH}/${productLineId}/brands`,
      params: axiosParams,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getSankeyIndicators(id: number) {
    const r = await axios.request<Sankey[]>({
      method: "GET",
      url: `${INDICATORS_PATH}/${id}/sankey`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getWordCloudIndicators(id: number, mode: boolean = true) {
    const r = await axios.request<Word[]>({
      method: "GET",
      url: `${INDICATORS_PATH}/${id}/word-cloud`,
      params: {
        mode,
      },
    } as AxiosRequestConfig);
    return r.data;
  }

  async save(productLine: Partial<ProductLineUpdate>) {
    const body: AxiosRequestConfig = {
      url: productLine.id ? `${BASE_PATH}/${productLine.id}` : BASE_PATH,
      headers: { "Content-Type": "application/json" },
      data: productLine,
    };
    const r = await axios.request({
      method: productLine.id ? "PUT" : "POST",
      ...body,
    });
    return r.data;
  }

  async saveProductLineBrands(lineId: number, brandIds: number[]) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/${lineId}/brands`,
      headers: { "Content-Type": "application/json" },
      data: brandIds,
    };
    const r = await axios.request(body);
    return r.data;
  }

  async removeBrandFromLine(lineId: number, brandId: number) {
    const r = await axios.request({
      method: "DELETE",
      url: `${BASE_PATH}/${lineId}/brands/${brandId}`,
      headers: { "Content-Type": "application/json" },
    } as AxiosRequestConfig);
    return r.data;
  }

  async merge(mergeRequest: MergeRequest) {
    const r = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data: mergeRequest,
    });
    return r.data;
  }
}

export const productLineService = new ProductLineService();
