import { Divider, message } from "antd";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemTextArea } from "components/FormItem/FormItemTextArea";
import { ImageUploadDragger } from "components/ImageUploadDragger/ImageUploadDragger";
import { Input } from "components/Input";
import { PixLink } from "components/PixLink/PixLink";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { SimilarityDisplay } from "components/SimilarityDisplay/SimilarityDisplay";
import { YehImage } from "components/YehImage/YehImage";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { SearchManufacturerSelect } from "containers/SearchManufacturersSelect/SearchManufacturerSelect";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  Brand,
  BrandById,
  BrandCreateRequest,
  BrandUpdateRequest,
  isBrandUpdate,
} from "shared/models/Brand";
import { Manufacturer } from "shared/models/Manufacturer";
import { BrandFormMetadata } from "shared/models/User";
import { brandService } from "shared/services/BrandService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { utcToPtBrDate } from "util/date.util";
import styles from "./CreateBrand.module.scss";

interface CreateBrandProps {
  brand?: BrandById;
  fixedManufacturer?: boolean;
  initialManufacturer?: Manufacturer;
  onSubmit: (brand: Brand) => void;
  onCancel: () => void;
}

export const CreateBrand = ({
  brand,
  fixedManufacturer,
  initialManufacturer,
  onSubmit,
  onCancel,
}: CreateBrandProps) => {
  const [brandId, setBrandId] = useState<number>();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [site, setSite] = useState<string>();
  const [manufacturerId, setManufacturerId] = useState<number>();
  const [deleted, setDeleted] = useState<boolean>();
  const [valid, setValid] = useState(true);
  const [similarBrands, setSimilarBrands] = useState<string[]>([]);
  const [loadingSimilarBrands, setLoadingSimilarBrands] = useState(false);
  const [brandSanitation, setBrandSanitation] = useState<string>();
  const [brandMetaData] = useState<BrandFormMetadata | null>(
    getEntityPermissions<BrandFormMetadata>("brand")
  );
  const [imageFile, setImageFile] = useState<any>();

  useEffect(() => {
    setValid(!!name?.length && !!description?.length && !!manufacturerId);
  }, [name, description, manufacturerId]);

  // Updates deleted when brand is read
  useEffect(() => {
    setBrandId(brand?.id);
    setName(brand?.name);
    setDescription(brand?.description);
    setDeleted(!!brand?.deleted);
    setManufacturerId(brand?.manufacturer.id);
    setBrandSanitation(brand?.brandSanitation?.value);
    setSite(brand?.site);
    setImageFile(undefined);
  }, [brand]);

  useEffect(() => {
    if (initialManufacturer) {
      setManufacturerId(initialManufacturer?.id);
    }
  }, [initialManufacturer]);

  const saveBrand = (brandRequest: BrandUpdateRequest | BrandCreateRequest) => {
    trackPromise(brandService.save(brandRequest))
      .then((response) => {
        message.success(
          `Marca ${
            isBrandUpdate(brandRequest.brand) ? "atualizada" : "cadastrada"
          } com sucesso!`
        );
        onSubmit(response);
      })
      .catch(() => {
        getErrorMessage(
          `Erro ao ${
            isBrandUpdate(brandRequest.brand) ? "atualizar" : "cadastrar"
          } marca`
        );
      });
  };

  const handleSubmit = (evt?: SyntheticEvent) => {
    evt?.preventDefault();
    saveBrand({
      brand: {
        id: brandId,
        description: description!,
        name: name!,
        site,
        manufacturerId: manufacturerId!,
        brandSanitation: brandSanitation,
        deleted,
        mainAsset: brand?.mainAsset,
      },
      file: imageFile,
    });
  };

  const getActionMessage = () => {
    if (!!brand?.totalProductLines && deleted) {
      return "Tem certeza que deseja desvincular a linha ativa e inativar a marca?";
    }

    return (
      "A marca está " +
      (deleted ? "ativa" : "inativa") +
      ". Tem certeza de que deseja " +
      (deleted ? "inativar" : "ativar") +
      "?"
    );
  };

  const onBlurBrandName = (value: string) => {
    value ? findSimilarBrands(value) : setSimilarBrands([]);
  };

  const findSimilarBrands = (value: string) => {
    setLoadingSimilarBrands(true);
    brandService
      .getBrands(0, { term: value }, [], 10)
      .then((res) => {
        setSimilarBrands(
          res.content.map(
            (brand) => `${brand.manufacturer.name} > ${brand.name}`
          )
        );
      })
      .catch(() => message.error("Erro ao buscar Marcas similares"))
      .finally(() => setLoadingSimilarBrands(false));
  };

  return (
    <div className={styles.wrapper}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormItemText
          id="create-brand-name"
          label="Marca"
          placeholder="Nome da marca"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
          onBlur={(evt) => onBlurBrandName(evt.target.value)}
          required
          allowClear
        />
        <div style={{ marginBottom: "3%" }}>
          <SimilarityDisplay
            values={similarBrands}
            loading={loadingSimilarBrands}
            entityNameToDisplay="Marcas"
          />
        </div>
        <SearchManufacturerSelect
          parentName="create-brand"
          onChange={setManufacturerId}
          fixedManufacturer={fixedManufacturer}
          initialManufacturer={initialManufacturer}
          fixedStatus
        />
        <FormItemTextArea
          id="create-brand-description"
          required
          label="Comentários"
          placeholder="Comentários"
          maxLength={300}
          autoSize={{ minRows: 4, maxRows: 6 }}
          onChange={(evt) => setDescription(evt.target.value)}
          value={description}
        />
        <FormItemText
          id="create-brand-site"
          label="Site"
          placeholder="Site"
          value={site}
          onChange={(evt) => setSite(evt.target.value)}
        />
        <SanitationSelect
          value={brandSanitation}
          onChange={setBrandSanitation}
          label="Saneamento"
        />
        {!!brand && (
          <YehImage
            imageUrl={
              imageFile ? URL.createObjectURL(imageFile) : brand.imageUrl
            }
            description={brand.name}
            maxWidth={"60%"}
          />
        )}
        <ImageUploadDragger setImageFile={setImageFile} />
        {!!brand && (
          <>
            <Input
              name="lastModified"
              id="brand-last-modified"
              label="Última atualização"
              placeholder="Última atualização"
              value={utcToPtBrDate(brand.lastModified)}
              disabled
            />
            <FormItemText
              id="create-brand-product-count"
              label="Total de produtos"
              placeholder="Total de produtos"
              value={brand.totalProducts}
              onChange={(evt) => setSite(evt.target.value)}
              disabled
              suffix={
                <PixLink
                  label="Ir para produtos"
                  link="/produto"
                  params={{ brandId: brand.id }}
                  paramsName="productFilters"
                />
              }
            />
            <Divider />
            <YehSwitch
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
              checked={!deleted}
              onChange={(f) => setDeleted(!f)}
            />
          </>
        )}
        <CancelAndSave
          onCancel={onCancel}
          showConfirm={!!brand && deleted !== brand.deleted}
          showConfirmTitle={getActionMessage()}
          onConfirm={handleSubmit}
          disabled={!brandMetaData?.update || !valid}
        />
      </form>
    </div>
  );
};
