import React, { useState, useEffect } from "react";
import styles from "./CombinationFilterOptions.module.scss";

import { filter, values } from "lodash";

import { Tooltip, Button } from "antd";
import { Icons } from "components/Icons";
import PixSearchByTerm from "containers/PixSearchByTerm/PixSearchByTerm";
import { AssociationFilterBase } from "shared/models/Association";

interface Props {
  filters: AssociationFilterBase;
  onAdvancedFilterClick: () => void;
  onSearch: (value: string[]) => void;
  onShowStatistics: () => void;
  showStatistics: boolean;
  tagColor: string;
}

export const CombinationFilterOptions = ({
  filters,
  onAdvancedFilterClick,
  onSearch,
  onShowStatistics,
  showStatistics = false,
  tagColor = "#32325d",
}: Props) => {
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => setSearchTerms(filters.term), [filters]);

  const handleSearch = (term: string) => {
    if (term && term.length) {
      const newFilter = [...searchTerms, term.trim()];
      onSearch(newFilter);
      setSearchValue("");
    }
  };

  const handleRemoveTerm = (term: string) => {
    if (term && term.length) {
      const newFilter = filter(searchTerms, (item) => item !== term);
      onSearch(newFilter);
    }
  };

  const filterCount = filter(values(filters), (item) => !!item && !!item.length)
    .length;

  return (
    <div className={styles.extraSection}>
      <div className={styles.productHeader}>
        <PixSearchByTerm
          filters={searchTerms}
          inputValue={searchValue}
          onChange={(value) => setSearchValue(value)}
          onClear={() => {
            setSearchTerms([]);
            setSearchValue("");
            onSearch([]);
          }}
          onRemoveFilter={handleRemoveTerm}
          onSearch={handleSearch}
          tagColor={tagColor}
        />
      </div>
      <div>
        {showStatistics && (
          <Tooltip
            title="Mostrar estatísticas de pesquisa realizada"
            placement="bottom"
          >
            <Button
              icon={<Icons name="areaChartOutlined" />}
              className={styles.cardButtons}
              onClick={onShowStatistics}
            />
          </Tooltip>
        )}
        <Tooltip title="Filtros Avançados" placement="bottom">
          <Button
            icon={<Icons name="filterOutlined" />}
            className={styles.cardButtons}
            onClick={onAdvancedFilterClick}
          >
            {filterCount > 0 && (
              <div className={styles.filterIndicator}>{filterCount}</div>
            )}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
