import axios, { AxiosRequestConfig, Method } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { ProductFilters } from "pages/Products/components/ProductsAdvancedFilters";
import {
  GtinCodeDetail,
  GtinCodeSimpleDetail,
  GtinCodeSimpleDetailFilter,
  GtinCreate,
  GtinListItem,
  UnitType,
} from "shared/models/GtinCode";
import { Pageable } from "shared/models/Pageable";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/gtins";

class GtinCodeService {
  findAllPackingUnits = async () => {
    const res = await axios.request<UnitType[]>({
      method: "GET",
      url: `${BASE_PATH}/packing-units`,
    } as AxiosRequestConfig);
    return res.data;
  };

  updateGtinPackingAttributes = async (gtins: GtinCodeSimpleDetail[]) => {
    const res = await axios.request<GtinCodeSimpleDetail[]>({
      method: "PATCH",
      url: `${BASE_PATH}/packing`,
      data: gtins,
    } as AxiosRequestConfig);
    return res.data;
  };

  findAll = async (filter: ProductFilters, pageable: Pageable) => {
    const res = await axios.request<PixResponsePage<GtinListItem>>({
      method: "GET",
      url: BASE_PATH,
      params: objectToParams({ ...filter, ...pageable }),
    } as AxiosRequestConfig);
    return res.data;
  };

  findDetailsByGtins = async (filter: GtinCodeSimpleDetailFilter) => {
    const res = await axios.request<GtinCodeSimpleDetail[]>({
      method: "GET",
      url: BASE_PATH + "/detail",
      params: objectToParams({ ...filter }),
    } as AxiosRequestConfig);
    return res.data;
  };

  findSalesCodesByGtins = async (gtins: string[]) => {
    const res = await axios.request<number[]>({
      method: "GET",
      url: BASE_PATH + `/sales-code`,
      params: objectToParams({ gtins }),
    });
    return res.data;
  };

  createSalesCode = async (gtin: string) => {
    const res = await axios.request<GtinCodeSimpleDetail>({
      method: "PUT",
      url: BASE_PATH + `/sales-code/${gtin}`,
    });
    return res.data;
  };

  updateMainAsset = async (gtin: string, mainAsset: number) => {
    const res = await axios.request<GtinCodeSimpleDetail>({
      method: "PUT",
      url: `${BASE_PATH}/${gtin}/main-asset/${mainAsset}`,
    } as AxiosRequestConfig);
    return res.data;
  };

  findById = async (gtin: string) => {
    const res = await axios.request<GtinCodeDetail>({
      method: "GET",
      url: `${BASE_PATH}/${gtin}`,
    } as AxiosRequestConfig);
    return res.data;
  };

  createUpdate = async (data: GtinCreate, method: Method) => {
    const res = await axios.request<GtinCodeDetail>({
      method,
      url: BASE_PATH,
      data,
    });
    return res.data;
  };

  create = async (data: GtinCreate) => this.createUpdate(data, "POST");
  update = async (data: GtinCreate) => this.createUpdate(data, "PUT");
}

export const gtinCodeService = new GtinCodeService();
