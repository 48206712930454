import { Button, Form, Input, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import EmptySelection from "components/EmptySelection/EmptySelection";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import OptionData from "shared/models/OptionData";
import { manufacturerService } from "shared/services/ManufacturerService";
import styles from "./DataClusterDetailAdvancedFilters.module.scss";

const { Option } = Select;

export class DataClusterFilter {
  authors?: string;
  bu?: string;
  clusterSize?: number;
  dataClusterId?: number;
  groupName?: string;
  hasIsbn?: string;
  isbns?: string[];
  multiplePublishers?: string;
  productIds?: number[];
  publisherIds?: number[];
  term?: string;
}

interface DataClusterAdvancedFiltersProps {
  dataClusterFilter: DataClusterFilter;
  onCancel: () => void;
  onOk: (dataClusterFilter: DataClusterFilter) => void;
  visible: boolean;
}

const DataClusterAdvancedFilters = ({
  dataClusterFilter,
  onCancel,
  onOk,
  visible,
}: DataClusterAdvancedFiltersProps) => {
  const [filters, setFilters] = useState<DataClusterFilter>({});
  const [publisherOptions, setPublisherOptions] = useState<OptionData[]>([]);
  const handleSearchPublisherIds = (term: string) => {
    if (term.length === 0) {
      return;
    }
    trackPromise(
      manufacturerService
        .getManufacturers(0, { term, deleted: "false" })
        .then((response) =>
          setPublisherOptions(
            response.content.map((manufacturer) => ({
              value: manufacturer.id,
              label: manufacturer.name,
            }))
          )
        )
    );
  };

  const debounceSearchPublisher = debounce((term: string) => {
    handleSearchPublisherIds(term);
  }, 500);

  const handleChangePublisherIds = (publisherIds: number[]) => {
    setFilters({ ...filters, publisherIds });
  };

  const handleFilterChange = (name: string, evt: any) => {
    evt.persist();
    setFilters({
      ...filters,
      [name]: evt.target.value,
    });
  };

  // Resets filter if user closes modal and gets back. Also if term changes.
  useEffect(() => setFilters(dataClusterFilter), [dataClusterFilter, visible]);

  return (
    <Modal
      visible={visible}
      onOk={() => onOk(filters)}
      onCancel={onCancel}
      cancelText="Cancelar"
      title={<h3>Filtros avançados para grupos</h3>}
    >
      <Form onFinish={onOk}>
        <Form.Item label={<FilterLabel text="Autores" htmlFor="authors" />}>
          <Input
            id="authors"
            value={filters.authors}
            onChange={(e) => handleFilterChange("authors", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel text="Quantidade de livros" htmlFor="cluster-size" />
          }
        >
          <Input
            id="cluster-size"
            value={filters.clusterSize}
            type="number"
            min="0"
            onChange={(e) => handleFilterChange("clusterSize", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel
              text="Identificador do grupo"
              htmlFor="data-cluster-id"
            />
          }
        >
          <Input
            id="data-cluster-id"
            value={filters.dataClusterId}
            type="number"
            min="1"
            onChange={(e) => handleFilterChange("dataClusterId", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel
              text="Identificadores de produtos"
              htmlFor="product-ids"
            />
          }
        >
          <Select<number[]>
            id="product-ids"
            className={styles.multiselect}
            mode="tags"
            onChange={(productIds) => setFilters({ ...filters, productIds })}
            tokenSeparators={[",", " "]}
            value={filters.productIds}
            open={false}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Descrição" htmlFor="description" />}
        >
          <Input
            id="description"
            value={filters.groupName}
            onChange={(e) => handleFilterChange("groupName", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Possui ISBN" htmlFor="has-isbn" />}
        >
          <Select
            id="has-isbn"
            allowClear
            value={filters.hasIsbn}
            onChange={(hasIsbn) => setFilters({ ...filters, hasIsbn })}
          >
            <Option value="true">Sim</Option>
            <Option value="false">Não</Option>
          </Select>
        </Form.Item>
        <Form.Item label={<FilterLabel text="ISBNs" htmlFor="isbns" />}>
          <Select<string[]>
            className={styles.multiselect}
            mode="tags"
            onChange={(value) => setFilters({ ...filters, isbns: value })}
            tokenSeparators={[",", " "]}
            value={filters.isbns}
            open={false}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel text="Várias editoras" htmlFor="multiple-publishers" />
          }
        >
          <Select
            id="multiple-publishers"
            allowClear
            value={filters.multiplePublishers}
            onChange={(multiplePublishers) =>
              setFilters({ ...filters, multiplePublishers })
            }
          >
            <Option value="true">Sim</Option>
            <Option value="false">Não</Option>
          </Select>
        </Form.Item>
        <Form.Item label={<FilterLabel text="Termo genérico" htmlFor="term" />}>
          <Input
            id="term"
            value={filters.term}
            onChange={(e) => handleFilterChange("term", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Editoras" htmlFor="publisher-ids" />}
        >
          <Select
            id="publisher-ids"
            mode="multiple"
            allowClear
            showSearch
            value={filters.publisherIds}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={debounceSearchPublisher}
            onChange={handleChangePublisherIds}
            notFoundContent={<EmptySelection />}
            options={publisherOptions}
          />
        </Form.Item>
        <div className={styles.cleanBtn}>
          <Button onClick={() => setFilters({})} type="link">
            Limpar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DataClusterAdvancedFilters;
