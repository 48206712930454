import { Select } from "antd";
import cx from "classnames";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { CharacteristicValue } from "shared/models/Characteristic";
import { ProductCharacteristic } from "shared/models/Product";
import { characteristicService } from "shared/services/CharacteristicService";
import { PixResponsePage } from "shared/services/PixResponse";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { scrollFunction } from "shared/utils/ScrollUtils";
import styles from "./PixSelectAttributeValue.module.scss";

const { Option } = Select;

type Props = {
  attributeId?: number;
  attributeType?: string;
  className?: string;
  onChangeValue: (value: number, option: any) => void;
  selectedValue?: Partial<ProductCharacteristic>;
};

export const PixSelectAttributeValue = ({
  attributeId,
  className,
  onChangeValue,
  selectedValue,
}: Props) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [lastPage, setLastPage] = useState(false);
  const [page, setPage] = useState(0);
  const [valuesList, setValuesList] = useState<CharacteristicValue[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueInList, setSearchValueInList] = useState(false);

  const processResultAfterService = useCallback(
    (result: PixResponsePage<CharacteristicValue>) => (
      oldValues: CharacteristicValue[]
    ) => {
      const processedResult: CharacteristicValue[] =
        page === 0 ? result.content : [...oldValues, ...result.content];
      if (
        selectedValue &&
        selectedValue.id &&
        processedResult.filter((item) => item.id === selectedValue.id)
          .length === 0
      ) {
        processedResult.unshift(
          convertSelectValueToCharacteristicValue(selectedValue)
        );
      }
      return processedResult;
    },
    [page, selectedValue]
  );

  const fetchValues = useCallback(
    (attributeId: number) => {
      trackPromise(
        characteristicService.getValues(attributeId, filters, page, false)
      )
        .then((result) => {
          setValuesList(processResultAfterService(result));
          setLastPage(result.last);
        })
        .catch((error) =>
          getErrorMessage("Erro ao buscar valores do atributo.")
        );
    },
    [filters, page] // eslint-disable-line
  );

  useEffect(() => {
    attributeId && fetchValues(attributeId);
  }, [filters, page]); // eslint-disable-line

  const debounceSearch = debounce((value) => {
    setPage(0);
    setFilters([value]);
    setSearchValue(value);
  }, 1000);

  const convertSelectValueToCharacteristicValue = (
    selectedValue: Partial<ProductCharacteristic>
  ) => {
    return {
      id: selectedValue.id!,
      description: selectedValue.value!,
      shortDescription: selectedValue.shortValue!,
      attributeId: selectedValue.attribute?.attributeId!,
      valueMask: selectedValue.valueMask,
    };
  };

  useEffect(() => {
    if (selectedValue && valuesList.length === 0) {
      setValuesList([convertSelectValueToCharacteristicValue(selectedValue)]);
    }
  }, [selectedValue, valuesList]);

  useEffect(() => {
    if (searchValue && searchValue.length) {
      setSearchValueInList(
        valuesList.filter((item) => item.description === searchValue).length > 0
      );
    } else {
      setSearchValueInList(false);
    }
  }, [searchValue, valuesList]);

  const onLoadMoreClick = () => {
    if (!lastPage) {
      setPage((oldpage) => oldpage + 1);
    }
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (open && attributeId && valuesList.length <= 1) {
      fetchValues(attributeId);
    }
  };

  return (
    <Select
      allowClear
      className={cx(styles.pixSelect, className)}
      defaultValue={selectedValue ? selectedValue.id : undefined}
      defaultActiveFirstOption
      filterOption={false}
      onChange={(value: number, option: any) => onChangeValue(value, option)}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onPopupScroll={(evnt) => scrollFunction(evnt, onLoadMoreClick)}
      onSearch={(value: string) => debounceSearch(value)}
      showSearch
    >
      {searchValue && searchValue.length > 0 && !searchValueInList && (
        <Option value={0} key={searchValue}>
          <b>Adicionar:</b>
          {" " + searchValue}
        </Option>
      )}
      {valuesList
        .filter((value) => !!value.id)
        .map((value) => (
          <Option key={value.id} value={value.id}>
            {value.description}
          </Option>
        ))}
    </Select>
  );
};
