import React, { memo } from "react";
import cx from "classnames";
import _map from "lodash/map";
import styles from "./Wordcloud.module.scss";

import ReactWordcloud, { Word, MinMaxPair, Callbacks } from "react-wordcloud";
import { Card, Button, Spin, Empty } from "antd";
import { Icons } from "components/Icons";

export const DEFAULT_WORDCLOUD_OPTIONS = {
  angles: 3,
  colors: ["#939393", "#565656"],
  deterministic: true,
  fontFamily: "HelveticaNeue, Arial, Helvetica, sans-serif",
  fontSizes: [12, 42] as MinMaxPair,
  fontWeight: "700",
  rotationAngles: [15, 60] as MinMaxPair,
  scale: "linear" as const,
  spiral: "archimedean" as const,
};

interface WordCloudProps {
  callbacks?: Partial<Callbacks>;
  disableButtons: boolean;
  handleFetching?: () => void;
  isLoadingWordcloud?: boolean;
  isWordcloudExpanded: boolean;
  onWordClick?: (word: Word, event?: MouseEvent) => void;
  setWordcloudExpanded: (value: boolean) => void;
  size?: MinMaxPair;
  values?: Word[];
}

export const getWordMaxAndMedian = (values?: Word[]) => {
  if (!values) {
    return { maxValue: 0, medianValue: 0 };
  }
  const wordValues = _map(values, (item: Word) => item.value);
  const maxValue = Math.max(...wordValues);
  const medianValue = wordValues[Math.floor(wordValues.length / 2)];
  return { maxValue, medianValue };
};

export const WordCloud = memo(
  ({
    callbacks,
    disableButtons,
    handleFetching,
    isLoadingWordcloud,
    isWordcloudExpanded,
    onWordClick,
    setWordcloudExpanded,
    size,
    values,
  }: WordCloudProps) => {
    const { maxValue, medianValue } = getWordMaxAndMedian(values);

    const wordCallbacks = {
      ...callbacks,
      onWordClick,
      getWordColor: (word: Word) =>
        word.value === maxValue
          ? "#000"
          : word.value > medianValue
          ? "#565656"
          : "#939393",
    };

    const wordcloudHeight = cx(styles.card, {
      [styles.fullHeight]: isWordcloudExpanded,
      [styles.regularHeight]: !isWordcloudExpanded,
    });

    if (!values) {
      return (
        <div className={styles.empty}>
          <Empty description="Selecione um registro para visualizar a nuvem de palavras." />
        </div>
      );
    }

    return (
      <Card className={wordcloudHeight}>
        <h2 className={styles.cardHeading}>Overview</h2>
        <div className={styles.wordCloudHeading}>
          <h3>Nuvem de Palavras</h3>
          <div className={styles.wordCloudActions}>
            <Button
              type="link"
              onClick={() => setWordcloudExpanded(!isWordcloudExpanded)}
              disabled={disableButtons}
            >
              <Icons
                name={
                  isWordcloudExpanded ? "shrinkOutlined" : "arrowsAltOutlined"
                }
              />
            </Button>
            {handleFetching && (
              <Button
                type="link"
                onClick={handleFetching}
                disabled={disableButtons}
              >
                <Icons name="retweetOutlined" />
              </Button>
            )}
          </div>
        </div>
        <div className={styles.divider} />
        {isLoadingWordcloud ? (
          <div className={styles.loader}>
            <Spin />
          </div>
        ) : (
          <div className={styles.wordCloud}>
            <ReactWordcloud
              size={size}
              options={DEFAULT_WORDCLOUD_OPTIONS}
              words={values}
              callbacks={wordCallbacks}
            />
          </div>
        )}
      </Card>
    );
  }
);
