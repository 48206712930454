import React from "react";
import {
  getQueryParameter,
  removeQueryParam,
  updateQueryParam,
} from "./query.param.util";

export function getInitialFilter<T extends object>(
  name: string,
  clazz: { new (): T } // Filters should be declared as class so we can take its keys
) {
  let filter: any;

  // Remove if empty
  const queryParam = getQueryParameter(name);
  if (!queryParam) {
    removeQueryParam(name);
    return {};
  }
  try {
    filter = JSON.parse(queryParam);

    // Create an object from the class so we can use its keys
    const allowedKeys = Object.keys(new clazz());
    const keys = Object.keys(filter);

    for (const key of keys) {
      // Maybe the user messed with param. We remove it
      if (!allowedKeys.includes(key)) {
        removeQueryParam(name);
        return {};
      }
    }
  } catch {
    filter = {};
  }
  const newParam = JSON.stringify(filter);
  if (newParam === "{}") {
    removeQueryParam(name);
  } else {
    updateQueryParam(name, JSON.stringify(filter));
  }
  return filter;
}

export const handleFilterChange = (
  name: string,
  evtOrValue: any,
  setFilters: React.Dispatch<React.SetStateAction<any>>
) => {
  if (evtOrValue?.persist) {
    evtOrValue.persist();
  }
  setFilters((oldFilters: any) => ({
    ...oldFilters,
    [name]:
      evtOrValue?.target?.value == null ? evtOrValue : evtOrValue.target.value,
  }));
};

// The inititial number array filter come as string, so we need to convert them to numbers
// TODO - Remove (Temporary function, the correct implementation would be to fix the updateQueryParam function)
export const getNormalizedNumberArrayFilter = (
  taxonomyIds: number[] | string
): number[] => {
  return String(taxonomyIds)
    .split(",")
    .filter((it) => it)
    .map((it) => Number(it));
};
