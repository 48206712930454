import { Alert } from "antd";
import { Icons } from "components/Icons";
import { PixInactiveAlert } from "components/PixInactiveAlert";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { PixTaxonomySelect } from "containers/PixTaxonomySelect/PixTaxonomySelect";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { BUSegment } from "shared/models/Taxonomy";
import {
  filterValidSegments,
  sortSegments,
  taxonomyService,
} from "shared/services/TaxonomyService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import TaxonomyList from "./TaxonomyList";
import styles from "./TaxonomyTab.module.scss";

interface CreateProductProps {
  taxonomiesIds?: number[];
  isInactive: boolean;
  productDescription: string;
  onSave: (taxonomiesIds: number[]) => Promise<void>;
  handleCancelEdit?: () => void;
}

const TaxonomyTab = ({
  taxonomiesIds,
  isInactive,
  productDescription,
  onSave,
  handleCancelEdit,
}: CreateProductProps) => {
  const [segments, setSegments] = useState<BUSegment[]>([]);
  const [taxonomiesTree, setTaxonomiesTree] = useState<BUSegment[]>([]);
  const [cachedTaxonomiesTree, setCachedTaxonomiesTree] = useState<
    BUSegment[]
  >();

  const fetchSegments = useCallback(() => {
    trackPromise(taxonomyService.getScopedTaxonomies())
      .then((result) => {
        const filteredSegments = filterValidSegments(result.content);
        filteredSegments.sort(sortSegments);
        setSegments(filteredSegments);
      })
      .catch((error) =>
        getErrorMessage("Erro ao buscar possíveis taxonomias.")
      );
  }, []);

  useEffect(fetchSegments, [fetchSegments]);

  const fetchProductTaxonomies = () => {
    if (!!taxonomiesIds && taxonomiesIds.length > 0) {
      trackPromise(taxonomyService.getTaxonomiesBranchTree(taxonomiesIds))
        .then((result) => {
          setTaxonomiesTree(result.content);

          // We only cache the first search so the user can restore original
          if (!cachedTaxonomiesTree) {
            setCachedTaxonomiesTree([...result.content]);
          }
        })
        .catch((error) =>
          getErrorMessage("Erro ao buscar taxonomias do produto.")
        );
    } else if (!cachedTaxonomiesTree) {
      setCachedTaxonomiesTree([]);
    }
  };

  useEffect(fetchProductTaxonomies, [taxonomiesIds]);

  const handleSave = (evt: SyntheticEvent) => {
    evt.preventDefault();
    let taxonomies = taxonomiesTree.map((it) => it.leafId);

    onSave(taxonomies).then(() => {
      // If user saves, the cancel should be updated
      setCachedTaxonomiesTree([...taxonomiesTree]);
    });
  };

  const handleRemoveTaxonomy = (index: number) => {
    let newTaxonomes = [...taxonomiesTree].filter((_, i) => i !== index);
    setTaxonomiesTree([...newTaxonomes]);
  };

  const handleAddTaxonomy = (newBusegment: BUSegment) =>
    setTaxonomiesTree([...taxonomiesTree, newBusegment]);

  const handleCancel = () => {
    if (!!cachedTaxonomiesTree) {
      setTaxonomiesTree([...cachedTaxonomiesTree]);
    }
    handleCancelEdit?.();
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSave}>
      <PixInactiveAlert isInactive={isInactive} />
      {!isInactive && (
        <Alert
          className={styles.alert}
          icon={<Icons name="infoCircleOutlined" />}
          message={
            <>
              Produto selecionado: <b>{productDescription}</b>
            </>
          }
          showIcon
          type="info"
        />
      )}
      {taxonomiesTree.length === 0 && (
        <Alert
          className={styles.alert}
          icon={<Icons name="warningOutlined" />}
          message="Produto sem Taxonomia cadastrada."
          showIcon
          type="warning"
        />
      )}

      <TaxonomyList
        taxonomiesTree={taxonomiesTree}
        handleRemoveTaxonomy={handleRemoveTaxonomy}
      />
      {!isInactive && (
        <>
          <div className={styles.divider} />
          <label className={styles.label}>Nova taxonomia</label>
          <PixTaxonomySelect
            segments={segments}
            onChange={handleAddTaxonomy}
            selectedBus={taxonomiesTree.map((it) => it.branch[1]?.id || -1)}
          />
          <div className={styles.divider} />
        </>
      )}
      {!isInactive && (
        <CancelAndSave
          onCancel={handleCancel}
          disabled={
            JSON.stringify(taxonomiesTree) ===
            JSON.stringify(cachedTaxonomiesTree)
          }
        />
      )}
    </form>
  );
};

export default TaxonomyTab;
