import { ArrowRightOutlined, MergeCellsOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";
import { Button, Dropdown, Menu } from "antd";
import cx from "classnames";
import { Icons } from "components/Icons";
import { ProtectedComponent } from "components/ProtectedComponent/ProtectedComponent";
import { get as _get } from "lodash";
import { linkToProduct } from "pages/Products/components/ProductsAdvancedFilters";
import React, { useState } from "react";
import { ApiRole } from "shared/models/ApiRole";
import { BuSegmentItem } from "shared/models/Taxonomy";
import styles from "./CategoryItem.module.scss";

interface CategoryItemProps {
  counter?: number;
  itemData: ItemData;
  isSelected?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  onDuplicateItem?: (item: BuSegmentItem) => void;
  onEditItem: (item: BuSegmentItem) => void;
  onMoveItem?: (item: BuSegmentItem) => void;
  onAsssociateExternal?: (item: BuSegmentItem) => void;
}

interface ItemData extends BuSegmentItem {
  onSelect: () => void;
}
interface DetailedSubtitleProps {
  subtitle: string;
  value: number;
}
interface IconisedSubtitleProps {
  subtitle: string;
  icon?: string;
}

const DetailedSubtitle = ({ subtitle, value }: DetailedSubtitleProps) => {
  return (
    <>
      <p className={styles.detailedSubtitleTitle}>{subtitle}</p>
      <p className={styles.detailedSubtitleValue}>{value}</p>
    </>
  );
};

const IconisedSubtitle = ({ subtitle, icon }: IconisedSubtitleProps) => {
  return (
    <div className={styles.iconisedSubtitleWrapper}>
      {icon && <Icons name={icon} />}
      <p style={{ marginLeft: icon ? "4px" : "" }}>{subtitle}</p>
    </div>
  );
};

export const CategoryItem = ({
  itemData,
  counter,
  isSelected,
  isLast,
  onDuplicateItem,
  onEditItem,
  onMoveItem,
  onAsssociateExternal,
}: CategoryItemProps) => {
  const [moreOptions, setMoreOptions] = useState(false);
  const itemWrapperStyle = cx([styles.itemWrapper], {
    [styles.thinWrapper]: counter === undefined,
    [styles.selectedItem]: isSelected,
  });
  const { icon, title, value, subtitle, onSelect, color } = itemData;
  const itemDataStyle = cx([styles.itemData], {
    [styles.centralizeContent]: !icon && !value,
  });

  const defineSubtitleComponent = (itemData: ItemData) => {
    const hasIcon = _get(itemData, "icon");
    if (hasIcon) {
      return (
        <div style={!isSelected ? { color } : undefined}>
          <IconisedSubtitle subtitle={subtitle!} icon={icon!} />
        </div>
      );
    }
    const hasValue = _get(itemData, "value");
    if (hasValue) {
      return <DetailedSubtitle subtitle={subtitle!} value={value!} />;
    }
    return (
      <div style={!isSelected ? { color } : undefined}>
        <IconisedSubtitle subtitle={subtitle!} />
      </div>
    );
  };

  const handleMoreOptions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMoreOptions(!moreOptions);
  };

  const options = () => {
    return (
      <Menu>
        {counter === 0 && (
          <Menu.Item key="0">
            <Link to={linkToProduct({ taxonomies: [itemData.id] })}>
              <Button type="link" className={styles.option}>
                <ArrowRightOutlined />
                <p>Listar Produtos</p>
              </Button>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="1">
          <Button
            type="link"
            className={styles.option}
            onClick={() => onEditItem(itemData)}
          >
            <Icons name="formOutlined" />
            <p>Editar</p>
          </Button>
        </Menu.Item>
        {onAsssociateExternal && (
          <Menu.Item key="2">
            <Button
              type="link"
              className={styles.option}
              onClick={() => onAsssociateExternal(itemData)}
            >
              <MergeCellsOutlined />
              <p>Associar</p>
            </Button>
          </Menu.Item>
        )}
        {onMoveItem && (
          <Menu.Item key="2">
            <Button
              type="link"
              className={styles.option}
              onClick={() => onMoveItem(itemData)}
            >
              <Icons name="swapOutlined" />
              <p>Mover</p>
            </Button>
          </Menu.Item>
        )}
        {onDuplicateItem && !isLast && (
          <Menu.Item key="3">
            <Button
              type="link"
              className={styles.option}
              onClick={() => onDuplicateItem(itemData)}
            >
              <Icons name="copyOutlined" />
              <p>Duplicar</p>
            </Button>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <div
      className={itemWrapperStyle}
      style={isSelected ? { backgroundColor: color } : {}}
      onClick={onSelect}
    >
      <div className={itemDataStyle}>
        <div className={styles.title}>{`${itemData.id} - ${title}`}</div>
        {defineSubtitleComponent(itemData)}
      </div>
      <div className={styles.itemActions}>
        <div
          className={styles.counter}
          style={
            isSelected
              ? { backgroundColor: "var(--color-white-with-opacity)" }
              : {}
          }
        >
          <Icons name="inboxOutlined" />
          <p>{counter}</p>
        </div>
        <div className={styles.buttonsWrapper} onClick={handleMoreOptions}>
          <ProtectedComponent
            allowedRoles={[ApiRole.SUPERVISOR, ApiRole.ADMIN]}
          >
            <Dropdown overlay={options} trigger={["click"]}>
              <button>
                <Icons name="customMore" />
              </button>
            </Dropdown>
          </ProtectedComponent>
          {!isLast && (
            <button className={styles.arrowButton} onClick={onSelect}>
              <Icons name="rightOutlined" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
