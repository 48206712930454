import axios from "axios";

import { UploadFile } from "antd/lib/upload/interface";
import { CORE_LOAD_BALANCER_URL } from "config/config";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/batch-process/import";

class UploadLambdaService {
  async uploadFile(file: UploadFile, filename: string, type: string) {
    const formData = new FormData();

    // append the file
    formData.append("file", file as any);

    // post the data on the s3 url
    const response = await axios.request({
      method: "POST",
      url: BASE_PATH + "?filename=" + filename + "&type=" + type,
      data: formData,
    });
    return response.data;
  }
}

export const uploadLambdaService = new UploadLambdaService();
