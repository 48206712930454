import React from "react";
import cx from "classnames";
import styles from "./Card.module.scss";

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

export const Card = ({ className, children }: CardProps) => {
  const customClass = cx(styles.baseClass, className);
  return (
    <div data-testid="card-wrapper" className={customClass}>
      {children}
    </div>
  );
};
