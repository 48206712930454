import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  RightOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Card, Col, Dropdown, Menu, message, Row, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Icons } from "components/Icons";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import DataCluster from "shared/models/DataCluster";
import dataClusterService from "shared/services/DataClusterService";
import styles from "./DataClusterList.module.scss";
interface DataClusterListProps {
  clearSelectedDataCluster: () => void;
  dataClusters: DataCluster[];
  selectedDataCluster?: DataCluster;
  setSelectedDataCluster: (dataCluster?: DataCluster) => void;
  setEditDataCluster: (dataCluster: DataCluster) => void;
  refresh: () => void;
}

const DataClusterList = ({
  clearSelectedDataCluster,
  dataClusters,
  selectedDataCluster,
  setSelectedDataCluster,
  setEditDataCluster,
  refresh,
}: DataClusterListProps) => {
  const [inactivateVisible, setInactivateVisible] = useState(false);
  const [blackListVisible, setBlackListVisible] = useState(false);

  const handleEdit = (dataCluster: DataCluster) => {
    setEditDataCluster(dataCluster);
  };

  const handleInactivate = (dataCluster: DataCluster) => {
    setSelectedDataCluster(dataCluster);
    setInactivateVisible(true);
  };

  const handleBlackList = (dataCluster: DataCluster) => {
    setSelectedDataCluster(dataCluster);
    setBlackListVisible(true);
  };

  const getOperationContent = (typeText: string) => (
    <React.Fragment key={typeText}>
      <p>
        Tem certeza que deseja <strong>{typeText}</strong> o seguinte grupo?
      </p>
      <p>
        <strong>{selectedDataCluster?.description}</strong>
      </p>
    </React.Fragment>
  );

  const submitInactivate = (dataCluster: DataCluster, deleted: boolean) => {
    trackPromise(dataClusterService.activateOrDeactivate(dataCluster, deleted))
      .then(() => {
        message.success(
          `Grupo ${deleted ? "inativado" : "reativado"} com sucesso`
        );
        refresh();
      })
      .catch(() =>
        message.error(`Erro ao ${deleted ? "inativar" : "reativar"} grupo`)
      )
      .finally(() => {
        setInactivateVisible(false);
        clearSelectedDataCluster();
      });
  };

  const submitBlackList = (dataCluster: DataCluster) => {
    trackPromise(dataClusterService.moveDataClusterToBlackList(dataCluster))
      .then(() => {
        message.success("Grupo movido para a black list com sucesso");
        refresh();
      })
      .catch(() => message.error("Falha ao mover grupo para black list"))
      .finally(() => {
        setBlackListVisible(false);
        clearSelectedDataCluster();
      });
  };

  return (
    <>
      {dataClusters.map((dataCluster) => {
        return (
          <Card
            key={dataCluster.dataClusterId}
            style={
              selectedDataCluster?.dataClusterId === dataCluster.dataClusterId
                ? { backgroundColor: "#dcf4ff" }
                : {}
            }
          >
            <Row>
              <Col span={20}>{dataCluster.description}</Col>
              <Col span={4}>
                <div className={styles.counter}>
                  <Icons name="inboxOutlined" />
                  <p>{dataCluster.clusterSize}</p>
                </div>
                {dataCluster.deleted && (
                  <div className={styles.counter}>
                    <p>Inativo</p>
                  </div>
                )}
              </Col>
            </Row>
            <Space />
            <Row>
              <Col span={20} className={styles.dataClusterCategory}>
                Categoria: {dataCluster.category}
              </Col>
              <Col span={4} className={styles.actionItems}>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => handleEdit(dataCluster)}>
                        <EditOutlined />
                        Editar
                      </Menu.Item>
                      <Menu.Item onClick={() => handleInactivate(dataCluster)}>
                        {!dataCluster.deleted ? (
                          <>
                            <DeleteOutlined />
                            Inativar
                          </>
                        ) : (
                          <>
                            <CheckCircleOutlined />
                            Reativar
                          </>
                        )}
                      </Menu.Item>
                      <Menu.Item onClick={() => handleBlackList(dataCluster)}>
                        <StopOutlined />
                        Mover todo o grupo para a black list
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MoreOutlined />
                </Dropdown>
                <RightOutlined
                  onClick={() => setSelectedDataCluster(dataCluster)}
                />
              </Col>
            </Row>
          </Card>
        );
      })}
      <Modal
        visible={inactivateVisible}
        onCancel={() => setInactivateVisible(false)}
        cancelText="Cancelar"
        onOk={() =>
          submitInactivate(selectedDataCluster!, !selectedDataCluster!.deleted)
        }
      >
        {getOperationContent(
          selectedDataCluster?.deleted ? "reativar" : "inativar"
        )}
      </Modal>
      <Modal
        visible={blackListVisible}
        onCancel={() => setBlackListVisible(false)}
        cancelText="Cancelar"
        onOk={() => submitBlackList(selectedDataCluster!)}
      >
        {getOperationContent("mover para a black list")}
      </Modal>
    </>
  );
};

export default DataClusterList;
