import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { join as _join } from "lodash";
import {
  BUSegment,
  TaxonomiesToSave,
  Taxonomy,
  TaxonomyBranch,
  TaxonomyToUpdate,
  TaxonomyType,
} from "shared/models/Taxonomy";
import { generateBaseSearchParams } from "shared/utils/QueryUtils";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";
import { Pageable } from "shared/models/Pageable";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/taxonomies";

class TaxonomyService {
  async getTaxonomies(
    term: string[] = [""],
    pageable: Pageable = { page: 0, sort: [""] },
    parentId?: number,
    type?: string
  ) {
    const params = objectToParams({ term, parentId, type, ...pageable });
    const r = await axios.request<PixResponsePage<Taxonomy>>({
      method: "GET",
      url: BASE_PATH,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getTaxonomiesByBranch(terms: string[] = [""], branchId?: number) {
    const params = new URLSearchParams();
    terms.forEach((term) => params.append("term", term));
    branchId && params.append("branchId", String(branchId));
    params.append("types", "SEGMENT"); // Fixo por conta do serviço
    const r = await axios.request<BUSegment[]>({
      method: "GET",
      url: BASE_PATH + "/branch",
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getTaxonomiesBranchTree(
    taxonomyIds: number[],
    term: string[] = [""],
    page: number = 0,
    sort: string[] = [""]
  ) {
    const params = generateBaseSearchParams(term, page, sort);
    taxonomyIds &&
      taxonomyIds.forEach((taxonomyId) =>
        params.append("taxonomyIds", String(taxonomyId))
      );
    const r = await axios.request<PixResponsePage<BUSegment>>({
      method: "GET",
      url: BASE_PATH + "/taxonomy/trees",
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getTaxonomyType(
    taxonomyTypeIds?: number[],
    description?: string,
    type?: string
  ) {
    const params = new URLSearchParams();
    description && params.append("description", description);
    type && params.append("type", type);
    taxonomyTypeIds &&
      taxonomyTypeIds.forEach((id) =>
        params.append("taxonomyTypeIds", String(id))
      );
    const r = await axios.request<TaxonomyType[]>({
      method: "GET",
      url: `${BASE_PATH}/type`,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async createTaxonomy(taxonomy: TaxonomiesToSave) {
    const body: AxiosRequestConfig = {
      method: "POST",
      url: BASE_PATH,
      headers: { "Content-Type": "application/json" },
      data: taxonomy,
    };
    const r = await axios.request<Taxonomy>(body);
    return r.data;
  }

  async updateTaxonomy(id: number, taxonomy: TaxonomyToUpdate) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/${id}`,
      headers: { "Content-Type": "application/json" },
      data: taxonomy,
    };
    const r = await axios.request<Taxonomy>(body);
    return r.data;
  }

  async updateTaxonomyType(
    typeId: number,
    maxLevel: number,
    description?: string
  ) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/type/${typeId}`,
      headers: { "Content-Type": "application/json" },
      data: {
        description,
        maxLevel,
      },
    };

    const r = await axios.request<Taxonomy>(body);
    return r.data;
  }

  async createTaxonomyType(description: string, maxLevel: number) {
    const body: AxiosRequestConfig = {
      method: "POST",
      url: BASE_PATH + "/type",
      headers: { "Content-Type": "application/json" },
      data: { type: "SEGMENT", description, maxLevel },
    };
    const r = await axios.request<TaxonomyType>(body);
    return r.data;
  }

  async getScopedTaxonomies(
    term: string[] = [""],
    page: number = 0,
    sort: string[] = [""]
  ) {
    const params = generateBaseSearchParams(term, page, sort, "100");
    params.append("scopedBranchTypes", "BU");
    params.append("scopedBranchTypes", "SEGMENT");
    const r = await axios.request<PixResponsePage<BUSegment>>({
      method: "GET",
      url: `${BASE_PATH}/scopedBranch`,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }
}

export const taxonomyService = new TaxonomyService();

const mapSegmentsBranch = (branch: TaxonomyBranch[]) => {
  return _join(branch.map((item) => item.label));
};

export const filterValidSegments = (segments: BUSegment[]) => {
  return segments.filter((item) => item.branch && item.branch.length === 2);
};

export const sortSegments = (item1: BUSegment, item2: BUSegment) => {
  const label1 = mapSegmentsBranch(item1.branch);
  const label2 = mapSegmentsBranch(item2.branch);
  return label1.indexOf("Yandeh") >= 0 ? -1 : label1.localeCompare(label2);
};
