import { UserPermissionsMetadata } from "shared/models/User";

export const USER_PERMISSION_STORAGE_KEY = "userPermissions";

export function getEntityPermissions<T>(
  entity:
    | "attribute"
    | "brand"
    | "subBrand"
    | "manufacturer"
    | "product"
    | "productLine"
): T | null {
  const userPermissions: UserPermissionsMetadata = JSON.parse(
    sessionStorage.getItem(USER_PERMISSION_STORAGE_KEY)!
  ) as UserPermissionsMetadata;

  if (!userPermissions) {
    return null;
  }
  return (userPermissions as any)[entity] as T;
}
