import { message } from "antd";
import EmptySelection from "components/EmptySelection/EmptySelection";
import { TabItem } from "components/Tabs/Tabs";
import {
  AttributeEdit,
  AttributeValueWrapper,
} from "containers/AttributeEdit/AttributeEdit";
import { CharacteristicsDisplay } from "containers/CharacteristicsDisplay/CharacteristicsDisplay";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import { EmptySelectionWrapper } from "containers/EmptySelectionWrapper/EmptySelectionWrapper";
import { PackageTab } from "pages/Products/components/PackageTab";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { GtinCodeDetail } from "shared/models/GtinCode";
import { characteristicService } from "shared/services/CharacteristicService";
import { gtinCodeService } from "shared/services/GtinCodeService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { GtinDetail } from "./GtinDetail";
import { GtinImagesTab } from "./GtinImagesTab";

export const gtinTabs: TabItem[] = [
  {
    title: "Detalhe",
    value: "DETALHE",
  },
  {
    title: "Características",
    value: "CARACTERISTICAS",
  },
  {
    title: "Embalagens",
    value: "EMBALAGENS",
  },
  {
    title: "Imagens",
    value: "IMAGENS",
  },
];

interface GtinDetailProps {
  tab: TabItem;
  gtin?: string;
  handleCancelClick: () => void;
  creating: boolean;
  isCreateExpanded: boolean;
  setTab: (t: TabItem) => void;
  setCreating: (flag: boolean) => void;
  refreshList: () => void;
  setCreateExpanded: (newValue: boolean) => void;
}

export const GtinTabs = ({
  tab,
  gtin,
  handleCancelClick,
  creating,
  isCreateExpanded,
  setTab,
  refreshList,
  setCreating,
  setCreateExpanded,
}: GtinDetailProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gtinDetail, setGtinDetail] = useState<GtinCodeDetail>();

  const toggleModal = () => setModalOpen((f) => !f);

  const getGtinDetail = useCallback(
    (gtin: string) => {
      trackPromise(
        gtinCodeService.findById(gtin).then((gtin) => {
          setGtinDetail(gtin);
          setCreating(false);
        })
      );
    },
    [setCreating]
  );

  const handleAttribute = (attributes: AttributeValueWrapper[]) => {
    trackPromise(characteristicService.updateGtin(gtin!, attributes))
      .then((response) => {
        setGtinDetail(response);
        toggleModal();
        refreshList();
        message.success("GTIN atualizado com sucesso");
      })
      .catch(() => {
        getErrorMessage("Erro ao buscar detalhes do gtin");
      });
  };

  const getCharacteristicsTab = () => {
    if (!gtinDetail) {
      return <EmptySelection />;
    }
    return (
      <>
        <AttributeEdit
          attributes={gtinDetail?.gtinAttributes || []}
          title={`Editar características do gtin ${gtinDetail?.gtin}`}
          onConfirm={handleAttribute}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          productLineId={gtinDetail.productLineId}
        />
        <CharacteristicsDisplay attributes={gtinDetail?.gtinAttributes || []} />
      </>
    );
  };

  const getImagesTab = () => {
    if (!gtinDetail) {
      return <EmptySelection />;
    }

    return (
      <GtinImagesTab
        gtinDetail={gtinDetail}
        refreshList={() => {
          refreshList();
          getGtinDetail(gtinDetail.gtin);
        }}
      />
    );
  };

  const handleRefreshDetail = (gtin: string) => {
    getGtinDetail(gtin);
  };

  useEffect(() => {
    if (gtin) {
      getGtinDetail(gtin);
    } else {
      setGtinDetail(undefined);
    }
  }, [getGtinDetail, gtin]);

  const onChangeTab = (item: TabItem) => {
    if (item.value === "EMBALAGENS") {
      setCreateExpanded(true);
    }
    setTab(item);
  };

  const afterGenerateSalesCode = (modifiedGtin: string) => {
    if (modifiedGtin === gtin) {
      refreshList();
    }
  };

  return (
    <>
      <CrudCreateComponent
        handleSelectedTab={onChangeTab}
        isCreateExpanded={isCreateExpanded}
        tabItems={gtinTabs}
        selectedTab={tab}
        setCreateExpanded={setCreateExpanded}
      />
      {tab.value === "DETALHE" &&
        (!creating && !gtinDetail ? (
          <EmptySelection />
        ) : (
          <GtinDetail
            gtinDetail={gtinDetail}
            handleCancelClick={handleCancelClick}
            refreshList={refreshList}
            refreshDetail={handleRefreshDetail}
            closeCreating={() => setCreating(false)}
          />
        ))}
      {tab.value === "CARACTERISTICAS" && getCharacteristicsTab()}
      {tab.value === "IMAGENS" && getImagesTab()}
      {tab.value === "EMBALAGENS" && (
        <div style={{ overflowX: "scroll" }}>
          <EmptySelectionWrapper emptyOn={gtinDetail}>
            <PackageTab
              productId={gtinDetail?.productId!}
              handleExpandTab={setCreateExpanded}
              isCreateExpanded={isCreateExpanded}
              afterSave={refreshList}
              afterGenerateSalesCode={afterGenerateSalesCode}
            />
          </EmptySelectionWrapper>
        </div>
      )}
    </>
  );
};
