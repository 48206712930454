import { message } from "antd";

export type PixError = {
  status: number;
  title: string;
};

export const getErrorMessage = (genericMessage: string) => {
  message.error(genericMessage);
};
