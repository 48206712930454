import { Divider, message } from "antd";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemTextArea } from "components/FormItem/FormItemTextArea";
import { Input } from "components/Input";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { SimilarityDisplay } from "components/SimilarityDisplay/SimilarityDisplay";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { useEffect, useReducer, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AttributeTypes } from "shared/models/Characteristic";
import {
  ProductLineAttribute,
  ProductLine as ProductLineType,
} from "shared/models/ProductLine";
import { ProductLineFormMetadata } from "shared/models/User";
import { productLineService } from "shared/services/ProductLineService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { utcToPtBrDate } from "util/date.util";
import { AssignedBrands } from "./AssignedBrands";

interface CreateProductLineProps {
  productLineId?: number;
  productLine?: ProductLineType;
  refresh: () => void;
}

type ProductLineT = {
  name?: string;
  description?: string;
  productLineSanitation?: string;
  deleted?: boolean;
};

const productLineReducer = (prev: ProductLineT, next: ProductLineT) => {
  return { ...prev, ...next };
};

export const CreateProductLine = ({
  productLine,
  refresh,
}: CreateProductLineProps) => {
  const [state, dispatch] = useReducer(productLineReducer, {});
  const [productLineMetaData] = useState<ProductLineFormMetadata | null>(
    getEntityPermissions<ProductLineFormMetadata>("productLine")
  );
  const [similarProductLines, setSimilarProductLines] = useState<string[]>([]);
  const [loadingSimilarProductLines, setLoadingSimilarProductLines] = useState(
    false
  );

  const findSimilarLinesName = (value: string) => {
    setLoadingSimilarProductLines(true);
    productLineService
      .getProductLines(0, { term: value }, [], 10)
      .then((response) => {
        setSimilarProductLines(
          response.content.map(
            (productLine) => `${productLine.id} - ${productLine.name}`
          )
        );
      })
      .catch(() => message.error("Erro ao buscar Linhas similares"))
      .finally(() => setLoadingSimilarProductLines(false));
  };

  const onBlurLineName = (value: string) => {
    value ? findSimilarLinesName(value) : setSimilarProductLines([]);
  };

  const getActionMessage = () => {
    if (state.deleted && state.deleted !== productLine?.deleted) {
      return "Tem certeza que deseja inativar a linha?";
    }

    return (
      "A linha está " +
      (state.deleted ? "ativa" : "inativa") +
      ". Tem certeza de que deseja " +
      (state.deleted ? "inativar" : "ativar") +
      "?"
    );
  };

  const handleSubmit = () => {
    const attributes: ProductLineAttribute[] = productLine?.attributes
      ? Object.keys(productLine.attributes)
          .flatMap((k) => productLine.attributes![k as AttributeTypes])
          .map((att) => ({ id: att.id, suggested: att.suggested }))
      : [];

    trackPromise(
      productLineService.save({
        description: state.description,
        productLineSanitation: state.productLineSanitation,
        id: productLine?.id,
        name: state.name,
        attributes,
        deleted: state.deleted,
      })
    )
      .then(() => {
        message.success("Linha atualizada com sucesso");
        refresh();
      })
      .catch(() => getErrorMessage("Erro ao atualizar linha"));
  };

  const onCancel = () => {};

  useEffect(() => {
    dispatch({
      name: productLine?.name,
      description: productLine?.description,
      productLineSanitation: productLine?.productLineSanitation?.value,
      deleted: productLine?.deleted,
    });
  }, [productLine]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormItemText
        id="pl-detail-name"
        label="Linha"
        placeholder="Nome da linha"
        value={state.name}
        onChange={(evt) => dispatch({ name: evt.target.value })}
        required
        allowClear
        onBlur={(e) => onBlurLineName(e.currentTarget.value)}
      />
      <SimilarityDisplay
        values={similarProductLines}
        loading={loadingSimilarProductLines}
        entityNameToDisplay="Linhas"
      />
      <FormItemTextArea
        id="pl-detail-description"
        required
        label="Comentários"
        placeholder="Comentários"
        autoSize={{ minRows: 4, maxRows: 6 }}
        onChange={(evt) => dispatch({ description: evt.target.value })}
        value={state.description}
      />

      <SanitationSelect
        value={state.productLineSanitation}
        onChange={(productLineSanitation) =>
          dispatch({ productLineSanitation })
        }
        label="Saneamento"
      />

      {!!productLine && (
        <>
          <Input
            name="lastModified"
            id="pl-detail-last-modified"
            label="Última atualização"
            placeholder="Última atualização"
            value={utcToPtBrDate(productLine.lastModified)}
            disabled
          />
          <AssignedBrands productLineId={productLine.id} />
          <Divider />
          <YehSwitch
            checkedChildren="Ativo"
            unCheckedChildren="Inativo"
            checked={!state.deleted}
            onChange={(deleted) => dispatch({ deleted: !deleted })}
          />
        </>
      )}
      <CancelAndSave
        onCancel={onCancel}
        showConfirm={!!productLine && productLine.deleted !== state.deleted}
        showConfirmTitle={getActionMessage()}
        onConfirm={handleSubmit}
        disabled={!productLineMetaData?.update}
      />
    </form>
  );
};
