import { Button, Drawer, message, Radio } from "antd";
import { Icons } from "components/Icons";
import { SimpleImageModal } from "components/SimpleImageModal/SimpleImageModal";
import { PixImage } from "containers/PixImage";
import { ProductDetailPopover } from "containers/ProductDetailPopover/ProductDetailPopover";
import { ProductEdit } from "pages/Products/components/ProductEdit";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductById, ProductListItem } from "shared/models/Product";
import { productService } from "shared/services/ProductService";
import { makeEllipsedString } from "shared/utils/Utils";
import styles from "./ProductCard.module.scss";
import { ProductDetails } from "./ProductDetails";

interface ProductCardProps {
  data: ProductListItem;
  isExpanded?: boolean;
  isSelected?: boolean;
  onExpand: (id?: number) => void;
  onSelect: (product: ProductListItem) => void;
  onAssociationClick: () => void;
  fetchProductData: (page: number) => void;
}

interface DataItemProps {
  title: string;
  description: string;
  fullDescription?: string;
}

const DataItem = ({ title, description, fullDescription }: DataItemProps) => (
  <div className={styles.subtitleWrapper} title={fullDescription}>
    <p className={styles.subtitle}>{title}</p>
    <p className={styles.subtitleContent}>{description}</p>
  </div>
);

export const NewProductCard = ({
  data,
  isSelected,
  onSelect,
  isExpanded,
  onExpand,
  onAssociationClick,
  fetchProductData,
}: ProductCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditExpanded, setIsEditExpanded] = useState(false);
  const [product, setProduct] = useState<ProductById>();
  const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);

  const expandButtonIcon = isExpanded
    ? { icon: "minusOutlined", text: "Detalhes" }
    : { icon: "plusOutlined", text: "Detalhes" };

  const handleExpand = () => {
    if (!isExpanded) {
      findProductDetail();
      onExpand(data.productId);
      handleSelection(data.productId);
    } else {
      onExpand(undefined);
    }
  };

  const handleSelection = (id: number) => (isSelected ? null : onSelect(data));

  const closeDrawer = () => {
    setIsEditing(false);
    setIsEditExpanded(false);
  };

  const afterEditProduct = () => {
    fetchProductData(0);
    findProductDetail();
  };

  const findProductDetail = () => {
    trackPromise(productService.getById(data.productId))
      .then(setProduct)
      .catch(() => message.error("Erro ao buscar detalhes do produto."));
  };

  return (
    <div
      className={styles.whiteBackgroundFix}
      onClick={() => handleSelection(data.productId)}
    >
      <SimpleImageModal
        isOpen={isImageModalOpen}
        imageUrl={data.imageUrl || ""}
        onClose={() => {
          setImageModalOpen(false);
        }}
      />
      <div className={isExpanded ? styles.expandedCard : ""}>
        <div className={styles.cardWrapper}>
          <Radio data-testid="product-card-radio" checked={isSelected} />
          <div className={styles.picture}>
            <div
              className={styles.productImage}
              onClick={() => setImageModalOpen(true)}
            >
              <PixImage
                imageUrl={data.imageUrl}
                alt={data.description}
                width="86px"
                height="86px"
              />
            </div>
          </div>
          <div className={styles.featuresWrapper}>
            <DataItem
              title="L:"
              description={makeEllipsedString(data.productLine.name, 15)}
              fullDescription={data.productLine.name}
            />
            <DataItem
              title="M:"
              description={makeEllipsedString(data.brand.name, 15)}
              fullDescription={data.brand.name}
            />
            <DataItem
              title="F:"
              description={makeEllipsedString(data.brand.manufacturer.name, 15)}
              fullDescription={data.brand.manufacturer.name}
            />
            <DataItem
              title="D:"
              description={makeEllipsedString(data.description, 65)}
              fullDescription={data.description}
            />
            <div className={styles.detailsButtonContainer}>
              <Button className={styles.detailsButton} onClick={handleExpand}>
                <Icons name={expandButtonIcon.icon} />
                <p>{expandButtonIcon.text}</p>
              </Button>
            </div>
            <ProductDetailPopover data={data} />
          </div>
        </div>
        {isExpanded && product && (
          <ProductDetails
            product={product}
            onAssociationClick={onAssociationClick}
            onClickEdit={() => setIsEditing(true)}
            addAttribute={() => {}}
          />
        )}
        <Drawer
          title="Editar produto"
          placement="right"
          onClose={closeDrawer}
          visible={isEditing}
          width={isEditExpanded ? "85%" : "25%"}
          destroyOnClose
          zIndex={10}
        >
          <ProductEdit
            isEditing={true}
            productProp={product}
            handleCancelProductEdit={closeDrawer}
            isCreateExpanded={isEditExpanded}
            setCreateExpanded={setIsEditExpanded}
            afterEdit={afterEditProduct}
          />
        </Drawer>
      </div>
    </div>
  );
};
