import { Manufacturer, newManufacturer } from "shared/models/Manufacturer";
import { Sanitation } from "./Sanitation";
export interface BrandCreate {
  name: string;
  description: string;
  site?: string;
  manufacturerId: number;
  brandSanitation?: string;
  mainAsset?: number;
}

export interface BrandUpdate extends BrandCreate {
  id: number;
  deleted: boolean;
  imageUrl?: string;
}

export function isBrandUpdate(
  brand: BrandUpdate | BrandCreate
): brand is BrandUpdate {
  return (brand as BrandUpdate).id !== undefined;
}

export interface BrandCreateRequest {
  brand: BrandCreate;
  file: any;
}

export interface BrandUpdateRequest {
  brand: BrandUpdate;
  file: any;
}

export interface Brand {
  id: number;
  name: string;
  description: string;
  site?: string;
  manufacturer: Manufacturer;
  manufacturerId: number;
  deleted: boolean;
  mainAsset?: number;
  imageUrl?: string;
  lastModified?: string;
  brandSanitation?: Sanitation;
}

export type BrandById = Brand & {
  totalProducts: number;
  totalProductLines: number;
};

export const newBrand = {
  name: "",
  description: "",
  manufacturer: { ...newManufacturer },
  site: "",
  brandSanitation: undefined,
} as Partial<Brand>;

export type BrandListView = Brand & {
  totalProductLines: number;
  productLineBrandId?: number;
};

export interface AdaptedBrandData {
  id: number;
  name: string;
  brandId?: number;
}
