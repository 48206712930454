import axios from "axios";
import { CustomPackaging } from "shared/models/CustomPackaging";
import { objectToParams } from "util/query.param.util";

import { CORE_LOAD_BALANCER_URL } from "config/config";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/custom-packaging";

class CustomPackagingService {
  async findAllByProductId(
    productId: number,
    page: number = 0,
    sort: string[] = ["id,asc"]
  ) {
    const params = objectToParams({ page, sort });

    const r = await axios.request<PixResponsePage<CustomPackaging>>({
      method: "GET",
      url: BASE_PATH + "/" + productId,
      params,
    });
    return r.data;
  }

  async putCustomPackaging(packaging: CustomPackaging[]) {
    const r = await axios.request<CustomPackaging[]>({
      method: "PUT",
      url: BASE_PATH,
      data: packaging,
    });
    return r.data;
  }

  async deleteCustomPackaging(packagingId: number) {
    const r = await axios.request<void>({
      method: "DELETE",
      url: BASE_PATH + "/" + packagingId,
    });
    return r.data;
  }
}

export const customPackagingService = new CustomPackagingService();
