import { MergeCellsOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { ExtractLogsButton } from "components/AuditLogExtract/ExtractLogsButton";
import EmptySelection from "components/EmptySelection/EmptySelection";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { PixColumn } from "components/PixInfinityTable/PixInfinityTable";
import { TabItem } from "components/Tabs/Tabs";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { useSubBrands } from "hook/SubBrandHook";
import React, { useContext, useEffect, useState } from "react";
import { EntitiesEnum } from "shared/enum/EntitiesEnum";
import { SubBrandListFilter, SubBrandListItem } from "shared/models/SubBrand";
import { SubBrandFormMetadata } from "shared/models/User";
import { subBrandService } from "shared/services/SubBrandService";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { readInitialTab } from "util/yeh.query.param.util";
import { SubBrandEdit } from "./SubBrandEdit";
import { SubBrandAdvancedFilters } from "./SubBrandsAdvancedFilters";

type SubBrandListProps = DefaultPage & RouteComponentProps;

const FIRST_PAGE = 0;

const tabItems: TabItem[] = [
  {
    title: "Sub Marca",
    value: "subBrand",
  },
];

const subBrandListColumns: PixColumn<SubBrandListItem>[] = [
  {
    title: "ID Sub Marca",
    dataIndex: "subBrandId",
    key: "subBrandId",
  },
  {
    title: "Sub Marca",
    dataIndex: "subBrandName",
  },
  {
    title: "Marca",
    dataIndex: "brandName",
  },
  {
    title: "Ativo",
    align: "center" as any,
    render: (_: string, item: SubBrandListItem) => (
      <ActiveInactiveItem deleted={item.deleted} />
    ),
  },
  {
    key: "sanitation",
    title: "Saneamento",
    dataIndex: ["subBrandSanitation", "label"],
    width: 100,
    align: "center" as any,
  },
];

export const SubBrandList = ({ title }: SubBrandListProps) => {
  const { setCurrentTitle } = useContext(PageContext);

  const {
    last,
    terms,
    filters,
    subBrands,
    clearFilters,
    handleSearch,
    fetchMoreData,
    fetchSubBrands,
    handleRemoveFilter,
  } = useSubBrands();

  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const [selected, setSelected] = useState<SubBrandListItem>();
  const [isWriting, setWriting] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(readInitialTab(tabItems));
  const [isListExpanded, setListExpanded] = useState(false);
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<SubBrandListItem[]>([]);
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [subBrandMetaData] = useState<SubBrandFormMetadata | null>(
    getEntityPermissions<SubBrandFormMetadata>("subBrand")
  );

  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  const handleCreateNewClick = () => {
    setSelected(undefined);
    setWriting(true);
  };

  const handleSelectedTab = (value: TabItem) => {
    setSelectedTab(value);
  };

  const getOnSelectItem = (brands: SubBrandListItem[]) => {
    if (isMergeMode) {
      return setSelectedItems(brands);
    }
    return onSelectSubBrand(brands);
  };

  const onSelectSubBrand = (values: SubBrandListItem[]) => {
    setSelectedRows(values.map((suBrand) => suBrand.subBrandId));
    setSelected(values?.length === 1 ? values[0] : undefined);
  };

  const onClickMergeMode = () => {
    if (isMergeMode) {
      setSelectedRows([]);
    }

    setIsMergeMode(!isMergeMode);
  };

  const getActionButtons = (
    <>
      <Button
        onClick={onClickMergeMode}
        icon={<MergeCellsOutlined />}
        id="btn-merge"
      >
        {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
      </Button>

      <ExtractLogsButton
        entityName={EntitiesEnum.SUB_BRAND}
        entityIds={selectedRows}
        hasPermission={subBrandMetaData?.readAudit}
      />
    </>
  );

  const onChangeMultiSelect = () => {
    setSelectedRows([]);
    setSelected(undefined);
    setWriting(false);
  };

  const secondaryActions = (
    <>
      <ToggleMultiSelectButton
        disabled={isMergeMode}
        multiselectMode={multiselectMode}
        setMultiselectMode={setMultiselectMode}
        onChange={onChangeMultiSelect}
      />
    </>
  );

  const getSearchComponent = () => {
    return (
      <CrudListContent<SubBrandListItem>
        columns={subBrandListColumns}
        actionButtons={getActionButtons}
        secondaryActions={secondaryActions}
        data={subBrands}
        fetchMoreData={fetchMoreData}
        filterProps={{
          terms,
          advancedFilters: filters,
          handleClearFilters: clearFilters,
          handleFilterIconClick: () => setAdvancedFilterOpen((open) => !open),
        }}
        deletedStatusProps={{
          onChangeDeletedStatus: (deleted) =>
            fetchSubBrands(0, { ...filters, deleted }),
          value: filters.deleted,
        }}
        last={last}
        setExpanded={setListExpanded}
        handleSearch={handleSearch}
        rowKey="subBrandId"
        setSelected={getOnSelectItem}
        title="Listagem de Sub Marcas"
        isExpanded={isListExpanded}
        handleRemoveFilter={handleRemoveFilter}
        handleCreateNewClick={handleCreateNewClick}
        selectedRows={selectedRows}
        multiselect={multiselectMode && !isMergeMode}
      />
    );
  };

  const handleCancel = () => {
    setSelected(undefined);
    setWriting(false);
  };

  const handleModalSubmit = (filters: SubBrandListFilter) => {
    fetchSubBrands(0, filters);
    setAdvancedFilterOpen(false);
  };

  const getCreateComponent = () => {
    if (!selected && !isWriting) {
      return <EmptySelection />;
    }
    return (
      <SubBrandEdit
        subBrandId={selected?.subBrandId}
        afterSave={(subBrand) => {
          setSelected(subBrand);
          fetchSubBrands(0, filters);
        }}
        afterCancel={handleCancel}
      />
    );
  };

  const onRemoveMergeItem = (remainingItems: SubBrandListItem[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.subBrandId)
      .filter((itemId) =>
        remainingItems.some((remainItem) => remainItem.subBrandId === itemId)
      );
    setSelectedRows(newSelectedRows);
  };

  const getEditSubBrandComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<SubBrandListItem>
          data={selectedItems}
          entityName="subBrand"
          getItemId={(subBrand) => subBrand.subBrandId}
          getLabeledItem={(subBrand) =>
            `${subBrand.subBrandId} - ${subBrand.subBrandName} (Marca: ${subBrand.brandName})`
          }
          mergeItems={(mergeRequest) => {
            return subBrandService.merge(mergeRequest);
          }}
          afterMerge={() => fetchSubBrands(FIRST_PAGE, filters)}
          onCancel={() => {
            setIsMergeMode(false);
            setSelectedRows([]);
            setSelectedItems([]);
          }}
          onRemoveItem={onRemoveMergeItem}
        />
      );
    }

    return (
      <>
        <CrudCreateComponent
          handleSelectedTab={handleSelectedTab}
          isCreateExpanded={isCreateExpanded}
          tabItems={tabItems}
          selectedTab={selectedTab}
          setCreateExpanded={setCreateExpanded}
        />
        {selectedTab.value === "subBrand" && getCreateComponent()}
      </>
    );
  };

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getEditSubBrandComponent()}
        isListExpanded={isListExpanded}
        isCreateExpanded={isCreateExpanded}
      />
      <SubBrandAdvancedFilters
        originalFilters={filters}
        onClose={() => setAdvancedFilterOpen(false)}
        onConfirm={handleModalSubmit}
        visible={advancedFilterOpen}
        parentName="sub-brand"
      />
    </>
  );
};
