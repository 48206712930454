import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row, Tag } from "antd";
import React from "react";
import { BUSegment } from "shared/models/Taxonomy";
import styles from "./TaxonomyTab.module.scss";

interface TaxonomyListProps {
  taxonomiesTree: BUSegment[];
  handleRemoveTaxonomy: (index: number) => void;
}

export const getFullTaxonomyName = (segment: BUSegment) =>
  segment.branch
    .slice(2)
    .map((item) => item.label)
    .join(" > ");

export const getSegmentName = (segment: BUSegment) =>
  segment.branch
    .slice(0, 2)
    .map((item) => item.label)
    .join(" > ");

const TaxonomyList = ({
  taxonomiesTree,
  handleRemoveTaxonomy,
}: TaxonomyListProps) => {
  return (
    <>
      {taxonomiesTree.map((taxonomy, index) => (
        <Row
          key={taxonomy.branch[0].id + "-" + taxonomy.leafId}
          justify="space-around"
          align="middle"
          className={styles.taxonomiesWrapper}
          gutter={[8, 0]}
        >
          <Col span={22}>
            <Tag className={styles.taxonomyItem} color="geekblue">
              {getSegmentName(taxonomy)}
            </Tag>
            <Tag
              className={styles.taxonomyItem}
              title={getFullTaxonomyName(taxonomy)}
            >
              {getFullTaxonomyName(taxonomy)}
            </Tag>
          </Col>
          <Col span={2}>
            <DeleteOutlined onClick={() => handleRemoveTaxonomy(index)} />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default TaxonomyList;
