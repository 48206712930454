import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { AttributeValueWrapper } from "containers/AttributeEdit/AttributeEdit";
import { AttributeCreate, AttributeDto } from "shared/models/Attribute";
import {
  AttributeUsageCountDto,
  Characteristic,
  CharacteristicById,
  CharacteristicFilters,
  CharacteristicValue,
} from "shared/models/Characteristic";
import { GtinCodeDetail } from "shared/models/GtinCode";
import { ProductById } from "shared/models/Product";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";
import { generateBaseSearchParams } from "shared/utils/QueryUtils";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";
import { NameAlias } from "./SelectOptionType";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/attributes";

class CharacteristicService {
  async getCharacteristics(
    filters: CharacteristicFilters,
    page: number = 0,
    sort: string[] = [""],
    size: string = "20"
  ): Promise<PixResponsePage<Characteristic>> {
    const params = objectToParams({ ...filters, page, sort, size });
    const r = await axios.request({
      method: "GET",
      url: BASE_PATH,
      params: params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async countUsages(id: number) {
    const r = await axios.request<AttributeUsageCountDto>({
      method: "GET",
      url: `${BASE_PATH}/${id}/usages`,
    });
    return r.data;
  }

  async getById(id: number) {
    const r = await axios.request<CharacteristicById>({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getTypes() {
    const r = await axios.request<NameAlias[]>({
      method: "GET",
      url: `${BASE_PATH}/types`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getValues(
    attributeId: number,
    term: string[] = [""],
    page: number = 0,
    deleted: boolean = false,
    sort: string[] = [""],
    size: string = "20",
    entityAttributeId?: number
  ) {
    let params = generateBaseSearchParams(term, page, sort, size);

    params.append("attributeId", String(attributeId));
    params.append("deleted", String(deleted));

    entityAttributeId &&
      params.append("entityAttributeId", String(entityAttributeId));

    const r = await axios.request<PixResponsePage<CharacteristicValue>>({
      method: "GET",
      url: `${BASE_PATH}/values`,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async saveValue(id: number, value: Partial<CharacteristicValue>) {
    const body: AxiosRequestConfig = {
      method: "POST",
      url: `${BASE_PATH}/${id}/values`,
      headers: { "Content-Type": "application/json" },
      data: value,
    };
    const r = await axios.request<CharacteristicValue>(body);
    return r.data;
  }

  async updateValue(id: number, value: Partial<CharacteristicValue>) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/${id}/values/${value.id}`,
      headers: { "Content-Type": "application/json" },
      data: value,
    };
    const r = await axios.request<CharacteristicValue>(body);
    return r.data;
  }

  async save(data: AttributeCreate | AttributeDto) {
    const body: AxiosRequestConfig = {
      url: BASE_PATH,
      data,
    };
    const r = await axios.request<AttributeDto>({
      method: "id" in data ? "PUT" : "POST",
      ...body,
    });
    return r.data;
  }

  async merge(mergeRequest: MergeRequest) {
    const r = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data: mergeRequest,
    });
    return r.data;
  }

  async updateProduct(productId: number, attributes: AttributeValueWrapper[]) {
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: `${BASE_PATH}/product/${productId}`,
      data: { attributes },
    };
    const r = await axios.request<ProductById>(request);
    return r.data;
  }

  async updateGtin(gtin: string, attributes: AttributeValueWrapper[]) {
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: `${BASE_PATH}/gtin/${gtin}`,
      data: { attributes },
    };
    const r = await axios.request<GtinCodeDetail>(request);
    return r.data;
  }

  async deleteByAttributeAndAttributeValue(
    attributeId: number,
    attributeValueId: number
  ) {
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `${BASE_PATH}/${attributeId}/value/${attributeValueId}`,
    };
    return await axios.request<void>(request);
  }
}

export const characteristicService = new CharacteristicService();
