import { Empty } from "antd";
import React from "react";
import type * as CSS from "csstype";

import styles from "./YehImage.module.scss";

interface YehImageProps {
  imageUrl?: string;
  description?: string;
  maxWidth?: string;
}

export const YehImage = ({
  imageUrl,
  description,
  maxWidth,
}: YehImageProps) => {
  const [imageNotFound, setImageNotFound] = React.useState(false);

  const onError = (_: any) => {
    if (imageUrl) {
      setImageNotFound(true);
    }
  };

  const getEmptyProps = () => {
    if (imageNotFound) {
      return {
        description: "Imagem não encontrada (404)",
      };
    }

    return {
      image: Empty.PRESENTED_IMAGE_SIMPLE,
      description: "Sem imagem",
    };
  };

  const style: CSS.Properties = {
    maxWidth: maxWidth,
    height: "auto",
  };

  return (
    <div className={styles.imgWrapper}>
      {!imageNotFound && !!imageUrl ? (
        <img
          alt={description}
          src={imageUrl}
          className={maxWidth ? "" : styles.imgProps}
          style={maxWidth ? style : undefined}
          onError={onError}
        />
      ) : (
        <Empty {...getEmptyProps()} />
      )}
    </div>
  );
};
