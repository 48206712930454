import { RouteComponentProps } from "@reach/router";
import { message } from "antd";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { SingleContent } from "containers/SingleContent";
import { orderBy as _orderBy } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  BuSegmentItem,
  TaxonomyBranch,
  TaxonomyItem,
} from "shared/models/Taxonomy";
import { taxonomyService } from "shared/services/TaxonomyService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import styles from "./Categories.module.scss";
import {
  adaptCategoryList,
  generateBaseItem,
  generateBuSegmentItem,
} from "./categoryAdapters";
import { Container } from "./components/Container";
import { CreateCategorySidenav } from "./components/CreateCategorySidenav";
import { CreateCategoryTreeSidenav } from "./components/CreateCategoryTreeSidenav";
import { Subcontainer } from "./components/Subcontainer";
import { MoveCategorySidenav } from "./components/MoveCategorySidenav";
import { ExternalTaxonomySideNav } from "./components/ExternalTaxonomySideNav";

type CategoriesProps = DefaultPage & RouteComponentProps;
interface SelectedItem {
  segmentId: string;
  selectedItem: number | null;
  order: number;
}

interface SearchValue {
  id: number;
  value: string;
  order: number;
  currentPage: number;
}

const sortSegments = (item1: BuSegmentItem, item2: BuSegmentItem) => {
  if (!item1.subtitle || !item2.subtitle) {
    return 0;
  }
  return item1.subtitle.includes("Yandeh")
    ? -1
    : item2.subtitle.includes("Yandeh")
    ? 1
    : item1.subtitle.localeCompare(item2.subtitle);
};

const Categories = ({ title }: CategoriesProps) => {
  /* Itens de primeiro nível */
  const [buSegmentList, setBuSegmentList] = useState<BuSegmentItem[]>([]);
  const [buSegmentPage, setBuSegmentPage] = useState<number>(0);
  const [buSegmentTerm, setBuSegmentTerm] = useState<string>("");
  const [hasMoreBuSegments, setHasMoreBuSegments] = useState<boolean>(false);

  /* Itens de segundo nivel adiante */
  const [categoryItemMaxLevel, setCategoryItemMaxLevel] = useState<number>(0);
  const [categoryItemList, setCategoryItemList] = useState<TaxonomyItem[]>([]);
  const [selectedCategoryItems, setSelectedCategoryItems] = useState<
    SelectedItem[]
  >([]);
  const [categoryItemSearchValues, setCategoryItemSearchValues] = useState<
    SearchValue[]
  >([]);

  const { setCurrentTitle } = useContext(PageContext);
  const [isCreateCategoryTreeOpen, setIsCreateCategoryTreeOpen] = useState<
    BuSegmentItem | boolean
  >(false);
  const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState<{
    parent: TaxonomyItem;
    item?: BuSegmentItem;
  }>();
  const [isMovingCategory, setMovingCategoryMode] = useState(false);
  const [isExternalTaxonomyOpen, setIsExternalTaxonomyOpen] = useState(false);

  const fetchScopedTaxonomies = useCallback(
    (term?: string[]) => {
      trackPromise(taxonomyService.getScopedTaxonomies(term, buSegmentPage))
        .then((res) => {
          // TODO Ajustar o serviço para não ser necessário fazer isso
          const filterSingleItems = res.content.filter(
            (item) => item.branch.length > 1
          );
          setHasMoreBuSegments(!res.last);
          setBuSegmentList((oldList) =>
            buSegmentPage > 0
              ? [...oldList, ...generateBuSegmentItem(filterSingleItems)]
              : [...generateBuSegmentItem(filterSingleItems)]
          );
        })
        .catch((err) => getErrorMessage("Erro ao buscar bus e segmentos."));
    },
    [buSegmentPage]
  );

  const displaySelectedTaxonomyBase = async (data: any) => {
    const BuNames = data && data[0].label.split("/");
    if (!BuNames) {
      return;
    }
    const { content: buData } = await trackPromise(
      taxonomyService.getScopedTaxonomies([BuNames[0].trim()], 0)
    );
    const taxonomyTypeData = await trackPromise(
      taxonomyService.getTaxonomyType([data[0].type])
    );
    const remainingItems = generateBaseItem(
      taxonomyTypeData[0].maxLevel
    ).filter((item) => item.order > data.length - 1);
    const filterSingleItems = buData.filter((item) => item.branch.length > 1);
    if (BuNames[0].trim().toLowerCase() !== "yandeh") {
      const selectedItem = filterSingleItems.filter(
        (item) => item.leafId === data[0].id
      );
      const buSegmentItemList = generateBuSegmentItem(selectedItem);
      setBuSegmentList([...buSegmentItemList]);
      setCategoryItemList((items) => [...items, ...remainingItems]);
      handleCategorySelection(buSegmentItemList[0], true, false);
    }
    if (BuNames[0].trim().toLowerCase() === "yandeh") {
      const buSegmentItemList = generateBuSegmentItem(filterSingleItems);
      setBuSegmentList([...buSegmentItemList]);
      await handleCategorySelection(buSegmentItemList[0], false, false);
    }
  };

  const displaySelectedTaxonomyBranches = async (
    data: any,
    parentId: number,
    order: number
  ) => {
    if (!data) {
      return;
    }
    const { content: branchContent } = await trackPromise(
      taxonomyService.getTaxonomies(undefined, undefined, parentId)
    );
    const taxonomyTypeData = await trackPromise(
      taxonomyService.getTaxonomyType([data.type])
    );
    setCategoryItemMaxLevel(taxonomyTypeData[0].maxLevel || 0);
    if (taxonomyTypeData.length > 0 && order < taxonomyTypeData[0].maxLevel) {
      const selectedData = branchContent.filter(
        (item) => item.leafId === data.id
      );
      const adaptItem = adaptCategoryList(selectedData, parentId, order);
      await handleCategorySelection(adaptItem[0], false, false, true);
    }
  };

  useEffect(() => {
    setCurrentTitle(title);
  }, [title, setCurrentTitle]);

  useEffect(() => {
    fetchScopedTaxonomies([]);
  }, [fetchScopedTaxonomies]);

  const handleItemSelection = (
    id: number,
    subcontainer: string,
    order: number,
    selectedByMenu?: boolean
  ) => {
    const designatedSubcontainer =
      order === 0 ? "Árvore de Categorias" : subcontainer;
    const filterExceedingSubcontainers = selectedCategoryItems.filter(
      (item) => item.order <= order
    );
    const otherSubcontainers = filterExceedingSubcontainers.filter(
      (item) => item.segmentId !== designatedSubcontainer
    );
    const currentSubcontainer = {
      segmentId: designatedSubcontainer,
      selectedItem: id,
      order,
    };
    const orderedCategoryItems = _orderBy(
      [...otherSubcontainers, currentSubcontainer],
      "order"
    );
    setSelectedCategoryItems((latestItems) => {
      return selectedByMenu ? latestItems : orderedCategoryItems;
    });
    if (!selectedByMenu) {
      setCategoryItemList((items) => {
        const filterRemainingItems = items.filter(
          (item) => item.order <= order
        );
        const generateRemainingItems = generateBaseItem(items.length).filter(
          (item) => item.order > order
        );
        return [...filterRemainingItems, ...generateRemainingItems];
      });
    }
  };

  const handleSelectedItem = (subcontainer: string) => {
    const currentSubcontainer = selectedCategoryItems.find(
      (item) => item.segmentId === subcontainer
    );
    if (!currentSubcontainer) {
      return null;
    }
    return currentSubcontainer.selectedItem;
  };

  const handleBuChange = (value?: string) => {
    if (!value || !value.length) {
      setBuSegmentTerm("");
      handleBuSegmentItemSearch();
      return;
    }
    setBuSegmentTerm(value);
  };

  const handleBuSegmentItemSearch = (item?: string) => {
    const buSegmentSearchableTerm =
      !item || item.length === 0 ? [] : [buSegmentTerm];
    fetchScopedTaxonomies(buSegmentSearchableTerm);
  };

  const handleCategoryItemSearch = (id: number, page: number = 0) => {
    const findItem = categoryItemSearchValues.find((item) => item.id === id);
    if (!findItem) {
      return null;
    }
    fetchCategoryItemDataByTerm(
      findItem.id,
      findItem.order,
      page,
      findItem.value
    );
  };

  const handleCategoryItemSearchChange = ({
    id,
    value,
    order,
  }: SearchValue) => {
    const filterSearchValues = categoryItemSearchValues.filter(
      (item) => item.id !== id
    );
    const adaptedSearchValue = { id, value, order, currentPage: 0 };
    setCategoryItemSearchValues([adaptedSearchValue, ...filterSearchValues]);
    if (!value || !value.length) {
      fetchCategoryItemDataByTerm(id, order, 0, "");
    }
  };

  const handleCategoryItemSearchValue = (id: number) => {
    const findItem = categoryItemSearchValues.find((item) => item.id === id);
    return findItem?.value || "";
  };

  const fetchBuData = () => setBuSegmentPage(buSegmentPage + 1);

  useEffect(fetchScopedTaxonomies, [buSegmentPage, fetchScopedTaxonomies]);

  const fetchCategoryItemDataByTerm = (
    id: number,
    order: number,
    currentPage: number,
    term: string
  ) => {
    const currentCategoryItem = categoryItemList.find((item) => item.id === id);
    if (!currentCategoryItem) {
      return;
    }
    trackPromise(
      taxonomyService.getTaxonomies([term], { page: currentPage }, id)
    )
      .then((res) => {
        const adaptedCategoryItem = {
          ...currentCategoryItem,
          hasMore: !res.last,
          currentPage: res.page,
          content: [
            ...adaptCategoryList(res.content, currentCategoryItem.id, order),
          ],
        };
        const replaceCategoryItem = categoryItemList.filter(
          (item) => item !== currentCategoryItem
        );
        const filterSearchValues = categoryItemSearchValues.filter(
          (item) => item.id !== id
        );
        const adaptedSearchValue = {
          id,
          value: term,
          order,
          currentPage: res.page,
        };
        setCategoryItemList(
          _orderBy([...replaceCategoryItem, adaptedCategoryItem], "order")
        );
        setCategoryItemSearchValues([
          ...filterSearchValues,
          adaptedSearchValue,
        ]);
      })
      .catch((err) =>
        getErrorMessage("Não foi possível buscar mais dados do item.")
      );
  };

  const fetchCategoryItemData = (
    id: number,
    order: number,
    currentPage: number,
    refresh?: boolean
  ) => {
    const currentCategoryItem = categoryItemList.find((item) => item.id === id);
    if (!currentCategoryItem) {
      return;
    }

    trackPromise(
      taxonomyService.getTaxonomies(
        undefined,
        { page: currentPage + 1, sort: [] },
        id
      )
    )
      .then((res) => {
        const adaptedCategoryItem = {
          ...currentCategoryItem,
          hasMore: !res.last,
          currentPage: res.page,
          content: refresh
            ? [...adaptCategoryList(res.content, currentCategoryItem.id, order)]
            : [
                ...(currentCategoryItem.content || []),
                ...adaptCategoryList(
                  res.content,
                  currentCategoryItem.id,
                  order
                ),
              ],
        };
        const replaceCategoryItem = categoryItemList.filter(
          (item) => item !== currentCategoryItem
        );
        setCategoryItemList(
          _orderBy([...replaceCategoryItem, adaptedCategoryItem], "order")
        );
      })
      .catch((err) =>
        getErrorMessage("Não foi possível buscar mais dados do item.")
      );
  };

  const fetchTaxonomiesByParentId = async (
    selectedId: number,
    order: number,
    typeId: number,
    title: string,
    color: string,
    categoryItemMaxLevel: number,
    segmentId: number,
    subtitle?: string
  ) => {
    try {
      const res = await trackPromise(
        taxonomyService.getTaxonomies(undefined, undefined, selectedId)
      );
      setCategoryItemList((oldCategoryItemList) => {
        const filteredItems = oldCategoryItemList.filter(
          (item) => item.order < order
        );
        const adaptedSelectedCategory = {
          order,
          id: selectedId,
          typeId,
          segmentId: segmentId,
          title: subtitle || title,
          currentPage: res.page,
          color,
          hasMore: !res.last,
          content: adaptCategoryList(res.content, selectedId, order),
        };
        const remainingLevels = generateBaseItem(categoryItemMaxLevel).filter(
          (item) => {
            return item.order > order;
          }
        );
        return [...filteredItems, adaptedSelectedCategory, ...remainingLevels];
      });
    } catch (error) {
      getErrorMessage("Erro ao buscar categorias.");
    }
  };

  const handleCategorySelection = async (
    selectedCategory: BuSegmentItem,
    isFirstLevel?: boolean,
    isLast?: boolean,
    selectedByMenu?: boolean,
    maxLevel?: number
  ) => {
    const {
      id,
      order,
      typeId,
      segmentId,
      subtitle,
      title,
      color,
    } = selectedCategory;
    handleItemSelection(id, String(segmentId), order, selectedByMenu);
    if (isLast) {
      return;
    }
    await trackPromise(
      taxonomyService.getTaxonomies(undefined, undefined, selectedCategory.id)
    ).then((res) => {
      const filteredItems = categoryItemList.filter(
        (item) => item.order <= selectedCategory.order
      );
      const adaptedSelectedCategory = {
        id,
        typeId,
        segmentId: selectedCategory.segmentId,
        order: order + 1,
        title: subtitle || title,
        color,
        currentPage: res.page,
        hasMore: !res.last,
        content: adaptCategoryList(
          res.content,
          selectedCategory.id,
          selectedCategory.order + 1
        ),
      };
      if (isFirstLevel) {
        trackPromise(taxonomyService.getTaxonomyType([typeId]))
          .then((result) => {
            const remainingLevels = generateBaseItem(result[0].maxLevel).filter(
              (item) => {
                return item.order > adaptedSelectedCategory.order;
              }
            );
            setCategoryItemMaxLevel(result[0].maxLevel);
            return setCategoryItemList([
              ...filteredItems,
              adaptedSelectedCategory,
              ...remainingLevels,
            ]);
          })
          .catch((err) =>
            getErrorMessage("Não foi possível buscar pelos níveis de produto.")
          );
      }
      setCategoryItemList((currentCategoryItemList) => {
        const alreadySelectedItems = currentCategoryItemList.filter(
          (item) => item.order < adaptedSelectedCategory.order
        );
        const remainingItems = currentCategoryItemList.filter(
          (item) => item.order > adaptedSelectedCategory.order
        );
        return [
          ...alreadySelectedItems,
          adaptedSelectedCategory,
          ...remainingItems,
        ];
      });
      handleCategoryItemSearchChange({
        id,
        value: "",
        order,
        currentPage: res.page,
      });
    });
  };

  const handleDuplicateItem = (selectedItem: BuSegmentItem) => {
    const newTaxonomyPayload = {
      descriptions: [selectedItem.title],
      taxonomyTypeId: selectedItem.typeId,
      parentId: selectedItem.id,
    };
    trackPromise(taxonomyService.createTaxonomy(newTaxonomyPayload))
      .then(async () => {
        message.success("Item duplicado com sucesso.");
        await reloadItemChildsFromParentId(selectedItem.segmentId);
        await reloadItemChildsFromParentId(selectedItem.id);
      })
      .catch((err) => getErrorMessage("Erro ao duplicar item."));
  };

  const handleCreateTreeSubmit = () => {
    fetchScopedTaxonomies([]);
    fetchSelectItem();
    setIsCreateCategoryTreeOpen(false);
  };

  const reloadItemChilds = async (item: TaxonomyItem | BuSegmentItem) => {
    const { id, order, segmentId, typeId, title, color } = item;
    if (order === 1) {
      fetchScopedTaxonomies([]);
    }
    await fetchTaxonomiesByParentId(
      id,
      order,
      typeId,
      title,
      color,
      categoryItemMaxLevel,
      segmentId
    );
  };

  const findItemInCategoryItemList = (itemId?: number) => {
    if (!itemId) {
      return undefined;
    }
    return categoryItemList.find((item) => item.id === itemId);
  };

  const reloadItemChildsFromParentId = async (parentId?: number) => {
    if (!!parentId) {
      const item = findItemInCategoryItemList(parentId);
      !!item && (await reloadItemChilds(item));
    }
  };

  const handleCreateCategory = async () => {
    if (!!selectedCategoryToEdit && !!selectedCategoryToEdit.parent) {
      const parentId = selectedCategoryToEdit.parent.segmentId;
      reloadItemChildsFromParentId(parentId);
      await reloadItemChilds(selectedCategoryToEdit.parent);
    }
    setSelectedCategoryToEdit(undefined);
  };

  const handleBuSegmentList = useCallback(() => {
    return buSegmentList.sort(sortSegments);
  }, [buSegmentList]);

  const fetchSelectItem = async (item?: TaxonomyBranch[]) => {
    setCategoryItemList([]);
    if (!item) {
      fetchScopedTaxonomies();
      setSelectedCategoryItems([]);
      setCategoryItemList([]);
      return;
    }
    await displaySelectedTaxonomyBase(item);
    const subcategories = item.slice(1);
    subcategories.forEach(async (subcategory, index) => {
      setSelectedCategoryItems((categoryItems) => {
        const currentlySelectedItem = {
          order: index + 1,
          segmentId: String(item[index].id),
          selectedItem: subcategory.id,
        };
        return [...categoryItems, currentlySelectedItem];
      });
      await displaySelectedTaxonomyBranches(
        subcategory,
        item[index].id,
        index + 1
      );
    });
    setSelectedCategoryItems((items) => {
      return items;
    });
  };

  const closeMoveSidenav = () => {
    setMovingCategoryMode(false);
    setSelectedCategoryToEdit(undefined);
    setIsExternalTaxonomyOpen(false);
  };

  const handleMoveSubmit = (item: TaxonomyBranch[]) => {
    fetchSelectItem(item);
    closeMoveSidenav();
  };

  return (
    <SingleContent
      component={
        <>
          <Container
            breadcrumbItems={categoryItemList}
            fetchSelectItem={fetchSelectItem}
            content={
              <div className={styles.contentWrapper}>
                <Subcontainer
                  isFirst={true}
                  title="Árvore de Categorias"
                  content={handleBuSegmentList()}
                  hasMore={hasMoreBuSegments}
                  disabled={false}
                  headerColor="var(--color-full-black)"
                  searchValue={buSegmentTerm}
                  onEditItem={(item: BuSegmentItem) =>
                    setIsCreateCategoryTreeOpen(item)
                  }
                  onHeaderClick={() => setIsCreateCategoryTreeOpen(true)}
                  onItemSelect={(selectedItem: BuSegmentItem) =>
                    handleCategorySelection(selectedItem, true)
                  }
                  onLoadMore={fetchBuData}
                  onSearchChange={handleBuChange}
                  onSearchEnter={handleBuSegmentItemSearch}
                  selectedItem={handleSelectedItem("Árvore de Categorias")}
                />
                {categoryItemList.length > 0 &&
                  categoryItemList.map((item, index) => (
                    <div
                      key={`${item.id}${index}`}
                      className={styles.subcontainerWrapper}
                    >
                      <Subcontainer
                        isFirst={false}
                        isLast={index === categoryItemMaxLevel - 1}
                        disabled={item.title.includes("(Não Selecionado)")}
                        title={item.title}
                        content={item.content}
                        hasMore={item.hasMore}
                        headerColor={item.color}
                        searchValue={handleCategoryItemSearchValue(item.id)}
                        onDuplicateItem={(item: BuSegmentItem) =>
                          handleDuplicateItem(item)
                        }
                        onEditItem={(toEdit: BuSegmentItem) =>
                          setSelectedCategoryToEdit({
                            parent: item,
                            item: toEdit,
                          })
                        }
                        onHeaderClick={() =>
                          setSelectedCategoryToEdit({ parent: item })
                        }
                        onItemSelect={(selectedItem: BuSegmentItem) =>
                          handleCategorySelection(
                            selectedItem,
                            false,
                            index === categoryItemMaxLevel - 1
                          )
                        }
                        onLoadMore={() =>
                          handleCategoryItemSearchValue(item.id).length > 0
                            ? handleCategoryItemSearch(
                                item.id,
                                item.currentPage + 1
                              )
                            : fetchCategoryItemData(
                                item.id,
                                item.order,
                                item.currentPage
                              )
                        }
                        onMoveItem={(toEdit: BuSegmentItem) => {
                          setSelectedCategoryToEdit({
                            parent: item,
                            item: toEdit,
                          });
                          setMovingCategoryMode(true);
                        }}
                        onAsssociateExternal={(
                          originTaxonomy: BuSegmentItem
                        ) => {
                          setSelectedCategoryToEdit({
                            parent: item,
                            item: originTaxonomy,
                          });
                          setIsExternalTaxonomyOpen(true);
                        }}
                        onSearchChange={(value) =>
                          handleCategoryItemSearchChange({
                            value,
                            id: item.id,
                            order: item.order,
                            currentPage: item.currentPage,
                          })
                        }
                        onSearchEnter={() => handleCategoryItemSearch(item.id)}
                        selectedItem={handleSelectedItem(String(item.id))}
                      />
                    </div>
                  ))}
                <div className={styles.expander} />
              </div>
            }
          />
          {!!isCreateCategoryTreeOpen && (
            <CreateCategoryTreeSidenav
              selected={isCreateCategoryTreeOpen}
              onSubmit={handleCreateTreeSubmit}
              onClose={() => setIsCreateCategoryTreeOpen(false)}
            />
          )}
          <CreateCategorySidenav
            isOpen={!!selectedCategoryToEdit && !isMovingCategory}
            onSubmit={handleCreateCategory}
            onClose={() => setSelectedCategoryToEdit(undefined)}
            selectedCategory={selectedCategoryToEdit}
          />
          <MoveCategorySidenav
            isOpen={isMovingCategory}
            onSubmit={handleMoveSubmit}
            onClose={closeMoveSidenav}
            selectedCategory={selectedCategoryToEdit}
          />
          <ExternalTaxonomySideNav
            isOpen={isExternalTaxonomyOpen}
            onClose={closeMoveSidenav}
            selectedCategory={selectedCategoryToEdit}
          />
        </>
      }
    />
  );
};

export default Categories;
