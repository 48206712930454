import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { MainUploadFilters } from "pages/Uploads/components/MainUploadsAdvancedFilters";
import {
  BatchProcessDetails,
  BatchProcessDetailsMetadata,
} from "shared/models/BatchProcess";
import BatchProcessType from "shared/models/BatchProcessType";
import { objectToParams } from "util/query.param.util";
import { BatchProcess } from "../models/BatchProcess";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/batch-process";

class BatchProcessService {
  async getBatchProcess(page: number = 0, filters: MainUploadFilters) {
    const params = objectToParams({ ...filters, page });
    const response = await axios.request<PixResponsePage<BatchProcess>>({
      method: "GET",
      url: `${BASE_PATH}`,
      params,
    } as AxiosRequestConfig);
    return response.data;
  }

  async getBatchProcessDetails(id: string, page = 0, pageSize = 20) {
    const response = await axios.request<PixResponsePage<BatchProcessDetails>>({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
      params: { page, size: pageSize },
    } as AxiosRequestConfig);
    return response.data;
  }

  async getBatchProcessDetailsMetadata(id: string) {
    const response = await axios.request<BatchProcessDetailsMetadata>({
      method: "GET",
      url: `${BASE_PATH}/${id}/meta`,
    });
    return response.data;
  }

  async listTypesForUpload() {
    const response = await axios.request<BatchProcessType[]>({
      method: "GET",
      url: `${BASE_PATH}/types/for-upload`,
    } as AxiosRequestConfig);
    return response.data;
  }
}

export const batchProcessService = new BatchProcessService();
