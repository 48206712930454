import * as React from "react";

function RedCircleAlert() {
  return (
    <svg width={34} height={34} viewBox="0 0 34 34">
      <g data-name="Group 126">
        <path
          d="M29 17A12 12 0 1117 5a12 12 0 0112 12zm-12 2.419a2.226 2.226 0 102.226 2.226A2.226 2.226 0 0017 19.419zm-2.113-8L15.246 18a.581.581 0 00.58.549h2.349a.581.581 0 00.58-.549l.359-6.581a.581.581 0 00-.58-.612h-3.067a.581.581 0 00-.58.612z"
          fill="#ff8400"
        />
        <g data-name="Ellipse 1" stroke="#ff8400" fill="none">
          <circle cx={17} cy={17} r={17} stroke="none" />
          <circle cx={17} cy={17} r={16.5} />
        </g>
      </g>
    </svg>
  );
}

export default RedCircleAlert;
