import React from "react";
import { InputNumber, InputNumberProps } from "antd";

type Props = Omit<InputNumberProps, "min" | "formatter" | "parser">;

export const INTEGER_MIN_VALUE = 1;
export const INTEGER_MAX_VALUE = 2147483647; // Maximum value 32 bits

const InputInteger: React.FC<Props> = (props) => {
  return (
    <>
      <InputNumber
        {...props}
        style={{ width: "100%" }}
        min={INTEGER_MIN_VALUE}
        formatter={(value) => value?.toString().replace(".", "") ?? ""}
        parser={(value) =>
          value?.substring(0, INTEGER_MAX_VALUE.toString().length) ?? ""
        }
      />
      {((props.value ?? 0) as number) > INTEGER_MAX_VALUE ? (
        <p style={{ color: "var(--color-warning)", fontSize: "10px" }}>
          Valor máximo permitido {INTEGER_MAX_VALUE}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default InputInteger;
