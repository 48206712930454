import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { ApiRole } from "shared/models/ApiRole";

export const useYehPermissions = () => {
  const [userRoles, setUserRoles] = useState<ApiRole[]>([]);

  const { user } = useAuth0();

  useEffect(() => {
    const userRoles =
      user?.["user.metadata"]?.permissions?.["pix-api"]?.roles || [];
    setUserRoles(userRoles);
  }, [user]);

  const userHasAnyRole = useCallback(
    (allowedRoles: ApiRole[]) =>
      userRoles.some((role: ApiRole) => allowedRoles.includes(role)),
    [userRoles]
  );

  return { userHasAnyRole };
};
