import React, { useContext, useEffect } from "react";

import { Result, Row, Col } from "antd";
import { RouteComponentProps } from "@reach/router";
import { DefaultContent, DefaultPage } from "containers/DefaultContent";
import { Card } from "components/Card";
import styles from "./UnauthorizedPage.module.scss";
import { PageContext } from "containers/Main/Main.context";

type UnauthorizedPage = DefaultPage & RouteComponentProps;

export const UnauthorizedPage: React.FC<UnauthorizedPage> = ({
  title,
}: UnauthorizedPage) => {
  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => setCurrentTitle(title), [setCurrentTitle, title]);

  return (
    <DefaultContent>
      <Row gutter={8}>
        <Col span={24}>
          <Card className={styles.card}>
            <Result
              status="403"
              title="403"
              subTitle="Desculpe, você não está autorizado a acessar essa página."
            />
          </Card>
        </Col>
      </Row>
    </DefaultContent>
  );
};
