import React from "react";
import { Typography } from "antd";
import styles from "./ItemContent.module.scss";
import cx from "classnames";

export interface ItemContentProps {
  key?: string;
  className?: string;
  children: any;
  title: string;
}

export const ItemContent = (props: ItemContentProps) => {
  const { Text } = Typography;

  return (
    <div className={cx(styles.itemContent, props.className)}>
      <Text type="secondary">{props.title}</Text>
      <div className={styles.block}>
        <Text>{props.children}</Text>
      </div>
    </div>
  );
};
