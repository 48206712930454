import { YehImage } from "components/YehImage/YehImage";
import { ImageList } from "containers/ImageList/ImageList";
import { useImages } from "hook/ImagesHook";
import React, { useEffect } from "react";
import { GtinCodeDetail } from "shared/models/GtinCode";

import styles from "./GtinImagesTab.module.scss";

interface GtinImagesTabProps {
  gtinDetail: GtinCodeDetail;
  refreshList: () => void;
}

export const GtinImagesTab = ({
  gtinDetail,
  refreshList,
}: GtinImagesTabProps) => {
  const { last, images, getDetails, loadMore } = useImages();

  useEffect(() => {
    getDetails(gtinDetail.productId, 0);
  }, [gtinDetail.productId, getDetails]);

  return (
    <>
      <p className={styles.subTitle}>Imagem do GTIN</p>
      <YehImage imageUrl={gtinDetail.imageUrl} />

      {!!gtinDetail.productId && (
        <>
          <p className={styles.subTitle}>Outras imagens disponíveis</p>
          <ImageList
            refresh={refreshList}
            gtin={gtinDetail.gtin}
            productId={gtinDetail.productId}
            mainImageUrl={gtinDetail.imageUrl}
            images={images}
            last={last}
            loadMore={loadMore}
            imageCardColSize={12}
          />
        </>
      )}
    </>
  );
};
