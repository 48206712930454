import cx from "classnames";
import { TabItem as TabItemType } from "components/Tabs/Tabs";
import React from "react";
import { TabArrow } from "shared/assets/icons/TabArrow";
import { updateQueryParam } from "util/query.param.util";
import styles from "./TabItem.module.scss";

interface TabItemProps {
  children: React.ReactNode;
  value: TabItemType;
  isSelected: boolean;
  filled?: boolean;
  hasArrows?: boolean;
  onClick: (value: TabItemType) => void;
}

export const TabItem = ({
  children,
  value,
  isSelected,
  filled,
  hasArrows,
  onClick,
}: TabItemProps) => {
  const buttonStyle = cx(styles.baseButton, {
    [styles.selectedItem]: isSelected && !hasArrows,
    [styles.availableItem]: !isSelected && !hasArrows,
    [styles.arrowedItem]: filled,
    [styles.arrowedSelectedItem]: isSelected && filled,
  });
  const handleArrowColor = () =>
    isSelected ? "var(--color-orange)" : "var(--color-light-blue)";
  return (
    <>
      <span
        id={`tab-item-${value.value}`}
        data-testid={`tab-item-wrapper${isSelected ? "-selected" : ""}`}
        className={buttonStyle}
        onClick={() => {
          updateQueryParam("tab", value.value);
          onClick(value);
        }}
      >
        {hasArrows && (
          <div className={styles.arrow}>
            <TabArrow backgroundColor={handleArrowColor()} />
          </div>
        )}
        {children}
      </span>
    </>
  );
};
