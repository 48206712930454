import {
  Button,
  Descriptions,
  Divider,
  List,
  message,
  PageHeader,
  Row,
  Skeleton,
  Spin,
} from "antd";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { ReactNode, useEffect, useState } from "react";
import { associationService } from "shared/services/AssociationService";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";

export type EntityMergeProps<T> = {
  data: T[];
  getLabeledItem: (item: T) => string;
  getItemId: (item: T) => number;
  onCancel: () => void;
  entityName: string;
  mergeItems: (mergeRequest: MergeRequest) => Promise<NameCount[]>;
  afterMerge?: () => void;
  onRemoveItem?: (remainingItems: T[]) => void;
  customConfirmAlertMessage?: ReactNode;
};

const MIN_DATA_TO_MERGE = 1;

const EntityMerge = <T extends object>({
  data,
  getLabeledItem,
  onCancel,
  entityName,
  getItemId,
  mergeItems,
  afterMerge,
  onRemoveItem,
  customConfirmAlertMessage,
}: EntityMergeProps<T>) => {
  const [mergeTargetItem, setMergeTargetItem] = useState<T>();
  const [sourceItems, setSourceItems] = useState<T[]>([]);
  const [isLoadingTotalAssociation, setLoadingTotalAssociation] = useState(
    false
  );
  const [isLoadingMergeSubmit, setLoadingMergeSubmit] = useState(false);
  const [associations, setAssociations] = useState<NameCount[]>([]);

  const onClickNewTarget = (item: T) => {
    let indexToRemove = findIndexInSourceItems(item);
    sourceItems.splice(indexToRemove, 1);
    if (mergeTargetItem) {
      sourceItems.push(mergeTargetItem);
    }
    setSourceItems(sourceItems);
    setMergeTargetItem(item);
  };

  const onClickRemoveSource = (item: T) => {
    let indexToRemove = findIndexInSourceItems(item);
    sourceItems.splice(indexToRemove, 1);
    const newSourceItems = [...sourceItems];
    setSourceItems(newSourceItems);

    if (mergeTargetItem) {
      onRemoveItem?.([...newSourceItems, mergeTargetItem]);
    } else {
      onRemoveItem?.([...newSourceItems]);
    }
  };

  const findIndexInSourceItems = (item: T) => {
    return sourceItems.findIndex((i) => getItemId(i) === getItemId(item));
  };

  const cancelMergeAndClearItems = () => {
    setMergeTargetItem(undefined);
    setSourceItems([]);
    onCancel();
  };

  useEffect(() => {
    const notDuplicatedData = data.filter((newItem) => {
      const newItemId = getItemId(newItem);

      let duplicatedWithSomeSource = sourceItems
        .map((i) => getItemId(i))
        .includes(getItemId(newItem));

      if (mergeTargetItem) {
        const duplicatedWithTarget = getItemId(mergeTargetItem) === newItemId;
        return !duplicatedWithSomeSource && !duplicatedWithTarget;
      }

      return !duplicatedWithSomeSource;
    });

    setSourceItems([...sourceItems, ...notDuplicatedData]);
  }, [data]); // eslint-disable-line

  const joinNamedCountInfo = (namedCounts: NameCount[]) => {
    return namedCounts.map((nc) => `${nc.name}: ${nc.count}`).join(", ");
  };

  const showErrorMessage = (text: string) => message.error(text);

  const canMerge = (): boolean =>
    !!mergeTargetItem &&
    !!getItemId(mergeTargetItem) &&
    sourceItems.length >= MIN_DATA_TO_MERGE;

  const onConfirmMergeItems = () => {
    if (canMerge()) {
      const mergeRequestBody = {
        targetId: getItemId(mergeTargetItem!),
        idsToMerge: sourceItems.map((item) => getItemId(item)),
      };
      setLoadingMergeSubmit(true);
      mergeItems(mergeRequestBody)
        .then((mergeResponse) => {
          if (mergeResponse) {
            message.success(
              `Itens mesclados com sucesso! Resumo: ${joinNamedCountInfo(
                mergeResponse
              )}`,
              5
            );
          }
          cancelMergeAndClearItems();
        })
        .catch(() =>
          showErrorMessage(
            "Erro ao mesclar os itens, atualize a página e tente novamente."
          )
        )
        .finally(() => {
          afterMerge?.();
          setLoadingMergeSubmit(false);
        });
    }
  };

  const findTotalAssociationInfoMessage = () => {
    if (!customConfirmAlertMessage) {
      setLoadingTotalAssociation(true);
      associationService
        .getTotalAssociationsByEntity(
          entityName,
          sourceItems.map((item) => getItemId(item))
        )

        .then((namedCounts) => {
          if (sourceItems.length) {
            setAssociations(namedCounts);
          }
        })
        .catch(() =>
          showErrorMessage(
            "Erro ao buscar o total de associações dos itens de origem."
          )
        )
        .finally(() => setLoadingTotalAssociation(false));
    }
  };

  const getAssociationsConfirmAlert = () => {
    if (customConfirmAlertMessage) {
      return customConfirmAlertMessage;
    }

    if (isLoadingTotalAssociation) {
      return (
        <div style={{ width: "400px" }}>
          <Skeleton />
        </div>
      );
    }

    return (
      <>
        Deseja confirmar a mesclagem? Total de associações que serão mescladas
        para o item de destino:
        <Divider style={{ marginBottom: "-10px" }} />
        {associations.map((association) => {
          return (
            <>
              <br />
              {association.name}: {association.count}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Mesclagem"
        subTitle={`Total de Origens ${sourceItems.length}`}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Destino">
            <p style={{ color: "var(--color-light-blue)" }}>
              {mergeTargetItem
                ? getLabeledItem(mergeTargetItem)
                : "Não selecionado"}
            </p>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <div
        id="scrollableDiv"
        style={{
          height: "60%",
          overflow: "auto",
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={sourceItems}
          style={{ padding: "3%" }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => onClickNewTarget(item)}
                  id="btn-select-target"
                >
                  Tornar destino
                </Button>,
                <Button
                  type="link"
                  style={{ color: "var(--color-warning)" }}
                  onClick={() => onClickRemoveSource(item)}
                  id="btn-remove-source"
                >
                  Remover
                </Button>,
              ]}
            >
              <List.Item.Meta title={getLabeledItem(item)} />
            </List.Item>
          )}
        />
      </div>

      {isLoadingMergeSubmit ? (
        <Row justify="center">
          <Spin
            tip="Mesclando registros, isso pode demorar um pouco... (A página pode ser fechada)"
            size="large"
          />
        </Row>
      ) : (
        <CancelAndSave
          onCancel={cancelMergeAndClearItems}
          showConfirm
          showConfirmTitle={getAssociationsConfirmAlert()}
          onConfirm={onConfirmMergeItems}
          beforeRenderPopconfirm={findTotalAssociationInfoMessage}
          disabled={!canMerge()}
        />
      )}
    </>
  );
};

export default EntityMerge;
