import React from "react";
import styles from "./ProductDetailPopover.module.scss";

import { Popover } from "antd";

import { Icons } from "components/Icons";
import { ProductListItem } from "shared/models/Product";

interface ProductDetailPopoverProps {
  data: ProductListItem;
}

export const ProductDetailPopover = ({ data }: ProductDetailPopoverProps) => {
  const content = (
    <div className={styles.detailsWrapper}>
      <p className={styles.title}>Linha:</p>
      <p className={styles.content}>{data.productLine.name}</p>
      <p className={styles.title}>Fabricante:</p>
      <p className={styles.content}>{data.brand.manufacturer.name}</p>
      <p className={styles.title}>Marca:</p>
      <p className={styles.content}>{data.brand.name}</p>
      <p className={styles.title}>Descrição:</p>
      <p className={styles.content}>{data.description}</p>
    </div>
  );
  return (
    <Popover
      placement="rightTop"
      title="Detalhes"
      content={content}
      className={styles.popoverIcon}
      overlayClassName={styles.openPopover}
    >
      <Icons name="infoCircleFilled" />
    </Popover>
  );
};
