import { Button, Form, message } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import {
  FormItemBoolean,
  SelectBooleanValue,
} from "components/FormItem/FormItemBoolean";
import { FormItemMultipleInteger } from "components/FormItem/FormItemMultipleInteger";
import { FormItemMultipleText } from "components/FormItem/FormItemMultipleText";
import { FormItemSelect } from "components/FormItem/FormItemSelect";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemWrapper } from "components/FormItem/FormItemWrapper";
import { TaxonomyCascader } from "containers/TaxonomyCascader/TaxonomyCascader";

import { SearchBrandsSelect } from "containers/SearchBrandsSelect/SearchBrandsSelect";
import { SearchProductLinesSelect } from "containers/SearchProductLinesSelect/SearchProductLinesSelect";

import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ValueLabel } from "shared/models/Product";
import { productService } from "shared/services/ProductService";

import { SearchSubBrandsSelect } from "containers/SearchSubBrandSelect/SearchSubBrandSelect";
import { commercialStatusService } from "shared/services/CommercialStatusService";
import {
  getNormalizedNumberArrayFilter,
  handleFilterChange,
} from "util/filter.util";
import { getInitialProductFilters } from "../Products";
import styles from "./AdvancedFilterYandehProduct.module.scss";

interface AdvancedFilterYandehProductProps {
  originalFilters: ProductFilters;
  onClose: (value: boolean) => void;
  onConfirm: (filter: ProductFilters) => void;
  parentName: string; // Helps defining unique ids since this component can be reused
  visible: boolean;
  fixedStatus?: boolean;
  isImageList?: boolean;
  label?: string;
}

export class ProductFilters {
  brandId?: number;
  deleted?: SelectBooleanValue;
  emitterCompanyCode?: string;
  salesCode?: number[];
  productId?: number[];
  productLineId?: number;
  skuCodes?: string[];
  term?: string;
  taxonomies?: number[];
  productStatus?: string;
  productSanitation?: string;
  subBrandId?: number;
  attributesId?: number[];
  attributeValueId?: number;

  // Used in assets
  assetGroupdIds?: number[];
  hasImage?: SelectBooleanValue;
  hasMainImage?: SelectBooleanValue;
  imageWidth?: number;
  imageHeight?: number;
  imageFormat?: string;
  tagIds?: number[];
  includeGtins?: boolean;
}

export const linkToProduct = (filter: ProductFilters) =>
  "/produto?productFilters=" + JSON.stringify(filter);

export const ProductsAdvancedFilters = ({
  originalFilters,
  onClose,
  onConfirm,
  parentName,
  visible,
  fixedStatus,
  isImageList,
  label,
}: AdvancedFilterYandehProductProps) => {
  const [filters, setFilters] = useState<ProductFilters>({});

  const [commercialStatus, setCommercialStatus] = useState<ValueLabel[]>([]);
  const [sanitation, setSanitation] = useState<ValueLabel[]>([]);

  useEffect(() => {
    trackPromise(commercialStatusService.findCommercialStatus())
      .then(setCommercialStatus)
      .catch(() => message.error("Erro ao buscar status comerciais."));

    trackPromise(productService.findSanitation())
      .then(setSanitation)
      .catch(() => message.error("Erro ao buscar saneamento."));
  }, []);

  const handleConfirmClick = () => {
    onConfirm(filters);
    onClose(false);
  };

  const handleAddTaxonomy = (taxonomyIds: number[]) => {
    const newTaxonomyIds = taxonomyIds?.length ? Array.from(taxonomyIds) : [];
    setFilters((oldFilters) => {
      return { ...oldFilters, taxonomies: newTaxonomyIds };
    });
  };

  const clearFilters = () => {
    setFilters((oldFilters) => ({
      deleted: fixedStatus ? oldFilters.deleted : undefined,
      taxonomies: [],
    }));
  };

  useEffect(() => setFilters({ ...originalFilters }), [originalFilters]);

  useEffect(() => {
    let productFilters = getInitialProductFilters();
    if (productFilters.taxonomies?.length) {
      const initalTaxonomyIds = getNormalizedNumberArrayFilter(
        productFilters.taxonomies
      );
      setFilters((oldFilters) => {
        return {
          ...oldFilters,
          taxonomies: initalTaxonomyIds,
        };
      });
    }
  }, []);

  return (
    <FormItemWrapper
      visible={visible}
      onCancel={() => onClose(false)}
      submit={handleConfirmClick}
      title={label || "Filtro Avançado Produto Yandeh"}
    >
      <FormItemMultipleInteger
        id={`${parentName}-filter-product-id`}
        label="IDs Produto Padrão"
        onChange={(value) => handleFilterChange("productId", value, setFilters)}
        value={filters.productId}
      />
      <FormItemText
        id={`${parentName}-filter-emitter-company-code`}
        label="CNPJ"
        onChange={(e) =>
          handleFilterChange("emitterCompanyCode", e, setFilters)
        }
        value={filters.emitterCompanyCode}
      />
      <FormItemMultipleText
        id={`${parentName}-filter-sku-code`}
        label="Código"
        onChange={(skuCodes) => setFilters((f) => ({ ...f, skuCodes }))}
        value={filters.skuCodes}
      />
      <FormItemMultipleInteger
        id={`${parentName}-filter-sales-code`}
        label="Códigos de venda"
        onChange={(e) => handleFilterChange("salesCode", e, setFilters)}
        value={filters.salesCode}
      />
      <FormItemBoolean
        id={`${parentName}-filters-deleted`}
        label="Status"
        onChange={(deleted) => setFilters({ ...filters, deleted })}
        textForFalse="Ativo"
        textForTrue="Inativo"
        value={filters.deleted}
        disabled={isImageList}
      />
      <FormItemBoolean
        id={`${parentName}-filter-has-image`}
        label="Possui imagem?"
        onChange={(hasImage) => setFilters({ ...filters, hasImage })}
        textForTrue="Sim"
        textForFalse="Não"
        value={filters.hasImage}
      />
      {!isImageList && (
        <>
          <SearchProductLinesSelect
            onChange={(productLineId) =>
              setFilters({ ...filters, productLineId })
            }
            parentName={parentName + "product"}
            productLineId={filters.productLineId}
            initialProductLineId={filters.productLineId}
            fixedFilters={{ deleted: "false" }}
          />
          <SearchBrandsSelect
            onChange={(brandId) => setFilters({ ...filters, brandId })}
            parentName={parentName + "product"}
            brandId={filters.brandId}
            initialBrandId={filters.brandId}
            fixedFilters={{ deleted: "false" }}
          />
          <SearchSubBrandsSelect
            onChange={(subBrandId) => setFilters({ ...filters, subBrandId })}
            parentName={parentName + "product"}
            subBrandId={filters.subBrandId}
            initialSubBrandId={filters.subBrandId}
            fixedFilters={{ deleted: "false" }}
          />
          <FormItemSelect<string>
            id={`${parentName}-filter-product-status-value`}
            label="Status Comercial"
            onChange={(productStatus) =>
              setFilters({ ...filters, productStatus })
            }
            options={commercialStatus}
            value={filters.productStatus}
          />
          <FormItemSelect<string>
            id={`${parentName}-filter-product-sanitation-value`}
            label="Saneamento"
            onChange={(productSanitation) =>
              setFilters({ ...filters, productSanitation })
            }
            options={sanitation}
            value={filters.productSanitation}
          />
          <FormItemText
            id={`${parentName}-filter-term`}
            label="Termo genérico"
            onChange={(e) => handleFilterChange("term", e, setFilters)}
            value={filters.term}
          />
        </>
      )}
      <Form.Item
        label={
          <FilterLabel
            text="Taxonomia"
            htmlFor={`${parentName}-filter-taxonomy`}
          />
        }
      >
        <TaxonomyCascader
          id={`${parentName}-filter-taxonomy`}
          onChange={handleAddTaxonomy}
          taxonomyIds={filters.taxonomies ?? []}
        />
      </Form.Item>

      <div className={styles.cleanBtn}>
        <Button onClick={clearFilters} type="link">
          Limpar filtros
        </Button>
      </div>
    </FormItemWrapper>
  );
};
