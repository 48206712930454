import { Form, Select } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import {
  ProductLinesAdvancedFilters,
  ProductLinesFilters,
} from "pages/ProductLines/components/ProductLinesAdvancedFilters";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { IdName } from "shared/models/IdName";
import { ProductLineListView } from "shared/models/ProductLine";
import { productLineService } from "shared/services/ProductLineService";
import { scrollFunction } from "shared/utils/ScrollUtils";

interface SearchProductLinesSelectProps {
  onChange?: (productLineId: number) => void;
  parentName: string;
  productLineId?: number;
  label?: string;
  setSelectedLine?: (idName?: IdName) => void;
  initialProductLineId?: number;
  fixedFilters?: Readonly<ProductLinesFilters>;
}

export const SearchProductLinesSelect = ({
  onChange,
  parentName,
  productLineId,
  label,
  setSelectedLine,
  initialProductLineId,
  fixedFilters,
}: SearchProductLinesSelectProps) => {
  const [page, setPage] = useState(0);
  const [
    productLinesFilters,
    setProductLinesFilters,
  ] = useState<ProductLinesFilters>(fixedFilters || new ProductLinesFilters());
  const [productLines, setProductLines] = useState<ProductLineListView[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [
    alreadyLoadInitialProductLine,
    setAlreadyLoadInitialProductLine,
  ] = useState(false);
  const [last, setLast] = useState(true);

  useEffect(() => {
    if (initialProductLineId && !alreadyLoadInitialProductLine) {
      productLineService
        .getProductLines(0, { productLineId: [initialProductLineId] })
        .then(({ content }) => setProductLines(content));
      setAlreadyLoadInitialProductLine(true);
    }
  }, [initialProductLineId, alreadyLoadInitialProductLine]);

  const fetchProductLines = (page: number, filters: ProductLinesFilters) => {
    const newFilters: ProductLinesFilters = {
      ...filters,
      ...fixedFilters,
    };
    trackPromise(
      productLineService.getProductLines(page, filters).then((response) => {
        setProductLines((oldLines) => [
          ...(page > 0 ? oldLines : []),
          ...response.content,
        ]);
        setPage(page);
        setOptionsOpen(true);
        setFiltersOpen(false);
        setLast(response.last);
        setProductLinesFilters(filters);
        setProductLinesFilters(newFilters);
      })
    );
  };

  const debounceSearchBrands = debounce(
    (term: string) => fetchProductLines(0, { ...productLinesFilters, term }),
    600
  );

  const loadMore = () => {
    if (!last) {
      fetchProductLines(page + 1, productLinesFilters);
    }
  };

  const handleChange = (id: number) => {
    if (onChange) {
      onChange(id);
    }
    if (setSelectedLine) {
      setSelectedLine(
        productLines
          .filter((pl) => pl.id === id)
          .map((pl) => ({ id: pl.id, name: pl.name }))
          .find((pl) => pl.id === id)
      );
    }
  };

  return (
    <Form.Item
      label={
        <>
          <FilterLabel
            text={label ?? "Linha"}
            htmlFor={`${parentName}-search-product-lines-select`}
          />
          <FilterIcon
            id="product-line-filter-icon"
            filters={productLinesFilters}
            onClick={() => setFiltersOpen(true)}
          />
        </>
      }
    >
      <ProductLinesAdvancedFilters
        visible={filtersOpen}
        parentName={parentName}
        originalFilters={productLinesFilters}
        onConfirm={(filters) => fetchProductLines(0, filters)}
        onClose={() => setFiltersOpen(false)}
        fixedProductLineFilters={fixedFilters}
      />
      <Select<number>
        id={`${parentName}-search-productLines-select`}
        filterOption={false}
        allowClear
        options={productLines.map((productLine) => ({
          value: productLine.id,
          label: productLine.name,
        }))}
        onChange={handleChange}
        onSearch={debounceSearchBrands}
        showSearch
        open={optionsOpen}
        onDropdownVisibleChange={setOptionsOpen}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
        value={productLineId}
      />
    </Form.Item>
  );
};
