import { Form, Select, SelectProps } from "antd";
import { SelectValue } from "antd/lib/select";
import EmptySelection from "components/EmptySelection/EmptySelection";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React from "react";

interface FormItemSelectProps<T extends SelectValue> extends SelectProps<T> {
  id: string;
  label: string;
}

export function FormItemSelect<T extends SelectValue>({
  id,
  label,
  ...rest
}: FormItemSelectProps<T>) {
  return (
    <Form.Item label={<FilterLabel text={label} htmlFor={id} />}>
      <Select<T>
        id={id}
        allowClear
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={<EmptySelection />}
        {...rest}
      />
    </Form.Item>
  );
}
