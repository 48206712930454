import cx from "classnames";
import { TabItem } from "components/Tabs/TabItem/TabItem";
import React from "react";
import styles from "./Tabs.module.scss";

export type TabItem = {
  title: string;
  value: string;
};

interface TabsProps {
  tabItems: TabItem[];
  selectedTab: TabItem;
  onClick: (value: TabItem) => void;
  className?: string;
}

export const Tabs = ({
  className,
  tabItems,
  onClick,
  selectedTab,
}: TabsProps) => {
  const childrenStyle = cx(styles.children);
  return (
    <div data-testid="tabs" className={cx(styles.wrapper, className)}>
      {tabItems.map((item) => (
        <TabItem
          key={item.value}
          onClick={onClick}
          isSelected={item.value === selectedTab.value}
          value={item}
        >
          <span className={childrenStyle}>{item.title}</span>
        </TabItem>
      ))}
    </div>
  );
};
