import { message } from "antd";
import { IndustryRotationSelect } from "components/IndustryRotationSelect/IndustryRotationSelect";
import { Input } from "components/Input";
import { MarketRotationSelect } from "components/MarketRotationSelect/MakertRotationSelect";
import { ProductStatusSelect } from "components/ProductStatusSelect/ProductStatusSelect";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { GtinCodeDetail } from "shared/models/GtinCode";
import { ValueLabel } from "shared/models/Product";
import { ProductFormMetadata } from "shared/models/User";
import { gtinCodeService } from "shared/services/GtinCodeService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { utcToPtBrDate } from "util/date.util";
import { getEntityPermissions } from "util/UserPermissionUtils";

import styles from "./GtinDetail.module.scss";

interface GtinDetailProps {
  gtinDetail?: GtinCodeDetail;
  refreshList: () => void;
  refreshDetail: (gtin: string) => void;
  handleCancelClick: () => void;
  closeCreating: () => void;
}

export const GtinDetail = ({
  gtinDetail,
  refreshList,
  closeCreating,
  refreshDetail,
  handleCancelClick,
}: GtinDetailProps) => {
  const [gtin, setGtin] = useState<string>();
  const [commercialStatus, setCommercialStatus] = useState<ValueLabel>();
  const [marketRotation, setMarketRotation] = useState<string>();
  const [industryRotation, setIndustryRotation] = useState<string>();
  //const [deleted, setDeleted] = useState<boolean>();
  const [sanitation, setSanitation] = useState<string>();
  const [productMetada] = useState<ProductFormMetadata | null>(
    getEntityPermissions<ProductFormMetadata>("product")
  );

  const handleUpdate = () => {
    trackPromise(
      gtinCodeService.update({
        gtin: gtinDetail?.gtin!,
        //deleted,
        sanitation,
        commercialStatusId: commercialStatus?.value,
        marketRotation,
        industryRotation,
      })
    )
      .then((updatedGtin) => {
        refreshList();
        refreshDetail?.(updatedGtin.gtin);
        message.success("GTIN atualizado com sucesso!");
      })
      .catch(() => {
        getErrorMessage("Erro ao atualizar GTIN");
      });
  };

  const handleCreate = () => {
    trackPromise(
      gtinCodeService.create({
        gtin: gtin!,
        sanitation,
        commercialStatusId: commercialStatus?.value,
        marketRotation,
        industryRotation,
      })
    )
      .then(() => {
        closeCreating();
        refreshList();
        message.success("GTIN criado com sucesso!");
      })
      .catch(() => {
        getErrorMessage("Erro ao criar GTIN");
      });
  };

  const handleSubmit = () => {
    if (gtinDetail?.gtin) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const onCancel = () => {
    handleCancelClick();
  };

  useEffect(() => {
    setCommercialStatus(gtinDetail?.commercialStatus);
    setSanitation(gtinDetail?.sanitation?.value);
    setMarketRotation(gtinDetail?.marketRotation?.value);
    setIndustryRotation(gtinDetail?.industryRotation?.value);
    // setDeleted(gtinDetail?.deleted);
    setGtin(gtinDetail?.gtin);
  }, [gtinDetail]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className={styles.wrapper}
    >
      <Input
        id="gtin"
        name="gtin"
        label="GTIN"
        value={gtin}
        disabled={!!gtinDetail}
        onChange={(e) => setGtin(e.target.value)}
      />
      <ProductStatusSelect
        value={commercialStatus?.value}
        onSelectStatus={(newStatus) =>
          setCommercialStatus({ value: newStatus })
        }
        label="Status Comercial"
        disabled={!productMetada?.editProductCommercialStatus}
      />
      <SanitationSelect
        value={sanitation}
        label="Saneamento"
        onChange={setSanitation}
        disabled={!productMetada?.editProductSanitation}
      />
      <MarketRotationSelect
        value={marketRotation}
        onChange={setMarketRotation}
        label="Giro de Mercado "
        disabled={!productMetada?.editMarketRotation}
      />
      <IndustryRotationSelect
        value={industryRotation}
        onChange={setIndustryRotation}
        label="Giro de Industria"
        disabled={!productMetada?.editIndustryRotation}
      />
      {!!gtinDetail && (
        <Input
          name="lastModified"
          id="product-last-modified"
          label="Última atualização"
          value={utcToPtBrDate(gtinDetail.lastModified)}
          disabled
        />
      )}
      <div className={styles.divider} />
      {/* {!!gtinDetail && (
        <YehSwitch
          checkedChildren="Ativo"
          unCheckedChildren="Inativo"
          checked={!deleted}
          onChange={(f) => setDeleted(!f)}
          disabled={!productMetada?.editDeleted}
        />
      )} */}
      <CancelAndSave
        onCancel={onCancel}
        // showConfirm={gtinDetail && deleted !== gtinDetail.deleted}
        // showConfirmTitle={`O GTIN está ${
        //   deleted ? "ativo" : "inativo"
        // }. Tem certeza de que deseja ${deleted ? "inativar" : "ativar"}?`}
        onConfirm={handleSubmit}
        disabled={!productMetada?.update || !gtin}
      />
    </form>
  );
};
