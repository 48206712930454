import { MergeCellsOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Button, message } from "antd";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { ExtractLogsButton } from "components/AuditLogExtract/ExtractLogsButton";
import EmptySelection from "components/EmptySelection/EmptySelection";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { PixColumn } from "components/PixInfinityTable/PixInfinityTable";
import { TabItem } from "components/Tabs/Tabs";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { uniq } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { EntitiesEnum } from "shared/enum/EntitiesEnum";
import {
  Manufacturer,
  ManufacturerListingType,
  ManufacturerRequest,
} from "shared/models/Manufacturer";
import { ManufacturerMetadata } from "shared/models/User";
import { manufacturerService } from "shared/services/ManufacturerService";
import { MergeRequest } from "shared/types/Merge";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { getInitialFilter } from "util/filter.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import { readInitialTab } from "util/yeh.query.param.util";
import { ManufacturerBrands } from "./components/ManufacturerBrands";
import { ManufacturerCharts } from "./components/ManufacturerCharts";
import { ManufacturerDetail } from "./components/ManufacturerDetail";
import {
  ManufacturersAdvancedFilters,
  ManufacturersFilters,
} from "./components/ManufacturersAdvancedFilters";

const getInitialFilters = () =>
  getInitialFilter("filters", ManufacturersFilters) as ManufacturersFilters;

const tabItems: TabItem[] = [
  {
    title: "Fabricante",
    value: "manufacturer",
  },
  {
    title: "Marca",
    value: "brand",
  },
  {
    title: "Gráficos",
    value: "charts",
  },
];

const columns: PixColumn<ManufacturerListingType>[] = [
  {
    title: "ID FABRICANTE",
    dataIndex: "id",
  },
  {
    title: "FABRICANTE",
    dataIndex: "manufacturerName",
    render: (_: string, item?: ManufacturerListingType) => item?.name,
  },
  {
    title: "NOME FANTASIA",
    dataIndex: "manufacturerShortName",
    render: (_: string, item?: ManufacturerListingType) => item?.shortName,
  },
  {
    title: "N. de marcas",
    dataIndex: "totalBrands",
    align: "center",
  },
  {
    title: "ATIVO",
    align: "center",
    render: (_: string, item: ManufacturerListingType) => (
      <ActiveInactiveItem deleted={item.deleted} />
    ),
  },
  {
    key: "sanitation",
    title: "Saneamento",
    dataIndex: ["manufacturerSanitation", "label"],
    width: 100,
    align: "center" as any,
  },
];

type ManufacturersProps = DefaultPage & RouteComponentProps;

export const Manufacturers = ({ title }: ManufacturersProps) => {
  const [isListExpanded, setListExpanded] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [isWriting, setWriting] = useState(false);
  const [filters, setFilters] = useState<ManufacturersFilters>({
    ...getInitialFilters(),
    deleted: "false",
  });
  const [lastPage, setLastPage] = useState(true);
  const [listData, setListData] = useState<ManufacturerListingType[]>([]);
  const [selected, setSelected] = useState<Manufacturer>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState(readInitialTab(tabItems));
  const [page, setPage] = useState(0);
  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const [terms, setTerms] = useState<string[]>(
    !!filters.term ? filters.term.split(" ") : []
  );
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ManufacturerListingType[]>(
    []
  );
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [manufacturerMetada] = useState<ManufacturerMetadata | null>(
    getEntityPermissions<ManufacturerMetadata>("manufacturer")
  );

  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => {
    setCurrentTitle(title);
  }, [title, setCurrentTitle]);

  const onClickMergeMode = () => {
    if (isMergeMode) {
      setSelectedRows([]);
    }

    setIsMergeMode(!isMergeMode);
  };

  const fetchManufacturers = (page: number, filters: ManufacturersFilters) => {
    trackPromise(manufacturerService.getManufacturers(page, filters))
      .then((response) => {
        setPage(response.page);
        setFilters(filters);
        setLastPage(response.last);
        setListData((oldList) => [
          ...(page === 0 ? [] : oldList),
          ...response.content,
        ]);
      })
      .catch(() => message.error("Erro ao efetuar busca de Fabricantes."));
  };

  const handleRemoveFilter = (term: string) => {
    let newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);

    let newFilters = { ...filters, term: newTerms.join(" ") };

    fetchManufacturers(0, newFilters);
  };

  useEffect(() => putObjectAsQueryParam(filters, "filters"), [filters]);

  useEffect(() => {
    fetchManufacturers(0, getInitialFilters());
  }, []);

  const handleCreateNewClick = () => {
    setSelected(undefined);
    setWriting(true);
    setSelectedTab(tabItems[0]);
  };

  const handleSearch = (value: string, filters: ManufacturersFilters) => {
    // Avoid duplication
    let newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    let newFilters = { ...filters, term: newTerms.join(" ") };

    fetchManufacturers(0, newFilters);
  };

  const handleFetchMoreData = () => fetchManufacturers(page + 1, filters);

  const handleSelectedManufacturer = (manufacturerIds: number[]) => {
    let manufacturerId = manufacturerIds[0];

    // User is clicking in the same line
    if (manufacturerIds?.length === 1 && manufacturerId === selected?.id) {
      return;
    }

    setWriting(false);
    if (manufacturerIds?.length === 1 && manufacturerId) {
      trackPromise(
        manufacturerService.getManufacturerById(manufacturerId)
      ).then((manufacturer) => {
        setSelected(manufacturer);
        setWriting(true);
      });
    } else {
      setWriting(false);
      setSelected(undefined);
    }
  };

  const getOnSelectItem = (manufacturers: ManufacturerListingType[]) => {
    if (isMergeMode) {
      return setSelectedItems(manufacturers);
    }

    const manufacturerIds = manufacturers.map((it) => it.id);
    setSelectedRows(manufacturerIds);

    return handleSelectedManufacturer(manufacturerIds);
  };

  const handleSelectedTab = (value: TabItem) => {
    setSelectedTab(value);
  };

  const handleCancel = () => {
    setWriting(false);
    setSelectedRows([]);
    setSelected(undefined);
  };

  const onRemoveMergeItem = (remainingItems: ManufacturerListingType[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.id)
      .filter((itemId) =>
        remainingItems.some((remainItem) => remainItem.id === itemId)
      );
    setSelectedRows(newSelectedRows);
  };

  const handleSave = (manufacturerRequest: ManufacturerRequest) => {
    const manufacturer = manufacturerRequest.manufacturer;
    trackPromise(manufacturerService.save(manufacturerRequest))
      .then(() => {
        fetchManufacturers(0, filters);
        if (!manufacturer.id) {
          handleCancel();
        }
        message.success(
          `Fabricante ${
            manufacturer.id ? "atualizado" : "cadastrado"
          } com sucesso`
        );
      })
      .catch(() => {
        getErrorMessage(
          `Falha ao ${manufacturer.id ? "atualizar" : "cadastrar"} fabricante`
        );
      });
  };

  const getManufacturerTab = () => {
    if (!isWriting) {
      return <EmptySelection />;
    }
    return (
      <ManufacturerDetail
        manufacturer={selected}
        isWriting={isWriting}
        onSubmit={handleSave}
        onCancel={handleCancel}
      />
    );
  };

  const getBrandsManufacturer = () => (
    <ManufacturerBrands
      manufacturer={selected}
      handleSave={() => {
        setSelected(undefined);
        fetchManufacturers(0, filters);
      }}
    />
  );

  const getSideActionComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<ManufacturerListingType>
          data={selectedItems}
          getLabeledItem={(m: ManufacturerListingType) =>
            `${m.id} ${m.name} - ${m.shortName}`
          }
          onCancel={() => {
            setIsMergeMode(false);
            setSelectedRows([]);
            setSelectedItems([]);
          }}
          entityName="manufacturer"
          getItemId={(m: ManufacturerListingType) => m.id}
          mergeItems={(mergeRequest: MergeRequest) =>
            manufacturerService.merge(mergeRequest)
          }
          afterMerge={() => fetchManufacturers(page, filters)}
          onRemoveItem={onRemoveMergeItem}
        />
      );
    }
    return (
      <>
        <CrudCreateComponent
          handleSelectedTab={handleSelectedTab}
          isCreateExpanded={isCreateExpanded}
          tabItems={tabItems}
          selectedTab={selectedTab}
          setCreateExpanded={setCreateExpanded}
        />
        {selectedTab.value === "manufacturer" && getManufacturerTab()}
        {selectedTab.value === "brand" && getBrandsManufacturer()}
        {selectedTab.value === "charts" && (
          <ManufacturerCharts manufacturer={selected} />
        )}
      </>
    );
  };

  const handleModalSubmit = (filters: ManufacturersFilters) => {
    fetchManufacturers(0, filters);
    setTerms(filters.term ? [filters.term] : []);
    setAdvancedFilterOpen(false);
  };

  const handleClearFilter = () => {
    const manufacturerFilters = new ManufacturersFilters();
    manufacturerFilters.deleted = "false";
    handleModalSubmit(manufacturerFilters);
    setFilters(manufacturerFilters);
  };

  const handleMultiSelectClick = () => {
    if (multiselectMode && selectedRows.length) {
      const firstSelected = selectedRows[0];
      handleSelectedManufacturer([firstSelected]);
    }
  };

  const actionButtons = (
    <>
      <Button
        onClick={onClickMergeMode}
        icon={<MergeCellsOutlined />}
        id="btn-merge"
        hidden={!manufacturerMetada?.merge}
      >
        {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
      </Button>
      <ExtractLogsButton
        entityName={EntitiesEnum.MANUFACTURER}
        entityIds={selectedRows}
        hasPermission={manufacturerMetada?.readAudit}
      />
    </>
  );

  const secondaryActions = (
    <>
      <ToggleMultiSelectButton
        multiselectMode={multiselectMode}
        setMultiselectMode={setMultiselectMode}
        disabled={false}
        onChange={handleMultiSelectClick}
      />
    </>
  );

  const searchComponent = (
    <CrudListContent<ManufacturerListingType>
      title="Listagem de Fabricantes"
      columns={columns}
      data={listData}
      isExpanded={isListExpanded}
      fetchMoreData={handleFetchMoreData}
      filterProps={{
        terms: terms,
        advancedFilters: filters,
        handleFilterIconClick: () => setAdvancedFilterOpen((f) => !f),
        handleClearFilters: handleClearFilter,
      }}
      last={lastPage}
      rowKey="id"
      selectedRows={selectedRows}
      setExpanded={setListExpanded}
      setSelected={getOnSelectItem}
      actionButtons={actionButtons}
      secondaryActions={secondaryActions}
      multiselect={multiselectMode}
      handleCreateNewClick={handleCreateNewClick}
      handleRemoveFilter={handleRemoveFilter}
      handleSearch={(value) => handleSearch(value, filters)}
      protectedCrudAttributes={{
        protectCreate: !manufacturerMetada?.create,
      }}
      deletedStatusProps={{
        onChangeDeletedStatus: (deleted) =>
          handleModalSubmit({ ...filters, deleted }),
        value: filters.deleted,
        disabled: !manufacturerMetada?.editDeleted,
      }}
    />
  );

  return (
    <>
      <CrudContent
        searchComponent={searchComponent}
        createComponent={getSideActionComponent()}
        isCreateExpanded={isCreateExpanded}
        isListExpanded={isListExpanded}
      />
      <ManufacturersAdvancedFilters
        parentName="manufacturers"
        originalFilters={filters}
        onClose={() => setAdvancedFilterOpen(false)}
        onConfirm={handleModalSubmit}
        visible={advancedFilterOpen}
        fixedStatus={!manufacturerMetada?.editDeleted}
      />
    </>
  );
};
