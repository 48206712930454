import { blue } from "@ant-design/colors";
import {
  AppstoreAddOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  MergeCellsOutlined,
} from "@ant-design/icons";
import { RouteComponentProps, navigate } from "@reach/router";
import { Button, Dropdown, Menu, Tooltip, message } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { ExtractLogsButton } from "components/AuditLogExtract/ExtractLogsButton";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { Icons } from "components/Icons";
import { PixColumn } from "components/PixInfinityTable/PixInfinityTable";
import { PixLink } from "components/PixLink/PixLink";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { PixImage } from "containers/PixImage";
import { useProducts } from "hook/ProductsHook";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { EntitiesEnum } from "shared/enum/EntitiesEnum";
import { ProductById, ProductListItem } from "shared/models/Product";
import { ProductFormMetadata } from "shared/models/User";
import { productService } from "shared/services/ProductService";
import { userService } from "shared/services/UserService";
import { MergeRequest } from "shared/types/Merge";
import {
  USER_PERMISSION_STORAGE_KEY,
  getEntityPermissions,
} from "util/UserPermissionUtils";
import { getInitialFilter } from "util/filter.util";
import {
  getStoredColumnsArray,
  setLocalStorageColumns,
} from "util/localStorage.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import styles from "./Products.module.scss";
import { MassEditModal } from "./components/MassEditModal";
import { ProductEdit } from "./components/ProductEdit";
import {
  ProductFilters,
  ProductsAdvancedFilters,
} from "./components/ProductsAdvancedFilters";

export const getInitialProductFilters = () =>
  getInitialFilter("filters", ProductFilters) as ProductFilters;

type ProductProps = DefaultPage & RouteComponentProps;

const HIDDEN_COLUMNS_DEFAULT = ["active", "imageColumn"];
const LOCAL_STORAGE_KEY = "unchecked_columns";

export const Products = ({ title }: ProductProps) => {
  const { setCurrentTitle } = useContext(PageContext);

  const {
    last,
    terms,
    filters,
    products,
    refreshList,
    handleSearch,
    clearFilters,
    fetchProducts,
    fetchMoreData,
    handleRemoveFilter,
  } = useProducts();

  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [isEditing, setEditMode] = useState(false);
  const [isListExpanded, setListExpanded] = useState(false);

  const [massEditModalStatus, setMassEditModalStatus] = useState<boolean>(
    false
  );
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [selected, setSelected] = useState<ProductById>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAdvancedFilterVisible, setAdvancedFilterVisible] = useState(false);
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ProductListItem[]>([]);
  const [productMetaData, setProductMetaData] = useState<ProductFormMetadata>();

  const getCheckedColumns = () =>
    getStoredColumnsArray(LOCAL_STORAGE_KEY) || HIDDEN_COLUMNS_DEFAULT;

  const [checkedColumns, setCheckedColumns] = useState<string[]>(
    getCheckedColumns()
  );

  const getDefaultColumns = () => {
    let defaultVisibleColumns: PixColumn<ProductListItem>[] = [
      {
        title: "ID PRODUTO PADRÃO",
        key: "productId",
        dataIndex: "productId",
        width: 10,
      },
      {
        key: "imageColumn",
        title: "Imagem",
        align: "center" as any,
        dataIndex: "image",
        width: 20,
        render: (_: string, item: ProductListItem) => {
          return (
            <PixImage
              alt={item.description}
              imageUrl={item.imageUrl}
              width="auto"
              imgStyle={styles.maxHeight40}
              height="auto"
              maxWidth="100%"
            />
          );
        },
      },
      {
        key: "descColumn",
        title: "Descrição",
        dataIndex: "description",
        render: (value: string, item: ProductListItem) => {
          let openDescription = item.openDescription;

          return (
            <>
              {value}
              {!!openDescription && (
                <Tooltip title={openDescription}>
                  &nbsp;
                  <InfoCircleFilled style={{ color: blue.primary }} />
                </Tooltip>
              )}
            </>
          );
        },
      },
      {
        key: "manufacturerColumn",
        title: "Fabricante",
        dataIndex: ["brand", "manufacturer", "name"],
        render: (value: string, item: ProductListItem) => {
          if (!value) {
            return "";
          }
          return (
            <PixLink
              label={value}
              link="/fabricante"
              params={{
                manufacturerId: item.brand.manufacturer.id,
              }}
            />
          );
        },
        width: 50,
      },
      {
        key: "brandColumn",
        title: "Marca",
        dataIndex: ["brand", "name"],
        render: (value: string, item: ProductListItem) => {
          if (!value) {
            return "";
          }
          return (
            <PixLink
              label={value}
              link={`/marca`}
              params={{
                brandId: item.brand.id,
              }}
            />
          );
        },
        width: 50,
      },
      {
        key: "hasImage",
        title: () => (
          <Tooltip title="Possui imagens?" placement="top">
            <Icons name="fileImageOutlined" />
          </Tooltip>
        ),
        align: "center" as any,
        render: (_: string, item: ProductListItem) => (
          <Icons
            name={item.imageUrl ? "checkCircleFilled" : "closeCircleFilled"}
            style={{ color: item.imageUrl ? "green" : "red" }}
          />
        ),
        width: 10,
      },
      {
        title: "Status",
        key: "productStatus",
        dataIndex: "productStatus",
        align: "center",
        width: 10,
      },
      {
        key: "productSanitation",
        title: "Saneamento",
        dataIndex: "productSanitation",
        align: "center",
        width: 10,
      },
    ];

    if (productMetaData?.editDeleted) {
      defaultVisibleColumns.push({
        key: "active",
        title: "Ativo",
        dataIndex: "active" as any,
        align: "center" as any,
        render: (_: string, item: ProductListItem) => (
          <ActiveInactiveItem deleted={item.deleted} />
        ),
      });
    }
    return defaultVisibleColumns;
  };

  const getActualColumns = () => {
    const defaultColumns = getDefaultColumns();
    const columnsToHide = getCheckedColumns();
    return defaultColumns.filter(
      (columnProp) => !columnsToHide.includes(columnProp.key?.toString() ?? "")
    );
  };

  const [columns, setColumns] = useState<PixColumn<ProductListItem>[]>(
    getActualColumns()
  );

  useEffect(() => {
    let productPermissions: ProductFormMetadata | null = getEntityPermissions<ProductFormMetadata>(
      "product"
    );
    //Needed because this function is executed before Route.tsx
    if (!productPermissions) {
      userService
        .getPermissions()
        .then((permissions) => {
          sessionStorage.setItem(
            USER_PERMISSION_STORAGE_KEY,
            JSON.stringify(permissions)
          );
          setProductMetaData(permissions.product);
          return;
        })
        .catch(() =>
          message.error("Erro na consulta de dados de permissão do usuário.")
        );
    }
    setProductMetaData(productPermissions!);
  }, []);

  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  useEffect(() => putObjectAsQueryParam(filters, "filters"), [filters]);

  useEffect(() => {
    let initialFilters = getInitialProductFilters();
    let productId =
      initialFilters.productId?.length === 1
        ? initialFilters.productId[0]
        : null;
    if (!!productId) {
      fetchById(productId).then(() => setSelectedRows([productId!]));
    }
  }, []);

  const handleCreateNewClick = () => {
    productMetaData?.create && navigate("/hierarquia");
  };

  const clearAndFilter = (
    filters: ProductFilters,
    updatedProduct?: ProductById,
    clearSelected: boolean = true
  ) => {
    if (clearSelected) {
      setSelected(undefined);
      setSelectedRows([]);
    }

    if (updatedProduct) {
      setSelected(updatedProduct);
      setEditMode(true);
    }

    fetchProducts(0, filters);
  };

  const fetchById = async (id: number) => {
    try {
      const result = await trackPromise(productService.getById(id));
      setSelected(result);
      setEditMode(true);
      return result;
    } catch (error) {
      message.error("Erro ao buscar detalhe de produto.");
    }
    return null;
  };

  const handleSelectProduct = (products: ProductListItem[]) => {
    if (isMergeMode) {
      return setSelectedItems(products);
    }

    setSelectedRows(products.map((item) => item.productId));

    if (products.length === 1 && !multiselectMode) {
      const { productId } = products[0];
      if (productId !== selected?.id) {
        fetchById(productId);
      }
    } else {
      setSelected(undefined);
      setEditMode(false);
    }
  };

  const getColumnOptions = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Colunas">
          {getDefaultColumns()
            .filter(
              (columnProps) =>
                columnProps.key && columnProps.dataIndex && columnProps.title
            )
            .map((columnProps, index) => (
              <Menu.Item key={index}>
                <Checkbox
                  id={columnProps.key?.toString()}
                  onChange={onChangeCheckedColumns}
                  checked={columns
                    .map((column) => column.key)
                    .includes(columnProps.key)}
                >
                  {columnProps.title}
                </Checkbox>
              </Menu.Item>
            ))}
        </Menu.ItemGroup>
      </Menu>
    );
  };

  const onChangeCheckedColumns = (event: CheckboxChangeEvent) => {
    let checkedColumnsAux = checkedColumns;

    if (event.target.checked) {
      checkedColumnsAux = checkedColumns.filter((id) => {
        return id !== event.target.id;
      });
    } else {
      checkedColumnsAux.push(event.target.id!);
    }

    let filtered = getDefaultColumns();

    filtered = filtered.filter(
      (column) => !checkedColumnsAux.includes(column.key?.toString()!)
    );

    setLocalStorageColumns(LOCAL_STORAGE_KEY, checkedColumnsAux);
    setColumns(filtered);
    setCheckedColumns(checkedColumnsAux);
  };

  const handleOpenMassEditModal = () => {
    if (selectedRows.length < 1) {
      message.warning(
        "Selecione pelo menos um item para a alteração me massa."
      );
      message.warning(
        "Caso precise alterar mais de um item, ative o modo de multi seleção."
      );
      return;
    }
    const inactiveItemsSelected = products.filter(
      (item) => selectedRows.indexOf(item.productId) > -1 && item.deleted
    ).length;
    if (!!inactiveItemsSelected) {
      message.warning(
        "Não é possível alterar um produto inativo em massa. Selecione apena produtos ativos."
      );
      return;
    }
    setMassEditModalStatus(true);
  };

  const handleMultiSelectClick = () => {
    if (multiselectMode && selectedRows.length) {
      const firstSelected = selectedRows[0];
      handleSelectProduct(
        products.filter((item) => item.productId === firstSelected)
      );
    }
  };

  const actionButtons = (
    <>
      <Button
        onClick={() => {
          setIsMergeMode(!isMergeMode);
          setSelectedRows([]);
        }}
        icon={<MergeCellsOutlined />}
        id="btn-merge"
        style={{ marginLeft: "10px" }}
        hidden={!productMetaData?.merge}
      >
        {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
      </Button>
      <ExtractLogsButton
        entityName={EntitiesEnum.PRODUCT}
        entityIds={selectedRows}
        hasPermission={productMetaData?.readAudit}
      />
      <Button
        className="pix-action-button"
        disabled={isMergeMode}
        onClick={handleOpenMassEditModal}
      >
        Alteração em massa
      </Button>
    </>
  );

  const secondaryActions = (
    <>
      <ToggleMultiSelectButton
        multiselectMode={multiselectMode}
        setMultiselectMode={setMultiselectMode}
        disabled={false}
        onChange={handleMultiSelectClick}
      />

      <Tooltip title="Ocultar/Mostrar colunas" placement="topLeft">
        <Dropdown overlay={getColumnOptions} trigger={["click"]}>
          <Button
            id="btn-icon-edit-column"
            type="link"
            className={styles.secondaryButton}
            icon={<AppstoreAddOutlined />}
          />
        </Dropdown>
      </Tooltip>
    </>
  );

  const getSearchComponent = () => {
    return (
      <CrudListContent<ProductListItem>
        actionButtons={actionButtons}
        columns={columns}
        data={products}
        fetchMoreData={fetchMoreData}
        handleCreateNewClick={handleCreateNewClick}
        handleRemoveFilter={handleRemoveFilter}
        handleSearch={handleSearch}
        isExpanded={isListExpanded}
        multiselect={multiselectMode && !isMergeMode}
        last={last}
        rowKey="productId"
        secondaryActions={secondaryActions}
        selectedRows={selectedRows}
        setExpanded={setListExpanded}
        setSelected={handleSelectProduct}
        title="Listagem de Produtos"
        searchSuffix={
          <Tooltip
            overlay={
              "Busca otimizada para termo/status. " +
              "Se, no máximo, esses dois forem informados, a busca por termo ocorre em múltiplos campos do produto (id, GTIN, descrição e outros). " +
              "Os resultados são atualizados, em média, a cada 3 minutos."
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        }
        protectedCrudAttributes={{
          protectCreate: !productMetaData?.create,
        }}
        deletedStatusProps={{
          onChangeDeletedStatus: (deleted) =>
            handleAdvancedFilterConfirm({ ...filters, deleted }),
          value: filters.deleted,
          disabled: !productMetaData?.editDeleted,
        }}
        filterProps={{
          terms,
          advancedFilters: filters,
          handleFilterIconClick: () =>
            setAdvancedFilterVisible(!isAdvancedFilterVisible),
          handleClearFilters: clearFilters,
        }}
      />
    );
  };

  const handleCancelCreationClick = () => {
    setEditMode(false);
    setSelected(undefined);
    setSelectedRows([]);
    setCreateExpanded(false);
  };

  const handleMassEditSuccess = () => {
    setMassEditModalStatus(false);
    fetchProducts(0, filters);
    setSelectedRows([]);
    setSelected(undefined);
  };

  const onRemoveItem = (remainingItems: ProductListItem[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.productId)
      .filter((itemId) =>
        remainingItems.some((remainItem) => remainItem.productId === itemId)
      );
    setSelectedRows(newSelectedRows);
  };

  const getCreateComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<ProductListItem>
          data={selectedItems}
          getLabeledItem={(data) => `${data.productId} - ${data.description}`}
          getItemId={(data) => data.productId}
          onCancel={() => {
            setIsMergeMode(false);
            setSelectedRows([]);
            setSelectedItems([]);
          }}
          entityName="product"
          mergeItems={(mergeRequest: MergeRequest) =>
            productService.merge(mergeRequest)
          }
          afterMerge={refreshList}
          onRemoveItem={onRemoveItem}
        />
      );
    }

    return (
      <ProductEdit
        isEditing={isEditing}
        productProp={selected}
        handleCancelProductEdit={handleCancelCreationClick}
        isCreateExpanded={isCreateExpanded}
        setCreateExpanded={setCreateExpanded}
        afterEdit={(updatedProduct: ProductById) =>
          clearAndFilter(filters, updatedProduct, false)
        }
      />
    );
  };

  const handleAdvancedFilterConfirm = (
    advancedFilterValues: ProductFilters
  ) => {
    fetchProducts(0, advancedFilterValues);
    setAdvancedFilterVisible(false);
  };

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getCreateComponent()}
        isCreateExpanded={isCreateExpanded}
        isListExpanded={isListExpanded}
      />
      <MassEditModal
        status={massEditModalStatus}
        productIds={selectedRows}
        onOk={handleMassEditSuccess}
        onCancel={() => setMassEditModalStatus(false)}
        formMetadata={productMetaData}
      />
      <ProductsAdvancedFilters
        parentName="products"
        originalFilters={filters}
        onClose={() => setAdvancedFilterVisible(false)}
        onConfirm={handleAdvancedFilterConfirm}
        visible={isAdvancedFilterVisible}
        fixedStatus={!productMetaData?.editDeleted}
      />
    </>
  );
};
