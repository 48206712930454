import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { BrandsFilters } from "pages/Brands/components/BrandsAdvancedFilters";
import { Word } from "react-wordcloud";
import {
  Brand,
  BrandById,
  BrandCreateRequest,
  BrandListView,
  BrandUpdateRequest,
  isBrandUpdate,
} from "shared/models/Brand";
import { Sankey } from "shared/models/Sankey";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/brands";
const INDICATORS_PATH = CORE_LOAD_BALANCER_URL + "/indicators/brands";

class BrandService {
  async getBrands(
    page: number,
    filters: BrandsFilters,
    sort: string[] = [],
    size: number = 20
  ) {
    const params = objectToParams({ page, sort, size, ...filters });
    const r = await axios.request<PixResponsePage<BrandListView>>({
      method: "GET",
      url: BASE_PATH,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getBrandById(id: number) {
    const r = await axios.request<BrandById>({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getWordCloudIndicators(
    id: number,
    mode: boolean = true
  ): Promise<Word[]> {
    const r = await axios.request({
      method: "GET",
      url: `${INDICATORS_PATH}/${id}/word-cloud`,
      params: {
        mode,
      },
    } as AxiosRequestConfig);
    return r.data;
  }

  async getSankeyIndicators(id: number): Promise<Sankey[]> {
    const r = await axios.request({
      method: "GET",
      url: `${INDICATORS_PATH}/${id}/sankey`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async save(brandRequest: BrandCreateRequest | BrandUpdateRequest) {
    let headers, data;

    if (brandRequest?.file) {
      data = new FormData();
      data.append("file", brandRequest.file);

      const blob = new Blob([JSON.stringify(brandRequest.brand)], {
        type: "application/json",
      });
      data.append("brand", blob);

      headers = { "Content-Type": "multipart/form-data" };
    } else {
      data = brandRequest.brand;
      headers = { "Content-Type": "application/json" };
    }

    const body: AxiosRequestConfig = {
      url: BASE_PATH,
      headers,
      data,
    };
    const r = await axios.request<Brand>({
      method: isBrandUpdate(brandRequest.brand) ? "PUT" : "POST",
      ...body,
    });
    return r.data;
  }

  async merge(mergeRequest: MergeRequest) {
    const r = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data: mergeRequest,
    });
    return r.data;
  }
}

export const brandService = new BrandService();
