import { createContext, useState, useCallback, ReactNode } from "react";

type PageContextType = {
  title: string | ReactNode;
  setCurrentTitle: (title: string | ReactNode) => void;
};

const DEFAULT_TITLE = { title: "", setCurrentTitle: () => {} };

export const PageContext = createContext<PageContextType>(DEFAULT_TITLE);

export const useTitle = (): PageContextType => {
  const [title, setTitle] = useState<string | ReactNode>("");

  const setCurrentTitle = useCallback(
    (currentTitle: string | ReactNode): void => {
      setTitle(currentTitle);
    },
    []
  );

  return {
    title,
    setCurrentTitle,
  };
};
