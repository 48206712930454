import { Button, Checkbox, Divider, Row } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { useState } from "react";
import {
  BUS_SESSION_STORAGE_KEY,
  SELECTED_BUS_SESSION_STORAGE_KEY,
} from "shared/types/AuthUtils";

const BU_STRING_SEPARATOR = ",";

type SelectBuCardContentProps = {
  afterCancel: () => void;
};

export const SelectBuCardContent = ({
  afterCancel,
}: SelectBuCardContentProps) => {
  const getStorageBus = (buStorageKey: string): string[] => {
    const busStorage = localStorage.getItem(buStorageKey);
    if (busStorage) {
      return busStorage.split(BU_STRING_SEPARATOR).sort();
    }
    return [];
  };

  const userBus = getStorageBus(BUS_SESSION_STORAGE_KEY);

  const [selectedBus, setSelectedBus] = useState<string[]>(
    getStorageBus(SELECTED_BUS_SESSION_STORAGE_KEY)
  );

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedBus(checkedValues as string[]);
  };

  const onSaveSelectedBus = () => {
    localStorage.setItem(
      SELECTED_BUS_SESSION_STORAGE_KEY,
      selectedBus.join(BU_STRING_SEPARATOR)
    );
    window.location.reload();
  };

  const onCancelSelectedBus = () => {
    setSelectedBus(getStorageBus(SELECTED_BUS_SESSION_STORAGE_KEY));
    afterCancel();
  };

  const onSelectAll = () => {
    setSelectedBus(userBus);
  };

  const onClearAll = () => {
    setSelectedBus([]);
  };

  return (
    <>
      <Checkbox.Group
        onChange={onChange}
        value={selectedBus}
        defaultValue={userBus}
      >
        {userBus.map((bu) => (
          <Row id={`row_${bu}`} key={bu}>
            <Checkbox value={bu}>
              <h4>{bu}</h4>
            </Checkbox>
          </Row>
        ))}
      </Checkbox.Group>
      <Divider style={{ margin: "0px" }} />
      <Row justify="center">
        <Button type="text" onClick={onClearAll}>
          Limpar todos
        </Button>
        <Button type="link" onClick={onSelectAll}>
          Marcar todos
        </Button>
      </Row>
      <Divider style={{ margin: "0px" }} />
      <Row justify="center">
        <CancelAndSave
          onCancel={onCancelSelectedBus}
          onConfirm={onSaveSelectedBus}
          showConfirmTitle="Deseja salvar as alterações de BU? (a página será recarregada)"
          showConfirm={true}
          disabled={!selectedBus.length}
        />
      </Row>
    </>
  );
};
