import { message } from "antd";
import { uniq } from "lodash";
import { ProductFilters } from "pages/Products/components/ProductsAdvancedFilters";
import { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductListItem } from "shared/models/Product";
import { productService } from "shared/services/ProductService";
import { getInitialFilter } from "util/filter.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import { readTermQueryParam } from "util/yeh.query.param.util";
import { getTerms } from "./HookUtil";

const PRODUCT_FILTERS = "productFilters";

export const getInitialProductFilters = () =>
  getInitialFilter(PRODUCT_FILTERS, ProductFilters) as ProductFilters;

export const useProducts = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<ProductFilters>({
    ...getInitialProductFilters(),
    deleted: "false",
  });
  const [terms, setTerms] = useState(readTermQueryParam());
  const [last, setLast] = useState(true);
  const [products, setProducts] = useState<ProductListItem[]>([]);

  const fetchProducts = useCallback((page: number, filters: ProductFilters) => {
    trackPromise(productService.getProducts(page, filters))
      .then((response) => {
        setFilters(filters);
        setPage(response.page);
        setLast(response.last);
        setTerms((o) => getTerms(o, filters.term));
        setProducts((old) => [...(page > 0 ? old : []), ...response.content]);
      })
      .catch(() => message.error("Erro ao buscar lista de produtos."));
  }, []);

  const handleRemoveFilter = (term: string) => {
    const newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);
    fetchProducts(0, { ...filters, term: newTerms.join(" ") });
  };

  const handleSearch = (value: string) => {
    const newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    const newFilters = { ...filters, term: newTerms.join(" ") };
    fetchProducts(0, newFilters);
  };

  const fetchMoreData = () => {
    if (!last) {
      fetchProducts(page + 1, filters);
    }
  };

  const refreshList = () => {
    fetchProducts(0, filters);
  };

  const clearFilters = () => {
    fetchProducts(0, { deleted: "false" });
  };

  useEffect(() => {
    fetchProducts(0, {
      ...getInitialProductFilters(),
      deleted: "false",
    });
  }, [fetchProducts]);

  useEffect(() => putObjectAsQueryParam(filters, PRODUCT_FILTERS), [filters]);

  return {
    last,
    terms,
    products,
    filters,
    setFilters,
    refreshList,
    clearFilters,
    handleSearch,
    fetchMoreData,
    fetchProducts,
    handleRemoveFilter,
  };
};
