import { Tag } from "antd";
import React from "react";
import {
  AssociationDetail,
  ProductAssociation,
} from "shared/models/Association";

export const getTag = (code: string, type: string) => (
  <Tag
    closable={false}
    color={"GTIN,PACKAGE".includes(type) ? "green" : "geekblue"}
    key={code}
  >
    <b>{type}: </b>
    {code}
  </Tag>
);

export const codeTag = (detail?: AssociationDetail | ProductAssociation) => {
  if (!detail) {
    return null;
  }
  let result = [];
  if (!!detail.gtinCode) {
    result.push(getTag(detail.gtinCode.gtin, detail.gtinCode.gtinType));
  }

  if (!!detail.internalSku) {
    result.push(getTag(detail.internalSku.sku, detail.internalSku.skuType));
  }

  return result;
};
