export const scrollFunction = (event: any, getMoreItems?: Function) => {
  const { target } = event;

  const maxScroll = Math.round(target.scrollHeight);
  let actualScroll = Math.round(target.scrollTop + target.offsetHeight);
  actualScroll = [1, -1].includes(maxScroll - actualScroll)
    ? maxScroll
    : actualScroll;

  if (actualScroll === maxScroll && getMoreItems) {
    getMoreItems();
  }
};
