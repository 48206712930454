import React, { ReactElement } from "react";

import cx from "classnames";
import styles from "./MenuItem.module.scss";

import { navigate } from "@reach/router";

import { Popover } from "antd";
import { Icons } from "components/Icons";

export interface MenuItem {
  color?: string;
  icon?: string;
  id: string;
  route?: ReactElement;
  submenu?: MenuItem[];
  title: string;
}
interface MenuItemProps {
  children?: React.ReactNode;
  color?: string;
  displaySubmenu?: boolean;
  icon?: string;
  id: string;
  isCollapsed?: boolean;
  isSelected?: boolean;
  path: string;
  setDisplaySubmenu: (id?: string) => void;
  setSelected: (id: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
}

export const MenuItem = ({
  children,
  color,
  displaySubmenu = false,
  icon,
  id,
  isCollapsed = false,
  isSelected = false,
  path,
  setDisplaySubmenu,
  setSelected,
  title,
}: MenuItemProps) => {
  const buttonWrapperStyle = cx(styles.buttonWrapper);
  const itemStyle = cx(styles.wrapper, {
    [styles.selectedItem]: isSelected,
    [styles.collapsedItem]: isCollapsed,
  });
  const iconStyle = cx(styles.icon, {
    [styles.rotateIcon]: displaySubmenu,
  });
  const submenuStyle = cx(styles.submenu, {
    [styles.activeSubmenu]: displaySubmenu,
  });
  const onClickHandle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    navigate(path);
    setSelected(event);
  };
  const handleDisplaySubmenu = () =>
    displaySubmenu === false
      ? setDisplaySubmenu(id)
      : setDisplaySubmenu(undefined);
  const getMenuDiv = () => {
    const menuDiv = (
      <li
        key={`${id}-${title}`}
        id={id}
        data-testid="menu-item-wrapper"
        onClick={onClickHandle}
        className={itemStyle}
        style={{
          backgroundColor: displaySubmenu
            ? "var(--color-light-gray)"
            : undefined,
        }}
      >
        <div>
          {icon && (
            <div
              title={title}
              data-testid="item-menu-icon"
              className={styles.menuIcon}
            >
              <Icons name={icon} />
            </div>
          )}
          {!isCollapsed && (
            <p className={styles.menuText} style={{ color }}>
              {title}
            </p>
          )}
        </div>
      </li>
    );
    if (isCollapsed) {
      return (
        <Popover
          content={children}
          overlayClassName={styles.colapsedMenu}
          placement="right"
          title={<>{title}</>}
        >
          {menuDiv}
        </Popover>
      );
    }
    return menuDiv;
  };
  return (
    <>
      <div className={buttonWrapperStyle}>
        {getMenuDiv()}
        {!isCollapsed && children && (
          <>
            <button
              data-testid="item-menu-arrow"
              onClick={() => handleDisplaySubmenu()}
              className={iconStyle}
            >
              <Icons name="rightOutlined" />
            </button>
          </>
        )}
      </div>
      {!isCollapsed && (
        <div data-testid="item-menu-submenu" className={submenuStyle}>
          {children}
        </div>
      )}
    </>
  );
};
