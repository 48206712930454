import { Button } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { FormItemBoolean } from "components/FormItem/FormItemBoolean";
import { FormItemMultipleInteger } from "components/FormItem/FormItemMultipleInteger";
import { FormItemSelect } from "components/FormItem/FormItemSelect";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemWrapper } from "components/FormItem/FormItemWrapper";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { IdName } from "shared/models/IdName";
import OptionData from "shared/models/OptionData";
import { SubBrandListFilter } from "shared/models/SubBrand";
import { brandService } from "shared/services/BrandService";
import { scrollFunction } from "shared/utils/ScrollUtils";
import { handleFilterChange } from "util/filter.util";

interface BrandsAdvancedFiltersProps {
  onClose: () => void;
  onConfirm: (filters: SubBrandListFilter) => void;
  visible: boolean;
  parentName: string;
  fixedBrand?: IdName;
  fixedStatus?: boolean;
  originalFilters: SubBrandListFilter;
}

export const SubBrandAdvancedFilters = ({
  onClose,
  onConfirm,
  visible,
  fixedBrand,
  parentName,
  fixedStatus,
  originalFilters,
}: BrandsAdvancedFiltersProps) => {
  const [filters, setFilters] = useState<SubBrandListFilter>({});

  const [brandsPage, setBrandsPage] = useState(0);
  const [brandsLast, setBrandsLast] = useState(true);
  const [brandsTerm, setBrandsTerm] = useState("");
  const [brandsOptions, setBrandsOptions] = useState<OptionData[]>([]);

  const handleConfirmClick = () => {
    onConfirm(filters);
  };

  const handleSearchBrands = (page: number, term: string) => {
    if (!term.length) {
      return;
    }
    brandService
      .getBrands(page, {
        term,
        deleted: "false",
        brandSanitation: "ALTO",
        brandId: fixedBrand?.id ? [fixedBrand?.id] : undefined,
      })
      .then((response) => {
        setBrandsOptions((oldOptions) => [
          ...(page > 0 ? oldOptions : []),
          ...response.content.map((it) => ({
            label: it.name,
            value: it.id,
          })),
        ]);
        setBrandsPage(page);
        setBrandsLast(response.last);
        setBrandsTerm(term);
      });
  };

  const loadMoreBrands = () => {
    if (!brandsLast) {
      handleSearchBrands(brandsPage + 1, brandsTerm);
    }
  };

  const debounceSearchBrands = debounce((term: string) => {
    handleSearchBrands(0, term);
  }, 600);

  useEffect(() => {
    setFilters({ ...originalFilters });
  }, [originalFilters]);

  useEffect(() => {
    if (!!fixedBrand) {
      setBrandsOptions([{ label: fixedBrand.name, value: fixedBrand.id }]);
      setFilters((oldFilters) => ({
        ...oldFilters,
        brandId: fixedBrand?.id,
      }));
    }
  }, [fixedBrand]);

  return (
    <FormItemWrapper
      title="Filtros avançados para sub marcas"
      visible={visible}
      submit={handleConfirmClick}
      onCancel={() => {
        setFilters({ ...originalFilters });
        onClose();
      }}
    >
      <FormItemMultipleInteger
        id="sub-brand-id-filter"
        label="IDs Sub Marca"
        onChange={(value) =>
          handleFilterChange("subBrandId", value, setFilters)
        }
        value={filters.subBrandId}
      />
      <FormItemSelect
        id={`${parentName}-filter-product-line-id`}
        label="Marca"
        onChange={(brandId) => setFilters({ ...filters, brandId })}
        onSearch={debounceSearchBrands}
        options={brandsOptions}
        value={fixedBrand?.id ?? filters.brandId}
        disabled={!!fixedBrand}
        onPopupScroll={(evt) => scrollFunction(evt, loadMoreBrands)}
      />
      <FormItemBoolean
        label="Status"
        id={`sub-brand-deleted-filter`}
        onChange={(value) => handleFilterChange("deleted", value, setFilters)}
        textForFalse="Ativo"
        textForTrue="Inativo"
        value={filters.deleted}
        disabled={fixedStatus}
      />
      <FormItem label={<FilterLabel text="Saneamento" htmlFor="saneamento" />}>
        <div id="saneamento" style={{ marginTop: "-4%" }}>
          <SanitationSelect
            onChange={(subBrandSanitation) =>
              setFilters({ ...filters, subBrandSanitation })
            }
            value={filters.subBrandSanitation}
          />
        </div>
      </FormItem>
      <FormItemText
        id="sub-brand-term-filter"
        label="Termo genérico"
        onChange={(value) => handleFilterChange("term", value, setFilters)}
        value={filters.term}
      />
      <div style={{ position: "absolute", bottom: "11px" }}>
        <Button
          onClick={() =>
            setFilters((oldFilters) => ({
              brandId: fixedBrand?.id,
              deleted: fixedStatus ? oldFilters.deleted : undefined,
            }))
          }
          type="link"
        >
          Limpar filtros
        </Button>
      </div>
    </FormItemWrapper>
  );
};
