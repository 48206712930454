import { Button, Card, Tooltip } from "antd";
import { Icons } from "components/Icons";
import AdvancedFilterProductCatch from "containers/AdvancedFilterProductCatch/AdvancedFilterProductCatch";
import React, { forwardRef, useState } from "react";
import { Word } from "react-wordcloud";
import {
  AssociationDataTypeSelect,
  AssociationFilterBase,
  AssociationStatistics,
  Transaction,
  VisionTypes,
} from "shared/models/Association";
import { ProductListItem } from "shared/models/Product";
import { AssociationList } from "./AssociationList";
import { CapturesList } from "./CapturesList";
import { CombinationFilterOptions } from "./CombinationFilterOptions";
import { StatisticsModal } from "./StatisticsModal";
import styles from "./TransactionList.module.scss";

interface Props {
  disabledConnect: boolean;
  onChangeOrder: () => void;
  onConnectClick: () => void;
  onSelectItems: (items: Transaction[]) => void;
  selectedVision: VisionTypes;
  target?: ProductListItem;
  associationDataType: AssociationDataTypeSelect[];
  associationDataTypeSelected: AssociationDataTypeSelect;
  onChangeDataType: (data: AssociationDataTypeSelect) => void;
  associateItems: (
    items: Transaction[],
    target: ProductListItem,
    reason: string
  ) => void;
  filters: AssociationFilterBase;
  setFilters: (filters: AssociationFilterBase) => void;
}

export const TransactionList = forwardRef(
  (
    {
      disabledConnect,
      onChangeOrder,
      onConnectClick,
      onSelectItems,
      selectedVision,
      target,
      associationDataType,
      associationDataTypeSelected,
      onChangeDataType,
      associateItems,
      filters,
      setFilters,
    }: Props,
    forwardedRef: any
  ) => {
    const [isAdvancedFilterCatchOpen, setIsAdvancedFilterCatchOpen] = useState(
      false
    );
    const [isStatisticsModalOpen, setStatisticsModalOpen] = useState(false);

    const [dataStatistics, setStatistics] = useState<AssociationStatistics>();

    const addValueToFilter = (value: string) => {
      setFilters({
        ...filters,
        skus: [...filters.skus, value],
      });
    };

    const handleAdvancedSearch = (appliedFilters: AssociationFilterBase) => {
      setFilters(appliedFilters);
      setIsAdvancedFilterCatchOpen(false);
    };

    const getCardAction = () => {
      return (
        <CombinationFilterOptions
          filters={filters}
          onAdvancedFilterClick={() => setIsAdvancedFilterCatchOpen(true)}
          onSearch={(term) => setFilters({ ...filters, term })}
          onShowStatistics={() => setStatisticsModalOpen(true)}
          showStatistics={
            !!dataStatistics && selectedVision === VisionTypes.UNASSOCIATED
          }
          tagColor="rgb(50, 50, 93)"
        />
      );
    };

    return (
      <>
        <Card
          className={styles.transactionCard}
          title={
            <>
              <p>Resultados</p>
              <div className={styles.headingButtonSection}>
                <Tooltip
                  title={`Tipo de dados: ${associationDataTypeSelected.title}`}
                  placement="bottom"
                >
                  <div
                    className={styles.dataType}
                    onClick={() => setIsAdvancedFilterCatchOpen(true)}
                  >
                    <Icons name="databaseOutlined" />{" "}
                    {associationDataTypeSelected.title}
                  </div>
                </Tooltip>
                <Tooltip title="Alternar quadros" placement="bottom">
                  <Button
                    key="changeBtn"
                    type="default"
                    icon={<Icons name="swapOutlined" />}
                    onClick={onChangeOrder}
                  />
                </Tooltip>
                <Button
                  key="associateBtn"
                  type="primary"
                  className={styles.associateButton}
                  onClick={onConnectClick}
                  disabled={disabledConnect}
                >
                  Associar
                </Button>
              </div>
            </>
          }
          extra={getCardAction()}
        >
          {selectedVision === VisionTypes.UNASSOCIATED && (
            <CapturesList
              filters={filters}
              onSelectItems={onSelectItems}
              setDataStatistics={setStatistics}
              addValueToFilter={addValueToFilter}
              ref={forwardedRef}
              associationDataTypeSelected={associationDataTypeSelected}
              associateItems={associateItems}
            />
          )}
          {selectedVision === VisionTypes.ASSOCIATED && (
            <AssociationList
              associationDataTypeSelected={associationDataTypeSelected}
              filters={filters}
              target={target}
            />
          )}
        </Card>
        {isAdvancedFilterCatchOpen && (
          <AdvancedFilterProductCatch
            onClose={() => setIsAdvancedFilterCatchOpen(false)}
            onConfirm={handleAdvancedSearch}
            parentFilters={filters}
            status={isAdvancedFilterCatchOpen}
            associationDataTypeSelected={associationDataTypeSelected}
            associationDataType={associationDataType}
            onChangeDataType={onChangeDataType}
          />
        )}
        <StatisticsModal
          isOpen={isStatisticsModalOpen}
          onClose={() => setStatisticsModalOpen(false)}
          onWordClick={(word: Word) => {
            setStatisticsModalOpen(false);
            setFilters({
              ...filters,
              term: [...filters.term, word.text],
            });
          }}
          statistics={dataStatistics}
          searchTerms={filters.term}
        />
      </>
    );
  }
);
