import { Button, Col, message, Row, Switch, Tooltip } from "antd";
import { AttributeSelect } from "components/AttributeSelect/AttributeSelect";
import { Icons } from "components/Icons";
import { CharacteristicsLink } from "components/YehLink/CharacteristicsLink";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { uniqBy } from "lodash";
import { DraggableList } from "pages/HierarchicalView/components/OrderFeaturesDrawer/DraggableList";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  ATTRIBUTE_TYPE_KEYS,
  AttributeTypes,
} from "shared/models/Characteristic";
import { IdName } from "shared/models/IdName";

import {
  ProductLine,
  ProductLineAttribute as ProductLineAttributeT,
  ProductLineAttributeDetail,
} from "shared/models/ProductLine";
import { productLineService } from "shared/services/ProductLineService";

type ProductLineAttributeIdName = { suggested: boolean } & IdName;

const initialAttributes = (): Record<
  AttributeTypes,
  ProductLineAttributeDetail[]
> => {
  return {
    PRIMARY: [],
    SECONDARY: [],
    FISCAL: [],
    ASSET: [],
    LOGISTIC: [],
  };
};

interface ProductLineAttributeProps {
  productLine: ProductLine;
  onSaveAttributes: (
    attributes?: Record<AttributeTypes, ProductLineAttributeDetail[]>
  ) => void;
  onCancel: () => void;
}

// Actualy Product Line only contains PRIMARY attributes,
// but the API and this component were built to easily support all kinds of attributes (this component requires some refactors)
export const ProductLineAttribute = ({
  productLine,
  onSaveAttributes,
  onCancel,
}: ProductLineAttributeProps) => {
  const [productLineAttributes, setProductLineAttributes] = useState<
    Record<AttributeTypes, ProductLineAttributeDetail[]>
  >(initialAttributes());

  const [orderedAttributes, setOrderedAttributes] = useState<
    ProductLineAttributeIdName[]
  >([]);
  const [pushNewAttribute, setPushNewAttribute] = useState(true);
  const [attributeSelectValue, setAttributeSelectValue] = useState<
    number | undefined
  >();

  useEffect(() => {
    setOrderedAttributes([]);

    setProductLineAttributes({
      ...initialAttributes(),
      ...productLine.attributes,
    });

    setAttributeSelectValue(undefined);
  }, [productLine]);

  useEffect(() => {
    if (productLineAttributes.PRIMARY) {
      const newAttributes = productLineAttributes.PRIMARY.map((attribute) => {
        const draggableListItem: ProductLineAttributeIdName = {
          name: attribute.description,
          id: attribute.id,
          suggested: attribute.suggested,
        };

        return draggableListItem;
      });

      setOrderedAttributes(newAttributes);
    } else {
      setOrderedAttributes([]);
    }
    setAttributeSelectValue(undefined);
  }, [productLineAttributes]);

  const updatePrimaryAttributes = (
    primaryAttributes: ProductLineAttributeDetail[]
  ) => {
    console.log(primaryAttributes);

    setProductLineAttributes({
      PRIMARY: uniqBy(primaryAttributes, (att) => att.id),
      SECONDARY: [],
      FISCAL: [],
      ASSET: [],
      LOGISTIC: [],
    });
  };

  const onReorder = (orderedAttributes: IdName[]) => {
    const orderedIds = orderedAttributes.map((idName) => idName.id);

    let primaryAttributes: ProductLineAttributeDetail[] =
      productLineAttributes.PRIMARY;

    primaryAttributes.sort(
      (i, j) => orderedIds.indexOf(i.id) - orderedIds.indexOf(j.id)
    );

    updatePrimaryAttributes(primaryAttributes);
  };

  const onChangeAttributeSelect = (id: number, option: any) => {
    setAttributeSelectValue(id);

    if (id && option) {
      let primaryAttributes: ProductLineAttributeDetail[] =
        productLineAttributes.PRIMARY ?? [];

      const newAttribute: ProductLineAttributeDetail = {
        id: option.value,
        description: option.label,
        type: "",
        suggested: false,
      };

      if (pushNewAttribute) {
        primaryAttributes.push(newAttribute);
      } else {
        primaryAttributes.unshift(newAttribute);
      }

      updatePrimaryAttributes(primaryAttributes);
    }
  };

  const onRemoveAttribute = (id: number) => {
    const primaryAttributes = productLineAttributes.PRIMARY;
    updatePrimaryAttributes(primaryAttributes.filter((att) => att.id !== id));
  };

  const onChangeSuggested = (item: ProductLineAttributeIdName) => {
    const primaryAttributes = productLineAttributes.PRIMARY;
    const index = primaryAttributes.findIndex((att) => att.id === item.id);
    const updatedAttribute = primaryAttributes[index];
    primaryAttributes.splice(index, 1, {
      ...updatedAttribute,
      suggested: !updatedAttribute.suggested,
    });
    updatePrimaryAttributes(primaryAttributes);
  };

  const getDraggableListActionButtons = (item: ProductLineAttributeIdName) => (
    <>
      <Tooltip title="Sugerir preenchimento no Produto">
        <Button
          type={item.suggested ? "text" : "link"}
          onClick={() => onChangeSuggested(item)}
        >
          {item.suggested ? (
            <p style={{ color: "green" }}>Sugerido</p>
          ) : (
            "Sugerir"
          )}
        </Button>
      </Tooltip>
      <Tooltip title="Remover item">
        <Button
          type="link"
          icon={<Icons name="deleteOutlined" />}
          onClick={() => onRemoveAttribute(item.id)}
        />
      </Tooltip>
      <CharacteristicsLink attributeId={item.id} />
    </>
  );

  const saveAttributes = () => {
    const updatedAttributes: ProductLineAttributeT[] = productLineAttributes.PRIMARY.map(
      (attr) => ({ id: attr.id, suggested: attr.suggested })
    );

    const defaultErrorMessage = () =>
      message.error("Erro ao salvar os atributos da linha");

    trackPromise(productLineService.getProductLineById(productLine.id))
      .then((fullProductLine) => {
        trackPromise(
          productLineService.save({
            ...fullProductLine,
            productLineSanitation: fullProductLine.productLineSanitation?.value,
            attributes: updatedAttributes,
          })
        )
          .then(() => {
            setAttributeSelectValue(undefined);
            onSaveAttributes(productLineAttributes);
            message.success("Atributos da linha salvos com sucesso");
          })
          .catch(defaultErrorMessage);
      })
      .catch(defaultErrorMessage);
  };

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Row style={{ height: "2%" }} justify="center">
          <AttributeSelect
            attributeType={ATTRIBUTE_TYPE_KEYS.PRIMARY}
            onChange={onChangeAttributeSelect}
            label="Adicionar"
            placeholder="Selecione uma característica PRIMÁRIA"
            value={attributeSelectValue}
          />
        </Row>
        <Row style={{ height: "5%" }} justify="end">
          <Col>
            <Switch
              onChange={setPushNewAttribute}
              checkedChildren="Adicionar ao final"
              unCheckedChildren="Adicional ao começo"
              defaultChecked
            />
          </Col>
        </Row>
      </div>

      {!!orderedAttributes.length ? (
        <DraggableList
          items={orderedAttributes}
          onReorder={onReorder}
          actionButtons={getDraggableListActionButtons}
          alwaysShowActionButtons
        />
      ) : (
        <Row justify="center" style={{ height: "5%" }}>
          <h3>A linha não possuí nenhuma característica ainda.</h3>
        </Row>
      )}

      <CancelAndSave
        onCancel={onCancel}
        showConfirm
        showConfirmTitle={`Deseja confirmar a alteração? Todos os produtos dessa linha terão suas características alteradas`}
        onConfirm={saveAttributes}
      />
    </>
  );
};
