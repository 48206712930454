import React from "react";
import cx from "classnames";
import styles from "./PixImage.module.scss";

import { Icons } from "components/Icons";

type Props = {
  alt?: string;
  className?: string;
  imageUrl?: string;
  height: string;
  width: string;
  imgStyle?: string;
  maxHeight?: string;
  maxWidth?: string;
  onClick?: () => void;
};

const objectFit = "contain";

const PixImage = ({
  alt,
  className,
  imageUrl,
  height,
  width,
  imgStyle,
  maxHeight,
  maxWidth,
  onClick,
}: Props) => (
  <div
    className={cx("pix-image", className, styles.pixImage)}
    onClick={() => {
      if (typeof onClick === "function") onClick();
    }}
  >
    {imageUrl && (
      <img
        className={imgStyle}
        style={{ width, height, maxWidth, maxHeight, objectFit }}
        alt={`Imagem do produto ${alt}`}
        src={imageUrl}
      />
    )}
    {!imageUrl && (
      <Icons
        width={width}
        name="fileImageTwoTone"
        className={styles.imageIcon}
      />
    )}
  </div>
);

PixImage.defaultProps = {
  height: "64px",
  width: "64px",
};

export { PixImage };
