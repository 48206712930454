import { SelectBooleanValue } from "components/FormItem/FormItemBoolean";
import { LabelValue } from "shared/types/utils/LabelValue";

export interface SubBrandListItem {
  subBrandId: number;
  brandId: number;
  brandName: string;
  subBrandName: string;
  deleted: boolean;
}

export class SubBrandListFilter {
  subBrandId?: number[];
  brandId?: number;
  deleted?: SelectBooleanValue;
  subBrandSanitation?: string;
  term?: string;
}
export interface SubBrandCreate {
  brandId: number;
  subBrandName?: string;
  subBrandComment?: string;
  subBrandSite?: string;
  subBrandSanitation?: string;
}
export interface SubBrandUpdate extends SubBrandCreate {
  subBrandId: number;
  lastModified?: string;
  deleted: boolean;
}

export interface SubrandDto {
  subBrandId: number;
  subBrandName: string;
}

export type SubBrandDetail = SubBrandUpdate & {
  subBrandSanitation: LabelValue;
  totalProducts: number;
};
