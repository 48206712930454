import { Form, Select } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import {
  BrandsAdvancedFilters,
  BrandsFilters,
} from "pages/Brands/components/BrandsAdvancedFilters";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { BrandListView } from "shared/models/Brand";
import { IdName } from "shared/models/IdName";
import { brandService } from "shared/services/BrandService";
import { scrollFunction } from "shared/utils/ScrollUtils";

interface SearchBrandsSelectProps {
  onChange?: (brandsIds: number) => void;
  parentName: string;
  brandId?: number;
  disabled?: boolean;
  setSelectedBrand?: (idName?: IdName) => void;
  fixedProductLine?: IdName;
  setProductLineBrandId?: (id?: number) => void;
  initialBrandId?: number;
  fixedFilters?: Readonly<BrandsFilters>;
}

export const SearchBrandsSelect = ({
  onChange,
  parentName,
  brandId,
  disabled,
  setSelectedBrand,
  fixedProductLine,
  setProductLineBrandId,
  initialBrandId,
  fixedFilters,
}: SearchBrandsSelectProps) => {
  const [page, setPage] = useState(0);
  const [brandsFilters, setBrandsFilters] = useState<BrandsFilters>(
    fixedFilters || {
      deleted: "false",
      brandSanitation: "ALTO",
    }
  );
  const [brands, setBrands] = useState<BrandListView[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [alreadyLoadInitialBrand, setAlreadyLoadInitialBrand] = useState(false);
  const [last, setLast] = useState(true);

  useEffect(() => {
    if (initialBrandId && !alreadyLoadInitialBrand) {
      brandService
        .getBrands(0, { brandId: [initialBrandId] })
        .then(({ content }) => setBrands(content));
      setAlreadyLoadInitialBrand(true);
    }
  }, [initialBrandId, alreadyLoadInitialBrand]);

  const fetchBrands = (page: number, filters: BrandsFilters) => {
    const newFilters: BrandsFilters = {
      ...filters,
      ...fixedFilters,
    };
    trackPromise(
      brandService.getBrands(page, filters).then((response) => {
        let newBrands = [...(page === 0 ? [] : brands), ...response.content];
        setBrands(newBrands);
        setOptionsOpen(true);
        setFiltersOpen(false);
        setBrandsFilters(newFilters);
        setLast(response.last);
        setPage(page);
      })
    );
  };

  const debounceSearchBrands = debounce(
    (term: string, filters: BrandsFilters, productLineId?: number) =>
      fetchBrands(0, { ...filters, term, productLineId }),
    600
  );

  const loadMore = () => {
    if (!last) {
      fetchBrands(page + 1, brandsFilters);
    }
  };

  const handleChange = (id: number) => {
    onChange?.(id);
    const brand = brands.find((b) => b.id === id);
    setProductLineBrandId && setProductLineBrandId(brand?.productLineBrandId);
    setSelectedBrand &&
      setSelectedBrand(brand ? { id: brand.id, name: brand.name } : undefined);
  };

  return disabled ? (
    <></>
  ) : (
    <Form.Item
      label={
        <>
          <FilterLabel
            text="Marca"
            htmlFor={`${parentName}-search-brands-select`}
          />
          <FilterIcon
            filters={brandsFilters}
            onClick={() => setFiltersOpen(true)}
          />
        </>
      }
    >
      <BrandsAdvancedFilters
        visible={filtersOpen}
        parentName={parentName}
        originalFilters={{
          productLineId: fixedProductLine?.id,
          ...brandsFilters,
        }}
        onConfirm={(filters) => fetchBrands(0, filters)}
        onClose={() => setFiltersOpen(false)}
        fixedStatus
        fixedProductLine={fixedProductLine}
      />
      <Select<number>
        id={`${parentName}-search-brands-select`}
        filterOption={false}
        allowClear
        options={brands.map((brand) => ({
          value: brand.id,
          label: `${brand.manufacturer.name} > ${brand.name}`,
        }))}
        onChange={handleChange}
        onSearch={(term) =>
          debounceSearchBrands(term, brandsFilters, fixedProductLine?.id)
        }
        showSearch
        open={optionsOpen}
        onDropdownVisibleChange={setOptionsOpen}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
        value={brandId}
      />
    </Form.Item>
  );
};
