import { message } from "antd";
import { AssignedSubBrand } from "pages/SubBrand/components/AssignedSubBrand";
import React, { useEffect, useState } from "react";
import { BrandById } from "shared/models/Brand";
import { SubBrandListItem } from "shared/models/SubBrand";
import { PixResponsePage } from "shared/services/PixResponse";
import { subBrandService } from "shared/services/SubBrandService";
import styles from "./SubBrandTab.module.scss";

const FIRST_PAGE = 0;

interface SubBrandProps {
  brand: BrandById;
}

export const SubBrandTab = ({ brand }: SubBrandProps) => {
  const [subBrandsData, setSubBrandsData] = useState<
    PixResponsePage<SubBrandListItem>
  >();

  const fetchSubBrands = (brandId: number, page: number) => {
    subBrandService
      .findAll(page, { brandId: brandId, deleted: "false" })
      .then(setSubBrandsData)
      .catch(() => message.error("Falha ao buscar sub marcas"));
  };

  useEffect(() => {
    fetchSubBrands(brand.id, FIRST_PAGE);
  }, [brand.id]);

  return (
    <div className={styles.wrapper}>
      <>
        <AssignedSubBrand
          brandId={brand.id}
          afterSave={() => fetchSubBrands(brand.id, FIRST_PAGE)}
          subBrandsData={subBrandsData?.content}
          onFetchMoreData={() =>
            fetchSubBrands(brand.id, subBrandsData?.page! + 1)
          }
          last={subBrandsData?.last!}
        />
      </>
    </div>
  );
};
