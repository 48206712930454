import React, { useState, ReactText, useCallback, useEffect } from "react";
import styles from "./ColumnSelector.module.scss";
import {
  map as _map,
  filter as _filter,
  includes as _includes,
  reduce as _reduce,
  sortBy as _sortBy,
} from "lodash";

import { DraggableList, DraggableListItem } from "./DraggableList";

import { Drawer, Button, Select as AntdSelect } from "antd";
import { Select } from "components/Select";
import { PixColumn } from "components/PixInfinityTable/PixInfinityTable";

interface ColumnSelectorProps<T extends object> {
  columns: PixColumn<T>[];
  handleSelectedColumns: (columns: PixColumn<T>[]) => void;
  initialColumns: PixColumn<T>[];
  isVisible: boolean;
  onClose: () => void;
}

export const ColumnSelector = <T extends object>({
  columns,
  handleSelectedColumns,
  initialColumns,
  isVisible,
  onClose,
}: ColumnSelectorProps<T>) => {
  const getKeys = (cols: PixColumn<any>[]) => {
    return _map(cols, (item, idx) => (item.key ? item.key : "col_" + idx));
  };
  const reorderItems = (cols: any) => {
    return _map(cols, (col, idx) => ({ ...col, order: idx + 1 }));
  };
  const checkSelectedColumns = (): ReactText[] => {
    const actualKeys = getKeys(columns);
    return _filter(getKeys(initialColumns), (col) =>
      _includes(actualKeys, col)
    );
  };

  const [selectedColumns, setSelectedColumns] = useState<ReactText[]>(
    checkSelectedColumns()
  );

  const getOrderedColumns = useCallback((): DraggableListItem[] => {
    const formattedItems = _map(columns, (col) => ({
      key: col.key,
      title: col.title as string,
      order: col.order || 0,
    }));
    return reorderItems(formattedItems);
  }, [columns]);
  const [orderedColumns, setOrderedColumns] = useState<DraggableListItem[]>(
    getOrderedColumns()
  );

  useEffect(() => {
    setOrderedColumns(getOrderedColumns());
  }, [selectedColumns, getOrderedColumns]);

  const handleClose = () => {
    onClose();
  };

  // const handleCleanStorage = () => {
  //   window.localStorage.removeItem('pix-association-table-columns');
  //   message.success('Preferências limpas com sucesso.');
  // };

  const getOrderAsObject = () => {
    return _reduce(
      orderedColumns,
      (acum, item) => {
        acum[item.key as string] = item.order;
        return acum;
      },
      {} as { [key: string]: number }
    );
  };

  const getFilteredColumns = () => {
    return _filter(initialColumns, (col) =>
      _includes(selectedColumns, col.key)
    );
  };

  const onSubmit = () => {
    const updateColumnList = getFilteredColumns();
    const colOrder = getOrderAsObject();
    const sorted = _sortBy(
      updateColumnList,
      (col) => colOrder[col.key as string]
    );
    handleSelectedColumns(reorderItems(sorted));
    onClose();
  };

  const handleReorder = useCallback((reorderedColumns: DraggableListItem[]) => {
    setOrderedColumns(reorderedColumns);
  }, []);

  return (
    <Drawer
      className={styles.drawer}
      closable={false}
      onClose={onClose}
      visible={isVisible}
      width={380}
      zIndex={10}
    >
      <div className={styles.heading}>
        <h1>Selecionar e Ordernar Campos de Tabela</h1>
        <h2>
          Selecione abaixo, os campos que serão exibidos na tabela de associação
          e, em seguida, defina a ordem das colunas.
        </h2>
      </div>
      <div className={styles.select}>
        <Select<ReactText[]>
          allowClear
          id="key"
          label="Colunas"
          onChange={(value) => setSelectedColumns(value)}
          placeholder="Selecione ao menos uma coluna..."
          tabIndex={2}
          mode="multiple"
          defaultValue={selectedColumns}
        >
          {_map(initialColumns, (option) => (
            <AntdSelect.Option
              key={option.key}
              value={option.key ? option.key : ""}
            >
              {option.title}
            </AntdSelect.Option>
          ))}
        </Select>
      </div>
      <div className={styles.wrapper}>
        {orderedColumns.length > 0 && (
          <DraggableList items={orderedColumns} onReorder={handleReorder} />
        )}
      </div>
      <div className={styles.buttons}>
        <Button type="link" onClick={handleClose}>
          Cancelar
        </Button>
        {/* <Button type="link" onClick={handleCleanStorage}>
          Limpar preferências
        </Button> */}
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={selectedColumns.length < 1}
        >
          Aplicar
        </Button>
      </div>
    </Drawer>
  );
};
