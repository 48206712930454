import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import styles from "./CreateCategoryTreeSidenav.module.scss";

import { useFormik } from "formik";

import { Button, Drawer, message } from "antd";
import { Input } from "components/Input";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import PixSelectFromModel from "containers/PixSelectFromModel/PixSelectFromModel";
import { trackPromise } from "react-promise-tracker";
import { BuSegmentItem, Taxonomy } from "shared/models/Taxonomy";
import { taxonomyService } from "shared/services/TaxonomyService";
import { getErrorMessage } from "shared/utils/ResponseUtils";

interface CreateCategoryTreeSidenavProps {
  selected: BuSegmentItem | boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const validationSchema = Yup.object({
  bu: Yup.object({ leafId: Yup.number().required("Campo obrigatório") }),
  segment: Yup.object({
    description: Yup.string().required("Campo obrigatório"),
  }),
  maxLevel: Yup.number()
    .required("Campo obrigatório")
    .min(1, "O número mínimo de níveis é 1")
    .max(10, "O número máximo de níveis é 10"),
});

export const CreateCategoryTreeSidenav = ({
  selected,
  onClose,
  onSubmit,
}: CreateCategoryTreeSidenavProps) => {
  const [initalMaxLevel, setInitialMaxLevel] = useState(0);
  const isEditMode = typeof selected !== "boolean";
  const initialBu = {
    leafId: !isEditMode ? undefined : (selected as BuSegmentItem).bu?.id,
    description: !isEditMode
      ? undefined
      : (selected as BuSegmentItem).bu?.label,
  };
  const initialSegment = {
    leafId: !isEditMode ? undefined : (selected as BuSegmentItem).segment?.id,
    description: !isEditMode
      ? undefined
      : (selected as BuSegmentItem).segment?.label,
  };

  const { handleSubmit, values, errors, resetForm, setFieldValue } = useFormik({
    initialValues: {
      bu: initialBu,
      segment: initialSegment,
      maxLevel: 1,
      deleted: false,
    },
    validationSchema,
    onSubmit: () => {
      save(values);
    },
  });

  const fetchTaxonomyType = useCallback(
    (typeId: number) => {
      trackPromise(taxonomyService.getTaxonomyType([typeId]))
        .then((result) => {
          if (result.length === 1) {
            setFieldValue("maxLevel", result[0].maxLevel);
            setInitialMaxLevel(result[0].maxLevel);
          }
        })
        .catch((error) => getErrorMessage("Erro ao buscar tipo de taxonomia."));
    },
    [setFieldValue]
  );

  useEffect(() => {
    const selectedType =
      typeof selected === "boolean" ? undefined : selected.typeId;
    if (!!selectedType) {
      fetchTaxonomyType(selectedType);
    }
  }, [selected, fetchTaxonomyType]);

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const saveSegment = (valuesToSave: typeof values, taxonomyTypeId: number) => {
    trackPromise(
      taxonomyService.createTaxonomy({
        descriptions: [valuesToSave.segment.description!],
        parentId: valuesToSave.bu.leafId!,
        taxonomyTypeId,
      })
    )
      .then((result) => {
        message.success("Segmento adicionado com sucesso.");
        onSubmit();
        resetForm();
      })
      .catch((error) => getErrorMessage("Erro ao salvar Segmento."));
  };

  const saveNew = (valuesToSave: typeof values) => {
    const taxonomyType = `${valuesToSave.bu.description}_${valuesToSave.segment.description}`.toUpperCase();
    trackPromise(
      taxonomyService.getTaxonomyType(undefined, taxonomyType, "SEGMENT")
    )
      .then((result) => {
        if (!result || !result.length) {
          saveSegmentType(values, taxonomyType);
        } else {
          saveSegment(values, result[0].id);
        }
      })
      .catch((error) => getErrorMessage("Erro ao salvar taxonomia."));
  };

  const edit = async (valuesToSave: typeof values) => {
    try {
      if (
        initialSegment.leafId &&
        valuesToSave.bu.leafId &&
        valuesToSave.segment.description
      ) {
        await trackPromise(
          taxonomyService.updateTaxonomy(initialSegment.leafId, {
            description: valuesToSave.segment.description,
            parentId: valuesToSave.bu.leafId,
            deleted: valuesToSave.deleted,
          })
        );
      }
      const typeId = (selected as BuSegmentItem).typeId;
      if (typeId && initalMaxLevel !== valuesToSave.maxLevel) {
        await trackPromise(
          taxonomyService.updateTaxonomyType(typeId, valuesToSave.maxLevel)
        );
      }
    } catch (error) {
      getErrorMessage("Erro ao atualizar taxonomia.");
      return;
    }
    message.success("Taxonomia atualizada com sucesso.");
    onSubmit();
  };

  const save = (valuesToSave: typeof values) => {
    isEditMode ? edit(valuesToSave) : saveNew(valuesToSave);
  };

  const saveSegmentType = (
    valuesToSave: typeof values,
    taxonomyTypeDescription: string
  ) => {
    trackPromise(
      taxonomyService.createTaxonomyType(
        taxonomyTypeDescription,
        valuesToSave.maxLevel
      )
    )
      .then((result) => {
        saveSegment(valuesToSave, result.id);
      })
      .catch(() => getErrorMessage("Erro ao criar segmento."));
  };

  const fetchBUs = useCallback((term?: string[], page?: number) => {
    return trackPromise(
      taxonomyService.getTaxonomies(term, { page: page ?? 0 }, undefined, "BU")
    );
  }, []);

  return (
    <Drawer
      className={styles.drawer}
      closable={false}
      destroyOnClose={true}
      onClose={handleClose}
      visible={!!selected}
      width={380}
      zIndex={10}
    >
      <div className={styles.heading}>
        <h1>Nova árvore</h1>
      </div>
      <div className={styles.wrapper}>
        <div>
          <PixSelectFromModel<Taxonomy>
            error={errors.bu?.leafId ? errors.bu.leafId : undefined}
            fetchFunction={fetchBUs}
            label="BU"
            objectAttributeLabel="description"
            objectAttributeValue="leafId"
            onChangeValue={(value, option) => {
              !value && setFieldValue("bu", { leafId: undefined });
              value && setFieldValue("bu", option.original);
            }}
            value={values.bu.leafId}
            tabIndex={2}
          />
          <Input
            label="Segmento"
            error={
              errors.segment?.description
                ? errors.segment.description
                : undefined
            }
            value={values.segment.description}
            onChange={(evnt) =>
              setFieldValue("segment", {
                ...values.segment,
                description: evnt.currentTarget.value,
              })
            }
          />
          <Input
            label="Número máximo de níveis"
            error={errors.maxLevel ? errors.maxLevel : undefined}
            value={values.maxLevel}
            onChange={(evnt) =>
              setFieldValue("maxLevel", evnt.currentTarget.value)
            }
            type="number"
          />
          <YehSwitch
            checkedChildren="Ativo"
            unCheckedChildren="Inativo"
            checked={!values.deleted}
            onChange={(value) => setFieldValue("deleted", !value)}
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button type="link" onClick={handleClose}>
          Cancelar
        </Button>
        <Button type="primary" onClick={() => handleSubmit()} disabled={false}>
          {isEditMode ? "Salvar" : "Criar"}
        </Button>
      </div>
    </Drawer>
  );
};
