import { Button } from "antd";
import React from "react";
import styles from "./Footer.module.scss";

interface FooterProps {
  resetOptions: () => void;
  openModal: () => void;
  disableCreate: boolean;
}

export const Footer = ({
  resetOptions,
  openModal,
  disableCreate,
}: FooterProps) => {
  return (
    <footer className={styles.footer}>
      <Button type="link" className={styles.footerClear} onClick={resetOptions}>
        Limpar
      </Button>

      <Button
        type="primary"
        className={styles.footerCreate}
        onClick={openModal}
        disabled={disableCreate}
      >
        Cadastrar produto
      </Button>
    </footer>
  );
};
