import { RouteComponentProps, Router } from "@reach/router";
import { message } from "antd";
import { getLinkTo, getMenuItems } from "components/Menu/menuItems";
import { NotFound } from "components/NotFound";
import { useYehPermissions } from "hook/PermissionHook";
import { Dashboard } from "pages/Dashboard";
import { HierarchicalView } from "pages/HierarchicalView/HierarchicalView";
import React, { useEffect } from "react";
import { userService } from "shared/services/UserService";
import { USER_PERMISSION_STORAGE_KEY } from "util/UserPermissionUtils";

const NotFoundRoute: React.FC<RouteComponentProps> = () => <NotFound />;

export const Routes = () => {
  const { userHasAnyRole } = useYehPermissions();

  // Find user permissions
  useEffect(() => {
    userService
      .getPermissions()
      .then((permissions) =>
        sessionStorage.setItem(
          USER_PERMISSION_STORAGE_KEY,
          JSON.stringify(permissions)
        )
      )
      .catch((e) =>
        message.error("Erro na consulta de dados de permissão do usuário.")
      );
  }, []);

  return (
    <Router>
      {getMenuItems(userHasAnyRole)
        .filter(({ route }) => !!route)
        .map(({ route }) => route)}
      {getMenuItems(userHasAnyRole)
        .filter(({ submenu }) => !!submenu)
        .map(({ submenu }) =>
          submenu?.filter(({ route }) => !!route).map(({ route }) => route)
        )}
      <HierarchicalView
        key="hierarchy"
        path="/hierarquia"
        title={getLinkTo("Produto", "/produto", "Visão Hierárquica")}
      />
      <Dashboard key="dashboard" path="/" title="Dashboard" />
      <NotFoundRoute default />
    </Router>
  );
};
