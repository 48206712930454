import { Button, Card, Drawer } from "antd";
import { Icons } from "components/Icons";
import { PixLink } from "components/PixLink/PixLink";
import React, { useState } from "react";
import { SubBrandListItem } from "shared/models/SubBrand";
import {
  LoadMoreButton,
  LoadMoreButtonProps,
} from "../../../components/LoadMoreButton/LoadMoreButton";
import { SubBrandEdit } from "../SubBrandEdit";
import styles from "./SubBrandComponents.module.scss";

interface SubBrandProps extends LoadMoreButtonProps {
  brandId: number;
  subBrandsData?: SubBrandListItem[];
  afterSave?: () => void;
}

export const AssignedSubBrand = ({
  brandId,
  afterSave,
  subBrandsData,
  last,
  onFetchMoreData,
}: SubBrandProps) => {
  const [createSubBrandVisible, setCreateSubBrandVisible] = useState<boolean>(
    false
  );

  const handleCreateSubBrand = () => {
    setCreateSubBrandVisible(true);
  };

  const handleAfterSave = () => {
    afterSave?.();
    setCreateSubBrandVisible(false);
  };

  return (
    <>
      <div className={styles.heading}>
        <h2>Sub marcas atribuídas</h2>
        <Button type="link" onClick={handleCreateSubBrand}>
          <Icons name="fontawesomePlusSquare" /> Adicionar sub marcas
        </Button>
      </div>

      <Card className={styles.subBrandsCard}>
        {subBrandsData?.map((subBrand) => (
          <PixLink
            key={subBrand.subBrandId}
            link="/sub-marca"
            paramsName="subBrandsFilters"
            params={{ subBrandId: subBrand.subBrandId }}
            label={
              <Button
                key={subBrand.subBrandId}
                className={styles.subBrandItem}
                type="link"
              >
                <p>{subBrand.subBrandName}</p>
              </Button>
            }
          />
        ))}
        <LoadMoreButton last={last} onFetchMoreData={onFetchMoreData} />
      </Card>
      <Drawer
        title="Criar nova sub marca"
        width="40vw"
        visible={createSubBrandVisible}
        bodyStyle={{ backgroundColor: "#f2f2f2" }}
        onClose={() => setCreateSubBrandVisible(false)}
      >
        {createSubBrandVisible && (
          <SubBrandEdit
            initialBrandId={brandId}
            afterCancel={() => setCreateSubBrandVisible(false)}
            afterSave={handleAfterSave}
          />
        )}
      </Drawer>
    </>
  );
};
