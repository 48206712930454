import React from "react";
import { Button } from "antd";
import styles from "./SuggestionFooter.module.scss";

interface SuggestionFooterProps {
  onOk: () => void;
}

export const SuggestionFooter = ({ onOk }: SuggestionFooterProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonSection}>
        <Button type="primary" onClick={() => onOk()}>
          Aceitar sugestão
        </Button>
      </div>
    </div>
  );
};
