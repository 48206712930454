import { ProfileFilled, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu as AntdMenu,
  Avatar,
  Button,
  Divider,
  Dropdown,
  Layout,
  Popover,
  Spin,
} from "antd";
import { Menu } from "components/Menu/";
import { MenuItem } from "components/Menu/MenuItem/MenuItem";
import { getMenuItems } from "components/Menu/menuItems";
import { PageContext, useTitle } from "containers/Main/Main.context";
import { SelectBuCardContent } from "containers/SelectBU/SelectBuCardContent";
import { filter as _filter, flatten as _flatten, map as _map } from "lodash";
import React, { ReactNode, useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { useLocalStorage } from "shared/utils/Hooks";
import styles from "./Main.module.scss";

type Props = {
  children: ReactNode;
};

const Main = ({ children }: Props) => {
  const menuItems = getMenuItems();
  const { logout } = useAuth0();

  const { Content, Header, Sider } = Layout;
  const [isMenuCollapsed, setMenuCollapsed] = useLocalStorage(
    "pix-menu-collapsed",
    false
  );
  const [displaySubmenu, setDisplaySubmenu] = useState<string>();

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>(
    menuItems[0]
  );
  const [selectedSubItem, setSelectedSubItem] = useState<MenuItem>();
  const titleContext = useTitle();
  const [visibleBuSelect, setVisibleBuSelect] = useState(false);

  const { promiseInProgress } = usePromiseTracker();

  const handleSelectMenuItem = (value: string) => {
    const filteredItems = _filter(menuItems, (item) => item.id === value);
    if (filteredItems.length > 0) {
      setSelectedMenuItem(filteredItems[0]);
    }
  };

  const handleSelectSubMenuItem = (value?: string) => {
    if (!value) {
      setSelectedSubItem(undefined);
      return;
    }
    const filteredItems = _filter(
      _flatten(_map(menuItems, (item) => item.submenu)),
      (subitem: MenuItem) => subitem && subitem.id === value
    ) as MenuItem[];
    if (filteredItems.length > 0) {
      setSelectedSubItem(filteredItems[0]);
    }
  };

  return (
    <PageContext.Provider value={titleContext}>
      <Layout data-testid="main-layout" className={styles.layout}>
        <div
          className={styles.darkBar}
          style={{
            backgroundColor:
              (selectedSubItem && selectedSubItem.color) ||
              selectedMenuItem.color,
          }}
        />
        {promiseInProgress && (
          <div className={styles.loading}>
            <Spin size="large" tip="Carregando" />
          </div>
        )}
        <Sider
          className={styles.sider}
          collapsed={isMenuCollapsed}
          collapsedWidth="60px"
        >
          <Menu
            isCollapsed={isMenuCollapsed}
            setMenuCollapsed={setMenuCollapsed}
            selectedItem={selectedMenuItem}
            selectedSubItem={selectedSubItem}
            displaySubmenu={displaySubmenu}
            setSelectedItem={handleSelectMenuItem}
            setSelectedSubItem={handleSelectSubMenuItem}
            setDisplaySubmenu={setDisplaySubmenu}
            menuItems={menuItems}
          />
        </Sider>
        <div
          className={styles.mainContent}
          style={{
            width: `calc(100% - ${isMenuCollapsed ? "61px" : "200px"})`,
          }}
        >
          <Header className={styles.header}>
            <div className={styles.headerWrapper}>
              <div className={styles.headerTitle}>{titleContext.title}</div>
              <div className={styles.selectBuContainer}>
                <Popover
                  content={() => (
                    <SelectBuCardContent
                      afterCancel={() => setVisibleBuSelect(false)}
                    />
                  )}
                  title="Seleção de BU"
                  trigger="click"
                  visible={visibleBuSelect}
                >
                  <Button
                    type="text"
                    size="small"
                    onClick={() => setVisibleBuSelect(true)}
                    icon={
                      <ProfileFilled
                        style={{ fontSize: "28px", color: "#afafaf" }}
                      />
                    }
                  />
                </Popover>
                <Divider type="vertical" />
                <Dropdown
                  overlay={
                    <AntdMenu>
                      <AntdMenu.Item>
                        <Button
                          onClick={() =>
                            logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              },
                            })
                          }
                        >
                          Sair
                        </Button>
                      </AntdMenu.Item>
                    </AntdMenu>
                  }
                  trigger={["hover"]}
                  placement="bottomLeft"
                >
                  <Avatar icon={<UserOutlined />} />
                </Dropdown>
              </div>
              <div id="py-container" className={styles.pyContainer} />
            </div>
          </Header>

          <Content>{children}</Content>
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export default Main;
