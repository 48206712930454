import { Link } from "@reach/router";
import React, { ReactElement } from "react";
import styles from "./PixLink.module.scss";

type PixLinkProps = {
  link: string;
  label: string | number | ReactElement;
  params?: any;
  paramsName?: string;
};

export const PixLink = ({ label, link, params, paramsName }: PixLinkProps) => (
  <Link
    onClick={(e) => {
      // This fix some erros (like manufacturer redirection in Products)
      e.stopPropagation();
    }}
    to={
      link +
      (!!params
        ? "?" +
          (paramsName || "filters") +
          "=" +
          encodeURI(JSON.stringify(params))
        : "")
    }
    className={styles.link}
  >
    {label}
  </Link>
);
