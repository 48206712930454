import cx from "classnames";
import React, { ReactNode } from "react";
import styles from "./DefaultContent.module.scss";

export interface DefaultPage {
  title: string | ReactNode;
}

type Props = {
  refContainer?: React.RefObject<HTMLDivElement>;
  children: ReactNode;
};

export const DefaultContent = ({ refContainer, children }: Props) => {
  return (
    <div
      className={cx("pix-default-content", styles.defaultContent)}
      ref={refContainer}
    >
      {children}
    </div>
  );
};
