import React from "react";
import styles from "./AttributeValueAttributeList.module.scss";

import { Alert, Button, List, Popconfirm, message } from "antd";
import { Icons } from "components/Icons";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { SearchInput } from "components/SearchInput";
import { AttributeValue } from "shared/models/AttributeValue";
import { useCharacteristics } from "hook/CharacteristicsHook";
import { CharacteristicsLink } from "components/YehLink/CharacteristicsLink";
import { DeleteOutlined } from "@ant-design/icons";
import { characteristicService } from "shared/services/CharacteristicService";
import { trackPromise } from "react-promise-tracker";

interface AttributeValueAttributeListProps {
  attributeValue: AttributeValue;
}

export const AttributeValueAttributeList = ({
  attributeValue,
}: AttributeValueAttributeListProps) => {
  const {
    characteristics,
    last,
    fetchMoreData,
    handleSearch,
    filters,
    fetchData,
  } = useCharacteristics({ attributeValueId: attributeValue.attributeValueId });

  const onDeleteAssociation = (attributeId: number) => {
    trackPromise(
      characteristicService.deleteByAttributeAndAttributeValue(
        attributeId,
        attributeValue.attributeValueId
      )
    )
      .then(() => {
        message.success(
          "Relação de Característica e Atributo removida com sucesso."
        );
        fetchData(0, filters);
      })
      .catch(() => message.success("Erro ao remover relação."));
  };

  return (
    <div className={styles.wrapper}>
      <Alert
        className={styles.alert}
        icon={<Icons name="infoCircleOutlined" />}
        message={
          <span>
            Estas são as característica do atributo:{" "}
            <b>{attributeValue.valueDescription}</b>
          </span>
        }
        showIcon
        type="info"
      />

      <SearchInput
        className={styles.searchInput}
        placeholder="Digite e tecle ENTER"
        onPressEnter={handleSearch}
      />

      <List
        dataSource={characteristics}
        locale={{ emptyText: "Nenhuma característica encontrada" }}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <CharacteristicsLink
                attributeId={item.id}
                key={"redirect_" + item.id}
              />,

              <Popconfirm
                title="Remover associação?"
                onConfirm={() => onDeleteAssociation(item.id)}
                okText="Sim"
                cancelText="Não"
                key={"remove_" + item.id}
              >
                <Button
                  id={`btn-remove-${item.id}`}
                  shape="circle"
                  type="primary"
                  size="small"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>,
            ]}
          >
            <div>
              <b>{item.id}</b> - {item.description}
            </div>
          </List.Item>
        )}
      />

      <LoadMoreButton last={last} onFetchMoreData={fetchMoreData} />
    </div>
  );
};
