import { message } from "antd";
import EmptySelection from "components/EmptySelection/EmptySelection";
import { VisualData } from "containers/VisualData/VisualData";
import React, { useCallback, useEffect, useState } from "react";
import { Word } from "react-wordcloud";
import { BrandById } from "shared/models/Brand";
import { brandService } from "shared/services/BrandService";

type BrandChartsProps = {
  brand?: BrandById;
};

export const BrandCharts = ({ brand }: BrandChartsProps) => {
  const [wordcloudData, setWordcloudData] = useState<Word[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [loadingWords, setLoadingWords] = useState(false);
  const [loadingSankey, setLoadingSankey] = useState(false);

  const fetchWordcloudData = useCallback(
    (brand?: BrandById, mode?: boolean) => {
      if (!brand) {
        return;
      }
      setLoadingWords(true);
      brandService
        .getWordCloudIndicators(brand.id, mode)
        .then(setWordcloudData)
        .catch(() =>
          message.error("Erro ao efetuar busca de Nuvem de Palavras")
        )
        .finally(() => setLoadingWords(false));
    },
    []
  );

  const fetchSankeyData = useCallback((brand?: BrandById) => {
    if (!brand) {
      return null;
    }
    setLoadingSankey(true);
    brandService
      .getSankeyIndicators(brand.id)
      .then((data) => {
        setChartData([
          ["From", "To", "Weight"],
          ...data.map((item) => [item.source, item.target, item.value]),
        ]);
      })
      .catch(() => message.error("Erro ao efetuar busca de dados para Sankey."))
      .finally(() => setLoadingSankey(false));
  }, []);

  useEffect(() => {
    if (brand?.id) {
      fetchWordcloudData(brand, true);
    }
  }, [brand, fetchWordcloudData]);

  useEffect(() => {
    if (brand?.id) {
      fetchSankeyData(brand);
    }
  }, [brand, fetchSankeyData]);

  return !brand ? (
    <EmptySelection />
  ) : (
    <VisualData
      disableButtons={wordcloudData.length === 0}
      selected={!!brand}
      chartData={chartData}
      wordCloudValues={wordcloudData}
      chartType="Sankey"
      isLoadingWordcloud={loadingWords || loadingSankey}
    />
  );
};
