import React from "react";
import styles from "./YehChart.module.scss";

import { Card, Button, Empty, Spin } from "antd";
import { Chart as GoogleChart } from "react-google-charts";
import { Icons } from "components/Icons";
import { GoogleChartWrapperChartType } from "react-google-charts/dist/types";

type ChartProps = {
  data?: any[]; // This really needs to be any since different google chart types requires different data format
  options?: any;
  onClickExpand: () => void;
  isExpanded: boolean;
  chartType: GoogleChartWrapperChartType;
};

const YehChart = ({
  data,
  options,
  isExpanded,
  onClickExpand,
  chartType,
}: ChartProps) => {
  return (
    <Card className={styles.chartGeneral}>
      {!!data && data.length > 1 ? (
        <div className={styles.wrapper}>
          <div className={styles.nav}>
            <div className={styles.chart}>
              <div>
                <Button
                  type="link"
                  title="Expandir/Contrair"
                  icon={
                    <Icons
                      name={isExpanded ? "shrinkOutlined" : "arrowsAltOutlined"}
                    />
                  }
                  onClick={onClickExpand}
                />
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.chartContent}>
            <GoogleChart
              chartType={chartType}
              data={data}
              height="100%"
              loader={
                <div className={styles.loader}>
                  <Spin size="large" />
                </div>
              }
              options={options}
            />
          </div>
        </div>
      ) : (
        <div className={styles.alignCenter}>
          <Empty description="Gráfico sem dados" />
        </div>
      )}
    </Card>
  );
};

export default YehChart;
