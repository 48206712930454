import { Cascader, Input } from "antd";
import { CascaderOptionType, CascaderValueType } from "antd/lib/cascader";
import React, { useEffect, useRef, useState } from "react";
import { BUSegment, TaxonomyBranch } from "shared/models/Taxonomy";
import { taxonomyService } from "shared/services/TaxonomyService";
import styles from "./PixTaxonomySelect.module.scss";

interface PixTaxonomyTreeSelectProps {
  segments: BUSegment[];
  selectedBus: number[];
  onChange: (newTaxonomy: BUSegment) => void;
  id?: string;
}

interface Option {
  value: string | number;
  label?: React.ReactNode;
  disabled?: boolean;
  children?: Option[];
}

const PixTaxonomySelect = ({
  selectedBus,
  segments,
  onChange,
  id,
}: PixTaxonomyTreeSelectProps) => {
  const [term, setTerm] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const buSegmentRef = useRef(null);
  const cascaderRef = useRef(null);
  const [selectedSegments, setSelectedSegments] = useState<
    CascaderOptionType[]
  >();

  const cascaderOptionToBuSegment = (
    it: CascaderOptionType
  ): TaxonomyBranch => ({
    id: it.value as number,
    label: it.label as string,
    type: -1,
  });

  const handleAddTaxonomy = (selectedOptions: CascaderOptionType[]) => {
    if (!selectedSegments) {
      return;
    }
    let buSegment = selectedSegments.map(cascaderOptionToBuSegment);
    let taxonomy = selectedOptions.map(cascaderOptionToBuSegment);
    let newBusegment: BUSegment = {
      leafId: taxonomy[taxonomy.length - 1].id,
      branch: [...buSegment, ...taxonomy],
      totalChildren: 0,
    };
    onChange(newBusegment);
  };

  useEffect(() => {
    if (
      !!selectedSegments &&
      !!selectedSegments[selectedSegments.length - 1]?.value
    ) {
      taxonomyService
        .getTaxonomies(
          [""],
          undefined,
          selectedSegments[selectedSegments.length - 1].value as number
        )
        .then((response) =>
          setOptions(
            response.content.map((it) => ({
              value: it.leafId,
              label: it.description,
              isLeaf: it.totalChildren === 0,
              disabled: selectedBus.includes(it.leafId),
            }))
          )
        );
    } else {
      setOptions([]);
    }
  }, [selectedSegments, selectedBus]);

  /**
   * Organize a list into a tree options-like.
   * [[a, b, c], [a, b, d]] => [{a: children: [b: children: [{c}, {d}]]}]
   * @param branch
   */
  const handleRecursive = (branch: any[]): any[] => {
    let out: any[] = [];

    for (let i = 0; i < branch?.length; i++) {
      let current = branch[i];
      let label = current[0]?.label;
      if (!label) {
        continue;
      }
      let index = out.map((it) => it.label).indexOf(label);
      if (index < 0) {
        out.push({ label, value: current[0].id, branch: [] });
        index = out.length - 1;
      }
      out[index].branch.push(current.slice(1));
    }

    let result = out.map((it) => {
      let children = handleRecursive(it.branch);
      let isLeaf = it.totalChildren === 0;
      let toReturn = {
        ...it,
        children,
        isLeaf,
        disabled: selectedBus.includes(it.value),
      };

      // Avoids strange antd behavior. If isLeaf is false and children = [],
      // no fetch is made.
      if (!toReturn.isLeaf && toReturn.children.length === 0) {
        toReturn.isLeaf = true;
        delete toReturn.children;
      }
      return toReturn;
    });

    return result;
  };

  const loadData = (selectedOptions: CascaderOptionType[] | undefined) => {
    if (selectedOptions == null) {
      return;
    }
    const targetOption = selectedOptions[selectedOptions.length - 1];

    if (typeof targetOption.value != "number") {
      return;
    }

    targetOption.loading = true;

    taxonomyService
      .getTaxonomies([""], undefined, targetOption.value)
      .then((response) => {
        targetOption.loading = false;
        targetOption.children = response.content.map((it) => ({
          value: it.leafId,
          label: it.description,
          isLeaf: it.totalChildren === 0,
          disabled: selectedBus.includes(it.leafId),
        }));
        setOptions([...options]);
      });
  };

  const handleTermChange = (value: string) => {
    setTerm(value);

    // Is case the user clears the text search,
    // this forces fetching initial data again
    if (!value || !selectedSegments?.length) {
      setSelectedSegments(
        !!selectedSegments ? [...selectedSegments] : undefined
      );
      return;
    }

    taxonomyService
      .getTaxonomiesByBranch(
        [value],
        selectedSegments[selectedSegments.length - 1].value as number
      )
      .then((response) => {
        let op = handleRecursive(response.map((it) => it.branch));
        setOptions(op);

        // Opens dialog on user search
        (
          cascaderRef.current || {
            handlePopupVisibleChange: (b: boolean) => {},
          }
        ).handlePopupVisibleChange(true);
      });
  };

  const displayRender = () => (
    <Input
      value={term}
      style={{ border: "none", outline: "none", width: "100%" }}
      onChange={(evt) => handleTermChange(evt.target.value)}
      placeholder="Clique para navegar ou digite a pesquisa"
      allowClear
    />
  );

  return (
    <>
      <Cascader
        id={id}
        className={styles.cascader}
        options={handleRecursive(segments.map((it) => it.branch))}
        placeholder="BU / Segmento"
        onChange={(_, selectedOptions) => {
          setSelectedSegments(selectedOptions);
          setTerm("");
        }}
        ref={buSegmentRef}
      />
      {!!selectedSegments && selectedSegments.length > 0 && (
        <>
          <Cascader
            className={styles.cascader}
            options={options}
            loadData={loadData}
            onChange={(
              _: CascaderValueType,
              selectedOptions: CascaderOptionType[] | undefined
            ) => {
              if (!!selectedOptions) {
                handleAddTaxonomy(selectedOptions);

                // Clear BU segment
                (
                  buSegmentRef.current || { clearSelection: (evt: any) => {} }
                ).clearSelection({
                  preventDefault: () => {},
                  stopPropagation: () => {},
                });
              }
            }}
            displayRender={displayRender}
            ref={cascaderRef}
          />
          <h5 style={{ color: "var(--color-warning" }}>
            Nenhuma taxonomia de último nível selecionada.
          </h5>
        </>
      )}
    </>
  );
};

export { PixTaxonomySelect };
