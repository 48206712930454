import { Modal } from "antd";
import { Icons } from "components/Icons";
import { compact } from "lodash";
import React from "react";
import { ProductCreateEntityAttribute } from "shared/models/Characteristic";
import { IdName } from "shared/models/IdName";
import styles from "./CreateProductModal.module.scss";

interface CreateProductModalProps {
  status: boolean;
  onOk: () => void;
  onCancel: () => void;
  lineData?: IdName;
  brandData?: IdName;
  entityAttributes: ProductCreateEntityAttribute[];
}

export const CreateProductModal = ({
  status,
  onOk,
  onCancel,
  lineData,
  brandData,
  entityAttributes,
}: CreateProductModalProps) => {
  if (!status) {
    return null;
  }

  const productName = compact([
    lineData?.name,
    brandData?.name,
    ...entityAttributes.map((ea) => ea.attributeValue),
  ]).join(" / ");

  return (
    <Modal
      title="Confirmação de Cadastro"
      visible={status}
      onOk={onOk}
      onCancel={onCancel}
      okText="Cadastrar"
      cancelText="Cancelar"
      className={styles.wrapper}
    >
      <div className={styles.warning}>
        <div className={styles.icon}>
          <Icons name="infoCircleOutlined" />
        </div>
        <p>
          Você está cadastrando o produto: <span>{productName}</span>
        </p>
      </div>
    </Modal>
  );
};
