const BatchProcessStatusEnum = {
  SUCCESS: {
    value: "SUCCESS",
    label: "Concluído",
    color: "green",
    icon: "checkCircleOutlined",
  },
  PROCESSING: {
    value: "PROCESSING",
    label: "Em andamento",
    color: "gold",
    icon: "syncOutlined",
  },
  ERROR: {
    value: "ERROR",
    label: "Atenção",
    color: "red",
    icon: "closeCircleOutlined",
  },
  CREATED: {
    value: "CREATED",
    label: "Em fila",
    color: "blue",
    icon: "hourglassOutlined",
  },
};

export default BatchProcessStatusEnum;
