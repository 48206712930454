import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { AssetUpdate } from "shared/models/Asset";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/assets";

class AssetService {
  update(data: AssetUpdate) {
    return axios.request<AssetUpdate>({
      method: "PUT",
      url: BASE_PATH,
      data,
    } as AxiosRequestConfig);
  }
}

export const assetService = new AssetService();
