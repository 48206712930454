import {
  ArrowUpOutlined,
  DeleteFilled,
  InfoCircleFilled,
  MoreOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Link } from "@reach/router";
import {
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { linkToProduct } from "pages/Products/components/ProductsAdvancedFilters";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import BookProduct from "shared/models/BookProduct";
import DataCluster from "shared/models/DataCluster";
import dataClusterService from "shared/services/DataClusterService";

interface DataClusterListDetailProps {
  bookClusterDetails: BookProduct[];
  dataCluster?: DataCluster;
  refresh: () => void;
}

const displayIsbns = (isbns?: string[]) => {
  if ((isbns?.length || 0) === 0) {
    return "-";
  }

  if (isbns?.length === 1) {
    return isbns[0];
  }

  return (
    <Tooltip overlay={isbns?.join(", ")}>
      <Tag color="cyan">Vários ISBNs</Tag>
    </Tooltip>
  );
};

const DataClusterListDetail = ({
  bookClusterDetails,
  dataCluster,
  refresh,
}: DataClusterListDetailProps) => {
  const [
    operationDataCluster,
    setOperationDataCluster,
  ] = useState<BookProduct>();
  const [blackListVisible, setBlackListVisible] = useState(false);
  const [removeItemVisible, setRemoveItemVisible] = useState(false);
  const [makeMainVisible, setMakeMainVisible] = useState(false);
  const columns: ColumnsType<BookProduct> = [
    {
      title: "ISBNs",
      dataIndex: "isbns",
      key: "isbns",
      width: 150,
      render: displayIsbns,
    },
    {
      title: "ID. Prod. Padrão",
      dataIndex: "productId",
      key: "productId",
      width: 100,
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      width: 300,
    },
    {
      title: "Autores",
      dataIndex: "authors",
      key: "authors",
      width: 200,
    },
    {
      title: "Editora",
      dataIndex: "publisher",
      key: "publisher",
    },
    {
      title: "Principal",
      dataIndex: "main",
      key: "main",
      width: 50,
      render: (_: string, dataClusterDetail: BookProduct) => (
        <Input
          style={{ transform: "scale(1.5)" }}
          type="checkbox"
          disabled
          checked={dataClusterDetail.main}
        />
      ),
    },
    {
      title: "Categoria Veja",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Black list",
      dataIndex: "blackList",
      key: "blackList",
      align: "center",
      width: 50,
      render: (_: string, dataClusterDetail: BookProduct) => (
        <input
          style={{ transform: "scale(1.5)" }}
          type="checkbox"
          disabled
          checked={dataClusterDetail.blackList}
        />
      ),
    },
    {
      title: "",
      dataIndex: "detail",
      key: "detail",
      width: 50,
      render: (_: string, dataClusterDetail: BookProduct) => {
        return (
          <Link
            to={linkToProduct({ productId: [dataClusterDetail.productId] })}
          >
            <Tooltip overlay="Ver detalhes do produto ou remover da black list">
              <InfoCircleFilled />
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: "",
      dataIndex: "options",
      key: "options",
      render: (_: string, dataClusterDetail: BookProduct) => {
        return (
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => handleBlackList(dataClusterDetail)}
                  disabled={dataClusterDetail.blackList}
                >
                  <>
                    <StopOutlined />
                    Mover para blacklist
                  </>
                </Menu.Item>
                <Menu.Item onClick={() => handleRemoveItem(dataClusterDetail)}>
                  <DeleteFilled />
                  Remover do grupo
                </Menu.Item>
                <Menu.Item
                  onClick={() => handleMakeMain(dataClusterDetail)}
                  disabled={dataClusterDetail.main}
                >
                  <ArrowUpOutlined />
                  Definir como principal
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      message.info(`Não implementado. Linhas ${selectedRowKeys}`);
    },
  };

  const handleBlackList = (bookClusterDetail: BookProduct) => {
    setOperationDataCluster(bookClusterDetail);
    setBlackListVisible(true);
  };

  const handleRemoveItem = (bookClusterDetail: BookProduct) => {
    setOperationDataCluster(bookClusterDetail);
    setRemoveItemVisible(true);
  };

  const handleMakeMain = (bookClusterDetail: BookProduct) => {
    setOperationDataCluster(bookClusterDetail);
    setMakeMainVisible(true);
  };

  const getOperationContent = (typeText: string) => (
    <>
      <p>
        Tem certeza que deseja <strong>{typeText}</strong> o seguinte item?
      </p>
      <p>
        <strong>{operationDataCluster?.title}</strong>
      </p>
    </>
  );

  const submitBlackList = () => {
    trackPromise(dataClusterService.moveItemToBlackList(operationDataCluster!))
      .then(() => {
        message.success("Produto movido para a black list com sucesso");
        refresh();
      })
      .catch(() => message.error("Erro ao mover para black list"))
      .finally(() => {
        setOperationDataCluster(undefined);
        setBlackListVisible(false);
      });
  };

  const submitRemoveItem = () => {
    trackPromise(
      dataClusterService.update({
        ...dataCluster!,
        productIds: dataCluster!.productIds.filter(
          (it) => it !== operationDataCluster!.productId
        ),
      })
    )
      .then(() => {
        message.success("Produto removido com sucesso");
        refresh();
      })
      .catch(() => message.error("Erro ao remover item"))
      .finally(() => {
        setOperationDataCluster(undefined);
        setRemoveItemVisible(false);
      });
  };

  const submitMakeMain = () => {
    trackPromise(
      dataClusterService.update({
        ...dataCluster!,
        productIds: [
          operationDataCluster!.productId,
          ...dataCluster!.productIds.filter(
            (it) => it !== operationDataCluster!.productId
          ),
        ],
      })
    )
      .then(() => {
        message.success("Produto definido como principal com sucesso");
        refresh();
      })
      .catch(() => message.error("Erro ao definir produto como principal"))
      .finally(() => {
        setOperationDataCluster(undefined);
        setMakeMainVisible(false);
      });
  };

  return (
    <>
      <Table<BookProduct>
        dataSource={bookClusterDetails}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        pagination={false}
        scroll={{ x: 1500 }}
        rowKey="productId"
      />
      <Modal
        visible={blackListVisible}
        onCancel={() => setBlackListVisible(false)}
        cancelText="Cancelar"
        onOk={submitBlackList}
      >
        {getOperationContent("mover para a black list")}
      </Modal>
      <Modal
        visible={removeItemVisible}
        onCancel={() => setRemoveItemVisible(false)}
        cancelText="Cancelar"
        onOk={submitRemoveItem}
      >
        {getOperationContent("remover")}
      </Modal>
      <Modal
        visible={makeMainVisible}
        onCancel={() => setMakeMainVisible(false)}
        cancelText="Cancelar"
        onOk={submitMakeMain}
      >
        {getOperationContent("definir como principal")}
      </Modal>
    </>
  );
};

export default DataClusterListDetail;
