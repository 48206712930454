import { ProductLineBrand } from "shared/models/ProductLine";

export const removeUndefined = ({
  filter,
}: {
  filter: string | undefined;
  color: string;
}) => !!filter;

export const makeEllipsedString = (value: string, ellipsisThreshold: number) =>
  value && value.length > ellipsisThreshold
    ? value.substr(0, ellipsisThreshold - 1) + "..."
    : value;

export const filterProductLineWithBrand = (list: ProductLineBrand[]) => {
  return list.filter((item) => !!item.brand);
};
