import { Link } from "@reach/router";
import { UnauthorizedPage } from "components/UnauthorizedPage/UnauthorizedPage";
import { Brands } from "pages/Brands/Brands";
import Categories from "pages/Categories/Categories";
import { Characteristics } from "pages/Characteristics/Characteristics";
import { Combination } from "pages/Combination/Combination";
import DataClusters from "pages/DataClusters/DataClusters";
import { GtinList } from "pages/Gtin/GtinList";
import { ImagesPage } from "pages/Images/ImagesPage";
import { Manufacturers } from "pages/Manufacturers/Manufacturers";
import ProductLines from "pages/ProductLines/ProductLines";
import { Products } from "pages/Products/Products";
import { SubBrandList } from "pages/SubBrand/SubBrandList";
import Uploads from "pages/Uploads/Uploads";
import React from "react";
import { ApiRole } from "shared/models/ApiRole";
import { MenuItem } from "./MenuItem/MenuItem";
import { AttributeValueList } from "pages/AttributeValue/AttributeValueList";

export const getLinkTo = (
  parent: string,
  parentLink: string,
  child: string
) => {
  return (
    <>
      <Link
        to={parentLink}
        style={{ color: "var(--color-dark-gray)", fontWeight: "normal" }}
      >
        {parent}
      </Link>
      <span style={{ marginLeft: "5px" }}>/ {child}</span>
    </>
  );
};

export const getMenuItems = (
  userHasAnyRole?: (rol: ApiRole[]) => boolean
): MenuItem[] => {
  const itens = [
    {
      id: "/linha",
      title: "Linha Yandeh",
      icon: "productLine",
      color: "#2980b9",
      route: (
        <ProductLines key="productLine" path="/linha" title="Linha Yandeh" />
      ),
    },
    {
      id: "/fabricante",
      title: "Fabricante",
      icon: "factory",
      color: "#8e44ad",
      route: <Manufacturers title="Fabricante" key="dash" path="/fabricante" />,
    },
    {
      id: "/marca",
      title: "Marcas",
      color: "#8e44ad",
      icon: "appstoreOutlined",
      route: <Brands key="brand" path="/marca" title="Marcas" />,
      submenu: [
        {
          id: "/sub-marca",
          title: "Sub Marcas",
          color: "#8e44ad",
          route: (
            <SubBrandList
              key="sub-brand"
              path="/sub-marca"
              title={getLinkTo("Marcas", "/marca", "Sub Marcas")}
            />
          ),
        },
      ],
    },
    {
      id: "/produto",
      title: "Produtos",
      icon: "product",
      color: "#32325d",
      route: <Products key="product" path="/produto" title="Produtos" />,
      submenu: [
        {
          id: "/caracteristicas",
          title: "Características",
          color: "#32325d",
          route: (
            <Characteristics
              key="characteristics"
              path="/caracteristicas"
              title={getLinkTo("Produto", "/produto", "Características")}
            />
          ),
        },
        {
          id: "/atributos",
          title: "Atributos",
          color: "#32325d",
          route: (
            <AttributeValueList
              key="attributeValue"
              path="/atributos"
              title={getLinkTo("Produto", "/produto", "Atributos")}
            />
          ),
        },
      ],
    },
    {
      id: "/gtins",
      title: "GTINs",
      icon: "barcodeOutlined",
      color: "#2980b9",
      route: <GtinList key="gtins" path="/gtins" title="GTINs" />,
    },
    {
      id: "/taxonomia",
      title: "Taxonomia",
      icon: "category",
      color: "#d35400",
      route: <Categories key="category" path="/taxonomia" title="Taxonomia" />,
    },
    {
      id: "/imagens",
      title: "Imagens",
      icon: "assets",
      color: "#27ae60",
      route: <ImagesPage key="imagens" path="/imagens" title="Imagens" />,
    },
    {
      id: "/associacoes",
      title: "Associações",
      icon: "apartmentOutlined",
      color: "#32325d",
      route: (
        <Combination
          key="poc"
          path="/associacoes"
          title={getLinkTo("Produto", "/produto", "Associações")}
        />
      ),
    },
    {
      id: "/agrupamentos",
      title: "Agrupamentos",
      icon: "goldOutlined",
      color: "#8e44ad",
      route: (
        <DataClusters
          key="data-cluster"
          path="/agrupamentos"
          title="Agrupamentos"
        />
      ),
    },
  ];

  itens.push(
    getProtectedMenuItem(
      {
        id: "/upload",
        title: "Importações",
        color: "#27ae60",
        icon: "uploads",
        route: <Uploads key="upload" path="/upload/*" title="Importações" />,
      },
      [ApiRole.ADMIN, ApiRole.SUPERVISOR, ApiRole.OPERATOR, ApiRole.RGM],
      userHasAnyRole
    )
  );
  return itens;
};

const getProtectedMenuItem = (
  item: MenuItem,
  roles: ApiRole[],
  userHasAnyRole?: (role: ApiRole[]) => boolean
): any => {
  if (!userHasAnyRole || userHasAnyRole?.(roles)) {
    return item;
  }
  return {
    ...item,
    id: "/nao-autorizado",
    route: (
      <UnauthorizedPage
        key="unauthorized"
        path={item.id}
        title="Acesso não autorizado"
      />
    ),
  };
};
