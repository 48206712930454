import { Modal } from "antd";
import React from "react";
import styles from "./SimpleImageModal.module.scss";

interface SimpleImageModalProps {
  isOpen: boolean;
  imageUrl: string;
  onClose: () => void;
}

export const SimpleImageModal = ({
  isOpen,
  imageUrl,
  onClose,
}: SimpleImageModalProps) => {
  return (
    <Modal width="1000px" visible={isOpen} onCancel={onClose} footer={[]}>
      <div className={styles.container}>
        <img
          className={styles.img}
          alt="Imagem do produto selecionado"
          src={imageUrl}
        />
      </div>
    </Modal>
  );
};
