import { useCallback, useEffect, useRef, useState } from "react";

// Usada com o useState porém guardando o valor no localstorage para guardar uma
// sessão de preferência do usuário
export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(`Erro ao buscar item de chave ${key} do localstorage`);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(
        `Erro ao salvar o valor ${value} na chave ${key} no localstorage`,
        error
      );
    }
  };

  return [storedValue, setValue];
}

export const useStateWithPromise = <T>(
  initialState: T
): [T, (value: T | ((arg: T) => T)) => Promise<T>] => {
  const [state, setState] = useState<T>(initialState);
  const resolverRef = useRef<(value: T) => void>();

  useEffect(() => {
    if (resolverRef.current) {
      resolverRef.current(state);
      resolverRef.current = undefined;
    }
  }, [state]);

  const handleSetState = useCallback(
    (stateAction) => {
      setState(stateAction);
      return new Promise<T>((resolve) => {
        resolverRef.current = resolve;
      });
    },
    [setState]
  );

  return [state, handleSetState];
};
