import { Empty } from "antd";
import React from "react";

const EmptySelection = () => {
  return (
    <div data-testid="details-empty-state" className="pix-empty-state">
      <Empty description="Nenhum item selecionado." />
    </div>
  );
};

export default EmptySelection;
