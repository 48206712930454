import { Icons } from "components/Icons";
import React from "react";

type ActiveInactiveItemProps = {
  deleted?: boolean;
};

export const ActiveInactiveItem = ({ deleted }: ActiveInactiveItemProps) => (
  <Icons
    name={deleted ? "closeCircleFilled" : "checkCircleFilled"}
    style={{ color: deleted ? "red" : "green" }}
  />
);
