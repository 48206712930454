import { message, Upload } from "antd";
import axios from "axios";
import { ImageUploadText } from "components/ImageUploadText/ImageUploadText";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import React from "react";
import { extractMessages } from "shared/services/AxiosAuth";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import styles from "./FileUploadDragger.module.scss";

const { Dragger } = Upload;

const HOST = CORE_LOAD_BALANCER_URL;

export interface FileUploadDraggerProps {
  productId?: number;
  refresh: () => void;
}

export const FileUploadDragger = ({
  productId,
  refresh,
}: FileUploadDraggerProps) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        display: "block",
        height: "auto",
      }}
    >
      <Dragger
        listType="picture"
        action={`${HOST}/images/`}
        maxCount={1}
        customRequest={async (options: any) => {
          const data = new FormData();
          data.append("file", options.file);
          const params = { productId };
          const config = {
            params,
            headers: {
              "content-type":
                "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
            },
          };
          axios
            .post(options.action, data, config)
            .then((res) => {
              message.success("Upload realizado com sucesso.");
              options.onSuccess(res.data, options.file);
              refresh();
            })
            .catch((err) => {
              extractMessages(err).forEach(getErrorMessage);
              options.onError(err.response?.data?.errors, options.file);
            });
        }}
        name="file"
      >
        <ImageUploadText />
      </Dragger>
    </div>
  );
};
