import { uniq } from "lodash";
import { ProductFilters } from "pages/Products/components/ProductsAdvancedFilters";
import { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { GtinListItem } from "shared/models/GtinCode";
import { gtinCodeService } from "shared/services/GtinCodeService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getInitialFilter } from "util/filter.util";
import { readTermQueryParam } from "util/yeh.query.param.util";
import { getTerms } from "./HookUtil";

export const getInitialFilters = () =>
  ({
    deleted: "false",
    ...getInitialFilter("filters", ProductFilters),
  } as ProductFilters);

export const useGtin = () => {
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);
  const [gtins, setGtins] = useState<GtinListItem[]>([]);
  const [terms, setTerms] = useState(readTermQueryParam());
  const [filters, setFilters] = useState<ProductFilters>(getInitialFilters());

  const fetchGtins = useCallback(
    (page: number, filters: ProductFilters) =>
      trackPromise(gtinCodeService.findAll(filters, { page }))
        .then((response) => {
          setPage(page);
          setFilters(filters);
          setLast(response.last);
          setTerms((o) => getTerms(o, filters.term));
          setGtins((old) => [
            ...(response.page === 0 ? [] : old),
            ...response.content,
          ]);
        })
        .catch(() => {
          getErrorMessage("Erro ao buscar GTINs");
        }),
    []
  );

  const handleRemoveFilter = (term: string) => {
    const newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);
    fetchGtins(0, { ...filters, term: newTerms.join(" ") });
  };

  const handleSearch = (value: string) => {
    const newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    const newFilters = { ...filters, term: newTerms.join(" ") };
    fetchGtins(0, newFilters);
  };

  const handleClearFilters = () => {
    fetchGtins(0, { deleted: "false" });
  };

  const fetchMoreData = () => {
    if (!last) {
      fetchGtins(page + 1, filters);
    }
  };

  const refresh = () => {
    fetchGtins(0, filters);
  };

  useEffect(() => {
    fetchGtins(0, getInitialFilters());
  }, [fetchGtins]);

  return {
    last,
    gtins,
    terms,
    filters,
    refresh,
    setTerms,
    fetchGtins,
    handleSearch,
    fetchMoreData,
    handleClearFilters,
    handleRemoveFilter,
  };
};
