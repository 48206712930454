import React, { useState, useEffect } from "react";
import styles from "./MoveCategorySidenav.module.scss";

import { Drawer, Button, message } from "antd";
import { trackPromise } from "react-promise-tracker";
import { Input } from "components/Input";
import {
  PixTaxonomyGlobalSelect,
  TaxonomyBranchWithDisplayValue,
} from "containers/PixTaxonomyGlobalSelect";
import { taxonomyService } from "shared/services/TaxonomyService";
import {
  TaxonomyItem,
  BuSegmentItem,
  TaxonomyBranch,
} from "shared/models/Taxonomy";
import { getErrorMessage } from "shared/utils/ResponseUtils";

interface MoveCategorySidenavProps {
  isOpen: boolean;
  selectedCategory?: {
    parent: TaxonomyItem;
    item?: BuSegmentItem;
  };
  onSubmit: (item: TaxonomyBranch[]) => void;
  onClose: () => void;
}

interface CategoryToSave {
  categories: string[];
}

export const MoveCategorySidenav = ({
  isOpen,
  selectedCategory,
  onClose,
  onSubmit,
}: MoveCategorySidenavProps) => {
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<TaxonomyBranchWithDisplayValue>();

  useEffect(() => {
    !isOpen && setSelectedItem(undefined);
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const updateCategoryParent = (parentId: number) => {
    const item = selectedCategory && selectedCategory.item;
    if (!!item && !!parentId) {
      trackPromise(
        taxonomyService.updateTaxonomy(item.id, {
          description: item.title,
          parentId: parentId,
          deleted: item.deleted,
        })
      )
        .then((result) => {
          message.success("Movido com sucesso.");
          onSubmit(selectedItem!.branches);
        })
        .catch((error) => getErrorMessage("Erro ao mover taxonomia."));
    }
  };

  const onChangeValue = (value: TaxonomyBranchWithDisplayValue) => {
    setSelectedItem(value);
  };

  const handleSubmit = () => {
    if (!selectedItem) {
      message.warning("Selecione uma categoria de destino para mover.");
      return;
    }
    const moveTo = selectedItem.branches[selectedItem.branches.length - 1];
    updateCategoryParent(moveTo.id);
  };

  return (
    <Drawer
      className={styles.drawer}
      destroyOnClose
      onClose={handleClose}
      visible={isOpen}
      width={380}
      zIndex={10}
    >
      <div className={styles.heading}>
        <h1>Mover categoria</h1>
      </div>
      <div className={styles.wrapper}>
        <div>
          <Input
            disabled={true}
            label="Categoria pai atual"
            value={selectedCategory && selectedCategory.parent.title}
          />
          <PixTaxonomyGlobalSelect
            label="Mover para categoria"
            onChange={onChangeValue}
            selected={selectedItem}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button type="link" onClick={handleClose}>
          Cancelar
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Mover
        </Button>
      </div>
    </Drawer>
  );
};
