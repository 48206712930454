import axios, { AxiosPromise } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { ExternalTaxonomyAssociation } from "shared/models/ExternalTaxonomyAssociation";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/external/taxonomy";

class ExternalTaxonomyService {
  associate(
    body: ExternalTaxonomyAssociation
  ): AxiosPromise<ExternalTaxonomyAssociation> {
    return axios.post(BASE_PATH + "/association", body);
  }

  delete(body: ExternalTaxonomyAssociation): AxiosPromise<void> {
    return axios.delete(BASE_PATH + "/association", { data: body });
  }

  findExternalTaxonomyAssociatedIds(
    internalTaxonomyId: number
  ): AxiosPromise<number[]> {
    return axios.get(BASE_PATH + "/association/" + internalTaxonomyId);
  }
}

export const externalTaxonomyService = new ExternalTaxonomyService();
