import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Card, Col, Modal, Row } from "antd";
import { FileUploadDragger } from "components/FileUploadDragger/FileUploadDragger";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { PixLink } from "components/PixLink/PixLink";
import { YehImage } from "components/YehImage/YehImage";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { SearchTopBar } from "containers/SearchTopBar/SearchTopBar";
import { useProducts } from "hook/ProductsHook";
import {
  ProductFilters,
  ProductsAdvancedFilters,
} from "pages/Products/components/ProductsAdvancedFilters";
import React, { useContext, useEffect, useState } from "react";
import { ProductListItem } from "shared/models/Product";
import { ImageDetailModal } from "./components/ImageDetailModal";

import styles from "./ImagesPage.module.scss";

type ImagesPageProps = DefaultPage & RouteComponentProps;

export const ImagesPage = ({ title }: ImagesPageProps) => {
  const { setCurrentTitle } = useContext(PageContext);
  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selected, setSelected] = useState<ProductListItem>();
  const [productUpload, setProductUpload] = useState<ProductListItem>();

  const {
    terms,
    last,
    products,
    filters,
    refreshList,
    clearFilters,
    handleSearch,
    fetchProducts,
    fetchMoreData,
    handleRemoveFilter,
  } = useProducts();

  const handleModalSubmit = (filters: ProductFilters) => {
    fetchProducts(0, filters);
    setFiltersOpen(false);
  };

  return (
    <Card bordered={false}>
      <SearchTopBar
        handleSearch={handleSearch}
        handleRemoveFilter={handleRemoveFilter}
        filterProps={{
          terms,
          advancedFilters: filters,
          handleFilterIconClick: () => setFiltersOpen((o) => !o),
          handleClearFilters: clearFilters,
        }}
      />
      <Row gutter={16}>
        {products.map((product) => (
          <Col key={product.productId} xs={24} sm={12} md={6}>
            <Card
              className={styles.img}
              size="default"
              cover={
                <YehImage
                  imageUrl={product.imageUrl}
                  description={product.description}
                />
              }
              actions={[
                <EditOutlined
                  key="edit"
                  onClick={() => setSelected(product)}
                />,
                <UploadOutlined
                  key="upload"
                  onClick={() => setProductUpload(product)}
                />,
              ]}
            >
              <div className={styles.center}>
                <small className={styles.subTitle}>
                  <PixLink
                    link="/produto"
                    paramsName="productFilters"
                    params={{ productId: product.productId }}
                    label={product.productId}
                  />
                </small>
                <br />
                <small>{product.description}</small>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <LoadMoreButton last={last} onFetchMoreData={fetchMoreData} />
      <ImageDetailModal
        product={selected}
        refresh={refreshList}
        onCancel={() => setSelected(undefined)}
      />
      <ProductsAdvancedFilters
        isImageList
        parentName="images"
        visible={filtersOpen}
        originalFilters={filters}
        onConfirm={handleModalSubmit}
        onClose={() => setFiltersOpen(false)}
        fixedStatus
      />
      <Modal
        visible={!!productUpload}
        footer={false}
        onCancel={() => setProductUpload(undefined)}
        title={productUpload?.description}
        width="80%"
      >
        <FileUploadDragger
          productId={productUpload?.productId}
          refresh={refreshList}
        />
      </Modal>
    </Card>
  );
};
