import { Badge, Button, Divider, Modal } from "antd";
import { SearchAttributesSelect } from "containers/SearchAttributessSelect/SearchAttributesSelect";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ATT_TYPES } from "shared/models/Characteristic";
import { ProductCharacteristic } from "shared/models/Product";
import { AttributeEditGroup } from "./AttributeEditGroup";

interface AttributeEditProps {
  attributes: ProductCharacteristic[];
  title: string;
  productLineId?: number;
  onConfirm: (attributes: AttributeValueWrapper[]) => void;
  modalOpen: boolean;
  toggleModal: () => void;
}

export interface AttributeValueWrapper {
  attributeId: number;
  attributeDescription: string;
  type: string;
  value: string;
}

export const AttributeEdit = ({
  attributes,
  title,
  productLineId,
  onConfirm,
  modalOpen,
  toggleModal,
}: AttributeEditProps) => {
  const [attributeValues, setAttributeValues] = useState<
    AttributeValueWrapper[]
  >([]);

  const handleChange = useCallback((attributeId: number, value: string) => {
    setAttributeValues((old) =>
      old.map((a) => (a.attributeId === attributeId ? { ...a, value } : a))
    );
  }, []);

  const updateAttributesByType = (
    attributeTypes: AttributeValueWrapper[],
    type: string
  ) => {
    setAttributeValues((old) => [
      ...old.filter((a) => a.type !== type),
      ...attributeTypes,
    ]);
  };

  useEffect(() => {
    setAttributeValues(
      attributes.map((att) => ({
        attributeId: att.attribute.id,
        attributeDescription: att.attribute.label,
        value: att.value,
        type: att.attribute.type,
      }))
    );
  }, [attributes, modalOpen]);

  return (
    <div style={{ margin: "5px", textAlign: "center" }}>
      <Button id="edit-att-btn" type="primary" onClick={toggleModal}>
        Editar
      </Button>
      <Modal
        visible={modalOpen}
        width="80%"
        onCancel={toggleModal}
        title={title}
        onOk={() => onConfirm(attributeValues)}
        cancelText="Cancelar"
        okText="Salvar"
      >
        {ATT_TYPES.map((at) => {
          const attributesByType = attributeValues.filter(
            (a) => a.type === at.type
          );
          return (
            <section key={at.type} id={`attributes-${at.type}`}>
              <Badge.Ribbon text={attributesByType.length}>
                <Divider>{at.header}</Divider>
              </Badge.Ribbon>
              <SearchAttributesSelect
                parentName={`attribute-edit-${at.type}`}
                placeHolder="Pesquise uma característica para inserir"
                fixedType={at.type}
                productLineId={
                  at.type === "PRIMARY" ? productLineId : undefined
                }
                onOk={(c) =>
                  setAttributeValues((old) =>
                    uniqBy(
                      [
                        ...old,
                        {
                          attributeId: c.id,
                          attributeDescription: c.description,
                          type: c.type,
                          value: "",
                        },
                      ],
                      (a) => a.attributeId
                    )
                  )
                }
              />
              <AttributeEditGroup
                attributes={attributesByType}
                handleChange={handleChange}
                reorderAttributes={(atts) =>
                  updateAttributesByType(atts, at.type)
                }
              />
            </section>
          );
        })}
      </Modal>
    </div>
  );
};
