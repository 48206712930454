import { DeleteOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Row } from "antd";
import { useAttributeValue } from "hook/AttributeValueHook";
import React, { useEffect } from "react";
import { scrollFunction } from "shared/utils/ScrollUtils";
import { AttributeValueWrapper } from "./AttributeEdit";

import style from "./AttributeEditGroup.module.scss";

interface AttributeSelectEditProps {
  attribute: AttributeValueWrapper;
  handleChange: (attributeId: number, value: string) => void;
  remove: () => void;
}

export const AttributeSelectEdit = ({
  attribute,
  handleChange,
  remove,
}: AttributeSelectEditProps) => {
  const {
    attributeValues,
    loadMore,
    debounceSearchTerm,
    term,
    setTerm,
  } = useAttributeValue(attribute.attributeId);

  useEffect(() => {
    handleChange(attribute.attributeId, term);
    debounceSearchTerm(0, term);
  }, [term, handleChange, attribute.attributeId, debounceSearchTerm]);

  return (
    <div className={style.attributeWrapper}>
      <label className={style.attributeLabel}>
        {attribute.attributeDescription}
      </label>
      <div>
        <Row>
          <Col span={22}>
            <AutoComplete
              id={`att_` + attribute.attributeId}
              allowClear
              showSearch
              searchValue={term}
              onSearch={setTerm}
              value={attribute.value}
              onPopupScroll={(e) => scrollFunction(e, loadMore)}
              onChange={(value) => handleChange(attribute.attributeId, value)}
              style={{ width: "100%" }}
              options={attributeValues.map((v) => ({
                label: v.description,
                value: v.description,
              }))}
            />
          </Col>
          <Col span={2}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                remove();
              }}
            >
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
