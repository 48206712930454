import axios from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { AttributeValue } from "shared/models/AttributeValue";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/attribute-value";

type AttributeValueFilter = {
  deleted?: boolean;
  term?: string;
};

class AttributeValueService {
  list(filter: AttributeValueFilter, page: number, size: number = 20) {
    return axios.get<PixResponsePage<AttributeValue>>(BASE_PATH, {
      params: objectToParams({ ...filter, page, size }),
    });
  }

  put(data: Partial<AttributeValue>) {
    return axios.put<AttributeValue>(BASE_PATH, data);
  }

  async merge(data: MergeRequest): Promise<NameCount[]> {
    const r = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data,
    });
    return r.data;
  }
}

export const attributeValueService = new AttributeValueService();
