import { message, Select } from "antd";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { SimpleImage } from "shared/models/Asset";
import { assetService } from "shared/services/AssetService";
import { assetTagsService } from "shared/services/AssetTagsService";
import { LabelValue } from "shared/types/utils/LabelValue";
import { getErrorMessage } from "shared/utils/ResponseUtils";

interface ImageTagSelectorProps {
  imageDetail: SimpleImage;
  refresh: () => void;
}

export const ImageTagSelector = ({
  imageDetail,
  refresh,
}: ImageTagSelectorProps) => {
  const [tags, setTags] = useState<LabelValue[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const updateAsset = (assetId: number, tagIds: string[]) => {
    trackPromise(assetService.update({ assetId, tagIds }))
      .then(() => {
        message.success("Tags atualizadas com sucesso");
        refresh();
      })
      .catch(() => getErrorMessage("Erro ao atualizar tags"));
  };

  const fetchTags = useCallback(() => {
    assetTagsService.list().then((response) => {
      setTags((old) => [
        ...(response.page === 0 ? [] : old),
        ...response.content,
      ]);
    });
  }, []);

  useEffect(fetchTags, [fetchTags]);
  useEffect(() => {
    setSelectedTags(imageDetail.tags.map((t) => t.id.toString()));
  }, [imageDetail]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateAsset(imageDetail.assetId, selectedTags);
      }}
    >
      <Select<string[]>
        showSearch
        allowClear
        options={tags}
        mode="multiple"
        filterOption={false}
        value={selectedTags}
        style={{ width: "100%" }}
        onChange={setSelectedTags}
        placeholder="Tags da imagem"
      />
      <CancelAndSave
        onCancel={() =>
          setSelectedTags(imageDetail.tags.map((t) => t.id.toString()))
        }
      />
    </form>
  );
};
