import { FilterFilled } from "@ant-design/icons";
import { Badge, Button, ButtonProps, Tooltip } from "antd";
import { compact } from "lodash";
import React from "react";
import styles from "./FilterIcon.module.scss";

interface FilterIconProps extends ButtonProps {
  id?: string;
  filters?: any;
  onClick: () => void;
}

const FilterIcon = ({ id, filters, onClick, ...rest }: FilterIconProps) => {
  const count = React.useMemo(
    () =>
      !!filters
        ? compact(Object.values(filters)).filter(
            // Apparently, compact won't filter empty arrays
            (it) => !Array.isArray(it) || it.length > 0
          ).length
        : 0,
    [filters]
  );
  return (
    <Tooltip title="Filtro avançado" placement="bottom">
      <Button
        id={id}
        type="link"
        className={styles.btnShowImage}
        onClick={onClick}
        {...rest}
      >
        <FilterFilled style={{ color: "gray", cursor: "pointer" }} />
        <Badge count={count} style={{ position: "absolute", zIndex: 10 }} />
      </Button>
    </Tooltip>
  );
};

export default FilterIcon;
