import { Button, Form, Input, Modal, Select } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React, { useState } from "react";
import styles from "./DataClusterDetailAdvancedFilters.module.scss";

const { Option } = Select;

export class DataClusterDetailFilter {
  authors?: string;
  blackList?: string;
  category?: string;
  isbns?: string[];
  main?: string;
  productIds?: number[];
  publisher?: string;
  term?: string;
  title?: string;
}

interface DataClusterDetailAdvancedFiltersProps {
  hideMain?: boolean;
  onCancel: () => void;
  onOk: (dataClusterDetailFilter: DataClusterDetailFilter) => void;
  parentName: string;
  dataClusterDetailFilter: DataClusterDetailFilter;
  visible: boolean;
}

const DataClusterAdvancedFilters = ({
  hideMain,
  onCancel,
  onOk,
  parentName,
  visible,
}: DataClusterDetailAdvancedFiltersProps) => {
  const [filters, setFilters] = useState<DataClusterDetailFilter>({});
  const handleFilterChange = (name: string, evt: any) => {
    evt.persist();
    setFilters({
      ...filters,
      [name]: evt.target.value,
    });
  };
  return (
    <Modal
      visible={visible}
      onOk={() => onOk(filters)}
      onCancel={onCancel}
      cancelText="Cancelar"
      title={<h3>Filtros avançados para detalhamento de grupos</h3>}
    >
      <Form onFinish={() => onOk(filters)}>
        <Form.Item
          label={
            <FilterLabel text="Autores" htmlFor={`${parentName}-authors`} />
          }
        >
          <Input
            id={`${parentName}-authors`}
            value={filters.authors}
            onChange={(e) => handleFilterChange("authors", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel
              text="Black list"
              htmlFor={`${parentName}-black-list`}
            />
          }
        >
          <Select
            id={`${parentName}-black-list`}
            allowClear
            value={filters.blackList}
            onChange={(blackList) =>
              setFilters({
                ...filters,
                blackList,
              })
            }
          >
            <Option value="true">Sim</Option>
            <Option value="false">Não</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel text="Categoria" htmlFor={`${parentName}-category`} />
          }
        >
          <Input
            id={`${parentName}-category`}
            value={filters.category}
            onChange={(e) => handleFilterChange("category", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="ISBNs" htmlFor={`${parentName}-isbns`} />}
        >
          <Select<string[]>
            id={`${parentName}-isbns`}
            className={styles.multiselect}
            mode="tags"
            onChange={(isbns) => setFilters({ ...filters, isbns })}
            tokenSeparators={[",", " "]}
            value={filters.isbns}
            open={false}
            allowClear
          />
        </Form.Item>
        {!hideMain && ( // When adding changing data cluster, there is not "main" concept in a single book product
          <Form.Item
            label={
              <FilterLabel text="Principal" htmlFor={`${parentName}-main`} />
            }
          >
            <Select
              id={`${parentName}-main`}
              allowClear
              value={filters.main}
              onChange={(main) =>
                setFilters({
                  ...filters,
                  main,
                })
              }
            >
              <Option value="true">Sim</Option>
              <Option value="false">Não</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            <FilterLabel
              text="Identificadores de produto padrão"
              htmlFor={`${parentName}-product-ids`}
            />
          }
        >
          <Select<number[]>
            id={`${parentName}-product-ids`}
            className={styles.multiselect}
            mode="tags"
            onChange={(productIds) => setFilters({ ...filters, productIds })}
            tokenSeparators={[",", " "]}
            value={filters.productIds}
            open={false}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel text="Editora" htmlFor={`${parentName}-publisher`} />
          }
        >
          <Input
            id={`${parentName}-publisher`}
            value={filters.publisher}
            onChange={(e) => handleFilterChange("publisher", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={
            <FilterLabel text="Termo genérico" htmlFor={`${parentName}-term`} />
          }
        >
          <Input
            id={`${parentName}-term`}
            value={filters.term}
            onChange={(e) => handleFilterChange("term", e)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<FilterLabel text="Título" htmlFor={`${parentName}-title`} />}
        >
          <Input
            id={`${parentName}-title`}
            value={filters.title}
            onChange={(e) => handleFilterChange("title", e)}
            allowClear
          />
        </Form.Item>
        <div className={styles.cleanBtn}>
          <Button onClick={() => setFilters({})} type="link">
            Limpar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DataClusterAdvancedFilters;
