import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Tooltip,
} from "antd";
import { Option } from "antd/lib/mentions";
import { SelectValue } from "antd/lib/select";
import InputInteger from "components/Input/InputInteger/InputInteger";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { GtinCodeSimpleDetail, UnitType } from "shared/models/GtinCode";
import { gtinCodeService } from "shared/services/GtinCodeService";

// Only GTINS with Unity type = UNIDADE can generate Sales Code
const UNIT_TYPE_UNIT = "UNIDADE";

type GtinPackingEditTableProps = {
  gtins: GtinCodeSimpleDetail[];
  afterSubmit?: (gtins: GtinCodeSimpleDetail[]) => void;
  afterCancel?: (gtins: GtinCodeSimpleDetail[]) => void;
  afterGenerateSalesCode?: (gtin: string) => void;
};

type GtinPackingFormType = {
  gtins: GtinCodeSimpleDetail[];
};

export const GtinPackingEditTable = ({
  gtins,
  afterSubmit,
  afterCancel,
  afterGenerateSalesCode,
}: GtinPackingEditTableProps) => {
  const [gtinsAux, setGtinsAux] = useState<GtinCodeSimpleDetail[]>(gtins);
  const [form] = Form.useForm<GtinPackingFormType>();
  const [unitTypes, setUnitTypes] = useState<UnitType[]>([]);
  const [isTouched, setIsTouched] = useState(false);
  const [productSalesCodes, setProductSalesCodes] = useState<number[]>([]);

  const findPersistedSalesCodes = useCallback(() => {
    trackPromise(
      gtinCodeService.findSalesCodesByGtins(
        gtinsAux.map((gtinDetail) => gtinDetail.gtin)
      )
    )
      .then((salesCodes) => {
        setProductSalesCodes(salesCodes);
      })
      .catch(() =>
        message.error(
          "Erro ao buscar os códigos de vendas disponíveis para os GTINS"
        )
      );
  }, [gtinsAux]);

  useEffect(() => {
    trackPromise(gtinCodeService.findAllPackingUnits())
      .then(setUnitTypes)
      .catch(() =>
        message.error("Erro ao buscar os tipos de unidade de embalagem.")
      );

    findPersistedSalesCodes();
  }, [findPersistedSalesCodes]);

  useEffect(() => {
    const onUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = "";
      window.confirm(
        "O formulário contem mudanças não salvas, deseja prosseguir?"
      );
    };
    if (isTouched) {
      window.addEventListener("beforeunload", onUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isTouched]);

  const updateAvailableProductSalesCodes = () => {
    if (gtins.length > 1) {
      const notPersistedSalesCodes: number[] = (form.getFieldValue(
        "gtins"
      ) as GtinCodeSimpleDetail[])
        .filter(
          (gtin) => gtin.salesCode && gtin.packing?.unit === UNIT_TYPE_UNIT
        )
        .map((gtin) => gtin.salesCode!);

      const availableSalesCodes = Array.from(new Set(notPersistedSalesCodes));
      setProductSalesCodes(availableSalesCodes);
    } else {
      findPersistedSalesCodes();
    }
  };

  const handleFinish = (_: any) => {
    const updatedPackingGtins = form.getFieldValue("gtins");
    trackPromise(
      gtinCodeService.updateGtinPackingAttributes(updatedPackingGtins)
    )
      .then((updatedGtins) => {
        updatedGtins.sort((g1, g2) => Number(g1.gtin) - Number(g2.gtin));
        form.setFieldsValue({
          gtins: updatedGtins,
        });
        setIsTouched(false);
        setGtinsAux(updatedGtins);
        afterSubmit?.(updatedGtins);
      })
      .catch(() => message.error("Erro ao atualizar GTINS"));
  };

  const handleCancel = () => {
    form.setFieldsValue({ gtins: gtins });
    setIsTouched(false);
    afterCancel?.(gtins);
  };

  const updateSalesCode = (index: number, newSalesCode?: number) => {
    let gtinsAux: GtinCodeSimpleDetail[] = form.getFieldValue("gtins");
    gtinsAux[index].salesCode = newSalesCode;
    form.setFieldsValue({ gtins: gtinsAux });
  };

  const createNewSalesCode = (gtin: string, index: number) => {
    trackPromise(gtinCodeService.createSalesCode(gtin))
      .then((updatedGtin) => {
        updateSalesCode(index, updatedGtin.salesCode);
        updateAvailableProductSalesCodes();
        afterGenerateSalesCode?.(gtin);
      })
      .catch(() =>
        message.error("Erro ao gerar o código de venda para o GTIN")
      );
  };

  const onChangeUnitTypeSelect = (_: SelectValue, index: number) => {
    updateSalesCode(index);
    updateAvailableProductSalesCodes();
  };

  const isSalesCodeValid = (gtin: GtinCodeSimpleDetail): boolean => {
    const gtinIsUnidadeOrNotContainsSalesCode =
      gtin.packing?.unit === UNIT_TYPE_UNIT || !gtin.salesCode;

    if (gtinIsUnidadeOrNotContainsSalesCode) {
      return true;
    }

    return productSalesCodes.includes(gtin.salesCode!);
  };

  const getSalesCodeValue = (index: number) => {
    const gtin: GtinCodeSimpleDetail = form.getFieldValue("gtins")[index];

    const salesCode = gtin.salesCode;

    if (salesCode) {
      return isSalesCodeValid(gtin) ? (
        salesCode
      ) : (
        <div style={{ color: "red" }}>
          <div style={{ display: "inline-flex" }}>
            <p>{salesCode}</p>
            <Tooltip title="Remover cód. inválido">
              <Button
                type="text"
                ghost
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => updateSalesCode(index)}
              />
            </Tooltip>
          </div>
          <p style={{ fontSize: "12px" }}>
            Cód. de venda inválido (será excluído ao salvar)
          </p>
        </div>
      );
    }

    if (!salesCode && gtin.packing?.unit === UNIT_TYPE_UNIT) {
      return (
        <Button
          type="text"
          onClick={() => createNewSalesCode(gtin.gtin, index)}
          style={{ color: "var(--color-light-blue)" }}
        >
          Criar cód de venda
        </Button>
      );
    }

    return (
      <>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.value?.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => updateSalesCode(index, Number(value))}
        >
          <Option value={undefined}>{""}</Option>
          {productSalesCodes?.map((option: number) => (
            <Option value={String(option)}>{option}</Option>
          ))}
        </Select>
      </>
    );
  };

  return (
    <>
      <Card
        headStyle={{ padding: 0, marginTop: "-5px", marginBottom: "10px" }}
        style={{
          borderRadius: "12px",
          overflow: "hidden",
        }}
        bodyStyle={{
          padding: 0,
        }}
        title={
          <Row
            justify="space-around"
            style={{
              backgroundColor: "var(--color-light-gray)",
              padding: "10px",
            }}
          >
            <Col span={2}>
              <span>GTIN</span>
            </Col>

            <Col span={4}>
              <Tooltip title="Unidade de medida faturada">
                <span>UM</span>
              </Tooltip>
            </Col>

            <Col span={2}>
              <Tooltip title="Quantidade da embalagem faturada">
                <span>Quantidade</span>
              </Tooltip>
            </Col>

            <Col span={2}>
              <Tooltip title="Conversão da unidade para unidade menor">
                <span>Conv</span>
              </Tooltip>
            </Col>

            <Col span={3}>
              <span>Cód Venda</span>
            </Col>
          </Row>
        }
      >
        {isTouched && (
          <Row justify="center" style={{ paddingBottom: "15px" }}>
            <Alert
              message="O formulário contem mudanças não salvas"
              type="warning"
              showIcon
            />
          </Row>
        )}
        <Form
          form={form}
          onFinish={handleFinish}
          onFieldsChange={() => setIsTouched(true)}
        >
          <Form.List name="gtins" initialValue={gtins}>
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <>
                      <Row
                        key={field.key}
                        justify="space-around"
                        style={{ marginBottom: "-20px" }}
                      >
                        <Col span={2}>
                          <Tooltip title="Ir para GTIN">
                            <Link
                              to={`/gtins?filters={"skuCodes":${gtinsAux[index]?.gtin}}`}
                              style={{ color: "var(--color-light-blue)" }}
                            >
                              <span>{gtinsAux[index]?.gtin}</span>
                            </Link>
                          </Tooltip>
                        </Col>

                        <Col span={4}>
                          <Form.Item name={[index, "packing", "unit"]}>
                            <Select
                              showSearch
                              onChange={(value) =>
                                onChangeUnitTypeSelect(value, index)
                              }
                              placeholder="Selecione uma unidade de medida"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.value
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              <Option value={undefined}>{""}</Option>
                              {unitTypes?.map((option: UnitType) => (
                                <Option value={option.description}>
                                  {option.description}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item name={[index, "packing", "size"]}>
                            <InputInteger />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item name={[index, "packing", "unitSize"]}>
                            <InputInteger />
                          </Form.Item>
                        </Col>

                        <Col span={3}>{getSalesCodeValue(index)}</Col>
                      </Row>
                      <Divider style={{ margin: "10px" }} />
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <CancelAndSave onCancel={handleCancel} />
        </Form>
      </Card>
    </>
  );
};
