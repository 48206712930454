import { message } from "antd";
import { Select } from "components/Select";
import React, { useEffect, useState } from "react";
import { ValueLabel } from "shared/models/Product";

import { Option } from "rc-select";
import { commercialStatusService } from "shared/services/CommercialStatusService";

type ProductStatusSelect = {
  value?: string;
  onSelectStatus: (value: string) => void;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
};

export const ProductStatusSelect = ({
  onSelectStatus,
  value,
  label,
  style,
  disabled,
}: ProductStatusSelect) => {
  const [commercialStatus, setCommercialStatus] = useState<ValueLabel[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    commercialStatusService
      .findCommercialStatus()
      .then(setCommercialStatus)
      .catch(() => message.error("Erro ao buscar status comerciais."))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Select<string>
        onChange={onSelectStatus}
        label={label}
        style={style}
        loading={loading}
        value={value}
        disabled={disabled}
      >
        {commercialStatus.map((status) => (
          <Option key={status.value} value={status.value}>
            {status.label}
          </Option>
        ))}
      </Select>
    </>
  );
};
