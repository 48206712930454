import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { ManufacturersFilters } from "pages/Manufacturers/components/ManufacturersAdvancedFilters";
import { Word } from "react-wordcloud";
import { IdName } from "shared/models/IdName";
import {
  ManufacturerDetailedType,
  ManufacturerListingType,
  ManufacturerRequest,
} from "shared/models/Manufacturer";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/manufacturers";
const INDICATORS_PATH = "indicators/manufacturers";

class ManufacturerService {
  async getManufacturers(page: number, filters: ManufacturersFilters) {
    const params = objectToParams({ page, ...filters });
    const r = await axios.request<PixResponsePage<ManufacturerListingType>>({
      method: "GET",
      url: BASE_PATH,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async getManufacturerById(id: number): Promise<ManufacturerDetailedType> {
    const r = await axios.request({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
    } as AxiosRequestConfig);
    return r.data;
  }

  async save(manufacturerRequest: ManufacturerRequest) {
    let headers;
    let data;

    if (manufacturerRequest?.file) {
      data = new FormData();
      data.append("file", manufacturerRequest.file);

      const blob = new Blob(
        [JSON.stringify(manufacturerRequest.manufacturer)],
        {
          type: "application/json",
        }
      );
      data.append("manufacturer", blob);

      headers = { "Content-Type": "multipart/form-data" };
    } else {
      data = manufacturerRequest.manufacturer;
      headers = { "Content-Type": "application/json" };
    }
    const body: AxiosRequestConfig = {
      url: BASE_PATH,
      headers,
      data: data,
    };
    const r = await axios.request({
      method: manufacturerRequest.manufacturer.id ? "PUT" : "POST",
      ...body,
    });
    return r.data;
  }

  async getManufacturerBrands(
    id: number,
    page: number = 0
  ): Promise<PixResponsePage<IdName>> {
    const r = await axios.request({
      method: "GET",
      url: `${BASE_PATH}/${id}/brands`,
      params: { page },
    } as AxiosRequestConfig);
    return r.data;
  }

  async getWordCloudIndicators(
    id: number,
    mode: boolean = true
  ): Promise<Word[]> {
    const r = await axios.request({
      method: "GET",
      url: `${INDICATORS_PATH}/${id}/word-cloud`,
      params: {
        mode,
      },
    } as AxiosRequestConfig);
    return r.data;
  }

  async merge(mergeRequest: MergeRequest) {
    const r = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data: mergeRequest,
    });
    return r.data;
  }
}

export const manufacturerService = new ManufacturerService();
