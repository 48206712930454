import React, { useState, useEffect, useCallback } from "react";
import styles from "./MultiplePasteInput.module.scss";

import cx from "classnames";
import { filter as _filter, map as _map, pullAt as _pullAt } from "lodash";

import { Button } from "antd";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { PixTextArea, PixTextAreaProps } from "components/PixTextArea";

interface MultiplePasteInputProps
  extends Omit<PixTextAreaProps, "onChange" | "value"> {
  onChange: (values: string[]) => void;
  defaultValue?: string;
}

export const MultiplePasteInput = ({
  defaultValue,
  onChange,
  className,
  ...otherProps
}: MultiplePasteInputProps) => {
  const [values, setValues] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState(defaultValue || "");

  const handleItemRemove = (idx: number) => {
    setValues((oldNames) => {
      const newNames = [...oldNames];
      _pullAt(newNames, [idx]);
      return newNames;
    });
  };

  const handleValuesChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (!event || !event.currentTarget) {
      return;
    }
    const currentValue = event.currentTarget.value;
    if (currentValue && currentValue.indexOf("\n") >= 0) {
      setValues((oldNames) =>
        _filter(
          [...oldNames, ...currentValue.split("\n")],
          (item) => item.length > 0
        )
      );
      setInputValue("");
    } else {
      setInputValue(currentValue);
    }
  };

  const changeCall = useCallback((values) => {
    onChange(values);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (inputValue && inputValue.length) {
      changeCall([...values, inputValue]);
    } else {
      changeCall(values);
    }
  }, [values, inputValue, changeCall]);

  const handleEnter = (event: React.KeyboardEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <PixTextArea
        autoSize={true}
        className={cx("pix-multiple-paste-input", className)}
        onChange={handleValuesChange}
        onPressEnter={handleEnter}
        value={inputValue}
        {...otherProps}
      />
      {values.length > 0 &&
        _map(values, (value, idx) => (
          <div key={idx} className={styles.valueInputs}>
            <Input
              disabled={true}
              name={`pix-value-list-item-${idx}`}
              value={value}
            />
            <Button
              type="link"
              icon={<Icons name="closeCircleOutlined" />}
              onClick={() => handleItemRemove(idx)}
            ></Button>
          </div>
        ))}
    </>
  );
};
