import React, { ReactNode } from "react";
import _omit from "lodash/omit";
import cx from "classnames";
import styles from "./Select.module.scss";

import { Select as AntdSelect, Divider, Button } from "antd";
import { SelectProps as AntdSelectProps, SelectValue } from "antd/lib/select";
import { Icons } from "components/Icons";

export interface SelectProps<T> extends AntdSelectProps<T> {
  label?: string | ReactNode;
  error?: string | null;
  viewOnly: boolean;
  loadMoreButton: boolean;
  customDropdownRender?: ReactNode;
  onLoadMoreClick: () => void;
}

const Select = <T extends SelectValue>(props: SelectProps<T>) => {
  const {
    className,
    label,
    error,
    disabled,
    viewOnly,
    loadMoreButton,
    onLoadMoreClick,
    customDropdownRender,
  } = props;
  const passableProps = _omit(props, [
    "viewOnly",
    "loadMoreButton",
    "onLoadMoreClick",
    "customDropdownRender",
  ]);
  const inputStyle = cx(styles.input, {
    [styles.selectError]: !!error,
    [styles.viewOnly]: viewOnly,
  });
  return (
    <div className={cx(styles.wrapper, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <AntdSelect
        {...passableProps}
        className={inputStyle}
        disabled={disabled || viewOnly}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {customDropdownRender}
            {loadMoreButton && (
              <>
                <Divider style={{ margin: "4px 0" }} />
                <div className={styles.loadMore}>
                  <Button
                    type="link"
                    onClick={onLoadMoreClick}
                    icon={<Icons name="reloadOutlined" />}
                  >
                    Carregar Mais
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

Select.defaultProps = {
  viewOnly: false,
  loadMoreButton: false,
  onLoadMoreClick: () => {},
};

export { Select };
