import { Button, Empty } from "antd";
import cx from "classnames";
import { Icons } from "components/Icons";
import { ProtectedComponent } from "components/ProtectedComponent/ProtectedComponent";
import { SearchInput } from "components/SearchInput";
import { find as _find, map as _map } from "lodash";
import React from "react";
import { ApiRole } from "shared/models/ApiRole";
import { BuSegmentItem } from "shared/models/Taxonomy";
import { CategoryItem } from "./CategoryItem";
import styles from "./Subcontainer.module.scss";

interface SubcontainerProps {
  title: string;
  isFirst: boolean;
  isLast?: boolean;
  content: BuSegmentItem[] | null;
  selectedItem?: number | null;
  hasMore: boolean;
  headerColor?: string;
  disabled?: boolean;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onSearchEnter: (value: any) => void;
  onHeaderClick: () => void;
  onItemSelect: (item: BuSegmentItem) => void;
  onDuplicateItem?: (item: BuSegmentItem) => void;
  onEditItem: (item: BuSegmentItem) => void;
  onMoveItem?: (item: BuSegmentItem) => void;
  onAsssociateExternal?: (item: BuSegmentItem) => void;
  onLoadMore: () => void;
}

export const Subcontainer = ({
  title = "Árvore de Categorias",
  isFirst = false,
  isLast = false,
  content,
  disabled,
  selectedItem = null,
  hasMore = false,
  searchValue,
  onSearchChange,
  onSearchEnter,
  headerColor = "var(--color-dark-gray)",
  onHeaderClick,
  onItemSelect,
  onDuplicateItem,
  onEditItem,
  onMoveItem,
  onAsssociateExternal,
  onLoadMore,
}: SubcontainerProps) => {
  const wrapperStyles = cx(styles.wrapper, {
    [styles.thinCard]: isFirst,
    [styles.wideCard]: !isFirst,
  });
  return (
    <div className={wrapperStyles}>
      <div className={styles.header} style={{ backgroundColor: headerColor }}>
        <p>{title}</p>
        <ProtectedComponent allowedRoles={[ApiRole.SUPERVISOR, ApiRole.ADMIN]}>
          <div className={styles.newItemButton}>
            <button onClick={onHeaderClick} disabled={disabled}>
              <Icons name="plusCircleOutlined" />
            </button>
          </div>
        </ProtectedComponent>
      </div>
      <div className={styles.search}>
        <SearchInput
          disabled={disabled}
          onChange={onSearchChange}
          onPressEnter={onSearchEnter}
          onBlur={searchValue.length === 0 ? undefined : onSearchEnter}
          value={searchValue}
        />
      </div>
      <div className={styles.itemList}>
        {(!_find(content, (item) => item.id) || !content) && (
          <Empty description="Nenhum item encontrado." />
        )}
        {content &&
          _map(content, (item) => (
            <CategoryItem
              key={item.id}
              isFirst={isFirst}
              isLast={isLast}
              counter={item.quantity}
              onDuplicateItem={onDuplicateItem}
              onEditItem={onEditItem}
              onMoveItem={onMoveItem}
              onAsssociateExternal={onAsssociateExternal}
              itemData={{
                ...item,
                onSelect: () => onItemSelect(item),
              }}
              isSelected={selectedItem === item.id}
            />
          ))}
      </div>
      <div className={styles.loadMore}>
        <Button type="default" disabled={!hasMore} onClick={onLoadMore}>
          {hasMore ? "Carregar mais..." : "Todos os dados carregados."}
        </Button>
      </div>
    </div>
  );
};
