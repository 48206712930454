import Icon, {
  ApartmentOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  ArrowsAltOutlined,
  BarcodeOutlined,
  BorderOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  ClearOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseSquareOutlined,
  CloudUploadOutlined,
  ContainerOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  DragOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationOutlined,
  ExportOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileImageTwoTone,
  FilterFilled,
  FilterOutlined,
  FormOutlined,
  FullscreenOutlined,
  GoldOutlined,
  GroupOutlined,
  HistoryOutlined,
  HomeOutlined,
  HourglassOutlined,
  ImportOutlined,
  InboxOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  InsertRowLeftOutlined,
  MinusOutlined,
  MoreOutlined,
  PartitionOutlined,
  PauseOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareFilled,
  QuestionCircleOutlined,
  RedoOutlined,
  ReloadOutlined,
  RetweetOutlined,
  RightOutlined,
  SearchOutlined,
  ShrinkOutlined,
  StarOutlined,
  SwapOutlined,
  SyncOutlined,
  TableOutlined,
  TagOutlined,
  UploadOutlined,
  UpOutlined,
  VerticalAlignMiddleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import React from "react";
import { ReactComponent as Assets } from "shared/assets/icons/assets.svg";
import { ReactComponent as AssociationIcon } from "shared/assets/icons/association.svg";
import { ReactComponent as Basket } from "shared/assets/icons/basket.svg";
import { ReactComponent as Book } from "shared/assets/icons/book.svg";
import { ReactComponent as Car } from "shared/assets/icons/car.svg";
import { ReactComponent as CategoryIcon } from "shared/assets/icons/category.svg";
import { ReactComponent as CrossShield } from "shared/assets/icons/cross-shield.svg";
import { ReactComponent as CustomMore } from "shared/assets/icons/custom-more.svg";
import { ReactComponent as DashboardIcon } from "shared/assets/icons/dashboard.svg";
import { ReactComponent as FontawesomePlusSquare } from "shared/assets/icons/fa-plusquare.svg";
import { ReactComponent as FactoryIcon } from "shared/assets/icons/factory.svg";
import { ReactComponent as DownloadFolder } from "shared/assets/icons/folder-download-line.svg";
import { ReactComponent as ForkKnife } from "shared/assets/icons/fork-knife.svg";
import { ReactComponent as Hammer } from "shared/assets/icons/hammer.svg";
import { ReactComponent as HierarchyIcon } from "shared/assets/icons/hierarchy.svg";
import { ReactComponent as FontawesomeList } from "shared/assets/icons/list.svg";
import { ReactComponent as ProductLineIcon } from "shared/assets/icons/productLine.svg";
import { ReactComponent as ProductIcon } from "shared/assets/icons/products.svg";

interface PixIcon {
  name: string;
  icon: (props: AntdIconProps, iconRef: any) => void;
}

const iconList: PixIcon[] = [
  {
    name: "areaChartOutlined",
    icon: (props, iconRef) => <AreaChartOutlined {...props} ref={iconRef} />,
  },
  {
    name: "arrowsAltOutlined",
    icon: (props, iconRef) => <ArrowsAltOutlined {...props} ref={iconRef} />,
  },
  {
    name: "checkCircleFilled",
    icon: (props, iconRef) => <CheckCircleFilled {...props} ref={iconRef} />,
  },
  {
    name: "closeCircleFilled",
    icon: (props, iconRef) => <CloseCircleFilled {...props} ref={iconRef} />,
  },
  {
    name: "closeCircleOutlined",
    icon: (props, iconRef) => <CloseCircleOutlined {...props} ref={iconRef} />,
  },
  {
    name: "databaseOutlined",
    icon: (props, iconRef) => <DatabaseOutlined {...props} ref={iconRef} />,
  },
  {
    name: "deleteOutlined",
    icon: (props, iconRef) => <DeleteOutlined {...props} ref={iconRef} />,
  },
  {
    name: "downOutlined",
    icon: (props, iconRef) => <DownOutlined {...props} ref={iconRef} />,
  },
  {
    name: "editOutlined",
    icon: (props, iconRef) => <EditOutlined {...props} ref={iconRef} />,
  },
  {
    name: "eyeInvisibleOutlined",
    icon: (props, iconRef) => <EyeInvisibleOutlined {...props} ref={iconRef} />,
  },
  {
    name: "exclamationOutlined",
    icon: (props, iconRef) => <ExclamationOutlined {...props} ref={iconRef} />,
  },
  {
    name: "eyeOutlined",
    icon: (props, iconRef) => <EyeOutlined {...props} ref={iconRef} />,
  },
  {
    name: "fileImageOutlined",
    icon: (props, iconRef) => <FileImageOutlined {...props} ref={iconRef} />,
  },
  {
    name: "fileDoneOutlined",
    icon: (props, iconRef) => <FileDoneOutlined {...props} ref={iconRef} />,
  },
  {
    name: "fileExcelOutlined",
    icon: (props, iconRef) => <FileExcelOutlined {...props} ref={iconRef} />,
  },
  {
    name: "fileImageTwoTone",
    icon: (props, iconRef) => <FileImageTwoTone {...props} ref={iconRef} />,
  },
  {
    name: "filterOutlined",
    icon: (props, iconRef) => <FilterOutlined {...props} ref={iconRef} />,
  },
  {
    name: "filterFilled",
    icon: (props, iconRef) => <FilterFilled {...props} ref={iconRef} />,
  },
  {
    name: "historyOutlined",
    icon: (props, iconRef) => <HistoryOutlined {...props} ref={iconRef} />,
  },
  {
    name: "homeOutlined",
    icon: (props, iconRef) => <HomeOutlined {...props} ref={iconRef} />,
  },
  {
    name: "plusCircleFilled",
    icon: (props, iconRef) => <PlusCircleFilled {...props} ref={iconRef} />,
  },
  {
    name: "plusOutlined",
    icon: (props, iconRef) => <PlusOutlined {...props} ref={iconRef} />,
  },
  {
    name: "minusOutlined",
    icon: (props, iconRef) => <MinusOutlined {...props} ref={iconRef} />,
  },
  {
    name: "plusCircleOutlined",
    icon: (props, iconRef) => <PlusCircleOutlined {...props} ref={iconRef} />,
  },
  {
    name: "plusSquareFilled",
    icon: (props, iconRef) => <PlusSquareFilled {...props} ref={iconRef} />,
  },
  {
    name: "retweetOutlined",
    icon: (props, iconRef) => <RetweetOutlined {...props} ref={iconRef} />,
  },
  {
    name: "redoOutlined",
    icon: (props, iconRef) => <RedoOutlined {...props} ref={iconRef} />,
  },
  {
    name: "reloadOutlined",
    icon: (props, iconRef) => <ReloadOutlined {...props} ref={iconRef} />,
  },
  {
    name: "rightOutlined",
    icon: (props, iconRef) => <RightOutlined {...props} ref={iconRef} />,
  },
  {
    name: "shrinkOutlined",
    icon: (props, iconRef) => <ShrinkOutlined {...props} ref={iconRef} />,
  },
  {
    name: "infoCircleOutlined",
    icon: (props, iconRef) => <InfoCircleOutlined {...props} ref={iconRef} />,
  },
  {
    name: "infoCircleFilled",
    icon: (props, iconRef) => <InfoCircleFilled {...props} ref={iconRef} />,
  },
  {
    name: "InfoCircleTwoTone",
    icon: (props, iconRef) => <InfoCircleTwoTone {...props} ref={iconRef} />,
  },
  {
    name: "upOutlined",
    icon: (props, iconRef) => <UpOutlined {...props} ref={iconRef} />,
  },
  {
    name: "verticalAlignMiddleOutlined",
    icon: (props, iconRef) => (
      <VerticalAlignMiddleOutlined {...props} ref={iconRef} />
    ),
  },
  {
    name: "warningOutlined",
    icon: (props, iconRef) => <WarningOutlined {...props} ref={iconRef} />,
  },
  {
    name: "barcodeOutlined",
    icon: (props, iconRef) => <BarcodeOutlined {...props} ref={iconRef} />,
  },
  {
    name: "groupOutlined",
    icon: (props, iconRef) => <GroupOutlined {...props} ref={iconRef} />,
  },
  {
    name: "downloadOutlined",
    icon: (props, iconRef) => <DownloadOutlined {...props} ref={iconRef} />,
  },
  {
    name: "tableOutlined",
    icon: (props, iconRef) => <TableOutlined {...props} ref={iconRef} />,
  },
  {
    name: "borderOutlined",
    icon: (props, iconRef) => <BorderOutlined {...props} ref={iconRef} />,
  },
  {
    name: "uploadOutlined",
    icon: (props, iconRef) => <UploadOutlined {...props} ref={iconRef} />,
  },
  {
    name: "tagOutlined",
    icon: (props, iconRef) => <TagOutlined {...props} ref={iconRef} />,
  },
  {
    name: "searchOutlined",
    icon: (props, iconRef) => <SearchOutlined {...props} ref={iconRef} />,
  },
  {
    name: "dragOutlined",
    icon: (props, iconRef) => <DragOutlined {...props} ref={iconRef} />,
  },
  {
    name: "fullscreenOutlined",
    icon: (props, iconRef) => <FullscreenOutlined {...props} ref={iconRef} />,
  },
  {
    name: "ellipsisOutlined",
    icon: (props, iconRef) => <EllipsisOutlined {...props} ref={iconRef} />,
  },
  {
    name: "moreOutlined",
    icon: (props, iconRef) => <MoreOutlined {...props} ref={iconRef} />,
  },
  {
    name: "checkCircleOutlined",
    icon: (props, iconRef) => <CheckCircleOutlined {...props} ref={iconRef} />,
  },
  {
    name: "closeSquareOutlined",
    icon: (props, iconRef) => <CloseSquareOutlined {...props} ref={iconRef} />,
  },
  {
    name: "containerOutlined",
    icon: (props, iconRef) => <ContainerOutlined {...props} ref={iconRef} />,
  },
  {
    name: "cloudUploadOutlined",
    icon: (props, iconRef) => <CloudUploadOutlined {...props} ref={iconRef} />,
  },
  {
    name: "checkSquareOutlined",
    icon: (props, iconRef) => <CheckSquareOutlined {...props} ref={iconRef} />,
  },
  {
    name: "clearOutlined",
    icon: (props, iconRef) => <ClearOutlined {...props} ref={iconRef} />,
  },
  {
    name: "pauseOutlined",
    icon: (props, iconRef) => <PauseOutlined {...props} ref={iconRef} />,
  },
  {
    name: "partitionOutlined",
    icon: (props, iconRef) => <PartitionOutlined {...props} ref={iconRef} />,
  },
  {
    name: "customMore",
    icon: (props, iconRef) => (
      <Icon component={CustomMore} {...props} ref={iconRef} />
    ),
  },
  {
    name: "inboxOutlined",
    icon: (props, iconRef) => <InboxOutlined {...props} ref={iconRef} />,
  },
  {
    name: "copyOutlined",
    icon: (props, iconRef) => <CopyOutlined {...props} ref={iconRef} />,
  },
  {
    name: "formOutlined",
    icon: (props, iconRef) => <FormOutlined {...props} ref={iconRef} />,
  },
  {
    name: "questionCircleOutlined",
    icon: (props, iconRef) => (
      <QuestionCircleOutlined {...props} ref={iconRef} />
    ),
  },
  {
    name: "swapOutlined",
    icon: (props, iconRef) => <SwapOutlined {...props} ref={iconRef} />,
  },
  {
    name: "syncOutlined",
    icon: (props, iconRef) => <SyncOutlined {...props} ref={iconRef} />,
  },
  {
    name: "importOutlined",
    icon: (props, iconRef) => <ImportOutlined {...props} ref={iconRef} />,
  },
  {
    name: "insertRowLeftOutlined",
    icon: (props, iconRef) => (
      <InsertRowLeftOutlined {...props} ref={iconRef} />
    ),
  },
  {
    name: "starOutlined",
    icon: (props, iconRef) => <StarOutlined {...props} ref={iconRef} />,
  },
  {
    name: "exportOutlined",
    icon: (props, iconRef) => <ExportOutlined {...props} ref={iconRef} />,
  },
  {
    name: "dashboard",
    icon: (props, iconRef) => (
      <Icon component={DashboardIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "productLine",
    icon: (props, iconRef) => (
      <Icon component={ProductLineIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "factory",
    icon: (props, iconRef) => (
      <Icon component={FactoryIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "product",
    icon: (props, iconRef) => (
      <Icon component={ProductIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "hierarchy",
    icon: (props, iconRef) => (
      <Icon component={HierarchyIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "assets",
    icon: (props, iconRef) => (
      <Icon component={Assets} {...props} ref={iconRef} />
    ),
  },
  {
    name: "association",
    icon: (props, iconRef) => (
      <Icon component={AssociationIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "category",
    icon: (props, iconRef) => (
      <Icon component={CategoryIcon} {...props} ref={iconRef} />
    ),
  },
  {
    name: "fontawesomePlusSquare",
    icon: (props, iconRef) => (
      <Icon component={FontawesomePlusSquare} {...props} ref={iconRef} />
    ),
  },
  {
    name: "fontawesomeList",
    icon: (props, iconRef) => (
      <Icon component={FontawesomeList} {...props} ref={iconRef} />
    ),
  },
  {
    name: "basket",
    icon: (props, iconRef) => (
      <Icon component={Basket} {...props} ref={iconRef} />
    ),
  },
  {
    name: "hammer",
    icon: (props, iconRef) => (
      <Icon component={Hammer} {...props} ref={iconRef} />
    ),
  },
  {
    name: "crossShield",
    icon: (props, iconRef) => (
      <Icon component={CrossShield} {...props} ref={iconRef} />
    ),
  },
  {
    name: "forkKnife",
    icon: (props, iconRef) => (
      <Icon component={ForkKnife} {...props} ref={iconRef} />
    ),
  },
  {
    name: "car",
    icon: (props, iconRef) => <Icon component={Car} {...props} ref={iconRef} />,
  },
  {
    name: "book",
    icon: (props, iconRef) => (
      <Icon component={Book} {...props} ref={iconRef} />
    ),
  },
  {
    name: "downloadAll",
    icon: (props, iconRef) => (
      <Icon component={DownloadFolder} {...props} ref={iconRef} />
    ),
  },
  {
    name: "apartmentOutlined",
    icon: (props, iconRef) => <ApartmentOutlined {...props} ref={iconRef} />,
  },
  {
    name: "goldOutlined",
    icon: (props, iconRef) => <GoldOutlined {...props} ref={iconRef} />,
  },
  {
    name: "uploads",
    icon: (props, iconRef) => <UploadOutlined {...props} ref={iconRef} />,
  },
  {
    name: "hourglassOutlined",
    icon: (props, iconRef) => <HourglassOutlined {...props} ref={iconRef} />,
  },
  {
    name: "checkOutlined",
    icon: (props, iconRef) => <CheckOutlined {...props} ref={iconRef} />,
  },
  {
    name: "appstoreOutlined",
    icon: (props, iconRef) => <AppstoreOutlined {...props} ref={iconRef} />,
  },
];

export default iconList;
