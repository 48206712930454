export const generateBaseSearchParams = (
  terms: string[],
  page: number,
  sorts?: string[],
  size?: string
): URLSearchParams => {
  const params = new URLSearchParams();
  terms.forEach((term) => params.append("term", term));
  params.append("page", String(page));
  params.append("size", size || "20");
  sorts?.forEach((sort) => params.append("sort", sort));
  return params;
};
