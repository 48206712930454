import { SearchBrandsSelect } from "containers/SearchBrandsSelect/SearchBrandsSelect";
import { SearchProductLinesSelect } from "containers/SearchProductLinesSelect/SearchProductLinesSelect";
import { SearchSubBrandsSelect } from "containers/SearchSubBrandSelect/SearchSubBrandSelect";
import React, { useState } from "react";
import { IdName } from "shared/models/IdName";

interface SearchLineAndBrandSelectProps {
  parentName: string;
  selectedLine?: IdName;
  selectedBrand?: IdName;
  setSelectedLine: (l?: IdName) => void;
  setSelectedBrand: (b?: IdName) => void;
  setSelectedSubBrand?: (sb?: IdName) => void;
  setProductLineBrandId: (v?: number) => void;
}

export const SearchLineAndBrandSelect = ({
  parentName,
  selectedLine,
  selectedBrand,
  setSelectedLine,
  setSelectedBrand,
  setSelectedSubBrand,
  setProductLineBrandId,
}: SearchLineAndBrandSelectProps) => {
  const [productLineId, setProductLineId] = useState<number>();
  const [brandId, setBrandId] = useState<number>();
  const [subBrandId, setSubBrandId] = useState<number>();
  return (
    <>
      <SearchProductLinesSelect
        parentName={parentName}
        productLineId={productLineId}
        setSelectedLine={(pl) => {
          setSelectedLine(pl);
          setProductLineId(pl?.id);
          setSelectedBrand();
          setSelectedSubBrand?.();
        }}
        fixedFilters={{ deleted: "false", productLineSanitation: "ALTO" }}
      />
      <SearchBrandsSelect
        disabled={!selectedLine}
        brandId={brandId}
        parentName={parentName}
        setSelectedBrand={(b) => {
          setSelectedBrand(b);
          setBrandId(b?.id);
          setSelectedSubBrand?.();
        }}
        fixedProductLine={selectedLine}
        setProductLineBrandId={setProductLineBrandId}
      />
      {!!selectedBrand && (
        <SearchSubBrandsSelect
          parentName={parentName}
          subBrandId={subBrandId}
          setSelectedSubBrand={(sb) => {
            setSelectedSubBrand?.(sb);
            setSubBrandId(sb?.id);
          }}
          fixedBrand={selectedBrand}
        />
      )}
    </>
  );
};
