import { message } from "antd";
import axios, { AxiosRequestConfig, Method } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { ProductFilters } from "pages/Products/components/ProductsAdvancedFilters";
import { SimpleImage } from "shared/models/Asset";
import { AssociationInfoCount } from "shared/models/Association";
import {
  BatchUpdateInput,
  ProductById,
  ProductCreate,
  ProductListItem,
  ProductUpdate,
  ValueLabel,
} from "shared/models/Product";
import { MergeRequest } from "shared/types/Merge";
import { NameCount } from "shared/types/utils/NameCount";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/products";

class ProductService {
  async countAssociations(productId: number) {
    const response = await axios.request<AssociationInfoCount>({
      method: "GET",
      url: `${BASE_PATH}/${productId}/association`,
    } as AxiosRequestConfig);
    return response.data;
  }

  async getProducts(page: number, filters: ProductFilters) {
    const response = await axios.get<PixResponsePage<ProductListItem>>(
      BASE_PATH,
      {
        params: objectToParams({ ...filters, page }),
      } as AxiosRequestConfig
    );
    return response.data;
  }

  async createProduct(body: ProductCreate) {
    const response = await axios.request<ProductById>({
      method: "POST",
      url: BASE_PATH,
      headers: { "Content-Type": "application/json" },
      data: body,
    });
    return response.data;
  }

  async update(productId: number, data: ProductUpdate) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/${productId}`,
      data,
    };
    const response = await axios.request<ProductById>(body);
    return response.data;
  }

  async getById(id: number) {
    const response = await axios.request<ProductById>({
      method: "GET",
      url: `${BASE_PATH}/${id}`,
    } as AxiosRequestConfig);
    return response.data;
  }

  async softRemoveProduct(id: number, deleteIndicator: boolean) {
    const response = await axios.request({
      method: "PATCH",
      url: `${BASE_PATH}/${id}`,
      data: deleteIndicator,
    } as AxiosRequestConfig);
    return response.data;
  }

  async batchUpdate(data: BatchUpdateInput) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: BASE_PATH,
      headers: { "Content-Type": "application/json" },
      data,
    };
    const response = await axios.request<ProductBatchResponse>(body);
    return response.data;
  }

  async getAssetsDetails(id: number, page?: number) {
    const response = await axios.request<PixResponsePage<SimpleImage>>({
      method: "GET",
      url: `${BASE_PATH}/${id}/assets-detail`,
      params: objectToParams({ page }),
    } as AxiosRequestConfig);
    return response.data;
  }

  async merge(mergeRequest: MergeRequest) {
    const response = await axios.request<NameCount[]>({
      method: "PUT",
      url: `${BASE_PATH}/merge`,
      data: mergeRequest,
    });
    return response.data;
  }

  async findSanitation() {
    let defaultUrl: string = `${BASE_PATH}/sanitation`;

    const response = await axios.request<ValueLabel[]>({
      method: "GET",
      url: defaultUrl,
      headers: { "Content-Type": "application/json" },
    } as AxiosRequestConfig);
    return response.data;
  }

  async findMarketRotation() {
    let defaultUrl: string = `${BASE_PATH}/market-rotation`;

    const response = await axios.request<ValueLabel[]>({
      method: "GET",
      url: defaultUrl,
      headers: { "Content-Type": "application/json" },
    } as AxiosRequestConfig);
    return response.data;
  }

  async findIndustryRotation() {
    let defaultUrl: string = `${BASE_PATH}/industry-rotation`;

    const response = await axios.request<ValueLabel[]>({
      method: "GET",
      url: defaultUrl,
      headers: { "Content-Type": "application/json" },
    } as AxiosRequestConfig);
    return response.data;
  }

  changeAsset = async (productId: number, assetId: number, method: Method) => {
    const response = await axios.request<PixResponsePage<SimpleImage>>({
      method,
      url: `${BASE_PATH}/${productId}/asset/${assetId}`,
    } as AxiosRequestConfig);
    return response.data;
  };

  updateMainAsset = async (productId: number, assetId: number) =>
    this.changeAsset(productId, assetId, "PUT");

  removeAsset = async (productId: number, assetId: number) =>
    this.changeAsset(productId, assetId, "DELETE");

  async findTotalProductByAssociatedEntity(filters: ProductFilters) {
    const response = await axios.get<NameCount>(`${BASE_PATH}/total`, {
      params: objectToParams({ ...filters }),
    } as AxiosRequestConfig);
    return response.data;
  }
}

export interface ProductBatchResponse {
  success: number[];
  errors: {
    id: number;
    message: string;
  }[];
}

export const productService = new ProductService();

export const processBatchResponse = (response: ProductBatchResponse) => {
  const { success, errors } = response;
  errors.length === 0 && message.success("Sucesso ao realizar a alteração.");
  errors.length > 0 &&
    success &&
    success.length &&
    message.warning(
      `Sucesso parcial na alteração em massa. ${success.length} iten(s) alterados com sucesso. ${errors.length} iten(s) com erro.`
    );
  errors &&
    errors.length &&
    errors.forEach((item) => message.error(item.message));
};
