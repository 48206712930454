import { Select } from "antd";
import { useCharacteristics } from "hook/CharacteristicsHook";
import { debounce } from "lodash";
import { AdvancedFiltersModal } from "pages/Characteristics/components/AdvancedFiltersModal";
import React, { useState } from "react";
import {
  AttributeTypes,
  Characteristic,
  CharacteristicFilters,
} from "shared/models/Characteristic";
import { scrollFunction } from "shared/utils/ScrollUtils";

import style from "./SearchAttributesSelect.module.scss";

interface SearchAttributesSelectProps {
  parentName: string;
  productLineId?: number;
  placeHolder: string;
  fixedType: AttributeTypes;
  onOk: (c: Characteristic) => void;
}

export const SearchAttributesSelect = ({
  parentName,
  placeHolder,
  productLineId,
  fixedType,
  onOk,
}: SearchAttributesSelectProps) => {
  const {
    filters,
    fetchData,
    characteristics,
    fetchMoreData,
  } = useCharacteristics(
    React.useMemo(
      // Avoid infinite loop
      () => ({
        type: fixedType,
        productLineId,
      }),
      [productLineId, fixedType]
    )
  );
  const [open, setOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [value, setValue] = useState<number>();

  const handleChange = (attributeId: number) => {
    setValue(attributeId);
    const attribute = characteristics.find((c) => c.id === attributeId);
    if (attribute) {
      onOk(attribute);
    }
  };

  const debounceSearchAttributes = debounce(
    (term: string, filters: CharacteristicFilters, productLineId?: number) =>
      fetchData(0, { ...filters, term, productLineId, deleted: "false" }),
    300
  );

  return (
    <div className={style.wrapper}>
      <AdvancedFiltersModal
        status={open}
        parentName={parentName}
        filters={filters}
        onOk={(filters) => fetchData(0, filters)}
        onCancel={() => setOpen(false)}
        types={[]}
      />
      <Select<number>
        className={style.select}
        placeholder={placeHolder}
        id={`${parentName}-search-attributes-select`}
        filterOption={false}
        allowClear
        options={characteristics.map((c) => ({
          value: c.id,
          label: c.description,
        }))}
        onSelect={handleChange}
        onSearch={(term) =>
          debounceSearchAttributes(term, filters, productLineId)
        }
        showSearch
        open={optionsOpen}
        onDropdownVisibleChange={setOptionsOpen}
        onPopupScroll={(e) => scrollFunction(e, fetchMoreData)}
        value={value}
      />
    </div>
  );
};
