import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Drawer, Row, Skeleton, Switch, Tag } from "antd";
import { AdvancedOptions, Option } from "components/AdvancedOptions";
import FilterIcon from "components/FilterIcon/FilterIcon";
import { Icons } from "components/Icons";
import { PixInfinityTable } from "components/PixInfinityTable/PixInfinityTable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BatchProcessStatusEnum from "shared/enum/BatchProcessStatus";
import BatchProcessType from "shared/models/BatchProcessType";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { utcToPtBrDate } from "util/date.util";
import { BatchProcess } from "../../../shared/models/BatchProcess";
import styles from "./BatchProcessHistory.module.scss";
import MainUploadsAdvancedFilters, {
  MainUploadFilters,
} from "./MainUploadsAdvancedFilters";
import { UploadDetails } from "./UploadDetails";

type Props = {
  fetchMoreData: () => void;
  filters: MainUploadFilters;
  lastPage: boolean;
  listData: BatchProcess[];
  batchProcessTypes: BatchProcessType[];
  loading: boolean;
  refreshHistory: (
    filters: MainUploadFilters,
    batchProcessTypes: BatchProcessType[]
  ) => void;
};

const BatchProcessHistory: React.FC<Props> = ({
  fetchMoreData,
  filters,
  lastPage,
  listData,
  batchProcessTypes,
  loading,
  refreshHistory,
}) => {
  const PIX_ADMIN_URL = process.env.REACT_APP_PIX_ADMIN_URL;

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [uploadId, setUploadId] = useState("");
  const [enableAutoRefresh, setEnableAutoRefresh] = useState(true);

  const filterRef = useRef(filters);

  useEffect(() => {
    if (filterRef.current === filters) {
      refreshHistory(filters, batchProcessTypes);
    }
  }, [refreshHistory, filters, batchProcessTypes]);

  const handleFiltersChange = (newFilters?: MainUploadFilters) => {
    if (!!newFilters) {
      refreshHistory(newFilters, batchProcessTypes);
    }

    setFiltersVisible(false);
  };

  useEffect(() => {
    const fifteenSeconds = 15000;

    const uploadInterval = setInterval(() => {
      // shouldn't fetch data if the drawer detail is open
      if (!uploadId && enableAutoRefresh) {
        refreshHistory(filters, batchProcessTypes);
      }
    }, fifteenSeconds);

    // On unmount component
    return () => clearInterval(uploadInterval);
  }, [
    listData,
    refreshHistory,
    filters,
    uploadId,
    batchProcessTypes,
    enableAutoRefresh,
  ]);

  const getStatus = (value: string) => {
    let keys = Object.values(BatchProcessStatusEnum);

    for (let i = 0; i < keys.length; i++) {
      if (value === keys[i].value) {
        return (
          <Icons
            title={keys[i].label}
            name={keys[i].icon}
            style={{ fontSize: "16px", color: keys[i].color }}
            spin={value === BatchProcessStatusEnum.PROCESSING.value}
          />
        );
      }
    }

    return <Tag>Status não reconhecido: {value}</Tag>;
  };

  const getAdvancedOptions = useCallback(
    (_: any, item: BatchProcess) => {
      const handleAdvancedOptions = (_: BatchProcess) => {
        const options: Option[] = [
          {
            title: "Mais detalhes",
            icon: "fileDoneOutlined",
            action: () => {
              setUploadId(item.batchProcessId);
            },
          },
        ];

        if (completedProcessing(item)) {
          const onClickExport = () => {
            if (item.fileExpired) {
              getErrorMessage("O arquivo encontra-se expirado.");
              return;
            }

            let a = document.createElement("a");
            a.href = `${PIX_ADMIN_URL}/${item.processedFileUri!}`;
            a.setAttribute("download", item.description);
            a.click();
            a.remove();
          };

          options.push({
            title: "Exportar resultado",
            icon: "downloadOutlined",
            action: onClickExport,
          });
        }

        return options;
      };

      return (
        <>
          <AdvancedOptions
            options={handleAdvancedOptions(item)}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<Icons name="moreOutlined" />}
              onClick={(e) => e.stopPropagation()}
            />
          </AdvancedOptions>
        </>
      );
    },
    [PIX_ADMIN_URL]
  );

  const completedProcessing = (item: BatchProcess) => {
    return (
      item.processedFileUri &&
      (BatchProcessStatusEnum.ERROR.value === item.status ||
        BatchProcessStatusEnum.SUCCESS.value === item.status)
    );
  };

  const columns = [
    {
      key: "batchProcessTypeDescription",
      title: "TIPO DE IMPORTAÇÃO",
      dataIndex: "batchProcessTypeDescription",
    },
    {
      key: "batchProcessId",
      title: "DESCRIÇÃO",
      dataIndex: "description",
    },
    {
      key: "batchProcessId",
      title: "CRIADO EM",
      dataIndex: "createdAt",
      render: utcToPtBrDate,
    },
    {
      key: "batchProcessId",
      title: "INICIADO EM",
      dataIndex: "startedAt",
      render: utcToPtBrDate,
    },
    {
      key: "batchProcessId",
      title: "FINALIZADO EM",
      dataIndex: "processedAt",
      render: utcToPtBrDate,
    },
    {
      key: "batchProcessId",
      title: "USUÁRIO",
      dataIndex: "username",
    },
    {
      key: "batchProcessId",
      title: "STATUS",
      dataIndex: "status",
      render: getStatus,
      align: "center" as any,
    },
    {
      key: "batchProcessId",
      title: "",
      align: "center" as any,
      width: 100,
      render: getAdvancedOptions,
    },
  ];

  const onChangeSwitch = (checked: boolean) => {
    setEnableAutoRefresh(checked);

    if (checked) {
      refreshHistory(filters, batchProcessTypes);
    }
  };

  return (
    <>
      <Row justify="end">
        <Col className={styles.colSwitch}>
          <Row>
            <h4 className={styles.labelSwitch}>Atualização automática</h4>
            <Switch
              loading={loading}
              defaultChecked
              onChange={onChangeSwitch}
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <MainUploadsAdvancedFilters
          applyFilters={handleFiltersChange}
          originalFilters={filters}
          visible={filtersVisible}
          batchProcessTypes={batchProcessTypes}
        />

        {enableAutoRefresh && (
          <Tag icon={<LoadingOutlined spin />} color="processing">
            O histórico é atualizado a cada 15 segundos.
          </Tag>
        )}

        <Col className={styles.marginTop20} span={23}>
          <span className={styles.historyTitle}>
            Histórico
            <FilterIcon
              filters={filters}
              onClick={() => setFiltersVisible((f) => !f)}
            />
          </span>

          {loading ? (
            <Card>
              <Skeleton />
            </Card>
          ) : (
            <PixInfinityTable<BatchProcess>
              columns={columns}
              dataSource={listData}
              rowKey="batchProcessId"
              last={lastPage}
              fetchMoreData={fetchMoreData}
              notSelectable={true}
              className={styles.table}
              cardClassName={styles.infinityTable}
            />
          )}
        </Col>
      </Row>

      <Drawer
        visible={!!uploadId}
        closable
        onClose={() => setUploadId("")}
        width={(2 * window.innerWidth) / 4}
        className={styles.drawer}
        destroyOnClose
      >
        <UploadDetails id={uploadId} />
      </Drawer>
    </>
  );
};

export default BatchProcessHistory;
