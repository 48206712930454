import { Empty } from "antd";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import React from "react";
import { ProductListItem } from "shared/models/Product";
import { ProductItem } from "./ProductItem";
import styles from "./RelatedProducts.module.scss";

interface RelatedProductsProps {
  relatedProductsData: ProductListItem[];
  fetchMoreData: () => void;
  isLast: boolean;
}

export const RelatedProducts = ({
  relatedProductsData,
  fetchMoreData,
  isLast,
}: RelatedProductsProps) => {
  return (
    <>
      <div className={styles.wrapperTitle}>
        <h1>Produtos Relacionados</h1>
      </div>
      <div className={styles.wrapperSubtitle}>
        <h2>Nome do produto</h2>
      </div>
      <div className={styles.productListing}>
        {relatedProductsData.length < 1 && (
          <div className={styles.emptyState}>
            <Empty description="Nenhum produto relacionado encontrado." />
          </div>
        )}
        {relatedProductsData.map((relatedProduct) => (
          <ProductItem
            key={relatedProduct.productId}
            product={relatedProduct}
            isNew={false}
          />
        ))}
        {relatedProductsData.length > 1 && (
          <LoadMoreButton onFetchMoreData={fetchMoreData} last={isLast} />
        )}
      </div>
    </>
  );
};
