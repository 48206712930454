import EmptySelection from "components/EmptySelection/EmptySelection";
import React, { ReactElement } from "react";

interface EmptySelectionWrapperProps {
  children: ReactElement;
  emptyOn: any;
}

export const EmptySelectionWrapper = ({
  children,
  emptyOn,
}: EmptySelectionWrapperProps) => {
  if (!emptyOn) {
    return <EmptySelection />;
  }
  return <>{children}</>;
};
