import { uniq } from "lodash";
import { ProductLinesFilters } from "pages/ProductLines/components/ProductLinesAdvancedFilters";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductLineListView } from "shared/models/ProductLine";
import { productLineService } from "shared/services/ProductLineService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getInitialFilter } from "util/filter.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import { readTermQueryParam } from "util/yeh.query.param.util";
import { getTerms } from "./HookUtil";

const PRODUCT_LINE_FILTERS = "productLineFilters";

const getInitialFilters = () =>
  ({
    deleted: "false",
    ...getInitialFilter(PRODUCT_LINE_FILTERS, ProductLinesFilters),
  } as ProductLinesFilters);

export const useProductLines = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<ProductLinesFilters>(
    getInitialFilters()
  );
  const [terms, setTerms] = useState(readTermQueryParam());
  const [last, setLast] = useState(true);
  const [productLines, setProductLines] = useState<ProductLineListView[]>([]);

  const fetchProductLines = (page: number, filters: ProductLinesFilters) =>
    trackPromise(productLineService.getProductLines(page, filters))
      .then((response) => {
        setFilters(filters);
        setPage(response.page);
        setLast(response.last);
        setTerms((o) => getTerms(o, filters.term));
        setProductLines((old) => [
          ...(page > 0 ? old : []),
          ...response.content,
        ]);
      })
      .catch(() => {
        getErrorMessage("Erro ao buscar linhas");
      });

  const handleRemoveFilter = (term: string) => {
    const newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);
    fetchProductLines(0, { ...filters, term: newTerms.join(" ") });
  };

  const handleSearch = (value: string) => {
    const newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    const newFilters = { ...filters, term: newTerms.join(" ") };
    fetchProductLines(0, newFilters);
  };

  const fetchMoreData = () => {
    if (!last) {
      fetchProductLines(page + 1, filters);
    }
  };

  const refreshList = () => {
    fetchProductLines(0, filters);
  };

  const clearFilters = () => {
    fetchProductLines(0, { deleted: "false" });
  };

  useEffect(() => {
    fetchProductLines(0, getInitialFilters());
  }, []);

  useEffect(() => putObjectAsQueryParam(filters, PRODUCT_LINE_FILTERS), [
    filters,
  ]);

  return {
    last,
    terms,
    filters,
    productLines,
    setFilters,
    refreshList,
    clearFilters,
    handleSearch,
    fetchMoreData,
    fetchProductLines,
    handleRemoveFilter,
  };
};
