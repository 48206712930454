import { RouteComponentProps } from "@reach/router";
import { Card, Col, Radio, Row } from "antd";
import classnames from "classnames";
import ConfirmAssociationModal from "containers/ConfirmAssociationModal/ConfirmAssociationModal";
import { DefaultContent, DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { SelectionCards } from "containers/SelectionCards/SelectionCards";
import _isEmpty from "lodash/isEmpty";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { trackPromise } from "react-promise-tracker";
import {
  AssociationDataTypeSelect,
  AssociationFields,
  AssociationFilterBase,
  Transaction,
  VisionTypes,
} from "shared/models/Association";
import { ProductListItem } from "shared/models/Product";
import {
  associationService,
  processAssociationResponse,
} from "shared/services/AssociationService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getQueryParameterArray } from "util/query.param.util";
import styles from "./Combination.module.scss";
import { TransactionList } from "./components/TransactionList";

type CombinationProps = DefaultPage & RouteComponentProps;

const BASE_LIST_SPAN = 17;
const BASE_CARD_SPAN = 7;
const REASON_ASSOCIATION = "Associação via tela";
const ASSOCIATION_DATA_TYPE: AssociationDataTypeSelect[] = [
  { title: "Dados da Captura", value: "transaction-items" },
  { title: "Dados Fornecedor", value: "supplier-catalog" },
];

interface ComponentCardType {
  order: number;
  span: number;
}

export const Combination = ({ title }: CombinationProps) => {
  const [associativeModalStatus, setAssociativeModalStatus] = useState(false);
  const [
    associationTypeSelected,
    setAssociationTypeSelected,
  ] = useState<AssociationDataTypeSelect>(ASSOCIATION_DATA_TYPE[0]);
  const [componentCard, setComponentCard] = useState<ComponentCardType>({
    order: 1,
    span: BASE_CARD_SPAN,
  });
  const [componentList, setComponentList] = useState<ComponentCardType>({
    order: 2,
    span: BASE_LIST_SPAN,
  });

  const [expandedContent, setExpandedContent] = useState<number>();
  const [selectedCard, setSelectedCard] = useState<ProductListItem>();
  const [selectedProducts, setSelectedProducts] = useState<Transaction[]>();
  const [selectedVision, setSelectedVision] = useState<VisionTypes>(
    VisionTypes.UNASSOCIATED
  );
  const [filters, setFilters] = useState<AssociationFilterBase>({
    term: getQueryParameterArray("term"),
    emitterCompanyCodes: [],
    receiverCompanyCodes: [],
    skus: [],
  });

  const transactionListRef = useRef(undefined);
  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => setCurrentTitle("Associações"), [setCurrentTitle]);

  const changeCardOrder = () => {
    setComponentCard((oldComponentCard) => ({
      ...oldComponentCard,
      order: oldComponentCard.order === 1 ? 2 : 1,
    }));
    setComponentList((oldComponentList) => ({
      ...oldComponentList,
      order: oldComponentList.order === 1 ? 2 : 1,
    }));
  };

  const handleAssociativeModalStatus = () => {
    setAssociativeModalStatus((oldStatus) => !oldStatus);
  };

  const handleSelectedItems = useCallback((items: Transaction[]) => {
    setSelectedProducts(items);
  }, []);

  const handleAssociateItems = (
    items: Transaction[],
    target: ProductListItem,
    reason: string = REASON_ASSOCIATION
  ) => {
    let productId = target?.productId;
    let associationsFields: AssociationFields = { productId };

    let associationInfos = items.map((it) => ({
      descriptionId: it.descriptionId,
      ignoredGtins: [],
    }));

    trackPromise(
      associationService.createAssociation(
        associationInfos,
        associationsFields,
        reason,
        filters,
        associationTypeSelected.value
      )
    )
      .then((result) => {
        processAssociationResponse(result, "associação");
        transactionListRef.current &&
          (transactionListRef.current! as any).fetchData();
      })
      .catch(() => getErrorMessage("Erro ao salvar associações."));
  };

  const handleVision = () => setSelectedVision(VisionTypes.ASSOCIATED);
  const leftSectionStyle = classnames(styles.leftSection);

  return (
    <DefaultContent>
      <div className={styles.switchHeaderButton}>
        <Radio.Group value={selectedVision} buttonStyle="solid">
          <Radio.Button
            value={VisionTypes.UNASSOCIATED}
            onClick={() => setSelectedVision(VisionTypes.UNASSOCIATED)}
          >
            Não Associados
          </Radio.Button>

          <Radio.Button
            value={VisionTypes.ASSOCIATED}
            onClick={() => setSelectedVision(VisionTypes.ASSOCIATED)}
            disabled={!selectedCard}
          >
            Associados
          </Radio.Button>
        </Radio.Group>
      </div>
      <Row gutter={8}>
        <Col
          span={componentCard.span}
          order={componentCard.order}
          className={styles.cardColumn}
        >
          <Card className={leftSectionStyle}>
            <SelectionCards
              pageTitle={title}
              selectedCard={selectedCard}
              expandedContent={expandedContent}
              setExpanded={setExpandedContent}
              setSelected={setSelectedCard}
              onAssociationClick={handleVision}
            />
          </Card>
        </Col>
        <Col
          span={componentList.span}
          order={componentList.order}
          className={styles.cardColumn}
        >
          <TransactionList
            disabledConnect={!selectedCard || _isEmpty(selectedProducts)}
            onChangeOrder={changeCardOrder}
            onConnectClick={handleAssociativeModalStatus}
            onSelectItems={handleSelectedItems}
            selectedVision={selectedVision}
            target={selectedCard}
            ref={transactionListRef}
            associationDataType={ASSOCIATION_DATA_TYPE}
            associationDataTypeSelected={associationTypeSelected}
            onChangeDataType={(value) => setAssociationTypeSelected(value)}
            associateItems={handleAssociateItems}
            filters={filters}
            setFilters={(newFilter) => setFilters({ ...newFilter })}
          />
        </Col>
      </Row>
      {associativeModalStatus && (
        <ConfirmAssociationModal
          status={associativeModalStatus}
          target={selectedCard}
          selectedProducts={selectedProducts}
          handleClick={handleAssociativeModalStatus}
          associateItems={handleAssociateItems}
        />
      )}
    </DefaultContent>
  );
};
