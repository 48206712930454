import { Alert, Divider, Form, Modal } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { Icons } from "components/Icons";
import { IndustryRotationSelect } from "components/IndustryRotationSelect/IndustryRotationSelect";
import { MarketRotationSelect } from "components/MarketRotationSelect/MakertRotationSelect";
import { ProductStatusSelect } from "components/ProductStatusSelect/ProductStatusSelect";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { PixTaxonomySelect } from "containers/PixTaxonomySelect/PixTaxonomySelect";
import { SearchLineAndBrandSelect } from "containers/SearchLineAndBrandSelect/SearchLineAndBrandSelect";
import TaxonomyList from "containers/TaxonomyTab/TaxonomyList";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { IdName } from "shared/models/IdName";
import { BUSegment } from "shared/models/Taxonomy";
import { ProductFormMetadata } from "shared/models/User";
import {
  processBatchResponse,
  productService,
} from "shared/services/ProductService";
import {
  filterValidSegments,
  sortSegments,
  taxonomyService,
} from "shared/services/TaxonomyService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getEntityPermissions } from "util/UserPermissionUtils";
import styles from "./MassEditModal.module.scss";

interface MassEditModalProps {
  productIds: number[];
  status: boolean;
  formMetadata?: ProductFormMetadata;
  onOk: () => void;
  onCancel: () => void;
}

export const MassEditModal = ({
  productIds,
  status,
  formMetadata,
  onOk,
  onCancel,
}: MassEditModalProps) => {
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<BUSegment[]>([]);
  const [segments, setSegments] = useState<BUSegment[]>([]);
  const [selectedLine, setSelectedLine] = useState<IdName>();
  const [selectedBrand, setSelectedBrand] = useState<IdName>();
  const [
    selectedProductLineBrandId,
    setSelectedProductLineBrandId,
  ] = useState<number>();
  const [selectedSubBrand, setSelectedSubBrand] = useState<IdName>();
  const [productStatus, setProductStatus] = useState<string>();
  const [productSanitation, setProductSanitation] = useState<string>();
  const [marketRotation, setMarketRotation] = useState<string>();
  const [industryRotation, setIndustryRotation] = useState<string>();
  const [productMetada] = useState<ProductFormMetadata | null>(
    getEntityPermissions<ProductFormMetadata>("product")
  );

  const fetchSegments = useCallback(() => {
    trackPromise(taxonomyService.getScopedTaxonomies())
      .then((result) => {
        const filteredSegments = filterValidSegments(result.content);
        filteredSegments.sort(sortSegments);
        setSegments(filteredSegments);
      })
      .catch(() => {
        getErrorMessage("Erro ao buscar taxonomias.");
      });
  }, []);

  useEffect(() => {
    fetchSegments();
  }, [fetchSegments]);

  const clearValues = () => {
    setSelectedTaxonomy([]);
    setProductSanitation(undefined);
    setMarketRotation(undefined);
  };

  const isTouchedForm = () =>
    selectedProductLineBrandId ||
    selectedTaxonomy.length ||
    productStatus ||
    productSanitation ||
    marketRotation ||
    industryRotation;

  const handleSave = () => {
    trackPromise(
      productService.batchUpdate({
        productIds,
        productLineBrandId: selectedProductLineBrandId,
        productStatusId: productStatus,
        taxonomies: selectedTaxonomy.map((t) => t.leafId),
        productSanitation,
        marketRotation: marketRotation,
        industryRotation: industryRotation,
        subBrandId: selectedSubBrand?.id,
      })
    )
      .then((result) => {
        processBatchResponse(result);
        clearValues();
        onOk();
      })
      .catch(() => {
        getErrorMessage("Erro ao executar alteração em massa.");
      });
  };

  const handleCancel = () => {
    clearValues();
    onCancel();
  };

  const handleRemoveTaxonomy = (index: number) => {
    let newTaxonomies = [...selectedTaxonomy].filter((_, i) => i !== index);
    setSelectedTaxonomy([...newTaxonomies]);
  };

  const handleAddTaxonomy = (newSegment: BUSegment) => {
    setSelectedTaxonomy([...selectedTaxonomy, newSegment]);
  };

  return (
    <Modal
      cancelText="Cancelar"
      className={styles.wrapper}
      destroyOnClose
      okText="Salvar"
      onCancel={handleCancel}
      onOk={handleSave}
      title="Alteração em Massa"
      visible={status}
      okButtonProps={{
        disabled: !isTouchedForm(),
      }}
    >
      <Alert
        className={styles.alert}
        icon={<Icons name="infoCircleOutlined" />}
        message={`Você selecionou ${productIds.length} produto(s).`}
        showIcon
        type="info"
      />
      <SearchLineAndBrandSelect
        parentName="mass-edit"
        selectedLine={selectedLine}
        selectedBrand={selectedBrand}
        setSelectedLine={setSelectedLine}
        setSelectedBrand={setSelectedBrand}
        setProductLineBrandId={setSelectedProductLineBrandId}
        setSelectedSubBrand={setSelectedSubBrand}
      />

      <Form layout="vertical">
        <Form.Item
          label={
            <FilterLabel
              text="Status Comercial"
              htmlFor="div-status-selected"
            />
          }
        >
          <div id="div-status-selected" style={{ marginTop: "-3%" }}>
            <ProductStatusSelect
              value={productStatus}
              onSelectStatus={setProductStatus}
              disabled={!productMetada?.editProductCommercialStatus}
            />
          </div>
        </Form.Item>

        <Form.Item
          label={<FilterLabel text="Saneamento" htmlFor="saneamento" />}
        >
          <div id="saneamento" style={{ marginTop: "-3%" }}>
            <SanitationSelect
              value={productSanitation}
              onChange={setProductSanitation}
              disabled={!productMetada?.editProductSanitation}
            />
          </div>
        </Form.Item>

        <Form.Item
          label={
            <FilterLabel text="Giro de Mercado" htmlFor="market-rotation" />
          }
        >
          <div id="market-rotation" style={{ marginTop: "-3%" }}>
            <MarketRotationSelect
              value={marketRotation}
              onChange={setMarketRotation}
              disabled={!formMetadata?.editMarketRotation}
            />
          </div>
        </Form.Item>

        <Form.Item
          label={
            <FilterLabel text="Giro de Industria" htmlFor="industry-rotation" />
          }
        >
          <div style={{ marginTop: "-3%" }}>
            <IndustryRotationSelect
              value={industryRotation}
              onChange={setIndustryRotation}
              disabled={!formMetadata?.editIndustryRotation}
            />
          </div>
        </Form.Item>

        <Divider />

        <Form.Item
          label={<FilterLabel text="Taxonomia" htmlFor="taxonomies-id" />}
        >
          <PixTaxonomySelect
            id="taxonomies-id"
            segments={segments}
            selectedBus={selectedTaxonomy.map((it) => it.branch[1]?.id || -1)}
            onChange={handleAddTaxonomy}
          />
        </Form.Item>
        <TaxonomyList
          taxonomiesTree={selectedTaxonomy}
          handleRemoveTaxonomy={handleRemoveTaxonomy}
        />
      </Form>
    </Modal>
  );
};
