import { PixResponsePage } from "shared/services/PixResponse";
import { GtinCodeSimpleDetail } from "./GtinCode";

export interface AssociationValueAndTotal {
  value: string;
  total: number;
}

export interface AssociationStatistics {
  totalTermMatching: number;
  totalResults: number;
  mostFrequentlyWords: AssociationValueAndTotal[];
}

interface ProductSuggestion {
  productId: number;
  name: string;
}

interface ProductLineSuggestion {
  productLineId: number;
  name: string;
}

interface BrandSuggestion {
  brandId: number;
  name: string;
}

interface TaxonomySuggestion {
  taxonomyId: number;
  name: string;
}

export interface Transaction {
  descriptionId: string;
  description: string;
  gtins: GtinCodeSimpleDetail[];
  totalGtins: number;
  totalInternalSkus: number;
  totalOccurrs: number;
  totalEmitters: number;
  totalReceivers?: number;
  bus: string[];
  productsSuggestion: ProductSuggestion[];
  productLinesSuggestion: ProductLineSuggestion[];
  brandsSuggestion: BrandSuggestion[];
  taxonomiesSuggestion: TaxonomySuggestion[];
  detail?: AssociationDetail[];
  page?: number;
  last?: boolean;
}

export interface AssociationResult {
  payload: Transaction[];
  statistics: AssociationStatistics;
  nextKeySetList: string;
  termQueryHash: string;
  termOperatorHash: string;
}

export interface AssociationCustomer {
  code: string;
  name: string;
  bu: string;
}

export interface AssociationTradeHistory {
  lastIngDate: number;
  firstIngDate: number;
  totalTradedDates: number;
  ingDateDecompCandidates: number[];
  avgPriceLast_30_days: number;
  avgPriceLast_60_days: number;
  avgPriceLast_90_days: number;
  avgPriceLast_180_days: number;
}

export interface InternalSku {
  sku: string;
  skuType: string;
  emitterCompanyCode: string;
  emitterCompanyDescription: string;
}

export interface InternalSkuAssociation extends InternalSku {
  bus?: string[];
  description: string;
}

export interface AssociationDetail {
  gtinCode?: GtinCodeSimpleDetail;
  internalSku?: InternalSku;
}

export interface AssociationFilterBase {
  term: string[];
  emitterCompanyCodes: string[];
  receiverCompanyCodes: string[];
  skus: string[];
}

export interface Associations {
  entityId: string;
  descriptionsIds: string[];
}

export interface ProductAssociation {
  gtinCode?: GtinCodeSimpleDetail;
  internalSku?: InternalSkuAssociation;
  detail?: PixResponsePage<ProductAssociationDetail>;
  transactionIdentifier: string; // For react use as key
}

export interface CodeAndType {
  code: string;
  type: string;
}

export interface ProductAssociationDetail {
  associationType: string;
  bu: string;
  code: CodeAndType;
  description: string;
  emitterCompanyCode: string;
  emitterCompanyDescription: string;
  sku: CodeAndType;
  transactionItemHash: string;
}

export interface SkuCode {
  code: string;
  type: string;
}

export interface AssociationInputItem {
  companyCode: string;
  transactionItemHash: string;
}

export interface GtinItem {
  gtin: string;
  type: string;
}

export interface UpdateAssociationInput {
  reason: string;
  entityType: string;
  associations: AssociationsInput[];
}

export interface AssociationsInput {
  entityId: number;
  reassociation?: AssociationFields;
  associatedItems?: AssociationInputItem[];
  gtins?: GtinItem[];
  associatedItemsB2b?: string[];
}

export interface AssociationFields {
  productId?: number;
}
export interface NewAssociationsInput extends AssociationFields {
  reassociation?: AssociationFields;
  associatedItems?: AssociationInputItem[];
  gtins?: GtinItem[];
  associatedItemsB2b?: string[];
}

export enum VisionTypes {
  ASSOCIATED = "ASSOCIATED",
  UNASSOCIATED = "UNASSOCIATED",
}

export type AssociationDataType =
  | "transaction-items"
  | "supplier-catalog"
  | "datascience";

export interface AssociationDataTypeSelect {
  title: string;
  value: AssociationDataType;
}

export interface SuggestionDataScience {
  entityId: number;
  entityType: "STANDARD_PRODUCT";
  description: string;
  imageUrl?: string;
}

export interface AssociationInfo {
  descriptionId: string;
  ignoredGtins: string[];
}

export interface AssociationsFields {
  brandId?: number;
  productId?: number;
  productLineId?: number;
  taxonomyIds?: number[];
}

export interface DisassociateBasePayload extends AssociationsFields {
  reason: string;
}

export enum DisassociationCodeType {
  SKU = "SKU",
  GTIN = "GTIN",
}

export interface DisassociateSkuPayload extends DisassociateBasePayload {
  description?: string;
  emitterCompanyCode?: string;
  skuType?: string;
  code: string;
  codeType: DisassociationCodeType;
}

export interface ReassociateGtinPayload extends DisassociateSkuPayload {
  associationInputs: AssociationsFields;
}

export interface ReassociateSkuPayload extends DisassociateSkuPayload {
  associationInputs: AssociationsFields;
}

export type AssociationEntityType = "STANDARD_PRODUCT";

export type AssociationType = "GTIN_ASSOCIATION" | "INTERNAL_SKU_ASSOCIATION";

export interface AssociationTabItem {
  title: string;
  value: AssociationEntityType;
  description: string;
}

export interface AssociationOperationResponse {
  operation: string;
  total: number;
}

export interface AssociationInfoCount {
  b2bItem: number;
  gtin: number;
  total: number;
  transaction: number;
}
