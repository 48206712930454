import { Alert, Button, message, Modal, Tooltip } from "antd";
import { Icons } from "components/Icons";
import { PixInfinityTable } from "components/PixInfinityTable/PixInfinityTable";
import { ProductCard } from "components/ProductCard";
import { difference, flatten, map, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import RedCircleAlert from "shared/assets/icons/RedCircleAlert";
import { Transaction } from "shared/models/Association";
import { ProductListItem } from "shared/models/Product";
import styles from "./ConfirmAssociationModal.module.scss";

interface ProductFilterModalProps {
  status: boolean;
  target?: ProductListItem;
  selectedProducts?: Transaction[];
  handleClick: () => void;
  associateItems: (items: Transaction[], target: any | null) => void;
}

interface RemoveButtonProps {
  product: Transaction;
  removeItem: () => void;
}

const ASSOCIATION_LIMIT_ALERT = 20000;

const RemoveButton = ({ product, removeItem }: RemoveButtonProps) => {
  return (
    <Tooltip title="Remover">
      <Button
        type="link"
        data-testid="remove-button"
        onClick={() => removeItem()}
        className={styles.removeButton}
      >
        <Icons name="deleteOutlined" />
      </Button>
    </Tooltip>
  );
};

const ConfirmAssociationModal = ({
  status,
  target,
  selectedProducts,
  handleClick,
  associateItems,
}: ProductFilterModalProps) => {
  const [currentlySelectedProducts, setCurrentlySelectedProducts] = useState<
    Transaction[]
  >([]);

  useEffect(() => {
    if (!status) {
      return;
    }
    if (selectedProducts) {
      setCurrentlySelectedProducts([...selectedProducts]);
    }
  }, [status, selectedProducts]);

  const handleSubmit = () => {
    associateItems(currentlySelectedProducts, target);
    handleClick();
  };

  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      width: 200,
      sortable: true,
      render: (value: string, item: Transaction) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ),
    },

    {
      align: "center" as any,
      key: "col_nr_items",
      title: "Qtd",
      dataIndex: "totalOccurrs",
      width: 50,
      render: (value: string, item: Transaction) => (
        <div className={styles.tagTotalItems}>
          {item.totalGtins + item.totalInternalSkus}
        </div>
      ),
    },

    {
      align: "center" as any,
      dataIndex: "delete",
      width: 50,
      render: (e: string, value: Transaction) => (
        <RemoveButton product={value} removeItem={() => handleDelete(value)} />
      ),
    },
  ];

  const handleDelete = (value: Transaction) => {
    if (currentlySelectedProducts && currentlySelectedProducts.length === 1) {
      message.warning("Deve haver pelo menos um item selecionado.");
      return;
    }
    setCurrentlySelectedProducts(
      difference(currentlySelectedProducts, [value])
    );
  };

  const selectedGtins = uniq(
    map(
      flatten(
        map(currentlySelectedProducts, (prod: Transaction) => prod.gtins)
      ),
      (gtin) => gtin.gtin
    )
  );

  const totalOccurrs = currentlySelectedProducts
    .map((it) => it.totalOccurrs)
    .reduce((a, b) => a + b, 0);

  return (
    <div>
      <Modal
        cancelText="Cancelar"
        centered
        className={styles.customModalStyle}
        okText="Associar"
        onCancel={handleClick}
        onOk={handleSubmit}
        title="Produtos"
        visible={status}
        width={700}
      >
        <div
          data-testid="association-modal-header"
          className={styles.modalHeader}
        >
          <div className={styles.alertIcon}>
            <RedCircleAlert />
          </div>
          <div>
            <h2>Associação de produtos</h2>
            <p>Os produtos abaixo serão associados ao ítem:</p>
            <ProductCard data={target} />
            <p>Deseja realmente continuar?</p>
          </div>
        </div>
        {selectedGtins.length > 1 && (
          <Alert
            icon={<Icons name="infoCircleOutlined" />}
            message={
              <>
                <span>
                  Você está associando mais de um GTIN ao produto. <br />
                  Os GTINs que serão associados são:{" "}
                  <b>{selectedGtins.join(" , ")}</b>
                </span>
              </>
            }
            showIcon
            type="warning"
          />
        )}
        {totalOccurrs >= ASSOCIATION_LIMIT_ALERT && (
          <Alert
            icon={<Icons name="infoCircleOutlined" />}
            message={
              <>
                {`O processamento dessa associação poderá ocorrer em até ${totalOccurrs}
                transações e poderá levar até 5 minutos para a conclusão,
                deseja continuar? Sugerimos que, se você prosseguir, aguarde o
                término do prazo informado para fazer outra solicitação de
                associação do mesmo tamanho. Quantidades menores que
                ${ASSOCIATION_LIMIT_ALERT}
                funcionarão normalmente.`}
              </>
            }
            showIcon
            type="warning"
          />
        )}
        <div
          data-testid="association-modal-table"
          className={styles.modalTable}
        >
          <PixInfinityTable<Transaction>
            columns={columns}
            dataSource={currentlySelectedProducts}
            notSelectable
            rowKey="descriptionId"
            size="small"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmAssociationModal;
