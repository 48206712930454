import { message } from "antd";
import axios, { AxiosRequestConfig } from "axios";
import {
  AssociationDataType,
  AssociationDetail,
  AssociationEntityType,
  AssociationFields,
  AssociationFilterBase,
  AssociationInfo,
  AssociationOperationResponse,
  AssociationsFields,
  DisassociateSkuPayload,
  ProductAssociation,
  ProductAssociationDetail,
  ReassociateSkuPayload,
  Transaction,
} from "shared/models/Association";
import { NameCount } from "shared/types/utils/NameCount";
import { objectToParams } from "util/query.param.util";

import {
  CORE_LOAD_BALANCER_URL,
  YEH_ASSOCIATION_LOAD_BALANCER_URL,
} from "config/config";
import { PixResponsePage } from "./PixResponse";

const BASE_ENDPOINT = "association";
const BASE_PATH = YEH_ASSOCIATION_LOAD_BALANCER_URL + BASE_ENDPOINT;
const CORE_ASSOCIATION_PATH = CORE_LOAD_BALANCER_URL + "/" + BASE_ENDPOINT;

class AssociationService {
  async getNotAssociatedTransactions(
    filters: AssociationFilterBase,
    associationDataType: AssociationDataType,
    page: number,
    selectedTab: AssociationEntityType
  ): Promise<PixResponsePage<Transaction>> {
    const r = await axios.request({
      method: "GET",
      baseURL: BASE_PATH,
      url: `/list-${associationDataType}`,
      headers: { "Content-Type": "application/json" },
      params: objectToParams({ page, ...filters, selectedTab }),
    });
    return r.data;
  }

  async getAssociationDetail(
    descriptionId: string,
    filters: AssociationFilterBase,
    associationDataType: AssociationDataType,
    page: number = 0
  ): Promise<PixResponsePage<AssociationDetail>> {
    const r = await axios.request({
      method: "GET",

      url: `${BASE_PATH}/detail-${associationDataType}/${descriptionId}`,
      headers: { "Content-Type": "application/json" },
      params: objectToParams({ page, ...filters }),
    });
    return r.data;
  }

  async createAssociation(
    associationInfos: AssociationInfo[],
    associationsFields: AssociationsFields,
    reason: string,
    filters: AssociationFilterBase,
    associationDataType: AssociationDataType
  ) {
    const body: AxiosRequestConfig = {
      baseURL: BASE_PATH,
      method: "POST",
      url: `/associate-${associationDataType}`,
      headers: { "Content-Type": "application/json" },
      data: {
        associationInfos,
        associationsFields,
        reason,
        ...filters,
        term: filters.term.join(" "),
      },
    };
    const r = await axios.request<AssociationResponse>(body);
    return r.data;
  }

  async getProductAssociations(
    page: number = 0,
    filters: AssociationFilterBase,
    associationFields: AssociationFields,
    associationDataType: AssociationDataType
  ): Promise<PixResponsePage<ProductAssociation>> {
    const params = objectToParams({
      ...filters,
      ...associationFields,
      page,
    });
    const r = await axios.request({
      method: "GET",
      baseURL: BASE_PATH,
      url: `/list-${associationDataType}-associations`,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  async gtinListDetail(
    gtin: string,
    page: number,
    term?: string,
    emitterCompanyCodes?: string[]
  ): Promise<PixResponsePage<ProductAssociationDetail>> {
    let params = objectToParams({
      page,
      gtin,
      term,
      emitterCompanyCodes,
    });
    const r = await axios.request({
      method: "GET",
      url: `${BASE_PATH}/gtin/${gtin}/list-detail`,
      params,
    } as AxiosRequestConfig);
    return r.data;
  }

  disassociateGtinOrSku = async (data: DisassociateSkuPayload[]) => {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/disassociate-code`,
      headers: { "Content-Type": "application/json" },
      data,
    };
    const r = await axios.request<AssociationOperationResponse[]>(body);
    return r.data;
  };

  reassociateGtinOrSku = async (data: ReassociateSkuPayload[]) => {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/reassociate-code`,
      headers: { "Content-Type": "application/json" },
      data,
    };
    const r = await axios.request<AssociationOperationResponse[]>(body);
    return r.data;
  };

  async invalidateGtin(associationType: string, transactionhash: string) {
    const body: AxiosRequestConfig = {
      method: "PUT",
      url: `${BASE_PATH}/${associationType}/${transactionhash}/invalidate-gtin`,
      headers: { "Content-Type": "application/json" },
    };
    const r = await axios.request(body);
    return r.data;
  }

  async getTotalAssociationsByEntity(
    entityName: string,
    entitiesIds: number[]
  ): Promise<NameCount[]> {
    const body: AxiosRequestConfig = {
      method: "GET",
      url: `${CORE_ASSOCIATION_PATH}/total/${entityName}/${entitiesIds}`,
    };

    const r = await axios.request(body);
    return r.data;
  }
}

export interface AssociationResponse {
  success: {
    descriptionId: string;
    description: string;
    total: number;
    operation: string;
  }[];
  errors: {
    descriptionId: string;
    description: string;
    error: string;
  }[];
}

export const associationService = new AssociationService();

export const processAssociationResponse = (
  response: AssociationResponse,
  type: string
) => {
  const { success, errors } = response;
  errors.length === 0 && message.success(`Sucesso ao realizar a ${type}.`);
  errors.length > 0 &&
    success &&
    success.length &&
    message.warning(
      `Sucesso parcial ao realizar ${type}. ${success.length} iten(s) processados com sucesso. ${errors.length} iten(s) com erro.`
    );
  errors &&
    errors.length &&
    errors.forEach((item) => message.error(item.error));
};
