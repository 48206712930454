import { Form, Select } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import {
  ProductFilters,
  ProductsAdvancedFilters,
} from "pages/Products/components/ProductsAdvancedFilters";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductListItem } from "shared/models/Product";
import { productService } from "shared/services/ProductService";
import { scrollFunction } from "shared/utils/ScrollUtils";

interface SearchProductsSelectProps {
  onChange: (productIds: number | number[]) => void;
  parentName: string;
  productIds?: number | number[];
  mode?: "multiple" | "tags";
  label?: string;
  fixActive?: boolean;
}

const SearchProductsSelect = ({
  mode,
  onChange,
  parentName,
  productIds,
  label,
  fixActive,
}: SearchProductsSelectProps) => {
  const [page, setPage] = useState(0);
  const [productFilters, setProductFilters] = useState<ProductFilters>({
    deleted: fixActive ? "false" : undefined,
  });
  const [products, setProdcts] = useState<ProductListItem[]>([]);
  const [productFiltersOpen, setProductFiltersOpen] = useState(false);
  const [productOptionsOpen, setProductOptionsOpen] = useState(false);
  const [last, setLast] = useState(true);

  const fetchProducts = (page: number, filters: ProductFilters) => {
    trackPromise(
      productService.getProducts(page, filters).then((response) => {
        let newProducts = [
          ...(page === 0 ? [] : products),
          ...response.content,
        ];
        setProdcts(newProducts);
        setProductOptionsOpen(true);
        setLast(response.last);
      })
    );
    setProductFilters(filters);
    setProductFiltersOpen(false);
    setPage(page);
  };

  const debounceSearchProducts = debounce(
    (term: string) => fetchProducts(0, { ...productFilters, term }),
    1000
  );

  const loadMore = () => {
    if (!last) {
      fetchProducts(page + 1, productFilters);
    }
  };

  const getLabel = () => {
    if (!!label) {
      return label;
    }
    return !mode ? "Produto" : "Produtos";
  };

  return (
    <Form.Item
      label={
        <>
          <FilterLabel text={getLabel()} htmlFor="search-products-select" />
          <FilterIcon
            filters={productFilters}
            onClick={() => setProductFiltersOpen((f) => !f)}
          />
        </>
      }
      rules={[
        {
          required: true,
          message: "Favor informar produtos",
        },
      ]}
    >
      <ProductsAdvancedFilters
        visible={productFiltersOpen}
        parentName={parentName}
        originalFilters={productFilters}
        onConfirm={(filters) => fetchProducts(0, filters)}
        onClose={() => setProductFiltersOpen(false)}
        fixedStatus={fixActive}
      />
      <Select<number | number[]>
        mode={mode}
        id="search-products-select"
        filterOption={false}
        allowClear
        value={productIds}
        options={products.map((product) => ({
          value: product.productId,
          label: product.description,
        }))}
        onChange={onChange}
        onSearch={debounceSearchProducts}
        showSearch
        open={productOptionsOpen}
        onDropdownVisibleChange={(f) => setProductOptionsOpen(f)}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
      />
    </Form.Item>
  );
};

export default SearchProductsSelect;
