import { Button } from "antd";
import { Input } from "components/Input";
import { PixLink } from "components/PixLink/PixLink";
import React from "react";
import { CountStatusEnum } from "shared/enum/CountStatusEnum";

interface InputClickToCountProps {
  countStatus: CountStatusEnum;
  entity: string;
  onCount?: () => void;
  total?: number;
  link?: string;
  linkParams?: any;
  linkParamsName?: string;
  linkText?: string;
}

export const InputClickToCount = ({
  countStatus,
  entity,
  link,
  linkParams,
  linkParamsName,
  linkText,
  onCount,
  total,
}: InputClickToCountProps) => {
  return (
    <Input
      id="count-total"
      label={
        <>
          Número de {entity}
          {link && (
            <PixLink
              label={linkText!}
              link={link}
              params={linkParams}
              paramsName={linkParamsName}
            />
          )}
          {total == null && onCount && (
            <Button
              type="link"
              className="countCharacteristicsUsageBtn"
              loading={countStatus === CountStatusEnum.LOADING_STATUS}
              onClick={onCount}
            >
              Contar
            </Button>
          )}
        </>
      }
      value={total ?? countStatus}
      disabled
    />
  );
};
