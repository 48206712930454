import { RouteComponentProps } from "@reach/router";
import {
  Badge,
  Col,
  ConfigProvider,
  Divider,
  Pagination,
  Row,
  Tag,
} from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { Icons } from "components/Icons";
import { ItemContent } from "components/ItemContent";
import { PixInfinityTable } from "components/PixInfinityTable/PixInfinityTable";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  BatchProcessDetails,
  BatchProcessDetailsMetadata,
} from "shared/models/BatchProcess";
import { batchProcessService } from "shared/services/BatchProcessService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import styles from "./UploadDetails.module.scss";

type UploadDetailsProps = {
  id: string;
} & RouteComponentProps;

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;

const COUNT_ERROR_MESSAGE =
  "Contador não processado ou a importação foi realizada antes da implementação dos contadores (Importação legada).";
const COUNT_ERROR_VALUE = "-";

const imageColumns = [
  {
    title: "TIPO DE IMPORTAÇÃO",
    render: (_: string, item: BatchProcessDetails) => (
      <span>{item.productId ? "PRODUTO" : "EAN"}</span>
    ),
  },
  {
    title: "CÓDIGO",
    render: (_: string, item: BatchProcessDetails) => (
      <span>{item.productId ? item.productId : item.ean}</span>
    ),
  },
];

const columns = [
  {
    title: () => (
      <>
        <Icons name="exclamationOutlined" className={styles.headerErrorIcon} />
        <span className={styles.headerError}>tipo de erro</span>
      </>
    ),
    render(_: string, item: BatchProcessDetails) {
      return <span style={{ color: "#eb0024" }}>{item.detail}</span>;
    },
  },
];

export const UploadDetails = ({ id }: UploadDetailsProps) => {
  const [details, setDetails] = useState<BatchProcessDetails[]>([]);

  const [
    detailsMetadata,
    setDetailsMetadata,
  ] = useState<BatchProcessDetailsMetadata>();

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const fetchBatchProcessDetailMetaData = useCallback((uuid: string) => {
    trackPromise(batchProcessService.getBatchProcessDetailsMetadata(uuid))
      .then(setDetailsMetadata)
      .catch((error) => getErrorMessage("Erro ao buscar importações."));
  }, []);

  const fetchBatchProcessDetailData = useCallback(
    (uuid: string, page: number, pageSize: number) => {
      trackPromise(
        batchProcessService.getBatchProcessDetails(uuid, page - 1, pageSize)
      )
        .then((response) => {
          setDetails(response.content);
          setCurrentPage(page);
          setPageSize(pageSize);
        })
        .catch((error) => getErrorMessage("Erro ao buscar importações."));
    },
    []
  );

  useEffect(() => {
    id && fetchBatchProcessDetailData(id, DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, [id, fetchBatchProcessDetailData]);

  useEffect(() => {
    id && fetchBatchProcessDetailMetaData(id);
  }, [id, fetchBatchProcessDetailMetaData]);

  const getStatusImport = () => {
    switch (detailsMetadata?.status) {
      case "PROCESSING":
        return "Em processamento";
      case "ERROR":
        return "Erro";
      default:
        return "Sucesso";
    }
  };

  const handlePaginationChange = (newPage: number, newPageSize?: number) => {
    const finalPage = newPageSize === pageSize ? newPage : 1;
    const finalPageSize = newPageSize ?? DEFAULT_PAGE_SIZE;

    fetchBatchProcessDetailData(id, finalPage, finalPageSize);
  };

  const getCountDisplay = (
    title: string,
    type: "success" | "error",
    countValue?: number
  ) => {
    const typeIconValues =
      type === "error"
        ? { iconClassName: "badgeRedIcon", iconName: "exclamationOutlined" }
        : { iconClassName: "badgeGreenIcon", iconName: "checkOutlined" };

    return (
      <ItemContent title={title} className={styles.marginHorizontal1p}>
        <Tag
          icon={
            <Icons
              name={typeIconValues.iconName}
              className={styles[typeIconValues.iconClassName]}
            />
          }
          color={type}
          className={styles.borderRadius20px}
        >
          {countValue ?? (
            <Badge
              count="?"
              title={COUNT_ERROR_MESSAGE}
              size="small"
              offset={[8, 0]}
            >
              <span>{COUNT_ERROR_VALUE}</span>
            </Badge>
          )}
        </Tag>
      </ItemContent>
    );
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="center" className={styles.rowContainer}>
        <Col span={24} className={styles.statusContainer}>
          <ItemContent title="Status" className={styles.marginHorizontal1p}>
            <strong>{getStatusImport()}</strong>
          </ItemContent>
          <Divider type="vertical" className={styles.dividerHorizontal} />
          {getCountDisplay(
            "Erros gerais",
            "error",
            detailsMetadata?.totalErrors
          )}
          <Divider type="vertical" className={styles.dividerHorizontal} />
          {getCountDisplay(
            "Linhas processadas",
            "success",
            detailsMetadata?.processedLines
          )}
          <Divider type="vertical" className={styles.dividerHorizontal} />
          {getCountDisplay(
            "Linhas com sucesso",
            "success",
            detailsMetadata?.successProcessedLines
          )}
          <Divider type="vertical" className={styles.dividerHorizontal} />
          {getCountDisplay(
            "Linhas com erro",
            "error",
            detailsMetadata?.errorProcessedLines
          )}
        </Col>

        <Col span={24}>
          <PixInfinityTable<BatchProcessDetails>
            columns={[
              ...(detailsMetadata?.type === "IMAGE_IMPORT" ? imageColumns : []),
              ...columns,
            ]}
            dataSource={details}
            rowKey="inconsistencyId"
            notSelectable={true}
            className={styles.table}
            cardClassName={styles.infinityTable}
          />
        </Col>
        <Col span={24} className={styles.pagination}>
          <ConfigProvider locale={ptBR}>
            <Pagination
              hideOnSinglePage
              showSizeChanger
              showQuickJumper
              total={detailsMetadata?.totalErrors ?? 0}
              current={currentPage}
              pageSize={pageSize}
              onChange={handlePaginationChange}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </>
  );
};
