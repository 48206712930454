import { Sanitation } from "./Sanitation";

export interface ManufacturerCreate {
  name: string;
  shortName: string;
  description: string;
  manufacturerSanitation?: string;
  site?: string;
  mainAsset?: number;
}

export interface Manufacturer extends ManufacturerCreate {
  id: number;
  deleted: boolean;
  lastModified?: string;
  imageUrl?: string;
}

export interface ManufacturerRequest {
  manufacturer: Partial<Manufacturer>;
  file?: any;
}

export const newManufacturer: Partial<Manufacturer> = Object.freeze({
  name: "",
  shortName: "",
  site: "",
  description: "",
});

export type ManufacturerListingType = Manufacturer & {
  totalBrands: number;
  manufacturerSanitation?: Sanitation;
};
export type ManufacturerDetailedType = Manufacturer & {
  createdAt: string;
  createdBy: string;
  manufacturerSanitation?: Sanitation;
};
