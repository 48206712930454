export enum ApiRole {
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  OPERATOR = "operator",
  RGM = "rgm",
  READ_ONLY = "read-only",
}

export const ApiRoleHierarchy: Record<ApiRole, number> = {
  admin: 3,
  supervisor: 2,
  operator: 1,
  rgm: 0,
  "read-only": 0,
};
