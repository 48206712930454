import React, { ReactNode, Key, createContext } from "react";
import styles from "./PixInfinityTable.module.scss";
import cx from "classnames";
import { filter as _filter, isEqual as _isEqual } from "lodash";

import { Table, Card, Empty } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnType } from "antd/lib/table";
import { SorterResult, ExpandableConfig } from "antd/lib/table/interface";
import { TableProps } from "antd/lib/table/Table";
import { Resizable, ResizableProps } from "react-resizable";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { ColumnGroupProps } from "antd/lib/table/ColumnGroup";

export const EditableContext = createContext<FormInstance | null>(null);

export type PixColumn<RecordType extends object> = {
  align?: "left" | "right" | "center";
  editable?: boolean;
  notResizeable?: boolean;
  order?: number;
  searchable?: boolean;
  sortBy?: string;
} & ColumnType<RecordType>;

type PixInfinityTableProps<RecordType extends object> = {
  className?: string;
  cardClassName?: string;
  columns?: PixColumn<RecordType>[];
  dataSource: RecordType[];
  expandable?: ExpandableConfig<RecordType>;
  fetchMoreData?: () => void;
  handleSelectedItems?: (value: RecordType[]) => void;
  handleSortChange?: (
    sort: SorterResult<RecordType> | SorterResult<RecordType>[]
  ) => void;
  last?: boolean;
  multiSelection?: boolean;
  notSelectable?: boolean;
  resizable: boolean;
  rowKey: string;
  showHeader?: boolean;
  size?: "middle" | "small";
  tableAction?: ReactNode;
  tableTitle?: string | ReactNode;
  columnsGroups?: React.ReactElement<ColumnGroupProps<RecordType>>[];
} & TableProps<RecordType>;
type PixInfinityTableState<RecordType extends object> = {
  columns?: PixColumn<RecordType>[];
  selectedRowKeys: Key[];
};

const ResizeableTitle = (props: ResizableProps) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      axis="x"
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const components = {
  header: {
    cell: ResizeableTitle,
  },
};

const COL_MIN_WIDTH = 70;
const COL_MAX_WIDTH = 600;
export class PixInfinityTable<
  RecordType extends object
> extends React.Component<
  PixInfinityTableProps<RecordType>,
  PixInfinityTableState<RecordType>
> {
  static defaultProps = {
    size: "middle",
    resizable: true,
  };
  state = {
    columns: this.props.columns,
    selectedRowKeys: [],
  };

  componentDidUpdate(prevProps: PixInfinityTableProps<RecordType>) {
    if (!_isEqual(prevProps.columns, this.props.columns)) {
      this.setState({ columns: this.props.columns });
    }
  }

  onSelectChange = (selectedRowKeys: Key[], value: RecordType[]) => {
    const { handleSelectedItems } = this.props;
    this.setState({ selectedRowKeys });
    handleSelectedItems && handleSelectedItems(value);
  };

  selectRow = (record: RecordType) => {
    const { rowKey, multiSelection } = this.props;

    const selectedRowKeys: any[] = !multiSelection
      ? []
      : [...this.state.selectedRowKeys];
    const key: any = record[rowKey as keyof RecordType];
    if (selectedRowKeys.indexOf(key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(key), 1);
    } else {
      selectedRowKeys.push(key);
    }
    this.setState({ selectedRowKeys }, () => {
      this.onSelectChange(
        this.state.selectedRowKeys,
        this.filterSelectedItems()
      );
    });
  };

  filterSelectedItems = () => {
    const { dataSource, rowKey } = this.props;
    const { selectedRowKeys } = this.state;
    const key = rowKey as keyof RecordType;
    return _filter(
      dataSource,
      (item: RecordType) => (selectedRowKeys as any[]).indexOf(item[key]) > -1
    );
  };

  selectRows = (rows: number[]) => {
    this.setState({ selectedRowKeys: rows });
  };

  handleOnRow = (record: RecordType) => ({
    onClick: () => this.selectRow(record),
  });

  handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
    extra: any
  ) => {
    const { handleSortChange } = this.props;
    handleSortChange && handleSortChange(sorter);
  };

  clearSelections = () => {
    this.setState({ selectedRowKeys: [] });
  };

  handleResize = (index: number, column: PixColumn<RecordType>) => (
    event: any,
    { size }: any
  ) => {
    if (
      column.notResizeable ||
      size.width < COL_MIN_WIDTH ||
      size.width > COL_MAX_WIDTH
    ) {
      return;
    }
    this.setState(({ columns }) => {
      const nextColumns = columns ? [...columns] : [];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render = () => {
    const {
      cardClassName,
      className,
      columns,
      dataSource,
      fetchMoreData,
      last = true,
      multiSelection,
      notSelectable,
      resizable,
      tableTitle,
      tableAction,
      columnsGroups,
      ...otherProps
    } = this.props;
    const { selectedRowKeys } = this.state;

    const resizableColumns = this.state.columns?.map((col, index) => ({
      ...col,
      onHeaderCell: (column: PixColumn<RecordType>): any => ({
        width: column.width || 100,
        onResize: this.handleResize(index, column),
      }),
    }));

    return (
      <>
        <Card
          id="pix-table-card"
          title={tableTitle}
          className={cx(styles.card, cardClassName)}
          extra={tableAction}
        >
          <Table<RecordType>
            className={cx(styles.table, className)}
            columns={resizable ? resizableColumns : this.state.columns}
            components={resizable ? components : undefined}
            dataSource={dataSource}
            locale={{
              emptyText: (
                <Empty
                  description="Nenhum dado encontrado"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
              cancelSort: "", // Sem locale porque o tooltip usado atrapalha a usabilidade das telas
              triggerDesc: "",
              triggerAsc: "",
            }}
            onChange={this.handleTableChange}
            onRow={this.handleOnRow}
            pagination={false}
            rowSelection={
              notSelectable
                ? undefined
                : {
                    type: multiSelection ? "checkbox" : "radio",
                    selectedRowKeys,
                    onChange: (key, value) => this.onSelectChange(key, value),
                  }
            }
            {...otherProps}
          >
            {!this.state.columns && columnsGroups ? columnsGroups : <></>}
          </Table>
        </Card>
        {!!fetchMoreData && dataSource && dataSource.length > 0 && (
          <LoadMoreButton last={last} onFetchMoreData={fetchMoreData} />
        )}
      </>
    );
  };
}
