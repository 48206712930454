import cx from "classnames";
import { findIndex as _findIndex, some as _some } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./AttributeTab.module.scss";

import { trackPromise } from "react-promise-tracker";
import { characteristicService } from "shared/services/CharacteristicService";
import { getErrorMessage } from "shared/utils/ResponseUtils";

import { Alert, Button, List, Tooltip, message } from "antd";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { SearchInput } from "components/SearchInput";
import {
  Characteristic,
  CharacteristicValue,
} from "shared/models/Characteristic";
import { BaseFormMetadata } from "shared/models/User";

interface AttributeTabProps {
  selected?: Characteristic;
  attributeUserPermissionsMetadata?: BaseFormMetadata | null;
}

export const AttributeTab = ({
  selected,
  attributeUserPermissionsMetadata,
}: AttributeTabProps) => {
  const [addValue, setAddValue] = useState<Partial<CharacteristicValue>>({});
  const [touched, setTouched] = useState({
    description: false,
    shortDescription: false,
  });
  const [editMode, setEditMode] = useState(0);
  const [isInserting, setIsInserting] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [page, setPage] = useState(0);
  const [terms, setTerms] = useState<string[]>([]);
  const [values, setValues] = useState<CharacteristicValue[]>([]);

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!isInserting) {
      if (!addValue.description || !addValue.shortDescription) {
        setTouched({ description: true, shortDescription: true });
        return;
      }
      if (selected && selected.id) {
        setIsInserting(true);
        trackPromise(characteristicService.saveValue(selected.id, addValue))
          .then((result) => {
            setPage(0);
            fetchItemValuesById();
            setAddValue({});
            setTouched({ description: false, shortDescription: false });
            setIsInserting(false);
          })
          .catch((error) => {
            getErrorMessage("Erro ao buscar lista de características.");
            setIsInserting(false);
          });
      }
    }
  };

  const handleSearch = (value: string) => {
    setPage(0);
    setTerms([value]);
  };

  const handleSaveEdit = (item: CharacteristicValue) => {
    if (item && item.id && selected && selected.id) {
      trackPromise(characteristicService.updateValue(selected.id, item))
        .then(() => {
          message.success("Valor alterado com sucesso.");
          setEditMode(0);
        })
        .catch((error) => getErrorMessage("Erro ao cadastrar valor."));
    }
  };

  const fetchItemValuesById = useCallback(() => {
    if (selected && selected.id) {
      trackPromise(characteristicService.getValues(selected.id, terms, page))
        .then((values) => {
          setValues((prevValues) =>
            page === 0 ? values.content : [...prevValues, ...values.content]
          );
          setLastPage(values.last);
        })
        .catch((error) => getErrorMessage("Erro ao cadastrar valor."));
    }
  }, [selected, page, terms]);

  useEffect(() => {
    fetchItemValuesById();
  }, [selected, page, terms, fetchItemValuesById]);

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  return (
    <div className={styles.wrapper}>
      <Alert
        className={styles.alert}
        icon={<Icons name="infoCircleOutlined" />}
        message={
          <span>
            Estes são os atributos da característica:{" "}
            <b>{selected?.description}</b>
          </span>
        }
        showIcon
        type="info"
      />

      <SearchInput
        className={styles.searchInput}
        placeholder="Digite e tecle ENTER"
        onPressEnter={handleSearch}
      />
      <List
        className={styles.attributeList}
        dataSource={values}
        header={
          <>
            <div className={styles.header}>
              <span className={styles.colValue}>VALOR</span>
              <span className={styles.colAbrev}>ABREVIAÇÃO</span>
              <div className={styles.colAction}></div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={styles.addNew}>
                <div className={styles.colValue}>
                  <Input
                    value={addValue.description}
                    placeholder="Valor"
                    error={
                      !editMode && !addValue.description && touched.description
                        ? "Campo obrigatório"
                        : null
                    }
                    onBlur={() => setTouched({ ...touched, description: true })}
                    onChange={(evnt) => {
                      const value = evnt.currentTarget.value;
                      setTouched({ ...touched, description: false });
                      setAddValue((prevValue) => ({
                        ...prevValue,
                        description: value,
                      }));
                    }}
                  />
                </div>
                <div className={styles.colAbrev}>
                  <Input
                    value={addValue.shortDescription}
                    placeholder="Abreviação"
                    error={
                      !editMode &&
                      !addValue.shortDescription &&
                      touched.shortDescription
                        ? "Campo obrigatório"
                        : null
                    }
                    onBlur={() =>
                      setTouched({ ...touched, shortDescription: true })
                    }
                    onChange={(evnt) => {
                      const value = evnt.currentTarget.value;
                      setTouched({ ...touched, shortDescription: false });
                      setAddValue((prevValue) => ({
                        ...prevValue,
                        shortDescription: value,
                      }));
                    }}
                  />
                </div>
                {attributeUserPermissionsMetadata?.create && (
                  <div className={styles.colAction}>
                    <Tooltip title="Adicionar item" placement="left">
                      <Button
                        type="link"
                        htmlType="submit"
                        icon={<Icons name="plusCircleFilled" />}
                        disabled={
                          !editMode &&
                          _some(touched) &&
                          (!addValue.description || !addValue.shortDescription)
                        }
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </form>
          </>
        }
        locale={{ emptyText: "Nenhum atributo encontrado" }}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <div className={styles.colValue}>
              {editMode === item.id && (
                <Input
                  placeholder="Valor"
                  value={item.description}
                  error={
                    !!editMode && touched.description === true
                      ? "Campo obrigatório"
                      : null
                  }
                  onBlur={(evnt) => {
                    const value = evnt.currentTarget.value;
                    if (!value) {
                      setTouched({ ...touched, description: true });
                    }
                    if (value) {
                      setTouched({ ...touched, description: false });
                    }
                  }}
                  onChange={(evnt) => {
                    setTouched({ ...touched, description: false });
                    const value = evnt.currentTarget.value;
                    setValues((oldValues) => {
                      oldValues[
                        _findIndex(oldValues, item)
                      ].description = value;
                      return [...oldValues];
                    });
                  }}
                />
              )}
              {editMode !== item.id && (
                <List.Item.Meta title={item.description} />
              )}
            </div>
            <div className={cx(styles.shortNameEdit, styles.colAbrev)}>
              {editMode === item.id && (
                <Input
                  placeholder="Abreviação"
                  defaultValue={item.shortDescription}
                  error={
                    !!editMode && touched.shortDescription === true
                      ? "Campo obrigatório"
                      : null
                  }
                  onBlur={(evnt) => {
                    const value = evnt.currentTarget.value;
                    if (!value) {
                      setTouched({ ...touched, shortDescription: true });
                    }
                    if (value) {
                      setTouched({ ...touched, shortDescription: false });
                    }
                  }}
                  onChange={(evnt) => {
                    setTouched({ ...touched, shortDescription: false });
                    const value = evnt.currentTarget.value;
                    setValues((oldValues) => {
                      oldValues[
                        _findIndex(oldValues, item)
                      ].shortDescription = value;
                      return [...oldValues];
                    });
                  }}
                />
              )}
              {editMode !== item.id && <>{item.shortDescription}</>}
            </div>
            <div
              className={cx(styles.editIcon, styles.colAction, {
                editMode: editMode === item.id,
              })}
            >
              {editMode === item.id && (
                <Tooltip title="Salvar edição" placement="left">
                  <Button
                    type="link"
                    icon={<Icons name="checkCircleFilled" />}
                    onClick={() => handleSaveEdit(item)}
                    disabled={_some(touched)}
                  />
                </Tooltip>
              )}
              {editMode !== item.id &&
                attributeUserPermissionsMetadata?.create && (
                  <Tooltip title="Editar item" placement="left">
                    <Button
                      type="link"
                      icon={<Icons name="editOutlined" />}
                      onClick={() => {
                        setTouched({
                          description: false,
                          shortDescription: false,
                        });
                        setEditMode(item.id);
                      }}
                    />
                  </Tooltip>
                )}
            </div>
          </List.Item>
        )}
      />
      <LoadMoreButton last={lastPage} onFetchMoreData={fetchMoreData} />
    </div>
  );
};
