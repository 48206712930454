import React, { ReactNode } from "react";
import styles from "./SingleContent.module.scss";

import { DefaultContent } from "containers/DefaultContent";
import { Row, Col, Card } from "antd";

type SingleContentProps = {
  component: ReactNode;
};

export const SingleContent = ({ component }: SingleContentProps) => {
  return (
    <DefaultContent>
      <Row gutter={8}>
        <Col span={24} order={1} className={styles.cardColumn}>
          <Card className={styles.card}>{component}</Card>
        </Col>
      </Row>
    </DefaultContent>
  );
};
