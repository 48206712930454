import { Icons } from "components/Icons";
import React from "react";

export const ImageUploadText = () => {
  return (
    <>
      <p className="ant-upload-drag-icon">
        <Icons name={"containerOutlined"} />
      </p>
      <p className="ant-upload-text">
        Clique ou arraste uma imagem para esta área para subir o arquivo.
      </p>
      <p className="ant-upload-hint">
        Formato PNG, JPG, JPEG - Mínimo: 500 x 500 pixels - Máximo: 1300 x 1300
        pixels - Tamanho: 10 KB a 1 MB
      </p>
    </>
  );
};
