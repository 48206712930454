import { AutoComplete, Col, Image, message, Row, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { Icons } from "components/Icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import excel from "shared/assets/images/microsoft-excel@3x.png";
import BatchProcessType from "shared/models/BatchProcessType";
import { uploadLambdaService } from "shared/services/UploadLambdaService";
import { getQueryParameter, updateQueryParam } from "util/query.param.util";
import styles from "./MainUploadPage.module.scss";
import { MainUploadFilters } from "./MainUploadsAdvancedFilters";

const { Dragger } = Upload;

const PIX_ADMIN_URL = process.env.REACT_APP_PIX_ADMIN_URL;

interface MainUploadPageProps {
  batchProcessTypes: BatchProcessType[];
  refreshHistory: (
    filters: MainUploadFilters,
    batchProcessTypes: BatchProcessType[]
  ) => void;
}

export const MainUploadPage = ({
  batchProcessTypes,
  refreshHistory,
}: MainUploadPageProps) => {
  const [uploadFile, setUploadFile] = useState<UploadFile>();
  const [selectedType, setSelectedType] = useState<BatchProcessType>();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (batchProcessTypes?.length) {
      let tab = getQueryParameter("tab");
      setSelectedType(batchProcessTypes.find((it) => it.id === tab));
    }
  }, [batchProcessTypes]);

  useEffect(() => {
    if (selectedType?.id) {
      updateQueryParam("tab", selectedType?.id);
    }
  }, [selectedType]);

  const isAcceptedFileType = (file: UploadFile, acceptedExtension?: string) =>
    !!acceptedExtension &&
    acceptedExtension
      ?.split(",")
      .filter((item) => file?.name?.toLowerCase().endsWith(item)).length > 0;

  const handleTabChange = (batchProcessType: string) => {
    setSelectedType(
      batchProcessTypes.find((it) => it.id === batchProcessType)!
    );
  };

  const sendFile = () => {
    if (!uploadFile || !selectedType) {
      return;
    }

    if (
      !isAcceptedFileType(
        uploadFile,
        selectedType.uploadConfiguration?.fileType
      )
    ) {
      message.warn("Arquivo com formato inválido: " + uploadFile.name);
      setUploadFile(undefined);
      return;
    }

    trackPromise(
      uploadLambdaService.uploadFile(
        uploadFile,
        uploadFile.name,
        selectedType.id
      )
    )
      .then(() => {
        message.success("Arquivo enviado com sucesso");
        refreshHistory({}, batchProcessTypes);
      })
      .catch(() => {
        message.error("Não foi possível fazer o upload, tente novamente.");
        setUploadFile(undefined);
      });
  };

  useEffect(sendFile, [uploadFile]);

  return (
    <>
      {batchProcessTypes.length > 0 && (
        <div className={styles.selectorContainer}>
          <h4 className={styles.labelSelector}>
            {selectedType?.description
              ? "Tipo da importação"
              : "Selecione um tipo para realizar a importação"}
          </h4>
          <div className={styles.selectorWrapper}>
            <AutoComplete
              data-testid="select-type"
              options={batchProcessTypes
                .filter(
                  (it) =>
                    search.length === 0 ||
                    it.description.toUpperCase().includes(search) ||
                    it.id.toUpperCase().includes(search)
                )
                .map((it) => ({
                  value: it.id,
                  label: it.description,
                }))}
              className={styles.uploadSelector}
              value={selectedType?.description}
              onChange={handleTabChange}
              size="large"
              allowClear
              defaultOpen
              autoFocus
              onSearch={(t) => setSearch(t.toUpperCase())}
            />
          </div>
        </div>
      )}
      {selectedType && (
        <Row justify="center">
          <Col span={17} className={styles.colDragger}>
            <Dragger
              accept={selectedType?.uploadConfiguration.fileType}
              multiple={false}
              fileList={!!uploadFile ? [uploadFile] : []}
              name="file"
              listType="text"
              onChange={(info) => setUploadFile(info.file)}
              beforeUpload={() => {
                // Prevent default antd behavior
                return false;
              }}
              disabled={!selectedType?.id}
              onRemove={() => setUploadFile(undefined)}
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <Icons name="inboxOutlined" />
              </p>
              <p className="ant-upload-text">
                Clique aqui para selecionar arquivos ou arraste arquivos
                compactados para essa área.
              </p>

              <h5>{`Tipo da importação: ${
                selectedType?.description ??
                "Sem tipo de importação selecionado"
              }
              `}</h5>
            </Dragger>
          </Col>
          <Col span={5} className={styles.excelContainer}>
            <Col span={24} className={styles.excelMessage}>
              <Col span={24}>
                <Icons className={styles.excelIcon} name="fileExcelOutlined" />
              </Col>
              <Col span={24} className={styles.downloadExcel}>
                Baixe o template em Excel
              </Col>
              <Col className={styles.colBtnExcel} span={24}>
                <a
                  className={styles.btnExcelDownload}
                  href={`${PIX_ADMIN_URL}/templates/${selectedType?.uploadConfiguration.template}`}
                  download
                >
                  <Icons name="downloadOutlined" /> Download
                </a>
              </Col>
            </Col>
            <Image
              src={excel}
              className={styles.excelImage}
              alt="Excel image"
              preview={false}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
