import { useYehPermissions } from "hook/PermissionHook";
import React from "react";
import { ApiRole } from "shared/models/ApiRole";

type ProtectedComponentProps = {
  allowedRoles: ApiRole[];
  children: React.ReactNode;
};

export const ProtectedComponent = ({
  allowedRoles: roles,
  children,
}: ProtectedComponentProps) => {
  const { userHasAnyRole } = useYehPermissions();

  return <>{userHasAnyRole(roles) && children}</>;
};
