import { MergeCellsOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { ExtractLogsButton } from "components/AuditLogExtract/ExtractLogsButton";
import EmptySelection from "components/EmptySelection/EmptySelection";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { PixLink } from "components/PixLink/PixLink";
import { TabItem } from "components/Tabs/Tabs";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { useBrands } from "hook/BrandHooK";
import { CreateBrand } from "pages/Brands/components/CreateBrand";
import React, { useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { EntitiesEnum } from "shared/enum/EntitiesEnum";
import { Brand, BrandById, BrandListView } from "shared/models/Brand";
import { BrandFormMetadata } from "shared/models/User";
import { brandService } from "shared/services/BrandService";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { putObjectAsQueryParam } from "util/query.param.util";
import { readInitialTab } from "util/yeh.query.param.util";
import { BrandCharts } from "./components/BrandCharts";
import {
  BrandsAdvancedFilters,
  BrandsFilters,
} from "./components/BrandsAdvancedFilters";
import { SubBrandTab } from "./components/SubBrandTab";

const tabItems: TabItem[] = [
  {
    title: "Marca",
    value: "brand",
  },
  { title: "Sub Marca", value: "subBrand" },
  {
    title: "Gráficos",
    value: "charts",
  },
];

const columns = [
  {
    title: "ID Marca",
    dataIndex: "id",
  },
  {
    title: "FABRICANTE",
    dataIndex: ["manufacturer", "name"],
    sorter: true,
    sortBy: "manufacturerName",
    render: (value: string, item: Brand) => (
      <PixLink
        label={value}
        link="/fabricante"
        params={{ manufacturerId: item.manufacturer.id }}
      />
    ),
  },
  {
    title: "MARCA",
    dataIndex: "name",
    sorter: true,
    sortBy: "brandName",
  },
  {
    title: "N. LINHAS",
    align: "center" as any,
    dataIndex: "totalProductLines",
  },
  {
    title: "ATIVO",
    align: "center" as any,
    render: (_: string, item: Brand) => (
      <ActiveInactiveItem deleted={item.deleted} />
    ),
  },
  {
    key: "sanitation",
    title: "Saneamento",
    dataIndex: ["brandSanitation", "label"],
    width: 100,
    align: "center" as any,
  },
];

type BrandsProps = DefaultPage & RouteComponentProps;

export const Brands = ({ title }: BrandsProps) => {
  const [isListExpanded, setListExpanded] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [isWriting, setWriting] = useState(false);

  const {
    terms,
    last,
    brands,
    filters,
    setFilters,
    fetchBrands,
    handleSearch,
    refreshList,
    fetchMoreData,
    handleRemoveFilter,
  } = useBrands();

  const [selectedBrand, setSelectedBrand] = useState<BrandById>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState(readInitialTab(tabItems));
  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<BrandListView[]>([]);
  const [brandMetaData] = useState<BrandFormMetadata | null>(
    getEntityPermissions<BrandFormMetadata>("brand")
  );
  const [multiselectMode, setMultiselectMode] = useState(false);

  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  const onClickMergeMode = () => {
    if (isMergeMode) {
      setSelectedRows([]);
    }

    setIsMergeMode(!isMergeMode);
  };

  const getActionButtons = (
    <>
      <Button
        onClick={onClickMergeMode}
        icon={<MergeCellsOutlined />}
        id="btn-merge"
        hidden={!brandMetaData?.merge}
      >
        {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
      </Button>

      <ExtractLogsButton
        entityName={EntitiesEnum.BRAND}
        entityIds={selectedRows}
        hasPermission={brandMetaData?.readAudit}
      />
    </>
  );

  useEffect(() => putObjectAsQueryParam(filters, "filters"), [filters]);

  const handleModalSubmit = (filters: BrandsFilters) => {
    fetchBrands(0, filters);
    setAdvancedFilterOpen(false);
  };

  const handleClearFilters = () => {
    const brandsFilters = new BrandsFilters();
    brandsFilters.deleted = "false";
    handleModalSubmit(brandsFilters);
    setFilters(brandsFilters);
  };

  const onChangeMultiSelect = () => {
    setSelectedRows([]);
    setSelectedBrand(undefined);
    setWriting(false);
  };

  const secondaryActions = (
    <>
      <ToggleMultiSelectButton
        multiselectMode={multiselectMode}
        setMultiselectMode={setMultiselectMode}
        onChange={onChangeMultiSelect}
        disabled={isMergeMode}
      />
    </>
  );

  const getSearchComponent = () => {
    return (
      <CrudListContent<BrandListView>
        actionButtons={getActionButtons}
        secondaryActions={secondaryActions}
        columns={columns}
        data={brands}
        isExpanded={isListExpanded}
        fetchMoreData={fetchMoreData}
        filterProps={{
          terms: terms,
          advancedFilters: filters,
          handleClearFilters,
          handleFilterIconClick: () => setAdvancedFilterOpen((f) => !f),
        }}
        last={last}
        handleCreateNewClick={handleCreateNewClick}
        handleRemoveFilter={handleRemoveFilter}
        handleSearch={handleSearch}
        rowKey="id"
        selectedRows={selectedRows}
        multiselect={multiselectMode && !isMergeMode}
        setExpanded={setListExpanded}
        setSelected={getOnSelectItem}
        title="Listagem de Marcas"
        protectedCrudAttributes={{
          protectCreate: !brandMetaData?.create ?? true,
        }}
        deletedStatusProps={{
          onChangeDeletedStatus: (deleted) =>
            handleModalSubmit({ ...filters, deleted }),
          value: filters.deleted,
          disabled: !brandMetaData?.editDeleted,
        }}
      />
    );
  };

  const handleCreateNewClick = () => {
    setWriting(false);
    setSelectedBrand(undefined);
    setTimeout(() => setWriting(true));
  };

  const handleSelectedBrand = (value: Partial<BrandListView>[]) => {
    setWriting(false);
    setSelectedRows(
      value
        .filter((brand) => brand.id)
        .map((brand) => brand as BrandListView)
        .map((brand) => brand.id)
    );
    if (value && value.length === 1 && value[0].id) {
      trackPromise(brandService.getBrandById(value[0].id)).then((brandById) => {
        setSelectedBrand(brandById);
        setWriting(true);
      });
    } else {
      setSelectedBrand(undefined);
      setWriting(false);
    }
  };

  const getOnSelectItem = (brands: BrandListView[]) => {
    if (isMergeMode) {
      return setSelectedItems(brands);
    }
    return handleSelectedBrand(brands);
  };

  const handleSelectedTab = (value: TabItem) => {
    setSelectedTab(value);
  };

  const handleSave = (brand: Brand) => {
    refreshList();
    const isCreating: boolean = !brand.manufacturer;
    if (isCreating) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setWriting(false);
    setSelectedRows([]);
    setSelectedBrand(undefined);
  };

  const onRemoveMergeItem = (remainingItems: BrandListView[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.id)
      .filter((itemId) =>
        remainingItems.some((remainItem) => remainItem.id === itemId)
      );
    setSelectedRows(newSelectedRows);
  };

  const getBrandTab = () => {
    if (!isWriting) {
      return <EmptySelection />;
    }
    return (
      <CreateBrand
        brand={selectedBrand}
        initialManufacturer={selectedBrand?.manufacturer}
        onSubmit={handleSave}
        onCancel={handleCancel}
      />
    );
  };

  const getSubBrandTab = () => {
    if (!selectedBrand || (!isWriting && !selectedBrand)) {
      return <EmptySelection />;
    }
    return <SubBrandTab brand={selectedBrand} />;
  };

  const getCreateComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<BrandListView>
          data={selectedItems}
          entityName="brand"
          getItemId={(brand) => brand.id}
          getLabeledItem={(brand) =>
            `${brand.id} ${brand.manufacturer.name} - ${brand.name}`
          }
          mergeItems={(mergeRequest) => {
            return brandService.merge(mergeRequest);
          }}
          afterMerge={refreshList}
          onCancel={() => {
            setIsMergeMode(false);
            setSelectedRows([]);
            setSelectedItems([]);
          }}
          onRemoveItem={onRemoveMergeItem}
        />
      );
    }
    return (
      <>
        <CrudCreateComponent
          handleSelectedTab={handleSelectedTab}
          isCreateExpanded={isCreateExpanded}
          tabItems={tabItems}
          selectedTab={selectedTab}
          setCreateExpanded={setCreateExpanded}
        />
        {selectedTab.value === "brand" && getBrandTab()}
        {selectedTab.value === "subBrand" && getSubBrandTab()}
        {selectedTab.value === "charts" && (
          <BrandCharts brand={selectedBrand} />
        )}
      </>
    );
  };

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getCreateComponent()}
        isCreateExpanded={isCreateExpanded}
        isListExpanded={isListExpanded}
      />
      <BrandsAdvancedFilters
        parentName="brands"
        originalFilters={filters}
        onClose={() => setAdvancedFilterOpen(false)}
        onConfirm={handleModalSubmit}
        visible={advancedFilterOpen}
        fixedStatus={!brandMetaData?.editDeleted}
      />
    </>
  );
};
