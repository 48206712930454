import { Button, message } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FilterLabel from "components/FilterLabel/FilterLabel";
import {
  FormItemBoolean,
  SelectBooleanValue,
} from "components/FormItem/FormItemBoolean";
import { FormItemMultipleInteger } from "components/FormItem/FormItemMultipleInteger";
import { FormItemSelect } from "components/FormItem/FormItemSelect";
import { FormItemText } from "components/FormItem/FormItemText";
import { FormItemWrapper } from "components/FormItem/FormItemWrapper";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import OptionData from "shared/models/OptionData";
import { brandService } from "shared/services/BrandService";
import { manufacturerService } from "shared/services/ManufacturerService";
import { scrollFunction } from "shared/utils/ScrollUtils";
import { handleFilterChange } from "util/filter.util";
import styles from "./ProductLineComponents.module.scss";

export interface FixedProductLineFilter {
  fixedActive?: boolean;
  sanitation?: string;
}

export class ProductLinesFilters {
  brandId?: number;
  deleted?: SelectBooleanValue;
  manufacturerId?: number;
  productLineId?: number[];
  productLineSanitation?: string;
  term?: string;
}

interface ProductLinesAdvancedFiltersProps {
  onClose: () => void;
  onConfirm: (filters: ProductLinesFilters) => void;
  originalFilters: ProductLinesFilters;
  visible: boolean;
  parentName: string; // Helps defining unique ids since this component can be reused
  fixedProductLineFilters?: Readonly<ProductLinesFilters>;
}

export const ProductLinesAdvancedFilters = ({
  onClose,
  onConfirm,
  originalFilters,
  visible,
  parentName,
  fixedProductLineFilters,
}: ProductLinesAdvancedFiltersProps) => {
  const [filters, setFilters] = useState<ProductLinesFilters>(originalFilters);

  const [manufacturersOptions, setManufacturersOptions] = useState<
    OptionData[]
  >([]);
  const [manufacturersPage, setManufacturersPage] = useState(0);
  const [manufacturersLast, setManufacturersLast] = useState(true);
  const [manufacturersTerm, setManufacturersTerm] = useState("");

  const [brandsOptions, setBrandsOptions] = useState<OptionData[]>([]);
  const [brandsPage, setBrandsPage] = useState(0);
  const [brandsLast, setBrandsLast] = useState(true);
  const [brandsTerm, setBrandsTerm] = useState("");

  const handleSearchManufacturers = (page: number, term: string) => {
    trackPromise(
      manufacturerService.getManufacturers(page, { term, deleted: "false" })
    )
      .then((response) => {
        setManufacturersOptions((oldOptions) => [
          ...(page > 0 ? oldOptions : []),
          ...response.content.map((it) => ({
            label: it.name,
            value: it.id,
          })),
        ]);
        setManufacturersPage(page);
        setManufacturersLast(response.last);
        setManufacturersTerm(term);
      })
      .catch(() => message.error("Erro ao buscar fabricantes"));
  };

  const handleSearchBrands = (page: number, term: string) => {
    trackPromise(brandService.getBrands(page, { term, deleted: "false" }))
      .then((response) => {
        setBrandsOptions((oldOptions) => [
          ...(page > 0 ? oldOptions : []),
          ...response.content.map((it) => ({
            label: it.name,
            value: it.id,
          })),
        ]);
        setBrandsPage(page);
        setBrandsLast(response.last);
        setBrandsTerm(term);
      })
      .catch(() => message.error("Erro ao buscar marcas"));
  };

  const debounceSearchBrands = debounce((term: string) => {
    handleSearchBrands(0, term);
  }, 600);

  const debounceSearchManufacturers = debounce((term: string) => {
    handleSearchManufacturers(0, term);
  }, 600);

  const loadMoreManufacturers = () => {
    if (!manufacturersLast) {
      handleSearchManufacturers(manufacturersPage + 1, manufacturersTerm);
    }
  };

  const loadMoreBrands = () => {
    if (!brandsLast) {
      handleSearchBrands(brandsPage + 1, brandsTerm);
    }
  };

  const handleConfirmClick = () => {
    onConfirm(filters);
  };

  useEffect(() => setFilters({ ...originalFilters }), [originalFilters]);

  return (
    <FormItemWrapper
      title="Filtros avançados para linhas"
      visible={visible}
      submit={handleConfirmClick}
      onCancel={() => {
        setFilters({ ...originalFilters });
        onClose();
      }}
    >
      <FormItemMultipleInteger
        id={`${parentName}-product-line-filter-brand-id`}
        label="IDs Linha"
        value={filters.productLineId}
        onChange={(value) =>
          handleFilterChange("productLineId", value, setFilters)
        }
      />
      <FormItemSelect
        id={`${parentName}-product-line-filter-manufacturer-id`}
        label="Fabricante"
        onChange={(value) =>
          handleFilterChange("manufacturerId", value, setFilters)
        }
        onSearch={debounceSearchManufacturers}
        options={manufacturersOptions}
        value={filters.manufacturerId}
        onPopupScroll={(evt) => scrollFunction(evt, loadMoreManufacturers)}
      />
      <FormItemSelect
        id={`${parentName}-filter-product-line-brand-id`}
        label="Marca"
        onChange={(value) => handleFilterChange("brandId", value, setFilters)}
        onSearch={debounceSearchBrands}
        options={brandsOptions}
        value={filters.brandId}
        onPopupScroll={(evt) => scrollFunction(evt, loadMoreBrands)}
      />
      <FormItemBoolean
        label="Status"
        id={`${parentName}-product-line-filters-deleted`}
        onChange={(deleted) => setFilters({ ...filters, deleted })}
        textForFalse="Ativo"
        textForTrue="Inativo"
        value={filters.deleted}
        disabled={fixedProductLineFilters?.deleted !== undefined}
      />
      <FormItem label={<FilterLabel text="Saneamento" htmlFor="saneamento" />}>
        <div id="saneamento" style={{ marginTop: "-4%" }}>
          <SanitationSelect
            onChange={(productLineSanitation) =>
              setFilters({ ...filters, productLineSanitation })
            }
            value={filters.productLineSanitation}
            disabled={
              fixedProductLineFilters?.productLineSanitation !== undefined
            }
          />
        </div>
      </FormItem>
      <FormItemText
        id={`${parentName}-product-line-filter-term`}
        label="Termo genérico"
        onChange={(e) => handleFilterChange("term", e, setFilters)}
        value={filters.term}
      />
      <div className={styles.cleanBtn}>
        <Button
          onClick={() =>
            setFilters(fixedProductLineFilters || new ProductLinesFilters())
          }
          type="link"
        >
          Limpar filtros
        </Button>
      </div>
    </FormItemWrapper>
  );
};
