import * as React from "react";

interface TabArrowProps {
  backgroundColor?: string;
}

export function TabArrow({ backgroundColor = "#619cf8" }: TabArrowProps) {
  return (
    <svg width={25.061} height={48.707} viewBox="0 0 25.061 48.707">
      <g data-name="Group 155">
        <path
          d="M.359.354l24 24-24 24z"
          data-name="Path 6"
          fill={backgroundColor}
        />
        <path
          d="M.359.354l24 24-24 24"
          data-name="Path 6"
          fill="none"
          stroke="#ebedf1"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  );
}
