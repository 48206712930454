import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { map as _map } from "lodash";
import { Icons } from "components/Icons";
import { getItemStyle, getListStyle } from "shared/utils/DraggableListUtils";

const reorder = (
  list: DraggableListItem[],
  startIndex: number,
  endIndex: number
) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return _map(result, (item, idx) => ({ ...item, order: idx + 1 }));
};

export interface DraggableListItem {
  key?: string | number;
  title: string;
  order: number;
}

interface DraggableListProps {
  items: DraggableListItem[];
  onReorder: (result: any) => void;
}

export const DraggableList = ({ items, onReorder }: DraggableListProps) => {
  const [stateItems, setItems] = useState(items);
  const [hovering, setHovering] = useState<number>();

  useEffect(() => {
    setItems(items);
  }, [items]);

  useEffect(() => {
    onReorder(stateItems);
  }, [stateItems, onReorder]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reordered = reorder(
      stateItems,
      result.source.index,
      result.destination.index
    );
    setItems(reordered);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {stateItems.map((item, index) => (
              <Draggable
                key={item.key}
                draggableId={item.order.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    onMouseEnter={() => setHovering(item.order)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    Coluna #{String(index).padStart(2, "0")}: {item.title}
                    {hovering === item.order && <Icons name="dragOutlined" />}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
