import { Form, Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React from "react";

interface FormItemTextAreaProps extends TextAreaProps {
  id: string;
  label: string;
  name?: string;
}

const { TextArea } = Input;

export const FormItemTextArea = ({
  id,
  label,
  name,
  ...rest
}: FormItemTextAreaProps) => (
  <Form.Item label={<FilterLabel text={label} htmlFor={id} />} name={name}>
    <TextArea allowClear id={id} {...rest} />
  </Form.Item>
);
