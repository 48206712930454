import React from "react";
import { map as _map } from "lodash";
import { Dropdown, Menu } from "antd";
import { Icons } from "components/Icons";
import styles from "./AdvancedOptions.module.scss";

interface AdvancedOptionsProps {
  children: React.ReactElement;
  className?: string;
  options: Option[];
  trigger?: ["hover"] | ["click"] | ["hover", "click"];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface Option {
  icon?: string;
  title: string;
  action: (event: React.MouseEvent<HTMLElement>) => void;
}

const generateOverlayOptions = (options: Option[]) => {
  return (
    <Menu>
      {_map(options, (option, idx) => (
        <Menu.Item key={idx} className={styles.menuItem}>
          <button
            onClick={(event) => option.action(event)}
            style={{ width: "100%" }}
          >
            {option.icon && <Icons name={option.icon} />}
            <span> {option.title}</span>
          </button>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const AdvancedOptions = ({
  onClick,
  className,
  children,
  options,
  trigger = ["hover"],
}: AdvancedOptionsProps) => {
  return (
    <span
      onClick={(event) => {
        if (typeof onClick === "function") onClick(event);
      }}
    >
      <Dropdown
        className={className}
        overlay={generateOverlayOptions(options)}
        overlayClassName={styles.dropdown}
        trigger={trigger}
      >
        {children}
      </Dropdown>
    </span>
  );
};
