import { Divider, message } from "antd";
import { ImageUploadDragger } from "components/ImageUploadDragger/ImageUploadDragger";
import { Input } from "components/Input";
import { PixTextArea } from "components/PixTextArea";
import { SanitationSelect } from "components/SanitationSelect/SanitationSelect";
import { SimilarityDisplay } from "components/SimilarityDisplay/SimilarityDisplay";
import { YehImage } from "components/YehImage/YehImage";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Manufacturer, ManufacturerRequest } from "shared/models/Manufacturer";
import { ManufacturerMetadata } from "shared/models/User";
import { manufacturerService } from "shared/services/ManufacturerService";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { utcToPtBrDate } from "util/date.util";
import styles from "./ManufacturerDetail.module.scss";

interface ManufacturerDetailProps {
  manufacturer?: Manufacturer;
  isWriting: boolean;
  onSubmit: (manufacturerRequest: ManufacturerRequest) => void;
  onCancel: () => void;
}

export const ManufacturerDetail = ({
  manufacturer,
  onCancel,
  onSubmit,
}: ManufacturerDetailProps) => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [site, setSite] = useState("");
  const [description, setDescription] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [imageFile, setImageFile] = useState<any>();
  const [
    manufacturerSanitation,
    setManufacturerSanitation,
  ] = useState<string>();
  const [similarManufacturers, setSimilarManufacturers] = useState<string[]>(
    []
  );
  const [
    loadingSimilarManufacturers,
    setLoadingSimilarManufacturers,
  ] = useState(false);
  const [manufacturerMetaData] = useState<ManufacturerMetadata | null>(
    getEntityPermissions<ManufacturerMetadata>("manufacturer")
  );

  useEffect(() => {
    setName(manufacturer?.name || "");
    setShortName(manufacturer?.shortName || "");
    setSite(manufacturer?.site || "");
    setDescription(manufacturer?.description || "");
    setDeleted(!!manufacturer?.deleted);
    setManufacturerSanitation(manufacturer?.manufacturerSanitation);
  }, [manufacturer]);

  const handleSubmit = (evt?: SyntheticEvent) => {
    evt?.preventDefault();

    onSubmit({
      manufacturer: {
        name,
        shortName,
        description,
        site,
        deleted,
        manufacturerSanitation,
        id: manufacturer?.id,
        mainAsset: manufacturer?.mainAsset,
      },
      file: imageFile,
    });
  };

  const onBlurManufacturerName = (value: string) => {
    setName(value);

    value ? findSimilarManufacturerName(value) : setSimilarManufacturers([]);
  };

  const findSimilarManufacturerName = (value: string) => {
    setLoadingSimilarManufacturers(true);
    manufacturerService
      .getManufacturers(0, { term: value })
      .then(({ content }) => {
        setSimilarManufacturers(
          content.map(
            (manufacturer) => `${manufacturer.id} - ${manufacturer.name}`
          )
        );
      })
      .catch(() => message.error("Erro ao buscar Fabricantes similares"))
      .finally(() => setLoadingSimilarManufacturers(false));
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <Input
          label="Razão Social"
          placeholder="Fabricante"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => onBlurManufacturerName(e.target.value)}
          required
        />
        <SimilarityDisplay
          values={similarManufacturers}
          loading={loadingSimilarManufacturers}
          entityNameToDisplay="Fabricantes"
        />
        <Input
          label="Nome Fantasia"
          placeholder="Nome Fantasia"
          value={shortName}
          onChange={(evt) => setShortName(evt.target.value)}
          required
        />
        <PixTextArea
          required
          label="Comentários"
          placeholder="Comentários"
          maxLength={300}
          autoSize={{ minRows: 4, maxRows: 6 }}
          onChange={(evt) => setDescription(evt.target.value)}
          value={description}
        />
        <Input
          label="Site"
          placeholder="Site"
          value={site}
          onChange={(evt) => setSite(evt.target.value)}
        />
        <SanitationSelect
          value={manufacturerSanitation}
          onChange={setManufacturerSanitation}
          label="Saneamento"
        />
        {!!manufacturer && (
          <YehImage
            imageUrl={
              imageFile ? URL.createObjectURL(imageFile) : manufacturer.imageUrl
            }
            description={manufacturer.name}
            maxWidth={"60%"}
          />
        )}
        <ImageUploadDragger setImageFile={setImageFile} />
        {!!manufacturer && (
          <>
            <Input
              name="lastModified"
              id="manufacturer-last-modified"
              label="Última atualização"
              placeholder="Última atualização"
              value={utcToPtBrDate(manufacturer.lastModified)}
              disabled
            />
            <Divider />
            <YehSwitch
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
              checked={!deleted}
              onChange={(f) => setDeleted(!f)}
            />
          </>
        )}
        <CancelAndSave
          onCancel={onCancel}
          showConfirm={!!manufacturer && deleted !== manufacturer.deleted}
          showConfirmTitle={`O fabricante está ${
            deleted ? "ativo" : "inativo"
          }. Tem certeza de que deseja ${deleted ? "inativar" : "ativar"}?`}
          onConfirm={handleSubmit}
          disabled={!manufacturerMetaData?.update}
        />
      </form>
    </div>
  );
};
