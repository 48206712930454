import { CheckCircleFilled } from "@ant-design/icons";
import { Alert, Row, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import { Icons } from "components/Icons";
import { PixSelectAttributeValue } from "containers/PixSelectAttributeValue";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductCreateEntityAttribute } from "shared/models/Characteristic";
import { characteristicService } from "shared/services/CharacteristicService";
import { productLineService } from "shared/services/ProductLineService";

type ProductLineAttributeSelectorProps = {
  productLineId: number;
  onChangeEntityAttributes: (
    entityAttributes: ProductCreateEntityAttribute[]
  ) => void;
};

export const ProductLineAttributeSelector = ({
  productLineId,
  onChangeEntityAttributes,
}: ProductLineAttributeSelectorProps) => {
  const [entityAttributes, setEntityAttributes] = useState<
    ProductCreateEntityAttribute[]
  >([]);

  useEffect(() => {
    setEntityAttributes([]);

    trackPromise(productLineService.getProductLineById(productLineId)).then(
      (productLine) => {
        const primaryAttributes = productLine.attributes?.PRIMARY ?? [];

        setEntityAttributes(
          primaryAttributes.map((att) => {
            return { attribute: att };
          })
        );
      }
    );
  }, [productLineId]);

  useEffect(() => {
    onChangeEntityAttributes(
      entityAttributes.filter((ea) => ea.entityAttributeId)
    );
  }, [entityAttributes, onChangeEntityAttributes]);

  const onSelectAttributeValue = (
    entityAttribute: ProductCreateEntityAttribute,
    value: number,
    option: any
  ) => {
    const attributeId = entityAttribute.attribute.id;

    if (!Number.isInteger(value)) {
      return updateEntityAttributeId(attributeId);
    }

    if (!value) {
      return saveNewEntityAttribute(attributeId, option.key);
    } else {
      updateEntityAttributeId(attributeId, value, option.children);
    }
  };

  const saveNewEntityAttribute = (
    attributeId: number,
    attributeValueDescription: string
  ) => {
    trackPromise(
      characteristicService.saveValue(attributeId, {
        description: attributeValueDescription,
        shortDescription: attributeValueDescription,
      })
    ).then((newEntityAttribute) => {
      updateEntityAttributeId(
        attributeId,
        newEntityAttribute.id,
        attributeValueDescription
      );
    });
  };

  const updateEntityAttributeId = (
    attributeId: number,
    newEntityAttributeId?: number,
    attributeValue?: string
  ) => {
    setEntityAttributes([
      ...entityAttributes.map((ea) => {
        if (ea.attribute.id === attributeId) {
          ea.entityAttributeId = newEntityAttributeId;
          ea.attributeValue = attributeValue;
        }
        return ea;
      }),
    ]);
  };

  return (
    <div id="product-line-attribute-selects">
      {entityAttributes.length ? (
        entityAttributes.map((entityAttribute) => (
          <div
            style={{ padding: "15px" }}
            key={`ea-a-${entityAttribute.attribute.id}`}
          >
            <div>
              <Row>
                <Text> {entityAttribute.attribute.description}</Text>

                {entityAttribute.attribute.suggested && (
                  <Tooltip title="Característica sugerida para preenchimento, conforme a Linha selecionada.">
                    <CheckCircleFilled
                      style={{
                        color: "green",
                        marginTop: "2px",
                        paddingLeft: "5px",
                      }}
                    />
                  </Tooltip>
                )}
              </Row>

              {!!entityAttribute.entityAttributeId && (
                <Icons
                  name="checkCircleFilled"
                  style={{ color: "green", height: "80%", marginLeft: "10px" }}
                />
              )}
            </div>
            <PixSelectAttributeValue
              attributeId={entityAttribute.attribute.id}
              onChangeValue={(value, option) =>
                onSelectAttributeValue(entityAttribute, value, option)
              }
            />
          </div>
        ))
      ) : (
        <Alert
          message="A linha selecionada não tem nenhuma característica vinculada"
          type="info"
          showIcon
        />
      )}
    </div>
  );
};
