import { message } from "antd";
import EmptySelection from "components/EmptySelection/EmptySelection";
import { VisualData } from "containers/VisualData/VisualData";
import React, { useCallback, useEffect, useState } from "react";
import { Word } from "react-wordcloud";
import { Manufacturer } from "shared/models/Manufacturer";
import { manufacturerService } from "shared/services/ManufacturerService";

type ManufacturerChartsProps = {
  manufacturer?: Manufacturer;
};

export const ManufacturerCharts = ({
  manufacturer,
}: ManufacturerChartsProps) => {
  const [wordcloudData, setWordcloudData] = useState<Word[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchWordcloudData = useCallback(
    (manufacturer?: Manufacturer, mode?: boolean) => {
      if (!manufacturer) {
        return null;
      }
      setLoading(true);
      manufacturerService
        .getWordCloudIndicators(manufacturer.id, mode)
        .then(setWordcloudData)
        .catch(() =>
          message.error("Erro ao efetuar busca de Nuvem de Palavras")
        )
        .finally(() => setLoading(false));
    },
    []
  );

  useEffect(() => {
    if (manufacturer?.id) {
      fetchWordcloudData(manufacturer, true);
    }
  }, [manufacturer, fetchWordcloudData]);

  return !manufacturer ? (
    <EmptySelection />
  ) : (
    <VisualData
      disableButtons={!manufacturer}
      isLoadingWordcloud={loading}
      wordCloudValues={wordcloudData}
    />
  );
};
