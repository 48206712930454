import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { AttributeValueWrapper } from "./AttributeEdit";
import { AttributeSelectEdit } from "./AttributeSelectEdit";

interface AttributeEditGroupProps {
  attributes: AttributeValueWrapper[];
  handleChange: (attributeId: number, value: string) => void;
  reorderAttributes: (a: AttributeValueWrapper[]) => void;
}

const reorder = (
  list: AttributeValueWrapper[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const AttributeEditGroup = ({
  attributes,
  handleChange,
  reorderAttributes,
}: AttributeEditGroupProps) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      attributes,
      result.source.index,
      result.destination.index
    );
    reorderAttributes(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {attributes.map((att, idx) => (
              <Draggable
                key={att.attributeId}
                draggableId={att.attributeId + ""}
                index={idx}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <AttributeSelectEdit
                      attribute={att}
                      handleChange={handleChange}
                      remove={() => {
                        reorderAttributes(
                          attributes.filter(
                            (a) => a.attributeId !== att.attributeId
                          )
                        );
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
