import React from "react";
import styles from "./PixTextArea.module.scss";

import _omit from "lodash/omit";
import cx from "classnames";

import { Input as AntdInput } from "antd";
import { TextAreaProps } from "antd/lib/input";

export interface PixTextAreaProps extends TextAreaProps {
  label?: string;
  error?: string | null;
  viewOnly?: boolean;
  maxLength?: number;
  value: string;
}

export const PixTextArea = (props: PixTextAreaProps) => {
  const {
    label,
    error,
    disabled,
    viewOnly,
    maxLength,
    value,
    className,
  } = props;
  const passableProps = _omit(props, ["viewOnly"]);
  const textareaStyle = cx(styles.input, {
    [styles.inputError]: error,
    [styles.viewOnly]: viewOnly,
  });
  return (
    <div className={cx(className, styles.wrapper)}>
      {label && <p className={styles.label}>{label}</p>}
      <AntdInput.TextArea
        {...passableProps}
        maxLength={maxLength}
        className={textareaStyle}
        disabled={disabled || viewOnly}
        value={value}
      />
      {maxLength && (
        <p className={styles.counter}>{value.length + "/" + maxLength}</p>
      )}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
