import React, { ReactNode, useState } from "react";
import styles from "./Container.module.scss";

import { map as _map } from "lodash";

import { Breadcrumb } from "antd";

import {
  PixTaxonomyGlobalSelect,
  TaxonomyBranchWithDisplayValue,
} from "containers/PixTaxonomyGlobalSelect";
import { TaxonomyBranch } from "shared/models/Taxonomy";

interface ContainerProps {
  content: ReactNode;
  breadcrumbItems: any[];
  fetchSelectItem: (item?: TaxonomyBranch[]) => void;
}

export const Container = ({
  content,
  breadcrumbItems,
  fetchSelectItem,
}: ContainerProps) => {
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<TaxonomyBranchWithDisplayValue>();

  const onChangeValue = (value: TaxonomyBranchWithDisplayValue) => {
    setSelectedItem(value);
    fetchSelectItem(value.branches);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.userNavigation}>
          <div className={styles.globalSearch}>
            <PixTaxonomyGlobalSelect
              onChange={onChangeValue}
              selected={selectedItem}
            />
          </div>

          <Breadcrumb className={styles.breadcrumb}>
            {_map(breadcrumbItems, (item, index) => (
              <Breadcrumb.Item key={`${item.title}${index}`}>
                {item.title}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        {/* <div className={styles.expandIcon}>
          <button>
            <Icons name="fullscreenOutlined" />
          </button>
        </div> */}
      </div>
      {content}
    </div>
  );
};
