import React from "react";
import styles from "./StatisticsModal.module.scss";

import { join, map } from "lodash";
import { Modal } from "antd";
import ReactWordcloud, { Word } from "react-wordcloud";

import {
  DEFAULT_WORDCLOUD_OPTIONS,
  getWordMaxAndMedian,
} from "components/WordCloud";
import {
  AssociationStatistics,
  AssociationValueAndTotal,
} from "shared/models/Association";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onWordClick: (value: Word) => void;
  searchTerms?: string[];
  statistics?: AssociationStatistics;
}

export const StatisticsModal = ({
  isOpen,
  onClose,
  onWordClick,
  searchTerms,
  statistics,
}: Props) => {
  const mapToWord = (items: AssociationValueAndTotal[]): Word[] => {
    return map(items, (item) => ({ text: item.value, value: item.total }));
  };

  const words: Word[] = !statistics
    ? []
    : mapToWord(statistics.mostFrequentlyWords);
  const { maxValue, medianValue } = getWordMaxAndMedian(words);

  const wordCallbacks = {
    onWordClick,
    getWordColor: (word: Word) =>
      word.value === maxValue
        ? "rgb(50, 50, 93)"
        : word.value > medianValue
        ? "rgb(87, 87, 150)"
        : "rgb(124, 124, 212)",
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        destroyOnClose
        footer={null}
        onCancel={() => onClose && onClose()}
        title="Estatísticas de busca"
        visible={isOpen}
        width="620px"
      >
        <div className={styles.contentWrapper}>
          {searchTerms && (
            <p>
              <b>Termos de busca:</b> {join(searchTerms, " , ")}
            </p>
          )}
          {statistics && (
            <>
              <p>
                <b>Total de itens encontrados:</b> {statistics.totalResults}
              </p>
              <p>
                <b>
                  Total de itens de transação que possuem exatamente os filtros
                  :
                </b>{" "}
                {statistics.totalTermMatching}
              </p>
              <p>
                <b>Palavras mais frequentes:</b>
              </p>
              <ReactWordcloud
                size={[600, 300]}
                options={DEFAULT_WORDCLOUD_OPTIONS}
                words={words}
                callbacks={wordCallbacks}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
