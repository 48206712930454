import { VisualData } from "containers/VisualData/VisualData";

import React, { useCallback, useEffect, useState } from "react";
import { Word } from "react-wordcloud";
import { ProductLine } from "shared/models/ProductLine";
import { productLineService } from "shared/services/ProductLineService";
import { getErrorMessage } from "shared/utils/ResponseUtils";

export type ProductLineCharts = {
  productLine?: ProductLine;
};

export const ProductLineCharts = ({ productLine }: ProductLineCharts) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [wordcloudData, setWordcloudData] = useState([] as Word[]);

  const fetchSankeyData = useCallback(async () => {
    if (!productLine) {
      return null;
    }
    await productLineService
      .getSankeyIndicators(productLine.id)
      .then((sankeyResult) => {
        setChartData([
          ["From", "To", "Weight"],
          ...sankeyResult?.map((item) => [
            item.source,
            item.target,
            item.value,
          ]),
        ]);
      })
      .catch(() => {
        getErrorMessage("Erro ao efetuar busca de dados para Sankey.");
        setChartData([]);
      });
  }, [productLine]);

  const fetchWordcloudData = useCallback(
    async (mode?: boolean) => {
      if (!productLine) {
        return null;
      }

      await productLineService
        .getWordCloudIndicators(productLine.id, mode)
        .then((res) => {
          setWordcloudData(res);
        })
        .catch(() => {
          getErrorMessage("Erro ao efetuar busca de Nuvem de Palavras.");
          setWordcloudData([] as Word[]);
        });
    },
    [productLine]
  );

  useEffect(() => {
    fetchSankeyData();
    fetchWordcloudData();
  }, [productLine, fetchSankeyData, fetchWordcloudData]);

  return (
    <VisualData
      chartData={chartData}
      chartType="Sankey"
      selected={!!productLine}
      wordCloudValues={wordcloudData}
    />
  );
};
