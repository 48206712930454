import { Badge, Button, Col, Popover, Row } from "antd";
import { Icons } from "components/Icons";
import { ItemSuggestion } from "components/ItemSugestion";
import {
  PixColumn,
  PixInfinityTable,
} from "components/PixInfinityTable/PixInfinityTable";
import { orderBy as _orderBy } from "lodash";
import React, {
  forwardRef,
  ReactText,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { trackPromise } from "react-promise-tracker";
import {
  AssociationDataTypeSelect,
  AssociationDetail,
  AssociationEntityType,
  AssociationFilterBase,
  AssociationStatistics,
  SuggestionDataScience,
  Transaction,
} from "shared/models/Association";
import { GtinCodeSimpleDetail } from "shared/models/GtinCode";
import { associationService } from "shared/services/AssociationService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import styles from "./CapturesList.module.scss";
import { codeTag } from "./CodeTag";
import { ColumnSelector } from "./ColumnSelector";

const REASON_ASSOCIATION_USER_ACCEPTED =
  "Usuário aceitou sugestão de associação";

interface Props {
  filters: AssociationFilterBase;
  onSelectItems: (items: Transaction[]) => void;
  setDataStatistics: (item: AssociationStatistics | undefined) => void;
  addValueToFilter: (item: string) => void;
  associationDataTypeSelected: AssociationDataTypeSelect;
  associateItems: (items: Transaction[], target: any, reason: string) => void;
}

const orderColumns = (
  columns: PixColumn<Transaction>[]
): PixColumn<Transaction>[] => {
  return _orderBy(columns, "order");
};

const getInfo = (detail: AssociationDetail) => {
  if (!detail || !detail.internalSku) {
    return <span>Cliente: N/A</span>;
  }
  const content = (
    <div>
      <p>{detail.internalSku.emitterCompanyDescription}</p>
    </div>
  );
  return (
    <Popover placement="top" title="Descrição" content={content}>
      <span>{detail.internalSku.emitterCompanyCode}</span>
    </Popover>
  );
};

const detailColumns = [
  {
    key: "col_gtin",
    dataIndex: "codes",
    render: (_: string, detail: AssociationDetail) => codeTag(detail),
    width: 120,
  },
  {
    key: "col_cnpj",
    dataIndex: ["customer", "code"],
    ellipsis: true,
    render: (_: string, detail: AssociationDetail) => getInfo(detail),
    width: 130,
  },
];

export const CapturesList = forwardRef(
  (
    {
      filters,
      onSelectItems,
      setDataStatistics,
      addValueToFilter,
      associationDataTypeSelected,
      associateItems,
    }: Props,
    ref: any
  ) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<ReactText[]>([]);
    const [isColumnSelectorOpen, setColumnSelectorOpen] = useState(false);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [dataTermOperatorHash, setTermOperatorHash] = useState<string>();
    const [last, setLast] = useState(true);
    const [page, setPage] = useState(0);

    useImperativeHandle(ref, () => ({
      fetchData() {
        fetchTransactionData(
          page,
          filters,
          associationDataTypeSelected,
          "STANDARD_PRODUCT"
        );
      },
      getOperatorHash() {
        return dataTermOperatorHash;
      },
      getAssociationType() {
        return associationDataTypeSelected;
      },
    }));

    const getValueAndTotalListComponent = (
      title: string,
      items?: GtinCodeSimpleDetail[]
    ) => {
      const popoverContent = (
        <div style={{ width: "600px" }}>
          <Row gutter={[8, 8]}>
            {items?.map((item, idx) => (
              <Col span={8} key={idx}>
                <div
                  className={styles.gtincode}
                  onClick={() => {
                    addValueToFilter(item.gtin);
                  }}
                >
                  {item.gtin}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      );
      return (
        <Popover content={popoverContent} title={title}>
          <Badge
            overflowCount={100}
            count={!items ? 0 : items.length}
            showZero={true}
            style={{
              backgroundColor: items && items.length > 1 ? "red" : "green",
            }}
          />
        </Popover>
      );
    };

    const callAssociation = (entityId: number, item: Transaction) => {
      // TODO consider line id and brand id
      associateItems(
        [item],
        { id: entityId },
        REASON_ASSOCIATION_USER_ACCEPTED
      );
    };

    const columns = [
      {
        key: "col_description",
        title: "Descrição",
        dataIndex: "description",
        width: 200,
        order: 2,
        render: (_: string, item: Transaction) => item.description,
      },
      {
        key: "col_gtins",
        dataIndex: "gtins",
        render: (_: string[], item: Transaction) =>
          getValueAndTotalListComponent("GTINS", item.gtins),
        title: "GTINS",
        width: 62,
        order: 3,
      },
      {
        key: "col_gtins",
        dataIndex: "totalInternalSkus",
        render: (_: string, transaction: Transaction) => (
          <Badge
            count={transaction.totalInternalSkus}
            showZero
            style={{ backgroundColor: "rgb(196, 196, 196)" }}
          />
        ),
        title: "CÓD. CLIENTE",
        width: 62,
        order: 5,
      },
      {
        key: "col_cnpj",
        dataIndex: "companyCustomerCodes",
        render: (_: string, transaction: Transaction) => (
          <Badge
            count={
              transaction.totalEmitters + (transaction.totalReceivers || 0)
            }
            showZero
            style={{ backgroundColor: "rgb(196, 196, 196)" }}
          />
        ),
        title: "CNPJ Emitente",
        width: 62,
        order: 5,
      },
      {
        key: "col_bu",
        title: "BUs",
        dataIndex: "customersBu",
        width: 100,
        order: 6,
        render: (_: string[], item: Transaction) => item.bus.join(", "),
      },
      {
        key: "col_suggestion",
        title: "Sugestão",
        dataIndex: "suggestion",
        width: 30,
        ellipsis: true,
        order: 7,
        render: (value: SuggestionDataScience[], item: Transaction) => {
          // TODO: remover return abaixo e voltar return do comentário quando liberar associações parciais
          return value != null &&
            value.some((sug) => sug.entityType === "STANDARD_PRODUCT") ? (
            // return value ? (
            <ItemSuggestion
              data={value}
              onOk={(entityId) => callAssociation(entityId, item)}
            >
              <Icons
                className={styles.iconInfoSuggestion}
                name="infoCircleOutlined"
              />
            </ItemSuggestion>
          ) : (
            ""
          );
        },
      },
    ];

    const colAction: PixColumn<Transaction> = {
      fixed: "right",
      key: "col_actions",
      title: () => (
        <Button
          type="link"
          icon={<Icons name="insertRowLeftOutlined" />}
          onClick={() => setColumnSelectorOpen((oldStatus) => !oldStatus)}
        />
      ),
      width: 40,
      notResizeable: true,
    };

    const formatColumnsWithOrder = (columns: PixColumn<Transaction>[]) => {
      const columnsWithOrder = columns.map((column, index) => ({
        ...column,
        order: index,
      }));
      return columnsWithOrder;
    };

    const orderedColumns = formatColumnsWithOrder(columns);

    const [currentColumns, setCurrentColumns] = useState([
      ...orderColumns(orderedColumns),
    ]);

    const handleSelectedColumns = (values: PixColumn<Transaction>[]) => {
      setCurrentColumns(values);
    };

    const clearSearch = useCallback(() => {
      setDataStatistics(undefined);
      setTermOperatorHash(undefined);
      setTransactions([]);
    }, [setDataStatistics]);

    const fetchTransactionData = useCallback(
      (
        page: number,
        filters: AssociationFilterBase,
        associationDataTypeSelected: AssociationDataTypeSelect,
        selectedTab: AssociationEntityType
      ) => {
        const {
          term,
          emitterCompanyCodes,
          receiverCompanyCodes,
          skus,
        } = filters;
        if (
          !term.length &&
          !receiverCompanyCodes.length &&
          !emitterCompanyCodes.length &&
          !skus.length
        ) {
          clearSearch();
          return;
        }
        trackPromise(
          associationService.getNotAssociatedTransactions(
            filters,
            associationDataTypeSelected.value,
            page,
            selectedTab
          )
        )
          .then((result) => {
            setLast(result.last);
            setTransactions((oldTransactions) => [
              ...(page === 0 ? [] : oldTransactions),
              ...result.content,
            ]);
            setPage(page);

            // We clear the selected items if it is the first page
            if (page === 0) {
              onSelectItems([]);
            }
          })
          .catch((error) => getErrorMessage("Erro ao buscar capturas."));
      },
      [clearSearch, onSelectItems]
    );

    const fetchTransactionDetailData = (
      record: Transaction,
      pageNumber: number
    ) => {
      const { descriptionId } = record;
      trackPromise(
        associationService
          .getAssociationDetail(
            descriptionId,
            filters,
            associationDataTypeSelected.value,
            pageNumber
          )
          .then((result) => {
            record.page = pageNumber;
            record.last = result.last;

            setTransactions((oldTransactions) => {
              const newTransactions = [...oldTransactions];
              const filtered = newTransactions.filter(
                (item) => item.descriptionId === descriptionId
              );
              if (filtered && filtered.length) {
                filtered[0].last = result.last;
                filtered[0].detail =
                  pageNumber === 0
                    ? result.content
                    : [...(filtered[0].detail || []), ...result.content];
              }
              return newTransactions;
            });
          })
          .catch((error) =>
            getErrorMessage("Erro ao buscar detalhe de captura.")
          )
      );
    };

    useEffect(() => {
      fetchTransactionData(
        0,
        filters,
        associationDataTypeSelected,
        "STANDARD_PRODUCT"
      );
    }, [filters, fetchTransactionData, associationDataTypeSelected]);

    const handleExpandRow = (expanded: boolean, record: Transaction) => {
      if (expanded && (!record.detail || !record.detail?.length)) {
        fetchTransactionDetailData(record, 0);
      }
    };

    const fetchMoreDetailData = (record: Transaction) => {
      fetchTransactionDetailData(record, (record.page || 0) + 1);
    };

    const getDetail = (record: Transaction) => {
      if (!record.detail) {
        return <>N/A</>;
      }
      return (
        <div className={styles.subtable}>
          <PixInfinityTable<AssociationDetail>
            className={styles.detailTable}
            dataSource={record.detail}
            columns={detailColumns}
            fetchMoreData={() => fetchMoreDetailData(record)}
            last={!!record.last}
            notSelectable={true}
            rowKey="transactionItemHash"
          />
        </div>
      );
    };

    return (
      <>
        <PixInfinityTable<Transaction>
          className={styles.capturesTable}
          columns={[...currentColumns, colAction]}
          dataSource={transactions}
          expandable={{
            expandIconColumnIndex: 1,
            expandedRowRender: getDetail,
            expandedRowKeys,
            indentSize: 0,
            onExpand: handleExpandRow,
            onExpandedRowsChange: (keys) => setExpandedRowKeys([...keys]),
            rowExpandable: (record) =>
              record.totalGtins + record.totalInternalSkus > 0,
          }}
          fetchMoreData={() =>
            fetchTransactionData(
              page + 1,
              filters,
              associationDataTypeSelected,
              "STANDARD_PRODUCT"
            )
          }
          handleSelectedItems={onSelectItems}
          last={last}
          multiSelection={true}
          rowKey="descriptionId"
        />
        {isColumnSelectorOpen && (
          <ColumnSelector<Transaction>
            columns={currentColumns}
            handleSelectedColumns={handleSelectedColumns}
            initialColumns={columns}
            isVisible={isColumnSelectorOpen}
            onClose={() => setColumnSelectorOpen(false)}
          />
        )}
      </>
    );
  }
);
