import React from "react";
import styles from "./ProductCard.module.scss";

import { PixImage } from "containers/PixImage";
import { ProductListItem } from "shared/models/Product";

interface ProductCardProps {
  data?: ProductListItem;
}

export const ProductCard = ({ data }: ProductCardProps) => {
  if (!data) {
    return <>Nenhum item selecionado!</>;
  }

  const description = data.description;
  const handleContent = (content?: string) => content || "--";

  return (
    <div className={styles.cardWrapper}>
      <div data-testid="product-card-wrapper" className={styles.wrapper}>
        {(data as any).productAttributes && (
          <PixImage width="64px" height="100%" imageUrl={data.imageUrl} />
        )}
        <div className={styles.infoWrapper}>
          <div data-testid="product-card-description">
            <h4>Descrição</h4>
            <p className={styles.productDescription}>
              {handleContent(description)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
