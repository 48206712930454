export const extractMessages = (
  object: any,
  errors: string[] = []
): string[] => {
  for (let key in object) {
    if (typeof object[key] === "object") {
      let res = extractMessages(object[key]);
      errors.push(...res);
    }
    if (key === "message") {
      if (typeof object[key] === "string") {
        errors.push(object[key]);
      } else {
        errors.push(JSON.stringify(object[key]));
      }
    }
  }
  return errors;
};
