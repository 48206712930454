import { Upload } from "antd";
import { ImageUploadText } from "components/ImageUploadText/ImageUploadText";
import React from "react";
import styles from "./ImageUploadDragger.module.scss";

const { Dragger } = Upload;

interface ImageUploadDraggerProps {
  setImageFile: (id?: number) => void;
}

export const ImageUploadDragger = ({
  setImageFile,
}: ImageUploadDraggerProps) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        display: "block",
        height: "auto",
      }}
    >
      <Dragger
        listType="picture"
        maxCount={1}
        beforeUpload={(options: any) => {
          setImageFile(options);
          return false;
        }}
        name="file"
        onRemove={() => setImageFile(undefined)}
      >
        <ImageUploadText />
      </Dragger>
    </div>
  );
};
