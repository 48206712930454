import { SelectBooleanValue } from "components/FormItem/FormItemBoolean";
import { ProductLineAttributeDetail } from "./ProductLine";

export type CharacteristicBase = {
  description: string;
  type: string;
};

export type Characteristic = CharacteristicBase & {
  id: number;
  attributeId?: number;
  deleted?: boolean;
};

export type CharacteristicById = Characteristic & {
  readOnly: boolean;
  lastModified: string;
  id: number;
  description: string;
  type: string;
  createdBy?: string;
  createdAt?: string;
  comment?: string;
};

export const newCharacteristic = {
  description: "",
  type: "",
  comment: "",
} as Partial<Characteristic>;

export interface CharacteristicValue {
  id: number;
  description: string;
  shortDescription: string;
  attributeId: number;
}

export type CharacteristicDetail = {
  name?: string;
  value?: string;
  type?: AttributeTypes;
  attributeId: number;
  attributeValueId?: number;
  entityAttributeId?: number;
};

export type AttributeTypes =
  | "PRIMARY"
  | "SECONDARY"
  | "LOGISTIC"
  | "FISCAL"
  | "ASSET";

export const ATTRIBUTE_TYPE_KEYS: Record<string, AttributeTypes> = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  LOGISTIC: "LOGISTIC",
  FISCAL: "FISCAL",
  ASSET: "ASSET",
};

export const ATT_TYPES = [
  { header: "Primárias", type: ATTRIBUTE_TYPE_KEYS.PRIMARY },
  { header: "Secundárias", type: ATTRIBUTE_TYPE_KEYS.SECONDARY },
  { header: "Logísticas", type: ATTRIBUTE_TYPE_KEYS.LOGISTIC },
  { header: "Fiscais", type: ATTRIBUTE_TYPE_KEYS.FISCAL },
];

export interface AttributeUsageCountDto {
  count: number;
}

export class CharacteristicFilters {
  attributeId?: number[];
  term?: string;
  type?: AttributeTypes;
  deleted?: SelectBooleanValue;
  productLineId?: number;
  attributeValueId?: number;
}

export type ProductCreateEntityAttribute = {
  entityAttributeId?: number;
  attribute: ProductLineAttributeDetail;
  attributeValue?: string;
};
