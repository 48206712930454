import { message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AttributeValue } from "shared/models/AttributeValue";
import { attributeValueService } from "shared/services/AttributeValueService";
import { Input } from "components/Input";

import * as Yup from "yup";
import { utcToPtBrDate } from "util/date.util";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import styles from "./AttributeValueEdit.module.scss";
import { CountStatusEnum } from "shared/enum/CountStatusEnum";
import { productService } from "shared/services/ProductService";
import { InputClickToCount } from "components/InputClickToCount/InputClickToCount";

type AttributeValueEditType = {
  attributeValue?: AttributeValue;
  onCancel: () => void;
  afterSubmit: (updatedAttributeValue: AttributeValue) => void;
};

const validationSchema = Yup.object({
  valueDescription: Yup.string().trim().required("Campo obrigatório"),
  shortValueDescription: Yup.string().required("Campo obrigatório"),
});

export const AttributeValueEdit = ({
  attributeValue,
  onCancel,
  afterSubmit,
}: AttributeValueEditType) => {
  const [countTotalStatus, setCountTotalStatus] = useState<CountStatusEnum>(
    CountStatusEnum.INIT_STATUS
  );
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    setCountTotalStatus(CountStatusEnum.INIT_STATUS);
    setTotal(undefined);
  }, [attributeValue]);

  const onClickCountTotal = () => {
    setCountTotalStatus(CountStatusEnum.LOADING_STATUS);
    productService
      .findTotalProductByAssociatedEntity({
        attributeValueId: attributeValue!.attributeValueId,
      })
      .then((nameCount) => setTotal(nameCount.count))
      .catch(() => setCountTotalStatus(CountStatusEnum.ERROR_STATUS));
  };

  const putAttributeValue = (value: Partial<AttributeValue>) => {
    trackPromise(attributeValueService.put(value))
      .then(({ data }) => {
        message.success("Atributo salvo com sucesso");
        afterSubmit(data);
      })
      .catch(() => message.error("Erro ao salvar o atributo"));
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<Partial<AttributeValue>>({
    initialValues: attributeValue ?? { deleted: false },
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      putAttributeValue(values);
    },
  });

  const onCancelForm = () => {
    resetForm();
    onCancel();
  };

  return (
    <div className={styles.wrapper}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Input
          autoFocus={true}
          error={errors.valueDescription}
          id="valueDescription"
          label="Descrição"
          name="valueDescription"
          onChange={handleChange}
          placeholder="Descrição"
          tabIndex={1}
          value={values.valueDescription}
        />

        <Input
          id="shortValueDescription"
          label="Descrição curta"
          error={errors.shortValueDescription}
          name="shortValueDescription"
          onChange={handleChange}
          placeholder="Descrição curta"
          tabIndex={1}
          value={values.shortValueDescription}
        />

        {!!attributeValue?.attributeValueId && (
          <>
            <Input
              name="lastModified"
              id="line-last-modified"
              label="Última atualização"
              placeholder="Última atualização"
              value={utcToPtBrDate(values.lastModified)}
              disabled
            />
            <YehSwitch
              style={{ marginTop: 16 }}
              label="Status"
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
              checked={!values.deleted}
              onClick={(f) => setFieldValue("deleted", !f)}
            />
            <InputClickToCount
              countStatus={countTotalStatus}
              entity={"Produtos associados"}
              onCount={onClickCountTotal}
              total={total}
              link="/produto"
              linkText="Ir para produtos"
              linkParams={{ attributeValueId: attributeValue.attributeValueId }}
              linkParamsName="productFilters"
            />
          </>
        )}
        <div />
        <CancelAndSave onCancel={onCancelForm} />
      </form>
    </div>
  );
};
