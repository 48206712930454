import { Form, Modal, ModalProps } from "antd";
import React from "react";

interface FormItemWrapperProps extends ModalProps {
  children: React.ReactNode;
  onCancel: () => void;
  submit: () => void;
  title: string;
  visible: boolean;
}

export const FormItemWrapper: React.FC<FormItemWrapperProps> = ({
  children,
  onCancel,
  submit,
  title,
  okText = "Filtrar",
  cancelText = "Cancelar",
  ...rest
}) => (
  <Modal
    title={<b>{title}</b>}
    onOk={submit}
    onCancel={onCancel}
    okText={okText}
    cancelText={cancelText}
    width="60%"
    {...rest}
  >
    <Form
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          submit();
        }
      }}
    >
      {children}
    </Form>
  </Modal>
);
