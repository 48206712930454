import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { CharacteristicValue } from "shared/models/Characteristic";
import { characteristicService } from "shared/services/CharacteristicService";

export const useAttributeValue = (attributeId: number) => {
  const [attributeValues, setAttributeValues] = useState<CharacteristicValue[]>(
    []
  );
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);

  const getAttributeValues = (page: number, term: string) => {
    if (!term) {
      return;
    }
    characteristicService
      .getValues(attributeId, [term], page, false)
      .then((response) => {
        setAttributeValues((old) => [
          ...(response.page === 0 ? [] : old),
          ...response.content,
        ]);
        setPage(response.page);
        setLast(response.last);
      });
  };

  const debounceSearchTerm = useCallback(debounce(getAttributeValues, 600), []);

  const loadMore = () => {
    if (!last) {
      getAttributeValues(page + 1, term);
    }
  };

  return {
    getAttributeValues,
    debounceSearchTerm,
    loadMore,
    term,
    setTerm,
    attributeValues,
  };
};
