import React from "react";
import styles from "./PixInactiveAlert.module.scss";

import { Alert } from "antd";
import { Icons } from "components/Icons";

interface Props {
  isInactive: boolean;
}

export const PixInactiveAlert = ({ isInactive }: Props) => {
  return (
    <>
      {isInactive && (
        <Alert
          className={styles.inactiveProduct}
          icon={<Icons name="infoCircleOutlined" />}
          message={
            <span>
              Este produto está <b>Inativo</b>
            </span>
          }
          showIcon
          type="warning"
        />
      )}
    </>
  );
};
