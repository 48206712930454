import { message } from "antd";
import EmptySelection from "components/EmptySelection/EmptySelection";
import { TabItem } from "components/Tabs/Tabs";
import {
  AttributeEdit,
  AttributeValueWrapper,
} from "containers/AttributeEdit/AttributeEdit";
import { CharacteristicsDisplay } from "containers/CharacteristicsDisplay/CharacteristicsDisplay";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import TaxonomyTab from "containers/TaxonomyTab/TaxonomyTab";
import React, { ReactNode, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductById, ProductUpdate } from "shared/models/Product";
import { characteristicService } from "shared/services/CharacteristicService";
import { productService } from "shared/services/ProductService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { readInitialTab } from "util/yeh.query.param.util";
import { CreateProduct } from "./CreateProduct";
import { PackageTab } from "./PackageTab";

import { CustomPackagingEdit } from "components/CustomPackagingEdit/CustomPackagingEdit";
import styles from "./ProductEdit.module.scss";

type ProductEditProps = {
  isEditing: boolean;
  productProp?: ProductById;
  productId?: number;
  handleCancelProductEdit: () => void;
  isCreateExpanded: boolean;
  setCreateExpanded: (value: boolean) => void;
  afterEdit: (updatedProduct: ProductById) => void;
};

enum ProductTabKey {
  PRODUTO = "PRODUTO",
  TAXONOMIA = "TAXONOMIA",
  CARACTERISTICAS = "CARACTERISTICAS",
  EMBALAGENS = "EMBALAGENS",
  CODIGOS_INTERNOS = "CODIGOS-INTERNOS",
}

const tabItems = [
  {
    title: "Produto",
    value: ProductTabKey.PRODUTO,
  },
  {
    title: "Taxonomia",
    value: ProductTabKey.TAXONOMIA,
  },
  {
    title: "Características",
    value: ProductTabKey.CARACTERISTICAS,
  },
  {
    title: "Embalagens",
    value: ProductTabKey.EMBALAGENS,
  },
  {
    title: "Códigos internos",
    value: ProductTabKey.CODIGOS_INTERNOS,
  },
];

export const ProductEdit = ({
  isEditing,
  productProp,
  productId,
  handleCancelProductEdit,
  isCreateExpanded,
  setCreateExpanded,
  afterEdit,
}: ProductEditProps) => {
  const [selectedTab, setSelectedTab] = useState<TabItem>(
    readInitialTab(tabItems)
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [product, setProduct] = useState<ProductById>();

  const toggleModal = () => setModalOpen((f) => !f);

  const getProductById = (id: number) => {
    trackPromise(
      productService
        .getById(id)
        .then(setProduct)
        .catch(() => {
          getErrorMessage("Erro ao buscar detalhe de produto.");
        })
    );
  };

  useEffect(() => {
    if (productProp?.id) {
      setProduct(productProp);
    } else if (productId) {
      getProductById(productId);
    }
  }, [productProp, productId]);

  const saveTaxonomies = (taxonomiesIds: number[]): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!!product?.id) {
        taxonomiesIds = taxonomiesIds || [];
        let productUpdate: ProductUpdate = {
          productId: product.id,
          productLineBrandId: product.productLineBrandId,
          attributeIds: product.productAttributes.map((at) => at.id),
          taxonomies: taxonomiesIds,
          productStatusId: product.productStatus?.value,
          deleted: product.deleted,
          openDescription: product.openDescription,
          productSanitation: product.productSanitation?.value,
          industryRotation: product.industryRotation?.value,
          marketRotation: product.marketRotation?.value,
          subBrandId: product.subBrand?.subBrandId,
          assets: product.assets,
        };
        trackPromise(productService.update(product.id, productUpdate))
          .then((updateProduct) => {
            message.success("Taxonomia(s) do produto salva(s) com sucesso.");
            product.taxonomies = taxonomiesIds;
            setProduct(updateProduct);
            resolve();
          })
          .catch(() => {
            getErrorMessage("Erro ao salvar taxonomias do produto.");
            reject();
          });
      }
    });
  };

  const getTabOrElseEmptySelection = (getTab: () => ReactNode) => {
    if (!isEditing || !product?.id) {
      return <EmptySelection />;
    }
    return getTab();
  };

  const getProductTab = () => {
    return getTabOrElseEmptySelection(() => (
      <CreateProduct
        isExpanded={isCreateExpanded}
        onCancel={handleCancelProductEdit}
        afterSave={afterEdit}
        selected={product!}
      />
    ));
  };

  const getTaxonomyTab = () => {
    return getTabOrElseEmptySelection(() => (
      <TaxonomyTab
        key={product?.id}
        onSave={saveTaxonomies}
        isInactive={product!.deleted}
        productDescription={product!.description}
        taxonomiesIds={product!.taxonomies}
        handleCancelEdit={handleCancelProductEdit}
      />
    ));
  };

  const handleAttribute = (attributes: AttributeValueWrapper[]) => {
    trackPromise(characteristicService.updateProduct(product!.id, attributes))
      .then((result) => {
        toggleModal();
        setProduct(result);
        message.success("Produto atualizado com sucesso");
        afterEdit(result);
      })
      .catch(() => {
        getErrorMessage("Erro ao atualizar produto");
      });
  };

  const getCharacteristicsTab = () => {
    return getTabOrElseEmptySelection(() => (
      <>
        <AttributeEdit
          attributes={product?.productAttributes || []}
          title={`Editar características do produto ${product?.description}`}
          productLineId={product?.productLine.id}
          onConfirm={handleAttribute}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
        />
        <CharacteristicsDisplay attributes={product?.productAttributes || []} />
      </>
    ));
  };

  const getPackageTab = () =>
    getTabOrElseEmptySelection(() => (
      <div style={{ overflowX: "scroll" }}>
        <PackageTab
          productId={product!.id}
          handleExpandTab={setCreateExpanded}
          isCreateExpanded={isCreateExpanded}
        />
      </div>
    ));

  const getCustomPackagingTab = () =>
    getTabOrElseEmptySelection(() => (
      <div style={{ overflowX: "scroll" }}>
        <CustomPackagingEdit
          productId={product!.id}
          isCreateExpanded={isCreateExpanded}
        />
      </div>
    ));

  return (
    <aside className={styles.wrapper}>
      <CrudCreateComponent
        handleSelectedTab={setSelectedTab}
        isCreateExpanded={isCreateExpanded}
        tabItems={tabItems}
        selectedTab={selectedTab}
        setCreateExpanded={setCreateExpanded}
      />

      {selectedTab.value === ProductTabKey.PRODUTO && getProductTab()}
      {selectedTab.value === ProductTabKey.TAXONOMIA && getTaxonomyTab()}
      {selectedTab.value === ProductTabKey.CARACTERISTICAS &&
        getCharacteristicsTab()}
      {selectedTab.value === ProductTabKey.EMBALAGENS && getPackageTab()}
      {selectedTab.value === ProductTabKey.CODIGOS_INTERNOS &&
        getCustomPackagingTab()}
    </aside>
  );
};
