import { Badge, Collapse } from "antd";
import { uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { ATT_TYPES } from "shared/models/Characteristic";
import { ProductCharacteristic } from "shared/models/Product";

import { CharacteristicsDetail } from "./CharacteristicsDetail";
import style from "./CharacteristicsDisplay.module.scss";

const { Panel } = Collapse;

interface CharacteristicsDisplayProps {
  attributes: ProductCharacteristic[];
}

export const CharacteristicsDisplay = ({
  attributes,
}: CharacteristicsDisplayProps) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  useEffect(() => {
    // Initially, only open tabs that has content
    setActiveKeys(uniq(attributes.map((att) => att.attribute.type)));
  }, [attributes]);

  return (
    <Collapse
      onChange={(ks) => setActiveKeys(ks as string[])}
      activeKey={activeKeys}
      className={style.wrapper}
    >
      {ATT_TYPES.map((att) => {
        const attributeTypes = attributes.filter(
          (a) => a.attribute.type === att.type
        );

        return (
          <Panel
            key={att.type}
            header={att.header}
            extra={<Badge.Ribbon text={attributeTypes.length} />}
          >
            {attributeTypes.map((att) => (
              <CharacteristicsDetail key={att.attribute.id} attribute={att} />
            ))}
          </Panel>
        );
      })}
    </Collapse>
  );
};
