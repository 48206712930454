export const utcToPtBrDate = (date?: string | Date) => {
  if (!date) {
    return "-";
  }
  let actualDate = new Date(
    date.toString() + (date.toString().endsWith("Z") ? "" : "Z")
  );

  let locale = navigator.language || "pt-BR";

  let localDate = actualDate.toLocaleDateString(locale);
  let localtIME = actualDate.toLocaleTimeString(locale);
  return [localDate, localtIME].join(" ");
};
