import { message } from "antd";
import { uniq } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  CharacteristicFilters,
  Characteristic as CharacteristicType,
} from "shared/models/Characteristic";
import { characteristicService } from "shared/services/CharacteristicService";
import { NameAlias } from "shared/services/SelectOptionType";
import { getInitialFilter } from "util/filter.util";
import { getTerms } from "./HookUtil";
import useDeepCompareEffect from "use-deep-compare-effect";

const getInitialCharacteristicsFilters = () =>
  ({
    ...getInitialFilter("filters", CharacteristicFilters),
    deleted: "false",
  } as CharacteristicFilters);

export const useCharacteristics = (initialFilters?: CharacteristicFilters) => {
  const [page, setPage] = useState(0);
  const [types, setTypes] = useState<NameAlias[]>([]);
  const [terms, setTerms] = useState<string[]>([]);
  const [last, setLast] = useState(true);
  const [filters, setFilters] = useState<CharacteristicFilters>({
    ...getInitialCharacteristicsFilters(),
    ...initialFilters,
  });

  const [characteristics, setCharacteristics] = useState<CharacteristicType[]>(
    []
  );

  const handleRemoveFilter = (term: string) => {
    const newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);
    fetchData(0, { ...filters, term: newTerms.join(" ") });
  };

  const handleSearch = (value: string) => {
    const newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    const newFilters = { ...filters, term: newTerms.join(" ") };
    fetchData(0, newFilters);
  };

  const fetchTypes = useCallback(() => {
    trackPromise(characteristicService.getTypes()).then(setTypes);
  }, []);

  const fetchData = useCallback(
    (page: number, newFilters: CharacteristicFilters) => {
      trackPromise(characteristicService.getCharacteristics(newFilters, page))
        .then((result) => {
          setCharacteristics((old) => [
            ...(page > 0 ? old : []),
            ...result.content,
          ]);
          setPage(page);
          setFilters(newFilters);
          setLast(result.last);
          setTerms((o) => getTerms(o, newFilters.term));
        })
        .catch(() => {
          message.error("Erro ao buscar lista de características.");
        });
    },
    []
  );

  const fetchMoreData = () => {
    if (!last) {
      fetchData(page + 1, filters);
    }
  };

  useDeepCompareEffect(() => {
    fetchData(0, { ...initialFilters, ...getInitialCharacteristicsFilters() });
  }, [fetchData, initialFilters]);

  useEffect(fetchTypes, [fetchTypes]);

  return {
    last,
    types,
    terms,
    filters,
    characteristics,
    fetchData,
    handleSearch,
    fetchMoreData,
    handleRemoveFilter,
  };
};
