import { navigate } from "@reach/router";
import { Empty } from "antd";
import cx from "classnames";
import { WordCloud } from "components/WordCloud";
import YehChart from "components/YehChart/YehChart";
import React, { useState } from "react";
import { GoogleChartWrapperChartType } from "react-google-charts/dist/types";
import { Word } from "react-wordcloud";
import styles from "./VisualData.module.scss";

interface VisualDataProps {
  disableButtons: boolean;
  fetchWordcloudData?: (mode: boolean) => void;
  isLoadingWordcloud: boolean;
  chartData?: any[]; // Really needs to be any
  selected: boolean;
  wordCloudValues?: Word[] | null;
  chartType?: GoogleChartWrapperChartType;
  chartOptions?: any; // Another one that needs to be any
}

const VisualData = ({
  disableButtons,
  fetchWordcloudData,
  isLoadingWordcloud,
  chartData,
  selected,
  wordCloudValues,
  chartType,
  chartOptions,
}: VisualDataProps) => {
  const [fetchMode, setFetchMode] = useState(false);
  const [isWordCloudExpanded, setWordCloudExpanded] = useState(false);
  const [isSankeyExpanded, setSankeyExpanded] = useState(false);

  const handleFetching = fetchWordcloudData
    ? () => {
        fetchWordcloudData && fetchWordcloudData(fetchMode);
        setFetchMode(!fetchMode);
      }
    : undefined;

  const handleClickSankeyExpand = () => {
    setSankeyExpanded(!isSankeyExpanded);
  };

  const sankeyCard = cx(styles.chart, {
    [styles.hide]: isWordCloudExpanded,
    [styles.fullHeight]: !isWordCloudExpanded && isSankeyExpanded,
    [styles.regularHeight]: !isWordCloudExpanded && !isSankeyExpanded,
  });

  const wordCloudCard = cx(styles.chart, styles.wordCloud, {
    [styles.hide]: isSankeyExpanded,
    [styles.fullHeight]: !isSankeyExpanded && isWordCloudExpanded,
    [styles.regularHeight]: !isSankeyExpanded && !isWordCloudExpanded,
  });

  if (!selected) {
    return (
      <div className={styles.empty}>
        <Empty description="Selecione um registro para visualizar os dados." />
      </div>
    );
  }

  const handleWordClick = (word: Word) => {
    word &&
      word.text &&
      navigate("/produto?filters=" + JSON.stringify({ term: word.text }));
  };

  return (
    <div className={styles.charts} data-testid="manufacturer-graphic-wrapper">
      {!!chartType && (
        <div className={sankeyCard}>
          <YehChart
            data={chartData}
            isExpanded={isSankeyExpanded}
            onClickExpand={handleClickSankeyExpand}
            chartType={chartType}
            options={{
              ...chartOptions,
              chartArea: { width: "100%", height: "100%" },
            }}
          />
        </div>
      )}
      {wordCloudValues && (
        <div
          className={wordCloudCard}
          style={{ display: isSankeyExpanded ? "none" : "block" }}
        >
          <WordCloud
            disableButtons={disableButtons}
            handleFetching={handleFetching}
            isLoadingWordcloud={isLoadingWordcloud}
            isWordcloudExpanded={isWordCloudExpanded}
            onWordClick={handleWordClick}
            setWordcloudExpanded={setWordCloudExpanded}
            values={wordCloudValues}
          />
        </div>
      )}
    </div>
  );
};

VisualData.defaultProps = {
  disableButtons: false,
  isLoadingWordcloud: false,
  selected: true,
};

export { VisualData };
