import React, { useEffect } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
// import styles from './Dashboard.module.scss';
import { DefaultPage } from "containers/DefaultContent";

type DashboardProps = DefaultPage & RouteComponentProps;

export const Dashboard = (props: DashboardProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/produto");
  }, [navigate]);

  return <></>;
};
