import { Form, Select, SelectProps } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React from "react";

const { Option } = Select;

export type SelectBooleanValue = "true" | "false";

export const selectBooleanValueFromBoolean = (
  value: boolean
): SelectBooleanValue => (value ? "true" : "false");

interface FormItemBooleanProps extends SelectProps<SelectBooleanValue> {
  id: string;
  label: string;
  textForFalse: string;
  textForTrue: string;
}

export const FormItemBoolean = ({
  id,
  label,
  textForFalse,
  textForTrue,
  ...rest
}: FormItemBooleanProps) => (
  <Form.Item label={<FilterLabel text={label} htmlFor={id} />}>
    <Select id={id} allowClear {...rest} placeholder="Selecione um status">
      <Option value="false">{textForFalse}</Option>
      <Option value="true">{textForTrue}</Option>
    </Select>
  </Form.Item>
);
