import { Card } from "antd";
import { ProductListItem } from "shared/models/Product";

import { PixLink } from "components/PixLink/PixLink";
import React from "react";

interface ProductItemProps {
  product: ProductListItem;
  isNew: boolean;
}

export const ProductItem = ({ product }: ProductItemProps) => {
  return (
    <Card>
      <PixLink
        link="/produto"
        paramsName="productFilters"
        params={{ productId: product.productId }}
        label={product.description}
      />
    </Card>
  );
};
