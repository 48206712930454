import { Button, Col, Row, Space } from "antd";
import React from "react";

interface LoginPageProps {
  loginWithRedirect: () => void;
  loading: boolean;
}

export const LoginPage = ({ loginWithRedirect, loading }: LoginPageProps) => {
  return (
    <Row style={{ height: "100%" }} align="middle">
      <Col
        span={12}
        style={{ backgroundColor: "#1F2938", height: "100%", color: "white" }}
      >
        <Space
          direction="horizontal"
          style={{ width: "100%", height: "100%", justifyContent: "center" }}
        >
          YEH
        </Space>
      </Col>
      <Col span={12}>
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "center" }}
        >
          <Button
            type="primary"
            onClick={() => loginWithRedirect()}
            size="large"
            style={{ backgroundColor: "#F96C18", border: "none" }}
            loading={loading}
          >
            Login
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
