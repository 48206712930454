import React from "react";
import { Radio } from "antd";
import styles from "./SuggestionCard.module.scss";
import cx from "classnames";
import { SuggestionDataScience } from "shared/models/Association";

interface SuggestionCardProps {
  data: SuggestionDataScience;
  isSelected: boolean;
  onSelect: (value: number) => void;
}

const translationObject = {
  BRAND: "Marca",
  LINE: "Linha",
  STANDARD_PRODUCT: "Produto Padrão",
};

export const SuggestionCard = ({
  data,
  isSelected,
  onSelect,
}: SuggestionCardProps) => {
  return (
    <div>
      <div
        className={cx(
          styles.wrapper,
          data.entityType === "STANDARD_PRODUCT"
            ? styles.backgroundLight
            : data.entityType === "LINE"
            ? styles.backgroundLightBlue
            : styles.backgroundGreen
        )}
      >
        <Radio checked={isSelected} onClick={() => onSelect(data.entityId)} />
        {data.imageUrl && (
          <div className={styles.picture}>
            <img
              src={data.imageUrl}
              alt={data.description}
              title={data.description}
            />
          </div>
        )}
        <div className={styles.infoWrapper}>
          <div
            data-testid="product-card-description"
            className={styles.suggestionType}
          >
            <h4 className={styles.descriptionTitle}>Tipo</h4>
            <p className={styles.brandDescription}>
              {translationObject[data.entityType]}
            </p>
          </div>
          <div data-testid="product-card-description">
            <h4 className={styles.descriptionTitle}>Descrição</h4>
            <p className={styles.brandDescription}>{data.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
