import { message } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { SearchBrandsSelect } from "containers/SearchBrandsSelect/SearchBrandsSelect";
import React from "react";
import { productLineService } from "shared/services/ProductLineService";
import { getErrorMessage } from "shared/utils/ResponseUtils";

interface AddBrandProps {
  productLineId: number;
  refresh: () => void;
}

export const AddBrand = ({ productLineId, refresh }: AddBrandProps) => {
  const handleChangeSelect = (productLineId: number, brandId?: number) => {
    // Clear selection
    if (!brandId) {
      return;
    }
    productLineService
      .saveProductLineBrands(productLineId, [brandId])
      .then(() => {
        message.success("Marca adicionada com sucesso");
        refresh();
      })
      .catch(() => getErrorMessage("Erro ao adicionar marca"));
  };
  return (
    <section>
      <FilterLabel
        text="Vincular Marca"
        htmlFor="add-brand-search-brands-select"
      />
      <SearchBrandsSelect
        parentName="add-brand"
        onChange={(brandId) => handleChangeSelect(productLineId, brandId)}
      />
    </section>
  );
};
