import { useAuth0 } from "@auth0/auth0-react";
import { message } from "antd";
import axios from "axios";
import Main from "containers/Main";
import { LoginPage } from "LoginPage";
import React, { useCallback, useEffect } from "react";
import { Routes } from "Routes";
import { extractMessages } from "shared/services/AxiosAuth";

const Root: React.FC = () => {
  const [interceptorAdded, setInterceptorAdded] = React.useState(false);
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
  } = useAuth0();

  const addInterceptors = useCallback(() => {
    getIdTokenClaims().then((claims) => {
      const token = claims?.__raw;

      axios.interceptors.request.use((config) => {
        config.headers.Authorization = token ? `Bearer ${token}` : undefined;
        return config;
      });

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          let errorMessages = extractMessages(error);
          errorMessages.forEach((errorMessage) => message.error(errorMessage));
          return Promise.reject(error);
        }
      );

      setInterceptorAdded(true);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (isAuthenticated) {
      addInterceptors();
    }
  }, [isAuthenticated, addInterceptors]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return isAuthenticated ? (
    <Main>{interceptorAdded && <Routes />}</Main>
  ) : (
    <LoginPage loginWithRedirect={loginWithRedirect} loading={isLoading} />
  );
};
export default Root;
