import axios from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { AuditRequestBody, AuditResponse } from "shared/models/Audit";
import { objectToParams } from "util/query.param.util";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/audit";

class AuditService {
  async getEntityLogs(auditRequest: AuditRequestBody) {
    const params = objectToParams(auditRequest);
    const r = await axios.request<AuditResponse>({
      method: "GET",
      url: BASE_PATH,
      params,
    });
    return r.data;
  }
}

export const auditService = new AuditService();
