import { Form, Input, InputProps } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import React from "react";

interface FormItemTextProps extends InputProps {
  id: string;
  label: string;
  name?: string;
}

export const FormItemText = ({
  id,
  label,
  name,
  ...rest
}: FormItemTextProps) => (
  <Form.Item label={<FilterLabel text={label} htmlFor={id} />} name={name}>
    <Input id={id} {...rest} />
  </Form.Item>
);
