import { Col, Empty, Row } from "antd";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { ImageDetail } from "pages/Images/components/ImageDetail";
import React, { useState } from "react";
import { SimpleImage } from "shared/models/Asset";

interface ImageListProps {
  gtin?: string;
  productId: number;
  description?: string;
  selected?: SimpleImage;
  refresh: () => void;
  setSelected?: (img?: SimpleImage) => void;
  mainImageUrl?: string;
  last: boolean;
  loadMore: () => void;
  images: SimpleImage[];
  imageCardColSize?: number;
}

export const ImageList = ({
  gtin,
  last,
  images,
  productId,
  description,
  mainImageUrl,
  refresh,
  loadMore,
  setSelected,
  selected,
  imageCardColSize = 6,
}: ImageListProps) => {
  const [lastSelected, setLastSelected] = useState<number>();

  const afterDelete = (deletedAssetId?: number) => {
    if (selected?.assetId === deletedAssetId) {
      setSelected?.(undefined);
    }
    refresh?.();
  };

  return images.length === 0 ? (
    <Empty description="Nenhuma imagem disponível." />
  ) : (
    <>
      <Row gutter={8}>
        {images.map((img) => (
          <Col
            key={img.assetId}
            span={imageCardColSize}
            onClick={() => {
              setLastSelected(img.assetId);
            }}
          >
            <ImageDetail
              gtin={gtin}
              image={img}
              productId={productId}
              description={description}
              lastSelected={lastSelected === img.assetId}
              mainImageUrl={mainImageUrl}
              afterUpdate={refresh}
              afterDelete={afterDelete}
              refreshList={refresh}
              onClick={() => {
                setSelected?.(img);
              }}
            />
          </Col>
        ))}
      </Row>
      <LoadMoreButton last={last} onFetchMoreData={loadMore} />
    </>
  );
};
