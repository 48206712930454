import { uniq } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { SubBrandListFilter, SubBrandListItem } from "shared/models/SubBrand";
import { subBrandService } from "shared/services/SubBrandService";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getInitialFilter } from "util/filter.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import { readTermQueryParam } from "util/yeh.query.param.util";
import { getTerms } from "./HookUtil";

const getInitialFilters = () =>
  ({
    deleted: "false",
    ...getInitialFilter("subBrandsFilters", SubBrandListFilter),
  } as SubBrandListFilter);

export const useSubBrands = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<SubBrandListFilter>({
    ...getInitialFilters(),
  });
  const [terms, setTerms] = useState(readTermQueryParam());
  const [last, setLast] = useState(true);
  const [subBrands, setSubBrands] = useState<SubBrandListItem[]>([]);

  const fetchSubBrands = useCallback(
    (page: number, filters: SubBrandListFilter) =>
      trackPromise(subBrandService.findAll(page, filters))
        .then((response) => {
          setFilters(filters);
          setPage(response.page);
          setLast(response.last);
          setTerms((o) => getTerms(o, filters.term));
          setSubBrands((old) => [
            ...(page > 0 ? old : []),
            ...response.content,
          ]);
        })
        .catch(() => {
          getErrorMessage("Erro ao buscar sub marcas");
        }),
    []
  );

  const clearFilters = () => {
    fetchSubBrands(0, {});
  };

  const handleRemoveFilter = (term: string) => {
    const newTerms = terms.filter((it) => it !== term);
    setTerms(newTerms);
    fetchSubBrands(0, { ...filters, term: newTerms.join(" ") });
  };

  const handleSearch = (value: string) => {
    const newTerms = uniq([...terms, value]);
    setTerms(newTerms);
    const newFilters = { ...filters, term: newTerms.join(" ") };
    fetchSubBrands(0, newFilters);
  };

  const fetchMoreData = () => {
    if (!last) {
      fetchSubBrands(page + 1, filters);
    }
  };

  const refreshList = () => {
    fetchSubBrands(0, filters);
  };

  useEffect(() => {
    fetchSubBrands(0, getInitialFilters());
  }, [fetchSubBrands]);

  useEffect(() => {
    putObjectAsQueryParam(filters, "subBrandsFilters");
  }, [filters]);

  return {
    terms,
    last,
    filters,
    subBrands,
    setFilters,
    refreshList,
    setSubBrands,
    clearFilters,
    fetchSubBrands,
    fetchMoreData,
    handleSearch,
    handleRemoveFilter,
  };
};
