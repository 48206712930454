import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { UserPermissionsMetadata } from "shared/models/User";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/user";

class UserService {
  async getPermissions() {
    const r = await axios.request<UserPermissionsMetadata>({
      method: "GET",
      url: BASE_PATH + "/permissions",
    } as AxiosRequestConfig);
    return r.data;
  }
}

export const userService = new UserService();
