import { RouteComponentProps } from "@reach/router";
import { Button, message } from "antd";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { selectBooleanValueFromBoolean } from "components/FormItem/FormItemBoolean";
import { TabItem } from "components/Tabs/Tabs";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { uniq } from "lodash";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AttributeValue } from "shared/models/AttributeValue";
import { attributeValueService } from "shared/services/AttributeValueService";
import { readInitialTab } from "util/yeh.query.param.util";
import { AttributeValueEdit } from "./AttributeValueEdit";
import { AttributeValueAttributeList } from "./AttributeValueAttributeList";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { MergeCellsOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "ID ATRIBUTO",
    dataIndex: "attributeValueId",
  },
  {
    title: "DESCRIÇÃO",
    dataIndex: "valueDescription",
  },
  {
    dataIndex: "deleted",
    title: "ATIVO",
    align: "center" as any,
    render: (deleted: boolean) => <ActiveInactiveItem deleted={deleted} />,
    sorter: true,
    sortBy: "deleted",
    width: 62,
  },
];

const tabItems: TabItem[] = [
  {
    title: "Atributo",
    value: "attribute",
  },
  {
    title: "Características",
    value: "characteristic",
  },
];

type AttributeValueCrudListFilter = {
  deleted: boolean;
  terms: string[];
};

const INITIAL_PAGE = 0;

const INITIAL_FILTER: AttributeValueCrudListFilter = {
  terms: [],
  deleted: false,
};

type AttributeValueProps = DefaultPage & RouteComponentProps;

export const AttributeValueList = ({ title }: AttributeValueProps) => {
  const [isListExpanded, setListExpanded] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);

  const [isWriting, setWriting] = useState(false);
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selected, setSelected] = useState<AttributeValue>();
  const [selectedItems, setSelectedItems] = useState<AttributeValue[]>([]);
  const [selectedTab, setSelectedTab] = useState(readInitialTab(tabItems));
  const [isMergeMode, setIsMergeMode] = useState(false);

  // Pageable states
  const [filter, setFilter] = useState<AttributeValueCrudListFilter>(
    INITIAL_FILTER
  );
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([]);

  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => {
    setCurrentTitle(title);
  }, [title, setCurrentTitle]);

  const findAttributeValues = useCallback(
    ({ terms, deleted }: AttributeValueCrudListFilter, page: number) => {
      trackPromise(
        attributeValueService.list({ term: terms.join(" "), deleted }, page)
      )
        .then(({ data }) => {
          setIsLastPage(data.last);
          setPage(page);
          setFilter({ terms, deleted });
          const newItems = page
            ? [...attributeValues, ...data.content]
            : data.content;
          setAttributeValues(newItems);
        })
        .catch(() => message.error("Erro ao buscar atributos"));
    },
    [attributeValues]
  );

  useEffect(() => {
    trackPromise(attributeValueService.list(INITIAL_FILTER, INITIAL_PAGE))
      .then(({ data }) => {
        setIsLastPage(data.last);
        setAttributeValues(data.content);
      })
      .catch(() => message.error("Erro ao buscar atributos"));
  }, []);

  const handleSearch = (value: string) => {
    const newFilter = { ...filter, terms: uniq([...filter.terms, value]) };
    setFilter(newFilter);
    findAttributeValues(newFilter, INITIAL_PAGE);
  };

  const handleRemoveFilter = (term: string) => {
    const newTerms = filter.terms.filter((it) => it !== term);
    findAttributeValues({ ...filter, terms: newTerms }, INITIAL_PAGE);
  };

  const onClickMergeMode = () => {
    if (isMergeMode) {
      setSelectedRows([]);
    }
    setIsMergeMode(!isMergeMode);
  };

  const getActionButtons = (
    <>
      <Button
        onClick={onClickMergeMode}
        icon={<MergeCellsOutlined />}
        id="btn-merge"
      >
        {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
      </Button>
    </>
  );

  const getSecondaryActions = () => (
    <ToggleMultiSelectButton
      onChange={(_) => setSelectedRows([])}
      setMultiselectMode={setMultiselectMode}
      multiselectMode={multiselectMode}
    />
  );

  const handleCreateNewClick = () => {
    setSelectedTab(tabItems[0]);
    setWriting(true);
    setSelected(undefined);
  };

  const getSearchComponent = () => {
    return (
      <CrudListContent<AttributeValue>
        actionButtons={getActionButtons}
        secondaryActions={getSecondaryActions()}
        multiselect={multiselectMode}
        columns={columns}
        data={attributeValues}
        fetchMoreData={() => findAttributeValues(filter, page + 1)}
        filterProps={{
          terms: filter.terms,
          handleClearFilters: () => {
            findAttributeValues(INITIAL_FILTER, INITIAL_PAGE);
          },
        }}
        last={isLastPage}
        handleCreateNewClick={handleCreateNewClick}
        handleRemoveFilter={handleRemoveFilter}
        handleSearch={handleSearch}
        rowKey="attributeValueId"
        setExpanded={setListExpanded}
        isExpanded={isListExpanded}
        setSelected={handleSelectItem}
        title="Listagem de Atributos"
        selectedRows={selectedRows}
        deletedStatusProps={{
          onChangeDeletedStatus: (deleted) => {
            findAttributeValues(
              { ...filter, deleted: deleted === "true" },
              INITIAL_PAGE
            );
          },
          value: selectBooleanValueFromBoolean(filter.deleted),
        }}
      />
    );
  };

  const handleSelectItem = (value: AttributeValue[]) => {
    if (isMergeMode) {
      return setSelectedItems(value);
    }

    if (value?.length === 1 && !multiselectMode) {
      setWriting(true);
      setSelected(value[0]);
    }
  };

  const onCancelMerge = () => {
    setIsMergeMode(false);
    setSelectedRows([]);
    setSelectedItems([]);
  };

  const onRemoveMergeItem = (remainingItems: AttributeValue[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.attributeValueId)
      .filter((itemId) =>
        remainingItems.some(
          (remainItem) => remainItem.attributeValueId === itemId
        )
      );
    setSelectedRows(newSelectedRows);
  };

  const getCreateComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<AttributeValue>
          data={selectedItems}
          entityName="attributevalue"
          getItemId={(attributeValue) => attributeValue.attributeValueId}
          getLabeledItem={(attributeValue) =>
            `${attributeValue.attributeValueId} - ${attributeValue.valueDescription}`
          }
          mergeItems={(mergeRequest) =>
            attributeValueService.merge(mergeRequest)
          }
          afterMerge={() => findAttributeValues(filter, page)}
          onCancel={onCancelMerge}
          onRemoveItem={onRemoveMergeItem}
        />
      );
    }

    return (
      <>
        <CrudCreateComponent
          handleSelectedTab={(tab) => setSelectedTab(tab)}
          isCreateExpanded={isCreateExpanded}
          tabItems={tabItems}
          selectedTab={selectedTab}
          setCreateExpanded={setCreateExpanded}
        />
        {selectedTab.value === "attribute" && (selected || isWriting) && (
          <AttributeValueEdit
            attributeValue={selected}
            onCancel={() => {
              setSelected(undefined);
            }}
            afterSubmit={(updatedAttributeValue) => {
              findAttributeValues(filter, INITIAL_PAGE);
              setSelected(updatedAttributeValue);
            }}
          />
        )}
        {selectedTab.value === "characteristic" && selected && (
          <AttributeValueAttributeList attributeValue={selected} />
        )}
      </>
    );
  };

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getCreateComponent()}
        isCreateExpanded={isCreateExpanded}
        isListExpanded={isListExpanded}
      />
    </>
  );
};
