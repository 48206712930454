import React from "react";
import styles from "./Menu.module.scss";
import cx from "classnames";
import _map from "lodash/map";

import {
  Location,
  LocationContext,
  NavigateFn,
  WindowLocation,
} from "@reach/router";
import { MenuOutlined } from "@ant-design/icons";
import { MenuItem } from "./MenuItem/MenuItem";
import { SubMenuItem } from "./SubMenuItem/SubMenuItem";
import { ReactComponent as YandehLogo } from "shared/assets/icons/yandeh-logo.svg";

interface MenuProps {
  isCollapsed: boolean;
  selectedItem: MenuItem;
  selectedSubItem?: MenuItem;
  displaySubmenu?: string;
  setSelectedItem: (value: string) => void;
  setSelectedSubItem: (value?: string) => void;
  setDisplaySubmenu: (value?: string) => void;
  setMenuCollapsed: (status: boolean) => void;
  menuItems: MenuItem[];
}

export const Menu = ({
  isCollapsed,
  selectedItem,
  selectedSubItem,
  displaySubmenu,
  setSelectedItem,
  setSelectedSubItem,
  setDisplaySubmenu,
  setMenuCollapsed,
  menuItems,
}: MenuProps) => {
  const checkSelectedItem = (
    id: string,
    location: WindowLocation,
    parentId?: string
  ): boolean => {
    const { pathname } = location;
    const selected = pathname.split("/")[1] === id.split("/")[1];
    selected && parentId && setDisplaySubmenu(parentId);
    return selected;
  };
  const menuItemSelection = (id: string) => {
    setSelectedItem(id);
    setSelectedSubItem(undefined);
  };
  const handleMenuCollapse = () => {
    setMenuCollapsed(!isCollapsed);
    setDisplaySubmenu(undefined);
  };
  const onClickHandle = (item: string, path: string, navigate: NavigateFn) => {
    navigate(path);
    setSelectedSubItem(item);
  };
  const wrapperStyle = cx(styles.wrapper, {
    [styles.collapsed]: isCollapsed,
  });
  const headerStyle = cx(styles.menuHeader, {
    [styles.collapsedHeader]: isCollapsed,
  });
  const itemListStyle = cx(styles.itemList);

  const getMenu = (location: WindowLocation, navigate: NavigateFn) => {
    return (
      <nav data-testid="menu-wrapper" className={wrapperStyle}>
        <div className={headerStyle}>
          {!isCollapsed && <YandehLogo />}
          <button
            title="Contrair/Expandir"
            type="button"
            className={styles.expandButton}
            onClick={() => handleMenuCollapse()}
          >
            <MenuOutlined />
          </button>
        </div>
        <ul className={itemListStyle}>
          {_map(menuItems, (item) => (
            <MenuItem
              color={item.color}
              displaySubmenu={displaySubmenu === item.id}
              icon={item.icon}
              id={item.id}
              isCollapsed={isCollapsed}
              isSelected={checkSelectedItem(item.id, location)}
              key={item.title}
              path={item.id}
              setDisplaySubmenu={setDisplaySubmenu}
              setSelected={(menuitem: React.MouseEvent<HTMLElement>) =>
                menuItemSelection(menuitem.currentTarget.id)
              }
              title={item.title}
            >
              {item.submenu &&
                _map(item.submenu, (subitem) => (
                  <SubMenuItem
                    color={subitem.color}
                    id={subitem.id}
                    isSelected={checkSelectedItem(
                      subitem.id,
                      location,
                      item.id
                    )}
                    key={subitem.id}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.stopPropagation();
                      onClickHandle(
                        event.currentTarget.id,
                        subitem.id,
                        navigate
                      );
                    }}
                    title={subitem.title}
                  />
                ))}
            </MenuItem>
          ))}
        </ul>
        {!isCollapsed && !!process.env.REACT_APP_VERSION && (
          <footer>
            Versão: {process.env.REACT_APP_VERSION.replace("release/", "")}
          </footer>
        )}
      </nav>
    );
  };

  return (
    <Location>
      {({ location, navigate }: LocationContext) => getMenu(location, navigate)}
    </Location>
  );
};
