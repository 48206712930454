import { EditOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, message, Modal, Popover, Row, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import ColumnGroup, { ColumnGroupProps } from "antd/lib/table/ColumnGroup";
import Text from "antd/lib/typography/Text";
import { GtinPackingEditTable } from "components/Gtin/GtinPackingEditTable/GtinPackingEditTable";
import { PixInfinityTable } from "components/PixInfinityTable/PixInfinityTable";
import DisassociationAlertModal from "containers/DisassociationAlertModal/DisassociationAlertModal";
import React, { ReactText, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  AssociationDataTypeSelect,
  AssociationFields,
  AssociationFilterBase,
  AssociationOperationResponse,
  DisassociateBasePayload,
  DisassociateSkuPayload,
  DisassociationCodeType,
  ProductAssociation,
  ProductAssociationDetail,
  ReassociateSkuPayload,
} from "shared/models/Association";
import { ProductListItem } from "shared/models/Product";
import { associationService } from "shared/services/AssociationService";
import styles from "./AssociationList.module.scss";
import { codeTag, getTag } from "./CodeTag";

interface Props {
  associationDataTypeSelected: AssociationDataTypeSelect;
  filters: AssociationFilterBase;
  target?: ProductListItem;
}

export const AssociationList = ({
  associationDataTypeSelected,
  filters,
  target,
}: Props) => {
  const [lastPage, setLastPage] = useState(false);
  const [page, setPage] = useState(0);
  const [productAssociations, setProductAssociations] = useState<
    ProductAssociation[]
  >([]);
  const [isDisassociationModalOpen, setDisassociationModalOpen] = useState(
    false
  );

  const [isEditPackingModalOpen, setEditPackingModalOpen] = useState(false);

  const [
    selectedAssociation,
    setSelectedAssociation,
  ] = useState<ProductAssociation>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<ReactText[]>([]);

  const [selectedAssociations, setSelectedAssociations] = useState<
    ProductAssociation[]
  >([]);

  const getInfo = (cnpj?: string, description?: string) => {
    if (!cnpj) {
      return <span>Cliente: N/A</span>;
    }
    const content = (
      <div>
        <p>{description}</p>
      </div>
    );
    return (
      <Popover placement="top" title="Descrição" content={content}>
        <span>{cnpj}</span>
      </Popover>
    );
  };

  const detailColumns = [
    {
      key: "col_codes",
      title: "SKU",
      dataIndex: "sku",
      align: "center" as any,
      width: 150,
      ellipsis: true,
      render: (_: string, item: ProductAssociationDetail) =>
        getTag(item.sku.code, item.sku.type),
    },
    {
      key: "col_description",
      title: "Descrição",
      dataIndex: "description",
      width: 150,
      ellipsis: true,
    },
    {
      key: "col_cnpj",
      title: "CNPJ",
      dataIndex: "emitterCompanyCode",
      align: "center" as any,
      width: 100,
      ellipsis: true,
      render: (value: string, item: ProductAssociationDetail) =>
        getInfo(item.emitterCompanyCode, item.emitterCompanyDescription),
    },
    {
      key: "col_bu",
      title: "BU",
      dataIndex: "bu",
      align: "center" as any,
      width: 100,
      ellipsis: true,
    },
  ];

  const clearSearch = () => {
    setProductAssociations([]);
    setPage(0);
    setLastPage(false);
  };

  // TODO remove this when api returns some sort of transaction id
  // this prevents stranges behavior like 'remembering' detail was open
  const fillKey = (associations: ProductAssociation[]) => {
    for (let i = 0; i < associations.length; i++) {
      let association = associations[i];
      let key = "" + i;
      if (!!association.gtinCode) {
        key += association.gtinCode.gtin + association.gtinCode.gtinType;
      }
      if (!!association.internalSku) {
        key += association.internalSku.sku + association.internalSku.skuType;
      }
      association.transactionIdentifier = key;
    }
  };

  const productId = target?.productId;

  const idWrapper = { productId };

  const fetchProductAssociateData = () => {
    if (!productId) {
      // Hopefully, id is not 0
      clearSearch();
      return;
    }
    trackPromise(
      associationService.getProductAssociations(
        page,
        filters,
        idWrapper,
        associationDataTypeSelected.value
      )
    )
      .then((result) => {
        setLastPage(result.last);
        let newList = [
          ...(page === 0 ? [] : productAssociations),
          ...result.content,
        ];
        fillKey(newList);
        setProductAssociations(newList);
        setSelectedAssociations([]);
      })
      .catch(() => message.error("Erro ao buscar associações do produto."));
  };

  useEffect(fetchProductAssociateData, [
    filters,
    associationDataTypeSelected.value,
    page,
    target,
  ]);

  useEffect(() => {
    setPage(0);
    setSelectedAssociations([]);
  }, [target]);

  const operationSuccess = (response: AssociationOperationResponse[]) => {
    let reassociation =
      (response.find((it) => it.operation === "reassociateGtin")?.total || 0) >
      0;

    message.success(
      `${reassociation ? "Reassociação " : "Desassociação "} com sucesso`
    );
    setPage(0);
    fetchProductAssociateData();
  };

  const handleDisassociation = (
    reassociation?: AssociationFields,
    reasonInput?: string
  ) => {
    if (!target || !selectedAssociations.length) {
      return;
    }

    const basePayload = buildBaseDisassociationPayload(reasonInput);

    if (!!reassociation) {
      reassociate(basePayload, reassociation);
    } else {
      disassociate(basePayload);
    }
  };

  const reassociate = (
    basePayload: DisassociateBasePayload,
    associationInputs: AssociationFields
  ) => {
    let reassociateInput: ReassociateSkuPayload[] = [];

    const gtinReassociation: ReassociateSkuPayload[] = selectedAssociations
      .filter((item) => !!item.gtinCode)
      .map((item) => {
        return {
          ...basePayload,
          code: item.gtinCode!.gtin,
          codeType: DisassociationCodeType.GTIN,
          associationInputs,
        };
      });

    const internalSkuReassociation: ReassociateSkuPayload[] = selectedAssociations
      .filter((item) => !!item.internalSku)
      .map((item) => item.internalSku)
      .map((internalSku) => {
        return {
          ...basePayload,
          description: internalSku!.description,
          emitterCompanyCode: internalSku!.emitterCompanyCode,
          code: internalSku!.sku,
          codeType: DisassociationCodeType.SKU,
          skuType: internalSku!.skuType,
          associationInputs,
        };
      });

    reassociateInput = [...gtinReassociation, ...internalSkuReassociation];

    trackPromise(
      associationService
        .reassociateGtinOrSku(reassociateInput)
        .then(operationSuccess)
        .catch(() => message.error("Falha na reassociação de GTINS e/ou SKUS"))
    );
  };

  const disassociate = (basePayload: DisassociateBasePayload) => {
    let disassociationInput: DisassociateSkuPayload[] = [];

    const gtinDisassociations: DisassociateSkuPayload[] = selectedAssociations
      .filter((item) => !!item.gtinCode)
      .map((item) => {
        return {
          ...basePayload,
          code: item.gtinCode!.gtin,
          codeType: DisassociationCodeType.GTIN,
        };
      });

    const internalSkuDisassociations: DisassociateSkuPayload[] = selectedAssociations
      .filter((item) => !!item.internalSku)
      .map((item) => item.internalSku)
      .map((internalSku) => {
        return {
          ...basePayload,
          description: internalSku!.description,
          emitterCompanyCode: internalSku!.emitterCompanyCode,
          code: internalSku!.sku,
          codeType: DisassociationCodeType.SKU,
          skuType: internalSku!.skuType,
        };
      });

    disassociationInput = [
      ...gtinDisassociations,
      ...internalSkuDisassociations,
    ];

    trackPromise(
      associationService
        .disassociateGtinOrSku(disassociationInput)
        .then(operationSuccess)
        .catch(() => message.error("Falha na desassociação de GTINS e/ou SKUS"))
    );
  };

  const buildBaseDisassociationPayload = (
    reasonInput?: string
  ): DisassociateBasePayload => {
    return {
      ...idWrapper,
      reason: reasonInput || "Realizado via tela",
    };
  };

  const fetchDetail = (record: ProductAssociation, page: number) => {
    if (!!record.gtinCode && !!target) {
      let gtin = record.gtinCode.gtin;
      trackPromise(
        associationService.gtinListDetail(
          gtin,
          page,
          filters.term?.join(" "),
          filters.emitterCompanyCodes
        )
      )
        .then((result) => {
          setProductAssociations((oldValues) => {
            const productAssociationsCopy = [...oldValues];
            const filtered = productAssociationsCopy.find(
              (item) =>
                (!!item.gtinCode &&
                  item.gtinCode.gtin === record.gtinCode?.gtin) ||
                (!!item.internalSku?.sku &&
                  item.internalSku.sku === record.internalSku?.sku)
            );
            if (!!filtered) {
              filtered.detail = {
                ...result,
                content: [
                  ...(page === 0 ? [] : filtered.detail?.content || []),
                  ...result.content,
                ],
              };
            }
            return productAssociationsCopy;
          });
        })
        .catch(() => message.error("Erro ao buscar detalhamento"));
    }
  };

  const handleExpandRow = async (
    expanded: boolean,
    record: ProductAssociation
  ) => {
    if (expanded && !record.detail) {
      fetchDetail(record, 0);
    }
  };

  const getDetail = (record: ProductAssociation) => {
    if (
      !record.detail ||
      !record.detail.content ||
      !record.detail.content.length
    ) {
      return <>Nenhum item encontrado</>;
    }
    return (
      <div className={styles.subtable}>
        <PixInfinityTable<ProductAssociationDetail>
          className={styles.detailTable}
          dataSource={record.detail.content}
          columns={detailColumns}
          fetchMoreData={() => fetchDetail(record, record.detail!.page + 1)}
          last={!!record.detail.last}
          notSelectable={true}
          resizable={false}
          rowKey="transactionItemHash"
          showHeader={false}
        />
      </div>
    );
  };

  const getColumnsGroup = () => {
    let defaultColumns: React.ReactElement<
      ColumnGroupProps<ProductAssociation>
    >[] = [
      <ColumnGroup title="Dados de captura" width={300}>
        <Column
          key="col_codes"
          title="SKU"
          dataIndex="sku"
          align="center"
          width={150}
          ellipsis
          render={(_: string, item: ProductAssociation) => codeTag(item)}
        />
        <Column
          key="col_description"
          title="Descrição"
          dataIndex="description"
          width={150}
          render={(_: string, item: ProductAssociation) => (
            <>
              <Text
                ellipsis={{ tooltip: item.internalSku?.description }}
                style={{ padding: "10px" }}
              >
                {item.internalSku?.description}
              </Text>
            </>
          )}
        />
        <Column
          key="col_cnpj"
          title="CNPJ"
          dataIndex="emitterCompanyCode"
          align="center"
          width={100}
          ellipsis
          render={(value: string, item: ProductAssociation) =>
            getInfo(
              item.internalSku?.emitterCompanyCode,
              item.internalSku?.emitterCompanyDescription
            )
          }
        />
        <Column
          key="col_bu"
          title="BU"
          dataIndex="bu"
          align="center"
          width={100}
          ellipsis
          render={(_: string, item: ProductAssociation) =>
            item.internalSku?.bus?.join(", ")
          }
        />
      </ColumnGroup>,
      <ColumnGroup title="Dados de embalagem" width={300}>
        <Column
          key="col_packing_unit"
          title={
            <Tooltip title="Unidade de medida faturada">
              <span>UM</span>
            </Tooltip>
          }
          dataIndex={["gtinCode", "packing", "unit"]}
          align="center"
          width={100}
          ellipsis
        />
        <Column
          key="col_packing_size"
          title={
            <Tooltip title="Quantidade da embalagem faturada">
              <span>Quantidade</span>
            </Tooltip>
          }
          dataIndex={["gtinCode", "packing", "size"]}
          align="center"
          width={100}
          ellipsis
        />
        <Column
          key="col_packing_unit_size"
          title={
            <Tooltip title="Conversão da unidade para unidade menor">
              <span>Conv</span>
            </Tooltip>
          }
          dataIndex={["gtinCode", "packing", "unitSize"]}
          align="center"
          width={100}
          ellipsis
        />
      </ColumnGroup>,

      <Column
        fixed="right"
        key="col_actions"
        width={30}
        render={(_: string, item: ProductAssociation) =>
          item.gtinCode ? (
            <Button
              id="btn-edit-packing"
              type="text"
              onClick={() => {
                setEditPackingModalOpen(true);
                setSelectedAssociation(item);
              }}
              icon={<EditOutlined />}
            />
          ) : (
            <></>
          )
        }
      />,
    ];

    return defaultColumns;
  };

  return (
    <>
      <Row justify="end" wrap={false} style={{ height: "8%", padding: "10px" }}>
        <Button
          icon={<UnorderedListOutlined />}
          id="btn-disassociation"
          type="primary"
          onClick={() => setDisassociationModalOpen(!isDisassociationModalOpen)}
          disabled={!selectedAssociations?.length}
        >
          Desassociar/Reassociar
        </Button>
      </Row>

      <PixInfinityTable<ProductAssociation>
        columnsGroups={getColumnsGroup()}
        dataSource={productAssociations}
        expandable={{
          expandIconColumnIndex: 0,
          expandedRowRender: getDetail,
          expandedRowKeys,
          indentSize: 0,
          onExpand: handleExpandRow,
          onExpandedRowsChange: (keys) => setExpandedRowKeys([...keys]),
          rowExpandable: (record) => !!record.gtinCode,
        }}
        fetchMoreData={() => setPage((oldpage) => oldpage + 1)}
        last={lastPage}
        resizable={false}
        rowKey="transactionIdentifier"
        bordered
        multiSelection
        handleSelectedItems={setSelectedAssociations}
      />
      <DisassociationAlertModal
        visible={isDisassociationModalOpen}
        target={target}
        handleCancelClick={() => {
          setDisassociationModalOpen(false);
        }}
        selectedAssociations={selectedAssociations}
        onDisassociationClick={handleDisassociation}
      />
      <Modal
        centered
        className={styles.customModalStyle}
        title="Embalagens"
        visible={isEditPackingModalOpen && !!setSelectedAssociation}
        width={1000}
        destroyOnClose
        footer={null}
        closable={false}
      >
        <GtinPackingEditTable
          gtins={
            selectedAssociation?.gtinCode ? [selectedAssociation.gtinCode] : []
          }
          afterSubmit={(gtins) => {
            fetchProductAssociateData();
            if (gtins.length) {
              setSelectedAssociation({
                ...selectedAssociation!,
                gtinCode: gtins[0],
              });
            }
            setEditPackingModalOpen(false);
          }}
          afterCancel={() => setEditPackingModalOpen(false)}
        />
      </Modal>
    </>
  );
};
