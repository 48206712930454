import axios, { AxiosRequestConfig } from "axios";
import { CORE_LOAD_BALANCER_URL } from "config/config";
import { DataClusterFilter } from "pages/DataClusters/components/DataClusterAdvancedFilters";
import { DataClusterDetailFilter } from "pages/DataClusters/components/DataClusterDetailAdvancedFilters";
import BookProduct from "shared/models/BookProduct";
import DataCluster from "shared/models/DataCluster";
import DataClusterBase from "shared/models/DataClusterBase";
import { objectToParams } from "util/query.param.util";
import { PixResponsePage } from "./PixResponse";

const BASE_PATH = CORE_LOAD_BALANCER_URL + "/data-cluster";

class DataClusterService {
  async listBooks(page: number, dataClusterFilter: DataClusterFilter) {
    const body: AxiosRequestConfig = {
      method: "GET",
      url: BASE_PATH + "/books",
      params: objectToParams({
        page,
        ...dataClusterFilter,
      }),
    };
    const r = await axios.request<PixResponsePage<DataCluster>>(body);
    return r.data;
  }

  async listBooksProducts(
    page: number,
    dataClusterDetailFilter: DataClusterDetailFilter
  ) {
    const body: AxiosRequestConfig = {
      method: "GET",
      url: BASE_PATH + "/books/products",
      params: objectToParams({
        page,
        ...dataClusterDetailFilter,
      }),
    };
    const r = await axios.request<PixResponsePage<BookProduct>>(body);
    return r.data;
  }

  async detailBooksCluster(
    dataClusterId: number,
    page: number,
    dataClusterDetailFilter: DataClusterDetailFilter,
    size = 20
  ) {
    const body: AxiosRequestConfig = {
      method: "GET",
      url: BASE_PATH + "/books/" + dataClusterId,
      params: objectToParams({ page, ...dataClusterDetailFilter, size }),
    };
    const r = await axios.request<PixResponsePage<BookProduct>>(body);
    return r.data;
  }

  async create(dataCluster: DataClusterBase) {
    let url = BASE_PATH;
    let body = {
      url,
      data: { ...dataCluster, dataClusterType: "VEJA", bu: "LIVROS" },
    };
    const r = await axios.request<DataCluster>({ ...body, method: "POST" });
    return r.data;
  }

  async activateOrDeactivate(dataCluster: DataCluster, deleted: boolean) {
    const r = await axios.request({
      method: "PATCH",
      url: `${BASE_PATH}/${dataCluster.dataClusterId}`,
      data: deleted,
    });
    return r.data;
  }

  async update(dataCluster: DataClusterBase) {
    const r = await axios.request<DataClusterBase>({
      method: "PUT",
      url: `${BASE_PATH}/${dataCluster.dataClusterId}`,
      data: dataCluster,
    });
    return r.data;
  }

  async moveDataClusterToBlackList(dataCluster: DataCluster) {
    const r = await axios.request({
      method: "PATCH",
      url: `${BASE_PATH}/books/${dataCluster.dataClusterId}/black-list`,
    });
    return r.data;
  }

  async moveItemToBlackList(bookProduct: BookProduct) {
    const r = await axios.request({
      method: "PATCH",
      url: `${BASE_PATH}/books/products/${bookProduct.productId}/black-list`,
    });
    return r.data;
  }
}

const dataClusterService = new DataClusterService();
export default dataClusterService;
