import { Col, Modal, Row } from "antd";
import { FileUploadDragger } from "components/FileUploadDragger/FileUploadDragger";
import { ImageList } from "containers/ImageList/ImageList";
import { useImages } from "hook/ImagesHook";
import React, { useEffect, useState } from "react";
import { SimpleImage } from "shared/models/Asset";
import { ProductListItem } from "shared/models/Product";
import { ImageTagSelector } from "./ImageTagSelector";

interface ImageDetailModalProps {
  product?: ProductListItem;
  onCancel: () => void;
  refresh: () => void;
}

export const ImageDetailModal = ({
  product,
  onCancel,
  refresh,
}: ImageDetailModalProps) => {
  const [selected, setSelected] = useState<SimpleImage>();

  const { images, last, loadMore, getDetails, page } = useImages();

  useEffect(() => {
    setSelected(undefined);
    getDetails(product?.productId, 0);
  }, [getDetails, product]);

  return (
    <Modal
      visible={!!product}
      onCancel={onCancel}
      title={product?.description}
      width="90%"
      footer={false}
      destroyOnClose
    >
      {!!product && (
        <Row gutter={8}>
          <Col xs={24} sm={6}>
            {!!selected?.imageUrl && (
              <img
                alt={product.description}
                src={selected?.imageUrl}
                width="100%"
                height="auto"
              />
            )}
            {!!selected && (
              <ImageTagSelector
                imageDetail={selected}
                refresh={() => getDetails(product.productId, page)}
              />
            )}
            <FileUploadDragger
              productId={product.productId}
              refresh={() => {
                refresh();
                getDetails(product.productId, page);
              }}
            />
          </Col>
          <Col xs={24} sm={18}>
            <ImageList
              refresh={() => {
                refresh();
                getDetails(product.productId, page);
              }}
              selected={selected}
              productId={product.productId}
              setSelected={setSelected}
              description={product.description}
              mainImageUrl={product.imageUrl}
              images={images}
              last={last}
              loadMore={loadMore}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};
