import React, { ReactNode } from "react";
import styles from "./Input.module.scss";

import _omit from "lodash/omit";
import cx from "classnames";

import { Input as AntdInput } from "antd";
import { InputProps as AntdInputProps } from "antd/lib/input";

interface InputProps extends AntdInputProps {
  label?: string | ReactNode;
  error?: string | null;
  viewOnly?: boolean;
}

export const Input = (props: InputProps) => {
  const { className, disabled, error, label, viewOnly } = props;
  const passableProps = _omit(props, ["viewOnly"]);
  const inputStyle = cx(styles.input, className, {
    [styles.inputError]: error,
    [styles.viewOnly]: viewOnly,
  });
  return (
    <div className={cx("pix-input-wrapper", styles.wrapper)}>
      {label && <p className={styles.label}>{label}</p>}
      <AntdInput
        {...passableProps}
        className={inputStyle}
        disabled={disabled || viewOnly}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
