import { Select as AntdSelect, message } from "antd";
import { Input } from "components/Input";
import { InputClickToCount } from "components/InputClickToCount/InputClickToCount";
import { PixTextArea } from "components/PixTextArea";
import { Select } from "components/Select";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import CancelAndSave from "containers/CancelAndSave/CancelAndSave";
import { useFormik } from "formik";
import React, { useState } from "react";
import { CountStatusEnum } from "shared/enum/CountStatusEnum";
import {
  CharacteristicById,
  newCharacteristic,
} from "shared/models/Characteristic";
import { AttributeFormMetadata } from "shared/models/User";
import { NameAlias } from "shared/services/SelectOptionType";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { utcToPtBrDate } from "util/date.util";
import * as Yup from "yup";
import { characteristicService } from "../../../shared/services/CharacteristicService";
import styles from "./CreateCharacteristics.module.scss";

export interface CreateCharacteristicsProps {
  onSubmit: (values: CharacteristicById) => void;
  onCancel: () => void;
  selected?: CharacteristicById;
  types: NameAlias[];
}

const validationSchema = Yup.object({
  description: Yup.string().trim().required("Campo obrigatório"),
  type: Yup.string().required("Campo obrigatório"),
  comment: Yup.string()
    .nullable()
    .max(2000, "Comentário não pode exceder 2000 caracteres"),
});

export const CreateCharacteristics = ({
  onSubmit: sendData,
  onCancel,
  selected,
  types,
}: CreateCharacteristicsProps) => {
  const [totalProducts, setTotalProducts] = useState<number>();
  const [countStatus, setCountStatus] = useState<CountStatusEnum>(
    CountStatusEnum.INIT_STATUS
  );
  const [attributeMetada] = useState<AttributeFormMetadata | null>(
    getEntityPermissions<AttributeFormMetadata>("attribute")
  );

  const getById = () => {
    if (selected) {
      return selected;
    }
    return {
      ...newCharacteristic,
    } as CharacteristicById;
  };

  const initialValues = getById();
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      sendData(values);
    },
  });

  const handleCancelClick = () => {
    resetForm();
    onCancel();
  };

  const countProducts = () => {
    setCountStatus(CountStatusEnum.LOADING_STATUS);
    characteristicService
      .countUsages(initialValues.id)
      .then((response) => {
        setTotalProducts(response.count);
        setCountStatus(CountStatusEnum.COMPLETED);
      })
      .catch(() => {
        setCountStatus(CountStatusEnum.ERROR_STATUS);
        message.error(CountStatusEnum.ERROR_STATUS);
      });
  };

  return (
    <div className={styles.wrapper}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Input
          autoFocus={true}
          error={touched.description ? errors.description : null}
          id="description"
          label="Nome"
          name="description"
          onBlur={() => setFieldTouched("description")}
          onChange={handleChange}
          placeholder="Nome"
          tabIndex={1}
          value={values.description}
          disabled={selected?.readOnly}
        />

        <Select<string>
          allowClear
          error={errors.type ? errors.type : null}
          id="type"
          label="Tipo de Característica"
          placeholder="Tipo de Característica"
          onChange={(value: string) => setFieldValue("type", value)}
          onBlur={() => setFieldTouched("type")}
          showSearch
          tabIndex={2}
          value={values.type}
          disabled={selected?.readOnly}
        >
          {types.map((type) => (
            <AntdSelect.Option key={type.name} value={type.name}>
              {type.alias}
            </AntdSelect.Option>
          ))}
        </Select>

        <PixTextArea
          id="comment-text-area"
          label="Comentário"
          value={values.comment ?? ""}
          onChange={(e) => setFieldValue("comment", e.target.value)}
          maxLength={2000}
        />

        {selected?.id && (
          <>
            <Input
              name="lastModified"
              id="line-last-modified"
              label="Última atualização"
              placeholder="Última atualização"
              value={utcToPtBrDate(values.lastModified)}
              disabled
            />
            <InputClickToCount
              countStatus={countStatus}
              entity="produtos"
              onCount={countProducts}
              total={totalProducts}
            />
            <YehSwitch
              style={{ marginTop: 16 }}
              label="Status"
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
              checked={!values.deleted}
              onClick={(f) => setFieldValue("deleted", !f)}
              disabled={!attributeMetada?.editDeleted}
            />
          </>
        )}
        <div className={styles.divider} />
        <CancelAndSave
          onCancel={handleCancelClick}
          disabled={
            selected?.readOnly ||
            (!attributeMetada?.create && !attributeMetada?.update)
          }
        />
      </form>
    </div>
  );
};
