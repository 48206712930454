import React from "react";
import cx from "classnames";
import styles from "./SubMenuItem.module.scss";

export interface SubMenuItem {
  id: string;
  title: string;
}
interface SubMenuItemProps {
  color?: string;
  id: string;
  isSelected?: boolean;
  onClick: (id: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
}

export const SubMenuItem = ({
  color,
  id,
  isSelected = false,
  onClick,
  title,
}: SubMenuItemProps) => {
  const itemStyle = cx(styles.wrapper, {
    [styles.selectedItem]: isSelected,
  });
  return (
    <li
      key={`${id}-${title}`}
      id={id}
      title={title}
      data-testid="submenu-item-wrapper"
      onClick={onClick}
      className={itemStyle}
      style={{
        borderLeft: isSelected ? `3px solid ${color}` : undefined,
      }}
    >
      <p style={{ color }}>{title}</p>
    </li>
  );
};
