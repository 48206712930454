import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Space } from "antd";
import { ExpandableConfig, SorterResult } from "antd/lib/table/interface";
import { SelectBooleanValue } from "components/FormItem/FormItemBoolean";
import {
  PixColumn,
  PixInfinityTable,
} from "components/PixInfinityTable/PixInfinityTable";
import { SearchTopBar } from "containers/SearchTopBar/SearchTopBar";
import React, { createRef, ReactNode, useEffect } from "react";
import styles from "./CrudListContent.module.scss";

type ProtectedCrudAttributes = {
  protectCreate: boolean;
};

interface CrudListContentProps<RecordType extends object> {
  advancedFilters?: { filter: string; color: string }[];
  actionButtons?: ReactNode;
  columns: PixColumn<RecordType>[];
  data: RecordType[];
  expandable?: ExpandableConfig<RecordType>;
  fetchMoreData: () => void;
  filterProps: {
    terms: string[];
    advancedFilters?: any;
    handleFilterIconClick?: () => void;
    handleClearFilters?: () => void;
  };
  deletedStatusProps?: {
    onChangeDeletedStatus: (newStatus: SelectBooleanValue) => void;
    value?: SelectBooleanValue;
    disabled?: boolean;
  };
  handleCreateNewClick?: () => void;
  handleRemoveFilter?: (filter: string) => void;
  handleSearch: (value: string) => void;
  handleSortChange?: (
    sort: SorterResult<RecordType> | SorterResult<RecordType>[]
  ) => void;
  isExpanded: boolean;
  last: boolean;
  multiselect?: boolean;
  rowKey: string;
  secondaryActions?: ReactNode;
  selectedRows?: number[];
  setExpanded: (expanded: boolean) => void;
  setSelected: (value: RecordType[]) => void;
  title: string;
  protectedCrudAttributes?: ProtectedCrudAttributes;
  searchSuffix?: ReactNode;
}

const CrudListContent = <RecordType extends object>({
  advancedFilters,
  actionButtons,
  data,
  isExpanded,
  filterProps,
  deletedStatusProps,
  handleCreateNewClick,
  handleRemoveFilter,
  handleSearch,
  multiselect = false,
  secondaryActions,
  selectedRows,
  setExpanded,
  setSelected,
  searchSuffix,
  title,
  protectedCrudAttributes,
  ...otherProps
}: CrudListContentProps<RecordType>) => {
  const tableRef = createRef<PixInfinityTable<RecordType>>();

  useEffect(() => {
    selectedRows &&
      tableRef &&
      tableRef.current &&
      tableRef.current.selectRows(selectedRows);
  }, [selectedRows]); // eslint-disable-line

  const handleClickCreate = () => {
    tableRef && tableRef.current && tableRef.current.clearSelections();
    handleCreateNewClick?.();
  };

  const getCreateButton = () => {
    if (!handleCreateNewClick) {
      return;
    }

    return (
      <Button
        id="create-new-item"
        type="primary"
        className={styles.action}
        onClick={handleClickCreate}
        hidden={protectedCrudAttributes?.protectCreate}
      >
        <PlusCircleOutlined />
        Cadastrar
      </Button>
    );
  };

  return (
    <Card
      id="main-list-content"
      bordered={false}
      title={
        <div className={styles.title}>
          <h2>{title}</h2>
          <div className={styles.actionButtons}>
            <Space size={6}>
              {actionButtons}
              {getCreateButton()}
            </Space>
          </div>
        </div>
      }
      className={styles.cardList}
    >
      <div className={styles.divider} />
      <SearchTopBar
        isExpanded={isExpanded}
        handleSearch={handleSearch}
        handleRemoveFilter={handleRemoveFilter}
        secondaryActions={secondaryActions}
        filterProps={filterProps}
        setExpanded={setExpanded}
        advancedFilters={advancedFilters}
        deletedStatusProps={deletedStatusProps}
        searchSuffix={searchSuffix}
      />
      <PixInfinityTable<RecordType>
        dataSource={data}
        className={styles.table}
        handleSelectedItems={setSelected}
        multiSelection={multiselect}
        ref={tableRef}
        {...otherProps}
      />
    </Card>
  );
};

CrudListContent.defaultProps = {
  multiselect: false,
};

export default CrudListContent;
