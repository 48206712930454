import { Button, Col, Popover, Row, Tag, Tooltip } from "antd";
import cx from "classnames";
import { Icons } from "components/Icons";
import { SearchInput } from "components/SearchInput";
import React, { useEffect } from "react";
import { removeQueryParam, updateQueryParam } from "util/query.param.util";
import styles from "./PixSearchByTerm.module.scss";

type Props = {
  advancedFilters?: { filter: string; color: string }[];
  filters: string[];
  inputValue: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  onRemoveFilter?: (value: string) => void;
  onSearch: (value: string) => void;
  tagColor?: string | ((tag: string) => string);
  suffix?: React.ReactNode;
};

const PixSearchByTerm = ({
  advancedFilters,
  filters,
  inputValue,
  onChange,
  onClear,
  onRemoveFilter,
  onSearch,
  tagColor = "#32325d",
  suffix,
}: Props) => {
  const clearButton = (
    <Tooltip title="Limpar filtros">
      <Button
        className={styles.clearButton}
        type="link"
        icon={<Icons name="clearOutlined" />}
        onClick={onClear}
      />
    </Tooltip>
  );
  useEffect(() => {
    if (filters.length > 0) {
      updateQueryParam("term", filters);
    } else {
      removeQueryParam("term");
    }
  }, [filters]);
  return (
    <div className={cx("pix-search-by-term", styles.search)}>
      <Col span={22}>
        <Row>
          <SearchInput
            placeholder="Digite e tecle ENTER"
            className={styles.searchInput}
            onPressEnter={onSearch}
            onChange={onChange}
            value={inputValue}
            suffix={
              <>
                {!!suffix && suffix}
                {onClear ? clearButton : undefined}
              </>
            }
          />
          {!!inputValue?.length && (
            <Popover
              className={styles.helpTermPopover}
              content={
                <>
                  Para excluir um termo da pesquisa, digite "--" + termo para
                  excluir. Exemplo:
                  <p className={styles.helpTermExample}>
                    chocolate amargo --branco
                  </p>
                </>
              }
              title=""
            >
              <Icons name="questionCircleOutlined" />
            </Popover>
          )}
        </Row>
        <Row className={styles.termRows}>
          {onRemoveFilter && (
            <div className={styles.pills}>
              {filters.map((item, idx) => (
                <Tag
                  key={idx}
                  closable
                  className={styles.pill}
                  color={
                    typeof tagColor === "string" ? tagColor : tagColor(item)
                  }
                  onClose={(evt: React.BaseSyntheticEvent) => {
                    evt.preventDefault();
                    onRemoveFilter(item);
                  }}
                >
                  {item}
                </Tag>
              ))}
              {advancedFilters &&
                advancedFilters.map((item, idx) => (
                  <Tag
                    key={`advanced_${idx}`}
                    className={styles.pill}
                    color={item.color}
                  >
                    {item.filter}
                  </Tag>
                ))}
            </div>
          )}
        </Row>
      </Col>
    </div>
  );
};

export default PixSearchByTerm;
