import { CheckSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import { Card, message, Popconfirm, Tooltip } from "antd";
import { Icons } from "components/Icons";
import { YehImage } from "components/YehImage/YehImage";
import React from "react";
import { trackPromise } from "react-promise-tracker";
import { SimpleImage } from "shared/models/Asset";
import { gtinCodeService } from "shared/services/GtinCodeService";
import { productService } from "shared/services/ProductService";

import styles from "./ImageDetailModal.module.scss";

interface ImageDetailProps {
  gtin?: string;
  productId: number;
  image: SimpleImage;
  description?: string;
  lastSelected: boolean;
  onClick: () => void;
  refreshList: () => void;
  afterUpdate: () => void;
  afterDelete?: (modifiedAssetId?: number) => void;
  mainImageUrl?: string;
}

export const ImageDetail = ({
  gtin,
  image,
  productId,
  lastSelected,
  description,
  mainImageUrl,
  onClick,
  afterUpdate,
  afterDelete,
  refreshList,
}: ImageDetailProps) => {
  const makeMainAsset = (assetId: number) => {
    if (!!gtin) {
      trackPromise(gtinCodeService.updateMainAsset(gtin, assetId)).then(() => {
        refreshList();
      });
    } else {
      trackPromise(productService.updateMainAsset(productId, assetId)).then(
        afterUpdate
      );
    }
  };

  const removeAsset = (productId: number, assetId: number) => {
    trackPromise(productService.removeAsset(productId, assetId))
      .then(() => {
        message.success("Imagem removida com sucesso!");
        afterDelete?.(assetId);
      })
      .catch(() => message.error("Erro ao remover imagem!"));
  };

  // For GTIN, we simply compare the image URL. For products, we check if the asset is main asset
  const isMainImage = !!gtin
    ? image.imageUrl === mainImageUrl
    : image.mainAsset;

  const actions = [
    <Tooltip overlay="Tornar imagem principal">
      <CheckSquareOutlined
        key="main"
        onClick={() => makeMainAsset(image.assetId)}
      />
    </Tooltip>,
  ];

  if (!gtin) {
    actions.push(
      <Popconfirm
        title="Deseja remover imagem do Produto?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => removeAsset(productId, image.assetId)}
      >
        <DeleteOutlined key="remove" />
      </Popconfirm>
    );
  }

  return (
    <Card
      hoverable
      bodyStyle={{
        border: lastSelected ? "2px solid #1890ff" : undefined,
      }}
      onClick={onClick}
      actions={actions}
    >
      {isMainImage && (
        <Icons className={styles.iconMainImage} name="checkCircleFilled" />
      )}
      <YehImage description={description} imageUrl={image.imageUrl} />
    </Card>
  );
};
