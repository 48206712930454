import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Button, Col, Input, message, Row, Tooltip } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import { Icons } from "components/Icons";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { DefaultContent, DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import BookProduct from "shared/models/BookProduct";
import DataCluster from "shared/models/DataCluster";
import DataClusterBase from "shared/models/DataClusterBase";
import dataClusterService from "shared/services/DataClusterService";
import { getInitialFilter } from "util/filter.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import CreateOrEditDataCluster from "./components/CreateOrEditDataCluster";
import DataClusterAdvancedFilters, {
  DataClusterFilter,
} from "./components/DataClusterAdvancedFilters";
import DataClusterListDetailAdvancedFilters, {
  DataClusterDetailFilter,
} from "./components/DataClusterDetailAdvancedFilters";
import DataClusterList from "./components/DataClusterList";
import DataClusterListDetail from "./components/DataClusterListDetail";
import styles from "./DataClusters.module.scss";

type DataClusterProps = DefaultPage & RouteComponentProps;

const DEFAULT_LIST_SPAN = 6;
const DEFAULT_DETAIL_SPAN = 18;
const DEFAULT_TOTAL_SPAN = 24;

// We do not put publisherIds in the query parameter since we'd need to put corresponding options as well
const getInitialDataClusterFilter = () =>
  getInitialFilter("dataClusterFilter", DataClusterFilter);

const getInitialDataClusterDetailFilter = () =>
  getInitialFilter("dataClusterDetailFilter", DataClusterDetailFilter);

const DataClusters = ({ title }: DataClusterProps) => {
  const [dataClusterFilter, setDataClusterFilter] = useState<DataClusterFilter>(
    getInitialDataClusterFilter()
  );
  const [
    dataClusterDetailFilter,
    setDataClusterDetailFilter,
  ] = useState<DataClusterDetailFilter>(getInitialDataClusterDetailFilter());
  const [term, setTerm] = useState(dataClusterFilter.term);
  const [description, setDescription] = useState(dataClusterDetailFilter.term);
  const [dataClusters, setDataClusters] = useState<DataCluster[]>([]);
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);
  const [selectedDataCluster, setSelectedDataCluster] = useState<DataCluster>();
  const [detailLast, setDetailLast] = useState(true);
  const [detailPage, setDetailPage] = useState(0);
  const [bookClusterDetails, setBookProducts] = useState<BookProduct[]>([]);
  const [dataClusterModalOpen, setDataClusterModalOpen] = useState(false);
  const [dataClusterDetailModalOpen, setDataClusterDetailModalOpen] = useState(
    false
  );
  const [isCreating, setIsCreating] = useState(false);
  const [editDataCluster, setEditDataCluster] = useState<DataCluster>();

  const { setCurrentTitle } = useContext(PageContext);

  useEffect(() => {
    setCurrentTitle(title);
  }, [title, setCurrentTitle]);

  const [isDetailExpanded, setIsDetailExpanded] = useState(false);

  const fetchDetailData = useCallback(
    (
      dataClusterId: number,
      page: number,
      dataClusterDetailFilter: DataClusterDetailFilter
    ) => {
      trackPromise(
        dataClusterService
          .detailBooksCluster(dataClusterId, page, dataClusterDetailFilter)
          .then((response) => {
            setBookProducts((d) => [
              ...(page === 0 ? [] : d),
              ...response.content,
            ]);
            setDetailLast(response.last);
            setDetailPage(page);
          })
      ).catch(() => message.error("Erro ao buscar detalhes"));
    },
    []
  );

  const handleSearchDataCluster = (dataClusterFilter: DataClusterFilter) => {
    setBookProducts([]);
    setSelectedDataCluster(undefined);
    setPage(0);
    fetchData(0, dataClusterFilter);
  };

  const handleSearchDetail = (
    dataClusterDetailFilter: DataClusterDetailFilter
  ) => {
    setDetailPage(0);

    if (!!selectedDataCluster) {
      fetchDetailData(
        selectedDataCluster.dataClusterId,
        0,
        dataClusterDetailFilter
      );
    }
  };

  const handleSelectDataCluster = useCallback(
    (dataCluster?: DataCluster, oldDataClusterId?: number) => {
      setSelectedDataCluster(dataCluster);

      if (
        dataCluster?.dataClusterId != null &&
        dataCluster?.dataClusterId !== oldDataClusterId
      ) {
        fetchDetailData(dataCluster.dataClusterId, 0, dataClusterDetailFilter);
      }
    },
    [fetchDetailData, dataClusterDetailFilter]
  );

  const fetchData = useCallback(
    (page: number, dataClusterFilter: DataClusterFilter) => {
      trackPromise(dataClusterService.listBooks(page, dataClusterFilter))
        .then((response) => {
          // Using trackpromise and the response here removes search params for some reason
          // Maybe it could be fixed by updating react-scripts and versions.
          setDataClusters((d) => [
            ...(page === 0 ? [] : d),
            ...response.content,
          ]);
          setLast(response.last);
          setPage(page);
        })
        .catch(() => message.error("Falha ao buscar agrupamentos de livros"));
    },
    []
  );

  const handleDataClusterModalSubmit = (newFilters: DataClusterFilter) => {
    setTerm(newFilters.term);
    setDataClusterModalOpen(false);
    setDataClusterFilter(newFilters);
    handleSearchDataCluster(newFilters);
  };

  const handleDataClusterDetailModalSubmit = (
    newFilters: DataClusterDetailFilter
  ) => {
    handleSearchDetail(newFilters);
    setDataClusterDetailFilter(newFilters);
    setDescription(newFilters.term);
    setDataClusterDetailModalOpen(false);
  };

  const closeCreateDataCluster = (dataCluster?: DataClusterBase) => {
    setIsCreating(false);
    if (!dataCluster) {
      return;
    }
    if (!!editDataCluster) {
      trackPromise(dataClusterService.update(dataCluster)).then((response) => {
        setDataClusterFilter({});
        fetchData(0, {});
        clearSelectedDataCluster();
        message.success(`Grupo ${response.description} editado com sucesso`);
      });
    } else {
      trackPromise(dataClusterService.create(dataCluster)).then((response) => {
        setDataClusterFilter({});
        fetchData(0, {});
        clearSelectedDataCluster();
        message.success(`Grupo ${response.description} criado com sucesso`);
      });
    }
  };

  const handleSearchTerm = () => {
    let newFilters = { ...dataClusterFilter, term };
    setDataClusterFilter(newFilters);
    handleSearchDataCluster(newFilters);
  };

  const handleDetailTermSearch = () => {
    let newFilters = {
      ...dataClusterDetailFilter,
      term: description,
    };
    setDataClusterDetailFilter(newFilters);
    handleSearchDetail(newFilters);
  };

  const clearSelectedDataCluster = () => {
    setSelectedDataCluster(undefined);
    setBookProducts([]);
  };

  const handleEditOrCreateDataCluster = (dataCluster?: DataCluster) => {
    setEditDataCluster(dataCluster);
    setIsCreating(true);
  };

  // Load initial data
  useEffect(() => fetchData(0, getInitialDataClusterFilter()), [fetchData]);

  useEffect(() => {
    putObjectAsQueryParam(dataClusterFilter, "dataClusterFilter");
  }, [dataClusterFilter]);

  useEffect(() => {
    putObjectAsQueryParam(dataClusterDetailFilter, "dataClusterDetailFilter");
  }, [dataClusterDetailFilter]);

  return (
    <>
      <DefaultContent>
        <Row gutter={[2, 0]}>
          <Col
            span={isDetailExpanded ? 0 : DEFAULT_LIST_SPAN}
            className={styles.cardColumn}
          >
            <div className={styles.card}>
              <div className={styles.title}>
                Grupos &nbsp;
                <PlusCircleFilled
                  onClick={() => handleEditOrCreateDataCluster()}
                />
              </div>
              <Row className={styles.headerSearch}>
                <Col span={20}>
                  <Input
                    className={styles.searchInput}
                    placeholder="Pesquisar Grupo"
                    suffix={<SearchOutlined onClick={handleSearchTerm} />}
                    value={term}
                    onChange={(e) => {
                      e.persist(); // Without this, updating state breaks
                      setTerm(e.target.value);
                    }}
                    onPressEnter={handleSearchTerm}
                  />
                </Col>
                <Col span={4}>
                  <FilterIcon
                    filters={dataClusterFilter}
                    onClick={() => setDataClusterModalOpen(true)}
                  />
                  <DataClusterAdvancedFilters
                    dataClusterFilter={dataClusterFilter}
                    onCancel={() => setDataClusterModalOpen(false)}
                    onOk={handleDataClusterModalSubmit}
                    visible={dataClusterModalOpen}
                  />
                </Col>
              </Row>
              <div className={styles.listItems}>
                <DataClusterList
                  clearSelectedDataCluster={clearSelectedDataCluster}
                  dataClusters={dataClusters}
                  selectedDataCluster={selectedDataCluster}
                  setEditDataCluster={(dataCluster) => {
                    handleEditOrCreateDataCluster(dataCluster);
                  }}
                  refresh={() => {
                    fetchData(0, {});
                    setDataClusterFilter({});
                    clearSelectedDataCluster();
                  }}
                  setSelectedDataCluster={(dataCluster?: DataCluster) =>
                    handleSelectDataCluster(
                      dataCluster,
                      selectedDataCluster?.dataClusterId
                    )
                  }
                />
              </div>
              <LoadMoreButton
                onFetchMoreData={() => fetchData(page + 1, dataClusterFilter)}
                last={last}
              />
            </div>
          </Col>
          <Col
            span={isDetailExpanded ? DEFAULT_TOTAL_SPAN : DEFAULT_DETAIL_SPAN}
            className={styles.cardColumn}
          >
            <div className={styles.card}>
              <div className={styles.title}>
                Detalhe &nbsp;
                <PlusCircleFilled
                  title="Adicionar produtos ao grupo"
                  onClick={() => {
                    !!selectedDataCluster &&
                      handleEditOrCreateDataCluster(selectedDataCluster);
                  }}
                  style={!selectedDataCluster ? { opacity: 0 } : {}}
                />
              </div>
              <Row className={styles.headerSearch}>
                <Col span={20}>
                  <Input
                    className={styles.searchInput}
                    placeholder="Pesquisar Produto"
                    suffix={<SearchOutlined onClick={handleDetailTermSearch} />}
                    value={description}
                    disabled={!selectedDataCluster}
                    title={
                      !selectedDataCluster ? "Selecione um grupo primeiro" : ""
                    }
                    onChange={(e) => {
                      e.persist();
                      setDescription(e.target.value);
                    }}
                    onPressEnter={handleDetailTermSearch}
                  />
                </Col>
                <Col span={2}>
                  <FilterIcon
                    filters={dataClusterDetailFilter}
                    onClick={() => setDataClusterDetailModalOpen(true)}
                  />
                  <DataClusterListDetailAdvancedFilters
                    dataClusterDetailFilter={dataClusterDetailFilter}
                    onCancel={() => setDataClusterDetailModalOpen(false)}
                    onOk={handleDataClusterDetailModalSubmit}
                    visible={dataClusterDetailModalOpen}
                    parentName="data-clusters"
                  />
                </Col>
                <Col span={2}>
                  <Tooltip title="Expandir" placement="bottom">
                    <Button
                      onClick={() => setIsDetailExpanded(!isDetailExpanded)}
                      type="text"
                    >
                      <Icons
                        name={
                          isDetailExpanded
                            ? "shrinkOutlined"
                            : "arrowsAltOutlined"
                        }
                      />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <div className={styles.listItems}>
                <DataClusterListDetail
                  bookClusterDetails={bookClusterDetails}
                  dataCluster={selectedDataCluster}
                  refresh={() => {
                    fetchData(0, dataClusterFilter);
                    clearSelectedDataCluster();
                  }}
                />
              </div>
              <LoadMoreButton
                onFetchMoreData={() => {
                  fetchDetailData(
                    selectedDataCluster!.dataClusterId,
                    detailPage + 1,
                    dataClusterDetailFilter
                  );
                }}
                last={detailLast}
              />
            </div>
          </Col>
        </Row>
      </DefaultContent>
      <CreateOrEditDataCluster
        isOpen={isCreating}
        onClose={closeCreateDataCluster}
        editDataCluster={editDataCluster}
      />
    </>
  );
};

export default DataClusters;
