import { Alert, message } from "antd";
import { SelectProps } from "antd/lib/select";
import { Select } from "components/Select";
import React, { useEffect, useState } from "react";
import { ValueLabel } from "shared/models/Product";
import { productService } from "shared/services/ProductService";

import { Option } from "rc-select";

type SanitationSelect = {
  label?: string | React.ReactNode;
  warning?: string;
} & SelectProps<string>;

export const SanitationSelect = (props: SanitationSelect) => {
  const [sanitation, setSanitation] = useState<ValueLabel[]>([]);
  const [loading, setLoading] = useState(true);
  const getLabel = () => {
    if (!props.label) return;

    return typeof props.label === "string" ? (
      <div>{props.label}</div>
    ) : (
      props.label
    );
  };
  useEffect(() => {
    productService
      .findSanitation()
      .then(setSanitation)
      .catch(() => message.error("Erro ao buscar saneamentos."))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div id="select-sanitation">
      <Select<string> label={getLabel()} loading={loading} {...props}>
        <Option key="no-value-key" value="">
          {""}
        </Option>
        {sanitation.map((sanitation: ValueLabel) => (
          <Option key={sanitation.value} value={sanitation.value}>
            {sanitation.label}
          </Option>
        ))}
      </Select>
      {props.warning && (
        <Alert message={props.warning} type="warning" showIcon closable />
      )}
    </div>
  );
};
