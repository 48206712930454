import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { Icons } from "components/Icons";
import { SuggestionCard } from "./SuggestionCard";
import styles from "./ItemSuggestion.module.scss";
import { SuggestionFooter } from "./SuggestionFooter";
import { SuggestionDataScience } from "shared/models/Association";

interface ItemSuggestionProps {
  data: SuggestionDataScience[];
  children: React.ReactElement;
  onOk: (value: number, type: string) => void;
}

interface ItemSuggestionContentProps {
  data: SuggestionDataScience[];
  onOk: (value: number, type: string) => void;
}

const ItemSuggestionContent = ({ data, onOk }: ItemSuggestionContentProps) => {
  const [sugestionSelected, setSugestionSelected] = useState<number>();
  const [sugestionTypeSelected, setSugestionTypeSelected] = useState<string>();

  useEffect(() => {
    if (data.length === 1) {
      setSugestionSelected(data[0].entityId);
      setSugestionTypeSelected(data[0].entityType);
    }
  }, [data]);

  return (
    <div>
      <div className={styles.itemContainer}>
        {data.map((suggestion, idx) => {
          // TODO: Remover if abaixo quando liberar associações parciais
          if (suggestion.entityType !== "STANDARD_PRODUCT") {
            return null;
          }
          return (
            <SuggestionCard
              key={`item_suggestion_content_${idx}`}
              data={suggestion}
              isSelected={sugestionSelected === suggestion.entityId}
              onSelect={(selected) => {
                setSugestionSelected(selected);
                setSugestionTypeSelected(suggestion.entityType);
              }}
            />
          );
        })}
      </div>
      <SuggestionFooter
        onOk={() => {
          sugestionSelected && onOk(sugestionSelected, sugestionTypeSelected!);
        }}
      />
    </div>
  );
};

export const ItemSuggestion = ({
  data,
  children,
  onOk,
}: ItemSuggestionProps) => {
  return (
    <Popover
      placement="left"
      overlayClassName={styles.popoverStyling}
      content={<ItemSuggestionContent data={data} onOk={onOk} />}
      title={
        <div className={styles.modalHeader}>
          <Icons name="infoCircleFilled" />
          <h1>Sugestão</h1>
        </div>
      }
    >
      {children}
    </Popover>
  );
};
