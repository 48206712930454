import { Form, Select } from "antd";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  AttributeTypes,
  Characteristic,
  CharacteristicFilters,
} from "shared/models/Characteristic";
import { characteristicService } from "shared/services/CharacteristicService";
import { scrollFunction } from "shared/utils/ScrollUtils";

type AttributeSelectProps = {
  attributeType: AttributeTypes;
  onChange: (attributeId: number, option: any) => void;
  label?: string;
  placeholder?: string;
  value?: number;
};

export const AttributeSelect = ({
  attributeType,
  onChange: onChangeProp,
  label,
  placeholder,
  value,
}: AttributeSelectProps) => {
  const [options, setOptions] = useState<Characteristic[]>([]);
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(true);
  const [
    attributeFilters,
    setAttributeFilters,
  ] = useState<CharacteristicFilters>({ type: attributeType });

  const fetchAttributes = useCallback(
    (page: number, filters: CharacteristicFilters) => {
      trackPromise(
        characteristicService
          .getCharacteristics(
            { type: attributeType, deleted: "false", ...filters },
            page
          )
          .then((response) => {
            setOptions((oldLines) => [
              ...(page > 0 ? oldLines : []),
              ...response.content,
            ]);
            setPage(page);
            setLast(response.last);
          })
      );

      setAttributeFilters(filters);
    },
    [attributeType]
  );

  useEffect(() => {
    fetchAttributes(0, { type: attributeType });
  }, [fetchAttributes, attributeType]);

  const loadMore = () => {
    if (!last) {
      fetchAttributes(page + 1, attributeFilters);
    }
  };

  const debounceSearchBrands = debounce(
    (term: string) => fetchAttributes(0, { term }),
    600
  );

  const onChange = (attributeId: number, option: any) => {
    onChangeProp(attributeId, option);
  };

  return (
    <Form.Item
      label={
        <FilterLabel
          text={label ?? "Característica"}
          htmlFor="search-attributes-select"
        />
      }
      style={{ width: "100%" }}
    >
      <Select<number>
        id="search-attributes-select"
        filterOption={false}
        allowClear
        placeholder={placeholder && "Selecione uma característica"}
        options={options.map((productLine) => ({
          value: productLine.id,
          label: productLine.description,
        }))}
        onChange={onChange}
        onSearch={debounceSearchBrands}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
        value={value}
        showSearch
      />
    </Form.Item>
  );
};
