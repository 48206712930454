import { Button, Card, Empty, Spin, Tooltip } from "antd";
import { Icons } from "components/Icons";
import { PixLink } from "components/PixLink/PixLink";
import React, { useState } from "react";
import { Brand } from "shared/models/Brand";
import { brandService } from "shared/services/BrandService";
import {
  LoadMoreButton,
  LoadMoreButtonProps,
} from "../../../components/LoadMoreButton/LoadMoreButton";
import styles from "./ProductLineComponents.module.scss";

interface LineBrandProps extends LoadMoreButtonProps {
  brandsData?: Brand[];
  deleteBrand: (brand: Brand) => void;
}

export const LineBrand = ({
  deleteBrand,
  brandsData,
  last,
  onFetchMoreData,
}: LineBrandProps) => {
  const [brandCommentary, setBrandCommentary] = useState("");
  const fetchCommentary = (id: number) => {
    setBrandCommentary("");
    brandService
      .getBrandById(id)
      .then((res) => {
        setBrandCommentary(res.description);
      })
      .catch((err) => {
        setBrandCommentary(`Erro ao buscar detalhes - ${err.message}`);
      });
  };

  if (brandsData && brandsData.length === 0) {
    return <Empty description="Nehuma marca atribuída a esta linha." />;
  }

  return (
    <Card className={styles.brandsCard}>
      {brandsData?.map((brand) => (
        <div key={brand.id} className={styles.brandItem}>
          <div className={styles.brandItemActionButtons}>
            <PixLink
              link="/marca"
              label={`${brand.name} - ${brand.manufacturer.name}`}
              params={{ brandId: brand.id }}
            />
          </div>
          <div className={styles.brandItemActionButtons}>
            <Tooltip
              title={!brandCommentary ? <Spin size="small" /> : brandCommentary}
              mouseEnterDelay={0.4}
              onVisibleChange={() => fetchCommentary(brand.id)}
              destroyTooltipOnHide
              placement="topRight"
            >
              <div
                data-testid="line-brand-item-info"
                className={styles.brandItemIcon}
              >
                <Icons name="infoCircleOutlined" />
              </div>
            </Tooltip>
            <Button
              type="link"
              data-testid="line-brand-delete-item"
              onClick={() => deleteBrand(brand)}
              className={styles.brandItemIcon}
            >
              <Icons name="deleteOutlined" />
            </Button>
          </div>
        </div>
      ))}
      <LoadMoreButton last={last} onFetchMoreData={onFetchMoreData} />
    </Card>
  );
};
