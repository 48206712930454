import { MergeCellsOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { Button, Divider, message } from "antd";
import { ActiveInactiveItem } from "components/ActiveInactiveItem/ActiveInactiveItem";
import { ExtractLogsButton } from "components/AuditLogExtract/ExtractLogsButton";
import EmptySelection from "components/EmptySelection/EmptySelection";
import EntityMerge from "components/EntityMerge/EntityMerge";
import { TabItem } from "components/Tabs/Tabs";
import { ToggleMultiSelectButton } from "components/ToggleMultiSelect/ToggleMultiSelectButton";
import { CrudContent } from "containers/CrudContent";
import { CrudCreateComponent } from "containers/CrudCreateComponent";
import CrudListContent from "containers/CrudListContent/CrudListContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { useCharacteristics } from "hook/CharacteristicsHook";
import { AdvancedFiltersModal } from "pages/Characteristics/components/AdvancedFiltersModal";
import { AttributeTab } from "pages/Characteristics/components/AttributeTab";
import { CreateCharacteristics } from "pages/Characteristics/components/CreateCharacteristics";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { EntitiesEnum } from "shared/enum/EntitiesEnum";
import {
  CharacteristicById,
  CharacteristicFilters,
  Characteristic as CharacteristicType,
} from "shared/models/Characteristic";
import { AttributeFormMetadata } from "shared/models/User";
import { characteristicService } from "shared/services/CharacteristicService";
import { NameAlias } from "shared/services/SelectOptionType";
import { MergeRequest } from "shared/types/Merge";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { getEntityPermissions } from "util/UserPermissionUtils";
import { readInitialTab } from "util/yeh.query.param.util";

const tabItems: TabItem[] = [
  {
    title: "Característica",
    value: "characteristic",
  },
  {
    title: "Atributos",
    value: "attributes",
  },
];

const reduceToObject = (list: NameAlias[]) =>
  list.reduce(
    (acum, format) => ({
      ...acum,
      [format.name]: format.alias,
    }),
    {}
  );

type CharacteristicsProps = DefaultPage & RouteComponentProps;

export const Characteristics = ({ title }: CharacteristicsProps) => {
  const {
    characteristics,
    last,
    fetchData,
    fetchMoreData,
    handleRemoveFilter,
    handleSearch,
    types,
    terms,
    filters,
  } = useCharacteristics();
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const [isListExpanded, setListExpanded] = useState(false);
  const [isCreateExpanded, setCreateExpanded] = useState(false);
  const [isWriting, setWriting] = useState(false);
  const [selected, setSelected] = useState<CharacteristicById>();
  const [selectedTab, setSelectedTab] = useState(readInitialTab(tabItems));
  const [typesById, setTypesById] = useState<{ [key: string]: string }>({});
  const [
    attributeUserPermissionsMetadata,
  ] = useState<AttributeFormMetadata | null>(
    getEntityPermissions<AttributeFormMetadata>("attribute")
  );
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<CharacteristicType[]>([]);

  const { setCurrentTitle } = useContext(PageContext);

  const columns = [
    {
      title: "ID CARACTERÍSTICA",
      dataIndex: "id",
    },
    {
      title: "NOME",
      dataIndex: "description",
      sorter: true,
      sortBy: "attributeDescription",
    },
    {
      title: "TIPO DE CARACTERÍSTICA",
      dataIndex: "type",
      sorter: true,
      sortBy: "attributeType",
      render: (formatValue: string) => {
        return <>{typesById[formatValue]}</>;
      },
    },
    {
      key: "active",
      dataIndex: "deleted",
      title: "ATIVO",
      align: "center" as any,
      render: (deleted: boolean) => <ActiveInactiveItem deleted={deleted} />,
      sorter: true,
      sortBy: "deleted",
      width: 62,
    },
  ];

  useEffect(() => {
    setTypesById(reduceToObject(types));
  }, [types]);

  useEffect(() => {
    setCurrentTitle(title);
  }, [title, setCurrentTitle]);

  const handleFilterIconClick = () => {
    setAdvancedFiltersOpen(!advancedFiltersOpen);
  };

  const handleClearFilters = () => {
    fetchData(0, {});
  };

  const getSecondaryActions = () => (
    <ToggleMultiSelectButton
      onChange={(_) => setSelectedRows([])}
      setMultiselectMode={setMultiselectMode}
      multiselectMode={multiselectMode}
    />
  );

  const onClickMergeMode = () => {
    if (isMergeMode) {
      setSelectedRows([]);
    }

    setIsMergeMode(!isMergeMode);
  };

  const getActionButtons = () => {
    return (
      <>
        <ExtractLogsButton
          entityName={EntitiesEnum.ATTRIBUTE}
          entityIds={selectedRows}
          hasPermission={attributeUserPermissionsMetadata?.readAudit}
        />
        <Button
          onClick={onClickMergeMode}
          icon={<MergeCellsOutlined />}
          id="btn-merge"
          hidden={!attributeUserPermissionsMetadata?.merge}
        >
          {isMergeMode ? "Cancelar mesclagem" : "Mesclagem"}
        </Button>
      </>
    );
  };

  const getSearchComponent = () => {
    return (
      <CrudListContent<CharacteristicType>
        actionButtons={getActionButtons()}
        secondaryActions={getSecondaryActions()}
        multiselect={multiselectMode}
        columns={columns}
        data={characteristics}
        isExpanded={isListExpanded}
        fetchMoreData={fetchMoreData}
        filterProps={{
          terms,
          advancedFilters: filters,
          handleFilterIconClick,
          handleClearFilters,
        }}
        last={last}
        handleCreateNewClick={handleCreateNewClick}
        handleRemoveFilter={handleRemoveFilter}
        handleSearch={handleSearch}
        rowKey="id"
        setExpanded={setListExpanded}
        setSelected={handleSelectItem}
        title="Listagem de Características"
        protectedCrudAttributes={{
          protectCreate: !attributeUserPermissionsMetadata?.create ?? true,
        }}
        selectedRows={selectedRows}
        deletedStatusProps={{
          onChangeDeletedStatus: (deleted) =>
            fetchData(0, { ...filters, deleted }),
          value: filters.deleted,
          disabled: !attributeUserPermissionsMetadata?.editDeleted,
        }}
      />
    );
  };

  const handleCreateNewClick = () => {
    setWriting(false);
    setSelected(undefined);
    setTimeout(() => setWriting(true));
  };

  const fetchItemById = useCallback((id: number) => {
    trackPromise(characteristicService.getById(id)).then((byId) => {
      setSelected(byId);
      setWriting(true);
    });
  }, []);

  const handleSelectItem = (value: CharacteristicType[]) => {
    setWriting(false);

    if (isMergeMode) {
      return setSelectedItems(value);
    }

    if (value && value.length === 1 && value[0].id) {
      fetchItemById(value[0].id);
      setTimeout(() => {
        setWriting(true);
      });
    }
    setSelectedRows(value.map((attr) => attr.id));
  };

  const handleSelectedTab = (value: TabItem) => {
    setSelectedTab(value);
  };

  const handleSave = (value: CharacteristicType) => {
    trackPromise(characteristicService.save(value))
      .then(() => {
        message.success(
          value.id
            ? "Característica atualizada com sucesso!"
            : "Característica cadastrada com sucesso!"
        );
        fetchData(0, filters);
      })
      .catch(() => getErrorMessage("Erro ao cadastrar característica."));
  };

  const getCharacteristicTab = () => {
    if (!isWriting) {
      return <EmptySelection />;
    }
    return (
      <CreateCharacteristics
        onSubmit={handleSave}
        onCancel={() => setWriting(false)}
        selected={selected}
        types={types}
      />
    );
  };

  const getAttributeTab = () => {
    if (!isWriting) {
      return <EmptySelection />;
    }
    return (
      <AttributeTab
        selected={selected}
        attributeUserPermissionsMetadata={attributeUserPermissionsMetadata}
      />
    );
  };

  const onRemoveMergeItem = (remainingItems: CharacteristicType[]) => {
    const newSelectedRows = selectedItems
      .map((item) => item.id)
      .filter((itemId) =>
        remainingItems.some((remainItem) => remainItem.id === itemId)
      );
    setSelectedRows(newSelectedRows);
  };

  const getCustomMergeConfirmAlertMessage = () => {
    return (
      <>
        Deseja confirmar a mesclagem? Todas as seguintes relações serão
        alteradas para o destino
        <Divider style={{ marginBottom: "-10px" }} />
        {["Produtos", "GTINs", "Linhas"].map((entityName) => (
          <>
            <br />
            {entityName}
          </>
        ))}
      </>
    );
  };

  const getCreateComponent = () => {
    if (isMergeMode) {
      return (
        <EntityMerge<CharacteristicType>
          data={selectedItems}
          getLabeledItem={(data) =>
            `${data.id} - ${data.description} (${typesById[data.type]})`
          }
          getItemId={(data) => data.id}
          onCancel={() => {
            setIsMergeMode(false);
            setSelectedRows([]);
            setSelectedItems([]);
          }}
          entityName="attribute"
          mergeItems={(mergeRequest: MergeRequest) =>
            characteristicService.merge(mergeRequest)
          }
          afterMerge={() => fetchData(0, filters)}
          onRemoveItem={onRemoveMergeItem}
          customConfirmAlertMessage={getCustomMergeConfirmAlertMessage()}
        />
      );
    }

    return (
      <>
        <CrudCreateComponent
          handleSelectedTab={handleSelectedTab}
          isCreateExpanded={isCreateExpanded}
          tabItems={tabItems}
          selectedTab={selectedTab}
          setCreateExpanded={setCreateExpanded}
        />
        {selectedTab.value === "characteristic" && getCharacteristicTab()}
        {selectedTab.value === "attributes" && getAttributeTab()}
      </>
    );
  };

  const handleAdvancedFilter = (newFilters: CharacteristicFilters) => {
    fetchData(0, newFilters);
    setAdvancedFiltersOpen(false);
  };

  return (
    <>
      <CrudContent
        searchComponent={getSearchComponent()}
        createComponent={getCreateComponent()}
        isCreateExpanded={isCreateExpanded}
        isListExpanded={isListExpanded}
      />
      <AdvancedFiltersModal
        status={advancedFiltersOpen}
        onOk={handleAdvancedFilter}
        onCancel={() => setAdvancedFiltersOpen(false)}
        types={types}
        filters={filters}
        fixedStatus={!attributeUserPermissionsMetadata?.editDeleted}
        parentName="attributes"
      />
    </>
  );
};
