import { Form, Select } from "antd";
import FilterIcon from "components/FilterIcon/FilterIcon";
import FilterLabel from "components/FilterLabel/FilterLabel";
import { debounce } from "lodash";
import DataClusterDetailAdvancedFilters, {
  DataClusterDetailFilter,
} from "pages/DataClusters/components/DataClusterDetailAdvancedFilters";
import { ProductFilters } from "pages/Products/components/ProductsAdvancedFilters";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import BookProduct from "shared/models/BookProduct";
import dataClusterService from "shared/services/DataClusterService";
import { scrollFunction } from "shared/utils/ScrollUtils";

interface SearchProductsSelectProps {
  hideMain: boolean;
  onChange: (bookProduct: BookProduct) => void;
  parentName: string;
}

const SearchBooksProductSelect = ({
  hideMain,
  onChange,
  parentName,
}: SearchProductsSelectProps) => {
  const [page, setPage] = useState(0);
  const [
    dataClusterDetailFilter,
    setDataClusterDetailFilter,
  ] = useState<DataClusterDetailFilter>({});
  const [products, setProdcts] = useState<BookProduct[]>([]);
  const [productFiltersOpen, setProductFiltersOpen] = useState(false);
  const [productOptionsOpen, setProductOptionsOpen] = useState(false);
  const [last, setLast] = useState(true);

  const fetchProducts = (page: number, filters: ProductFilters) => {
    trackPromise(
      dataClusterService.listBooksProducts(page, filters).then((response) => {
        let newProducts = [
          ...(page === 0 ? [] : products),
          ...response.content,
        ];
        setProdcts(newProducts);
        setProductOptionsOpen(true);
        setLast(response.last);
      })
    );
    setDataClusterDetailFilter(filters);
    setProductFiltersOpen(false);
    setPage(page);
  };

  const debounceSearchBooksProducts = debounce(
    (term: string) => fetchProducts(0, { ...dataClusterDetailFilter, term }),
    1000
  );

  const loadMore = () => {
    if (!last) {
      fetchProducts(page + 1, dataClusterDetailFilter);
    }
  };

  return (
    <Form.Item
      label={
        <>
          <FilterLabel text="Produto" htmlFor="search-products-select" />
          <FilterIcon
            filters={dataClusterDetailFilter}
            onClick={() => setProductFiltersOpen((f) => !f)}
          />
        </>
      }
      rules={[
        {
          required: true,
          message: "Favor informar produtos",
        },
      ]}
    >
      <DataClusterDetailAdvancedFilters
        hideMain={hideMain}
        parentName={parentName}
        onCancel={() => setProductFiltersOpen(false)}
        visible={productFiltersOpen}
        onOk={(filters) => {
          setProductFiltersOpen(false);
          fetchProducts(0, filters);
        }}
        dataClusterDetailFilter={dataClusterDetailFilter}
      />
      <Select<number>
        id="search-products-select"
        filterOption={false}
        allowClear
        options={products.map((product) => ({
          value: product.productId,
          label: product.description,
        }))}
        onChange={(value) => {
          let bookProduct = products.find((it) => it.productId === value);
          if (!!bookProduct) {
            onChange(bookProduct);
          }
        }}
        onSearch={debounceSearchBooksProducts}
        showSearch
        open={productOptionsOpen}
        onDropdownVisibleChange={(f) => setProductOptionsOpen(f)}
        onPopupScroll={(e) => scrollFunction(e, loadMore)}
      />
    </Form.Item>
  );
};

export default SearchBooksProductSelect;
