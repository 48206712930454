import { RouteComponentProps } from "@reach/router";
import { Card, Col, Divider, message, Row } from "antd";
import { DefaultContent, DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { BatchProcess } from "shared/models/BatchProcess";
import BatchProcessType from "shared/models/BatchProcessType";
import { batchProcessService } from "shared/services/BatchProcessService";
import { getInitialFilter } from "util/filter.util";
import { putObjectAsQueryParam } from "util/query.param.util";
import BatchProcessHistory from "./components/BatchProcessHistory";
import { MainUploadPage } from "./components/MainUploadPage";
import { MainUploadFilters } from "./components/MainUploadsAdvancedFilters";
import styles from "./Uploads.module.scss";

type UploadsProps = DefaultPage & RouteComponentProps;

const getInitialFilters = () =>
  getInitialFilter("filters", MainUploadFilters) as MainUploadFilters;

const Uploads = ({ title }: UploadsProps) => {
  const { setCurrentTitle } = useContext(PageContext);

  const [batchProcessTypes, setBatchProcessTypes] = useState<
    BatchProcessType[]
  >([]);
  const [listData, setListData] = useState<BatchProcess[]>([]);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(true);
  const [filters, setFilters] = useState<MainUploadFilters>(
    getInitialFilters()
  );
  const [loading, setLoading] = useState(false);

  const fetchBatchProcessData = useCallback(
    (
      newPage: number,
      newFilters: MainUploadFilters,
      batchProcessTypes: BatchProcessType[]
    ) => {
      if (!newFilters.batchProcessTypes?.length) {
        newFilters.batchProcessTypes = batchProcessTypes.map((type) => type.id);
      }

      if (newFilters.batchProcessTypes?.length) {
        setLoading(true);
        batchProcessService
          .getBatchProcess(newPage, newFilters)
          .then((result) => {
            setListData((oldValues) =>
              newPage === 0 ? result.content : [...oldValues, ...result.content]
            );
            setLastPage(result.last);
            setPage(newPage);
            setFilters(newFilters);
          })
          .catch((error) => message.error("Erro ao buscar importações.", error))
          .finally(() => setLoading(false));
      }
    },
    []
  );

  useEffect(() => setCurrentTitle(title), [setCurrentTitle, title]);

  useEffect(() => {
    trackPromise(batchProcessService.listTypesForUpload()).then((response) => {
      setBatchProcessTypes(response);
    });
  }, []);

  const fetchMoreData = () => {
    fetchBatchProcessData(page + 1, filters, batchProcessTypes);
  };

  useEffect(() => {
    putObjectAsQueryParam(filters, "filters");
  }, [filters]);

  const refreshHistory = useCallback(
    (newFilters: MainUploadFilters, batchProcessTypes: BatchProcessType[]) => {
      const FIRST_PAGE = 0;
      fetchBatchProcessData(FIRST_PAGE, newFilters, batchProcessTypes);
    },
    [fetchBatchProcessData]
  );

  return (
    <DefaultContent>
      <Row gutter={8}>
        <Col span={24} className={styles.cardColumn}>
          <Card bordered={false} className={styles.card}>
            <Card bordered={false} className={styles.cardList}>
              <MainUploadPage
                refreshHistory={refreshHistory}
                batchProcessTypes={batchProcessTypes}
              />
              <Divider />
              <BatchProcessHistory
                refreshHistory={refreshHistory}
                fetchMoreData={fetchMoreData}
                filters={filters}
                listData={listData}
                lastPage={lastPage}
                batchProcessTypes={batchProcessTypes}
                loading={loading}
              />
            </Card>
          </Card>
        </Col>
      </Row>
    </DefaultContent>
  );
};

export default Uploads;
