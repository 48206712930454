import { Button, Drawer, Empty, Input, Tooltip } from "antd";
import cx from "classnames";
import { Card } from "components/Card";
import FilterIcon from "components/FilterIcon/FilterIcon";
import { LoadMoreButton } from "components/LoadMoreButton/LoadMoreButton";
import { NewProductCard } from "components/NewProductCard/NewProductCard";
import { useProducts } from "hook/ProductsHook";
import { CreateBrand } from "pages/Brands/components/CreateBrand";
import { HierarchicalView } from "pages/HierarchicalView/HierarchicalView";
import { ManufacturerDetail } from "pages/Manufacturers/components/ManufacturerDetail";
import {
  ProductFilters,
  ProductsAdvancedFilters,
} from "pages/Products/components/ProductsAdvancedFilters";
import React, { ReactNode, useState } from "react";
import { ProductListItem } from "shared/models/Product";
import styles from "./SelectionCards.module.scss";

export interface SelectionCardsProps {
  expandedContent?: number;
  pageTitle: string | ReactNode;
  selectedCard?: ProductListItem;
  setExpanded: (value?: number) => void;
  setSelected: (value?: ProductListItem) => void;
  onAssociationClick: () => void;
}

export const SelectionCards = ({
  expandedContent,
  pageTitle,
  selectedCard,
  setExpanded,
  setSelected,
  onAssociationClick,
}: SelectionCardsProps) => {
  const {
    last,
    filters,
    products,
    fetchProducts,
    fetchMoreData,
  } = useProducts();

  const [term, setTerm] = useState("");

  const [isAdvancedFilterVisible, setAdvancedFilterVisible] = useState(false);
  const [
    isCreateProductDrawerVisible,
    setCreateProductDrawerVisible,
  ] = useState<string>();

  const handleSelected = (id: number) =>
    !!selectedCard && id === selectedCard?.productId;
  const handleExpanded = (id: number | null) => id === expandedContent;

  const expandedData = products.find(
    (item) => item.productId === expandedContent
  );
  const handleData =
    expandedContent && expandedData ? [expandedData] : products;
  const cardStyle = cx({ [styles.cardWrapper]: !expandedContent });

  const handleTabEmptyState = () => {
    return products && products.length === 0;
  };

  const getTabContent = () => {
    if (handleTabEmptyState()) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Nenhum dado filtrado"
        />
      );
    }
    return (
      <>
        {handleData.map((product) => (
          <NewProductCard
            key={product.productId}
            data={product}
            onSelect={setSelected}
            onExpand={setExpanded}
            isSelected={handleSelected(product.productId)}
            isExpanded={handleExpanded(product.productId)}
            onAssociationClick={onAssociationClick}
            fetchProductData={(page) => fetchProducts(page, filters)}
          />
        ))}
        {!expandedContent && (
          <LoadMoreButton last={last} onFetchMoreData={fetchMoreData} />
        )}
      </>
    );
  };

  const onAdvancedFilterClick = () => setAdvancedFilterVisible(true);
  const handleAdvancedFilterClose = () => setAdvancedFilterVisible(false);

  const handleAdvancedFilterConfirm = (newFilters: ProductFilters) => {
    setTerm(newFilters.term || "");
    fetchProducts(0, newFilters);
    setAdvancedFilterVisible(false);
  };

  const getDrawerDetails = () => {
    if (!isCreateProductDrawerVisible) {
      return { title: "", content: <></>, width: "40vw" };
    }
    switch (isCreateProductDrawerVisible) {
      case "F":
        return {
          title: "Fabricante / Cadastro",
          content: (
            <ManufacturerDetail
              onSubmit={() => setCreateProductDrawerVisible(undefined)}
              onCancel={() => setCreateProductDrawerVisible(undefined)}
              isWriting
            />
          ),
          width: "40vw",
        };
      case "M":
        return {
          title: "Marca / Cadastro",
          content: (
            <CreateBrand
              onSubmit={() => setCreateProductDrawerVisible(undefined)}
              onCancel={() => setCreateProductDrawerVisible(undefined)}
            />
          ),
          width: "40vw",
        };
      default:
        return {
          title: "Produto / Visualização Hierárquica",
          content: <HierarchicalView title={pageTitle} />,
          width: "90vw",
        };
    }
  };

  const getDrawer = () => {
    const details = getDrawerDetails();
    return (
      <Drawer
        bodyStyle={{ backgroundColor: "#f2f2f2" }}
        className={styles.createProductDrawer}
        onClose={() => setCreateProductDrawerVisible(undefined)}
        title={details.title}
        visible={!!isCreateProductDrawerVisible}
        width={details.width}
        zIndex={5}
      >
        {details.content}
      </Drawer>
    );
  };

  return (
    <>
      <ProductsAdvancedFilters
        originalFilters={filters}
        onClose={handleAdvancedFilterClose}
        onConfirm={handleAdvancedFilterConfirm}
        parentName="selection-cards"
        visible={isAdvancedFilterVisible}
        fixedStatus
      />

      {getDrawer()}
      <h2 className={styles.heading}>
        PRODUTO YANDEH
        <div className={styles.addButtons}>
          <Tooltip title="Cadastrar Fabricante" placement="bottom">
            <Button onClick={() => setCreateProductDrawerVisible("F")}>
              F
            </Button>
          </Tooltip>
          <Tooltip title="Cadastrar Marca" placement="bottom">
            <Button onClick={() => setCreateProductDrawerVisible("M")}>
              M
            </Button>
          </Tooltip>
          <Tooltip title="Cadastrar Produto" placement="bottom">
            <Button onClick={() => setCreateProductDrawerVisible("P")}>
              P
            </Button>
          </Tooltip>
        </div>
      </h2>
      <div className={styles.filterWrapper}>
        <div className={styles.searchInput}>
          <Input
            value={term}
            placeholder="Pesquisar"
            onChange={(evt) => setTerm(evt.target.value)}
            onPressEnter={() => fetchProducts(0, { ...filters, term })}
          />
        </div>
        <FilterIcon filters={filters} onClick={onAdvancedFilterClick} />
      </div>
      <Card className={cardStyle}>{getTabContent()}</Card>
    </>
  );
};
