import { RouteComponentProps } from "@reach/router";
import { Col, Input, message, Row } from "antd";
import { CrudContent } from "containers/CrudContent";
import { DefaultPage } from "containers/DefaultContent";
import { PageContext } from "containers/Main/Main.context";
import { SearchLineAndBrandSelect } from "containers/SearchLineAndBrandSelect/SearchLineAndBrandSelect";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ProductCreateEntityAttribute } from "shared/models/Characteristic";
import { IdName } from "shared/models/IdName";
import { ProductListItem } from "shared/models/Product";
import { productService } from "shared/services/ProductService";
import { CreateProductModal } from "./components/CreateProductModal/CreateProductModal";
import { Footer } from "./components/HierarchySection/Footer";
import { ProductLineAttributeSelector } from "./components/ProductLineAttributeSelector/ProductLineAttributeSelector";
import { RelatedProducts } from "./components/RelatedProducts/RelatedProducts";

import styles from "./HierarchicalView.module.scss";

type HierarchicalViewProps = DefaultPage & RouteComponentProps;

export interface AttributeValue {
  attribute: IdName;
  value?: IdName;
}

export const HierarchicalView = ({ title }: HierarchicalViewProps) => {
  const { setCurrentTitle } = useContext(PageContext);
  const [selectedLine, setSelectedLine] = useState<IdName>();
  const [selectedBrand, setSelectedBrand] = useState<IdName>();
  const [selectedSubBrand, setSelectedSubBrand] = useState<IdName>();
  const [
    selectedProductLineBrandId,
    setSelectedProductLineBrandId,
  ] = useState<number>();
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [relatedProducts, setRelatedProducts] = useState<ProductListItem[]>([]);
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(false);
  const [entityAttributes, setEntityAttributes] = useState<
    ProductCreateEntityAttribute[]
  >([]);

  useEffect(() => setCurrentTitle(title), [title, setCurrentTitle]);

  const fetchRelatedProductsData = useCallback(
    (
      page: number,
      productLineId?: number,
      brandId?: number,
      entityAttributeIds?: number[]
    ) => {
      if (!productLineId || !brandId) {
        return;
      }

      productService
        .getProducts(page, {
          productLineId,
          brandId,
          attributesId: entityAttributeIds,
        })
        .then((response) => {
          setPage(page);
          setLast(response.last);
          setRelatedProducts((oldProducts) => [
            ...(page > 0 ? oldProducts : []),
            ...response.content,
          ]);
        })
        .catch(() =>
          message.error("Erro ao buscar por Produtos relacionados.")
        );
    },
    []
  );

  const resetOptions = () => {
    setSelectedLine(undefined);
    setSelectedBrand(undefined);
    setSelectedSubBrand(undefined);
    setSelectedProductLineBrandId(undefined);
    setEntityAttributes([]);
    setRelatedProducts([]);
  };

  const createNewProduct = useCallback(
    (
      productLineBrandId: number,
      productLineId: number,
      brandId: number,
      attributeIds: number[],
      subBrandId?: number
    ) => {
      const body = {
        attributeIds,
        productLineBrandId,
        productStatus: "VENDA_INATIVA",
        subBrandId,
      };
      trackPromise(productService.createProduct(body))
        .then((response) => {
          fetchRelatedProductsData(0, productLineId, brandId);
          message.success(`Produto ${response.id} cadastrado com sucesso!`);
        })
        .catch(() => message.error("Erro ao cadastrar produto."))
        .finally(() => setModalStatus(false));
    },
    [fetchRelatedProductsData]
  );

  useEffect(() => {
    fetchRelatedProductsData(
      0,
      selectedLine?.id,
      selectedBrand?.id,
      entityAttributes
        .map((ea) => ea.entityAttributeId)
        .filter((ea) => !!ea) as number[]
    );
  }, [selectedLine, selectedBrand, entityAttributes, fetchRelatedProductsData]);

  const getSearchComponent = () => {
    return (
      <>
        <div>
          <div className={styles.header}>
            <h2>Cadastro de produtos</h2>
          </div>
          <Row>
            <Col xs={12}>
              <div style={{ padding: "10px" }}>
                <SearchLineAndBrandSelect
                  parentName="create-product"
                  selectedLine={selectedLine}
                  selectedBrand={selectedBrand}
                  setSelectedLine={setSelectedLine}
                  setSelectedBrand={setSelectedBrand}
                  setSelectedSubBrand={setSelectedSubBrand}
                  setProductLineBrandId={setSelectedProductLineBrandId}
                />
              </div>
            </Col>
            <Col xs={12}>
              <div style={{ padding: "10px" }}>
                <h4>Características primárias</h4>
                {selectedLine?.id ? (
                  <ProductLineAttributeSelector
                    productLineId={selectedLine?.id}
                    onChangeEntityAttributes={setEntityAttributes}
                  />
                ) : (
                  <Input
                    disabled
                    placeholder="Selecione uma linha para carregar as características"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>

        <Footer
          resetOptions={resetOptions}
          openModal={() => setModalStatus(true)}
          disableCreate={
            !selectedLine || !selectedBrand || !entityAttributes.length
          }
        />
        <CreateProductModal
          status={modalStatus}
          onCancel={() => setModalStatus(false)}
          onOk={() =>
            createNewProduct(
              selectedProductLineBrandId!,
              selectedLine!.id,
              selectedBrand!.id,
              entityAttributes.map((ea) => ea.entityAttributeId!),
              selectedSubBrand?.id
            )
          }
          lineData={selectedLine}
          brandData={selectedBrand}
          entityAttributes={entityAttributes}
        />
      </>
    );
  };

  return (
    <CrudContent
      searchComponent={getSearchComponent()}
      createComponent={
        <RelatedProducts
          fetchMoreData={() =>
            fetchRelatedProductsData(
              page + 1,
              selectedLine?.id,
              selectedBrand?.id,
              entityAttributes
                .map((ea) => ea.entityAttributeId)
                .filter((ea) => !!ea) as number[]
            )
          }
          isLast={last}
          relatedProductsData={relatedProducts}
        />
      }
      isCreateExpanded={false}
      isListExpanded={false}
      borderless
    />
  );
};
