import { Modal, Radio, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { ProductCard } from "components/ProductCard";
import SearchProductsSelect from "containers/SearchProductsSelect/SearchProductsSelect";
import { codeTag } from "pages/Combination/components/CodeTag";
import React, { useEffect, useState } from "react";
import {
  AssociationFields,
  ProductAssociation,
} from "shared/models/Association";
import { GtinCodeSimpleDetail } from "shared/models/GtinCode";
import { ProductListItem } from "shared/models/Product";
import { gtinCodeService } from "shared/services/GtinCodeService";
import styles from "./DisassociationAlertModal.module.scss";

interface DisassociationAlertModalProps {
  visible: boolean;
  handleCancelClick: () => void;
  target?: ProductListItem;
  selectedAssociations?: ProductAssociation[];
  onDisassociationClick: (
    reassociation?: AssociationFields,
    reason?: string
  ) => void;
}

const ModalTitle = () => (
  <div className={styles.modalHeader}>
    <Icons name="infoCircleFilled" />
    <h1>Confirmação</h1>
  </div>
);

const DisassociationAlertModal = ({
  visible,
  handleCancelClick,
  target,
  selectedAssociations,
  onDisassociationClick,
}: DisassociationAlertModalProps) => {
  const [selectedEntity, setSelectedEntity] = useState<number>();
  const [isReassociation, setReassociation] = useState(false);
  const [reason, setReason] = useState<string>();
  const [associationGtins, setAssociationGtins] = useState<
    GtinCodeSimpleDetail[]
  >();

  useEffect(() => {
    if (selectedAssociations) {
      setAssociationGtins(
        selectedAssociations
          .filter((association) => association.gtinCode)
          .map((association) => association.gtinCode!)
      );
    }
  }, [selectedAssociations]);

  useEffect(() => setSelectedEntity(undefined), [visible, isReassociation]);

  const createReassociation = (productId?: number) => {
    if (!isReassociation || !productId) {
      return undefined;
    }
    let reassociation: AssociationFields = { productId };
    return reassociation;
  };

  const handleSubmit = () => {
    onDisassociationClick(createReassociation(selectedEntity), reason);

    if (associationGtins?.length) {
      gtinCodeService.updateGtinPackingAttributes(associationGtins);
    }

    handleCancelClick();
    setReason(undefined);
    setReassociation(false);
  };

  const handleValueChange = (value: number | number[]) => {
    if (typeof value === "number") {
      setSelectedEntity(value);
    }
  };

  const getDrawerDetails = () => {
    return (
      <div className={styles.searchSelectWrapper}>
        <SearchProductsSelect
          parentName="disassociate"
          productIds={selectedEntity}
          onChange={handleValueChange}
          fixActive
        />
      </div>
    );
  };

  const associationsSkuDetailsTableColumns: ColumnsType<ProductAssociation> = [
    {
      title: "Código",
      render: (_: string, item: ProductAssociation) => codeTag(item),
    },
    {
      title: "Descrição",
      dataIndex: ["internalSku", "description"],
      render: (value: string, _: ProductAssociation) =>
        !!value && <p className={styles.itemDescription}>{value}</p>,
    },
  ];

  return (
    <Modal
      visible={visible}
      title={<ModalTitle />}
      className={styles.modalBody}
      onCancel={handleCancelClick}
      width={1000}
      onOk={handleSubmit}
      cancelText="Cancelar"
      okText={isReassociation ? "Reassociar" : "Desassociar"}
      destroyOnClose
      okButtonProps={{ disabled: isReassociation && !selectedEntity }}
    >
      <div className={styles.modalContent}>
        <p className={styles.modalTextContent}>
          Você está desassociando as capturas:
        </p>
        <Table
          dataSource={selectedAssociations}
          columns={associationsSkuDetailsTableColumns}
          size="small"
          scroll={{ x: true }}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
        <p className={styles.modalTextContent}>
          do item abaixo. Deseja realmente continuar?
        </p>
        <div className={styles.yandehProduct}>
          <ProductCard data={target} />
        </div>

        <div style={{ paddingBottom: 10 }}>
          <Input
            name="name"
            id="display-manufacturer-name"
            label="Motivo:"
            title="Motivo"
            maxLength={100}
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />
          <p className={styles.modalTextContent}>
            Gostaria de reassociar a outro item?
          </p>
          <Radio.Group value={isReassociation} style={{ marginTop: 10 }}>
            <Radio value={false} onClick={() => setReassociation(false)}>
              Nao
            </Radio>
            <Radio value={true} onClick={() => setReassociation(true)}>
              Sim
            </Radio>
          </Radio.Group>
        </div>
        {isReassociation && getDrawerDetails()}
      </div>
    </Modal>
  );
};

export default DisassociationAlertModal;
